import {
  Heading,
  InputBox,
  SearchAccount,
  SelectBox,
} from "../../../../../components";
import { useEffect, useRef, useState } from "react";
import { FcPlus } from "react-icons/fc";
import { FcCancel } from "react-icons/fc";
import { useAppDispatch } from "../../../../../store/hooks/redux-hooks";
import { object, string } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addFixedFeeInvoice } from "../../../../../store/slice/Accounting/OfficeAccounts/OfficePostings/FixedFeeInvoiceSlice";
import { toast } from "react-toastify";
import { FaPlus } from "react-icons/fa6";
import { AddOfficeFeeTypesDisbursement } from "../../AddOfficeFeeTypes&Disbursement";
import { SearchNominalLedger } from "../../../SearchNominalLedger";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { NumericFormat } from "react-number-format";

const AddFixedFeeInvoice = ({
  openModal,
  Close,
  isEdit,
  fixedFeeInvoice,
}: {
  openModal: boolean;
  Close: any;
  isEdit: boolean;
  fixedFeeInvoice: any;
}) => {
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [modalPlacement] = useState("center");
  const [isLoading, setIsLoading] = useState(false);
  const [setExistingFixedFeeInvoice] = useState(fixedFeeInvoice);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
 
  const [openDescriptionModal, setOpenDescriptionModal] = useState(false);
  const [openLedgerModal, setOpenLedgerModal] = useState(false);

  const [selectedValue, setSelectedValue] = useState<any>();
  const [selectedRow, setSelectedRow] = useState<number>(0);

  const [invoiceAmount, setInvoiceAmount] = useState(0);
  const [vatAmount, setVatAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);


  const [selectedInvoiceNote, setSelectedInvoiceNote] = useState('');
  const [selectedInvoiceNotice, setSelectedInvoiceNotice] = useState('');
  const [selectedInvoiceNoteTitle, setSelectedInvoiceNoteTitle] = useState('');
  const [selectedInvoiceNoticeTitle, setSelectedInvoiceNoticeTitle] = useState('');

  const [selectedNominalLedger, setSelectedNominalLedger] = useState<any>();

  const { invoiceNotices } = useSelector((state: RootState) => state.invoiceNotice);
  const [invoiceNoticeLists, setInvoiceNoticeLists] = useState<any[]>([]);

  const { branchUsers } = useSelector((state: RootState) => state.userManage);
  const [branchUserLists, setBranchUserLists] = useState<any[]>([]);

  const { officeVatRates } = useSelector((state: RootState) => state.officeVatRate);
  const [officeVatRateLists, setOfficeVatRateLists] = useState<any[]>([]);

  const [openFromBankAccountModal, setOpenFromBankAccountModal] = useState(false);
  const [fromBankAccount, setFromBankAccount] = useState<string>(""); // Display selected account type in input field
//  const [selectedFromAccountBalance, setSelectedFromAccountBalance] = useState<string>("");
 

  const handleSelect = (value: any) => {
    console.log(value);
    let description = "";
    let selectedId = null;
    if (value != null && value.selectedType === "feeType") {
      description = value.data.feeType;
      selectedId = value.data.feeTypeId;
    } else {
      description = value.data.disbursementType;
      selectedId = value.data.disbursementTypeId;
    }

    const updatedRows = [...rows];
    updatedRows[selectedRow].description = description;
    updatedRows[selectedRow].selectedTypeId = selectedId;
    //updatedRows[selectedRow].type = value.selectedType;
    setRows(updatedRows);
    setSelectedValue(value);
    setOpenDescriptionModal(false);
  };

  const [rows, setRows] = useState([
    {
      selectedTypeId: "",
      description: "",
      staff: "",
      type: "",
      amount: 0,
      vatRate: 0,
      vat: 0,
      total: 0,
    },
  ]);

  const addRow = () => {
    setRows([
      ...rows,
      {
        selectedTypeId: "",
        description: "",
        staff: "",
        type: "",
        amount: 0,
        vatRate: 0,
       vat: 0,
        total: 0,
      },
    ]);
  };

  useEffect(() => {
    const newTotalVat = rows.reduce((acc, row) => acc + (row.amount * row.vatRate) / 100, 0);
    const newTotalAmount = rows.reduce((acc, row) => acc + row.amount + (row.amount * row.vatRate) / 100, 0);
    const newInvoiceAmount = rows.reduce((acc, row) => acc + row.amount, 0);
  
    setInvoiceAmount(newInvoiceAmount);
    setVatAmount(newTotalVat);
    setTotalAmount(newTotalAmount);
  }, [rows]);

  const ItemDescribitionType = [
    { label: "Professional Fees", value: 1 },
    { label: "Disbursements", value: 2 },
  ];

  const mappedItemDescribitionType = ItemDescribitionType.map((type: any) => ({
    label: type.label,
    value: type.value,
  }));

  

  const validationSchema = object().shape({
    customerName: string(),
    customerDetails: string(),
    addressLine1: string(),
    addressLine2: string(),
    city: string(),
    postCode: string(),

    // itemDescription: string(),
    staff: string(),
    // type: string(),
    // amount: string(),
    // vatRate: string(),

    invoiceNotesTitle: string(),
    invoiceNotes: string(),
    invoiceNoticeTitle: string(),
    invoiceNotices: string(),

    invoiceAmount: string(),
    vAT: string(),
    totalAmount: string(),
    balanceDue: string(),

    toLedger: string(),
    notes: string(),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: any) => {
    const combinedData = {
      ...data, // Form validation schema values
     items: rows, // Table data
    };

    console.log(JSON.stringify(combinedData, null, 2));
    setIsLoading(true);
    try {
      await dispatch(addFixedFeeInvoice(combinedData)).unwrap();
      Close();
      toast.success("Successfully Added Fixed Fee Invoice");
    } catch (error: any) {
      toast.error(error.toString());
    } finally {
      setIsLoading(false);
    }
  };

  const onError = (errors: any) => {
    console.log("Form submission errors", errors);
  };

  useEffect(() => {
    if (isEdit && fixedFeeInvoice) {
      setExistingFixedFeeInvoice(fixedFeeInvoice);
      reset(fixedFeeInvoice);
    }
  }, [fixedFeeInvoice, isEdit, reset, setValue]);

  const toggleLedgerModal = () => {
    setOpenLedgerModal(!openLedgerModal);
  };

  const [isDisabled, setIsDisabled] = useState(true);

  const toggleDescriptionModal = (index: number) => {
    setSelectedRow(index);
    setOpenDescriptionModal(!openDescriptionModal);
  };

  const removeRow = (index: number) => {
    setRows(rows.filter((_, i) => i !== index));
  };

  // const handleSubmit = (e: React.FormEvent) => {
  //   e.preventDefault();
  //   console.log(rows);
  // };

  const handleInputChange = (index: number, field: string, value: any) => {
    const updatedRows = [...rows];
    updatedRows[index] = {
      ...updatedRows[index],
      [field]: value,
    };

    // Automatically calculate VAT and total for the row if amount or vATRate changes
  

    setRows(updatedRows);
  };

  const toggleFromBankAccountModal = () => {
    setOpenFromBankAccountModal(!openFromBankAccountModal);
  };

  const handleFromAccountSelect = (selectedAccountType: any,) => {
    setFromBankAccount(selectedAccountType.accountName); // Set the selected account type to display in input field
    console.log(selectedAccountType.accountId);
    setValue('toLedger', selectedAccountType.accountId);

    // if (selectedAccountType){
    //   setSelectedFromAccountBalance(selectedAccountType.runningBalance);
    //   setValue("fromBankAvailableBalance",selectedAccountType.runningBalance);
    //  }
    // console.log(selectedAccountType.accountId);
  //  console.log(selectedAccountType.runningBalance);
    setOpenFromBankAccountModal(false);
  
  };

  useEffect(() => {
    if (invoiceNotices.length > 0) {
      console.log(invoiceNotices);
      let data = invoiceNotices.map((invoiceNotice: any, index) => ({
        value: invoiceNotice,
        label: `${invoiceNotice.title}`,
      }));
      setInvoiceNoticeLists(data);
    }
  }, [invoiceNotices]);

  useEffect(() => {
    if (branchUsers.length > 0) {
      console.log(branchUsers);
      let data = branchUsers.map((branchUser: any, index) => ({
        value: branchUser.userId,
        label: `${branchUser.firstName} ${branchUser.lastName} `,
      }));
      setBranchUserLists(data);
    }
  }, [branchUsers]);

  useEffect(() => {
    if (officeVatRates.length > 0) {
      console.log(officeVatRates);
      let data = officeVatRates.map((officeVatRate: any, index) => ({
        value: officeVatRate,
        label: `${officeVatRate.vatType}`,
        vatPercentage: officeVatRate.percentage,
      
      }));
      setOfficeVatRateLists(data);
     
    }
  }, [officeVatRates]);

  const handleVatRateChange = (index: number, selectedOption: any) => {
    const updatedRows = [...rows];
    if (selectedOption) { 
     updatedRows[index] = {
      ...updatedRows[index],
      vatRate: selectedOption.percentage, // Use 'percentage' for VAT calculation
      selectedTypeId: selectedOption.vatTypeId, // Use 'vatTypeId' for selected type
    
    };
  }
    // Update the state with the new rows
    setRows(updatedRows);
  };


   const handleInvoiceNoteTitleChange = (selectedOption: any) => {
    setSelectedInvoiceNoteTitle(selectedOption.title)
    setValue("invoiceNotesTitle", selectedOption.invoiceNoticeId);
    if (selectedOption) {
      setSelectedInvoiceNote(selectedOption.invoiceNotice); // Populate textarea with the selected note
      setValue("invoiceNotes",selectedOption.invoiceNoticeId);
      
    } 
  };

  const handleInvoiceNoticeTitleChange = (selectedOption: any) => {
    setSelectedInvoiceNoticeTitle(selectedOption.title)
    setValue("invoiceNoticeTitle",selectedOption.invoiceNoticeId );
    if (selectedOption) {
      setSelectedInvoiceNotice(selectedOption.invoiceNotice); // Populate textarea with the selected note
      setValue("invoiceNotices",selectedOption.invoiceNoticeId);
      
    } 
  };


  return (
    <>
      {openModal !== false && (
        <form onSubmit={handleSubmit(onSubmit, onError)} className="mx-auto my-5">
          <div className="">
            <div className="flex justify-between p-10">
              <div className="flex items-start space-x-6">
                <div className="">
                  <img
                    src=""
                    alt="Image"
                    className="border border-gray-300 rounded-lg"
                  />
                  <div>
                    <div className="mt-5">
                      <input
                        type="text"
                        className={`focus:bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block w-full py-2.5 px-4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                        placeholder="Customer Name"
                        {...register("customerName")}
                      />
                    </div>
                    <div className="flex items-center space-x-4 mt-4">
                      <span className="">
                        <textarea
                          className={`focus:bg-gray-100 border-gray-300 border-solid h-32 p-4 rounded-sm placeholder:text-blue_gray-500 text-blue_gray-500 text-sm w-60 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                          placeholder=" Address Line 1
                      Address Line 2
                      City
                      Post Code"
                          {...register("customerDetails")}
                        ></textarea>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="space-y-5 my-5">
                <div className="text-right">
                  <p className="font-medium">Shan & Co Solicitors</p>
                  <p>189 Northolt Road, Harrow, Middlesex</p>
                  <p>HA2 0LY</p>
                </div>
                <div className="space-y-2">
                  <div className="flex items-center">
                    <label className="w-1/2 text-sm font-semibold dark:text-white-bg">
                      Phone
                    </label>
                    <span className="mx-2 text-sm font-semibold dark:text-white-bg">
                      :
                    </span>
                    <p className="w-1/2 text-sm dark:text-white-bg">
                      +44 (0) 2088647070
                    </p>
                  </div>
                  <div className="flex items-center">
                    <label className="w-1/2 text-sm font-semibold dark:text-white-bg">
                      Fax
                    </label>
                    <span className="mx-2 text-sm font-semibold dark:text-white-bg">
                      :
                    </span>
                    <p className="w-1/2 text-sm dark:text-white-bg">
                      +44 (0) 18 9922 5284
                    </p>
                  </div>
                  <div className="flex items-center">
                    <label className="w-1/2 text-sm font-semibold dark:text-white-bg">
                      Email
                    </label>
                    <span className="mx-2 text-sm font-semibold dark:text-white-bg">
                      :
                    </span>
                    <p className="w-1/2 text-sm dark:text-white-bg">
                      shan@laitlegal.com
                    </p>
                  </div>
                </div>
                <div className="space-y-2">
                  <p className="text-lg font-bold">Tax Invoice</p>
                  <div className="flex items-center">
                    <label className="w-1/2 text-sm font-semibold dark:text-white-bg">
                      VAT NO
                    </label>
                    <span className="mx-2 text-sm font-semibold dark:text-white-bg">
                      :
                    </span>
                    <p className="w-1/2 text-sm dark:text-white-bg">
                      210118825
                    </p>
                  </div>
                </div>
                <div className="space-y-2">
                  <div className="flex items-center">
                    <label className="w-1/2 text-sm font-semibold dark:text-white-bg">
                      Invoice
                    </label>
                    <span className="mx-2 text-sm font-semibold dark:text-white-bg">
                      :
                    </span>
                    <p className="w-1/2 text-sm dark:text-white-bg">38</p>
                  </div>
                  <div className="flex items-center">
                    <label className="w-1/2 text-sm font-semibold dark:text-white-bg">
                      Invoice Date
                    </label>
                    <span className="mx-2 text-sm font-semibold dark:text-white-bg">
                      :
                    </span>
                    <p className="w-1/2 text-sm dark:text-white-bg">
                      02-09-2024
                    </p>
                  </div>
                  <div className="flex items-center">
                    <label className="w-1/2 text-sm font-semibold dark:text-white-bg">
                      Due Date
                    </label>
                    <span className="mx-2 text-sm font-semibold dark:text-white-bg">
                      :
                    </span>
                    <p className="w-1/2 text-sm dark:text-white-bg">
                      02-09-2024
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="relative h-auto sm:rounded-lg mt-5">
              <table className="w-full text-xs text-black text-left rtl:text-right dark:text-gray-400">
                <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                  <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <th scope="col" className="px-6 py-3"></th>
                    <th scope="col" className="px-6 py-3">
                      Item Description
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Staff
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Type
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Amount
                    </th>
                    <th scope="col" className="px-6 py-3">
                      VAT Rate
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Vat
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rows.map((row, index) => (
                    <tr
                      key={index}
                      className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                    >
                      <td className="cursor-pointer px-6 py-3">
                        <button
                          type="button"
                          onClick={() => toggleDescriptionModal(index)}
                        >
                          <FcPlus />
                        </button>
                        {openDescriptionModal && (
                          <AddOfficeFeeTypesDisbursement
                            openDrawer={openDescriptionModal}
                            Close={() => toggleDescriptionModal(index)}
                            onFeeOrDisbursmentSelect={handleSelect}
                          />
                        )}
                      </td>
                      <td className="px-6 py-3">
                        <InputBox
                          dataType={"string"}
                          placeholder={"Enter Item name / Description"}
                          value={row.description}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "description",
                              e.target.value
                            )
                          }
                        />
                      </td>
                      <td className="px-6 py-3">
                        <SelectBox
                          className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          isMulti={false}
                          name="staff"
                          isSearchable={true}
                          placeholder="Select"
                          options={branchUserLists}
                          value={getValues().staff}
                          onChange={(selectedOption) =>
                            handleInputChange(index, "staff", selectedOption)
                          }
                        />
                      </td>
                      <td className="px-6 py-3">
                        <div className="">
                          <div className="mb-2"></div>
                          <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            isMulti={false}
                            name="type"
                            isSearchable={true}
                            options={mappedItemDescribitionType}
                            
                            placeholder="Select"
                            onChange={(selectedOption) =>
                              handleInputChange(index, "type", selectedOption)
                            }
                          />
                        </div>
                      </td>
                      <td className="px-6 py-3">
                      <td className="px-6 py-3">
                  <NumericFormat
                    value={row.amount}
                    className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                    placeholder="Amount"
                    decimalScale={2}
                    onValueChange={(values) => handleInputChange(index, 'amount', values.floatValue || 0)}
                  />
                </td>
              </td>
              <td className="px-6 py-3">
              <SelectBox
          placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
          indicator={
            <svg
              width="15"
              className="fill-current mr-2"
              height="15"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5 0 45.3l160 160z" />
            </svg>
          }
          isMulti={false}
          name={`vatRate-${index}`}
          isSearchable={true}
          options={officeVatRateLists}
          value={
            officeVatRateLists.find((option) => option.vatTypeId === row.selectedTypeId) || null
          }
          placeholder="Select VAT Type"
          shape="round"
          color="indigo_50"
          size="xs"
          onChange={(selectedOption) => handleVatRateChange(index, selectedOption)}
        />
        </td>
              <td className="px-6 py-3">
                  <NumericFormat
                    value={(row.amount * row.vatRate / 100).toFixed(2)}
                    className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                    displayType="text"
                    thousandSeparator={true}
                    prefix="£"
                  />
                </td>
                <td className="px-6 py-3">
                  <NumericFormat
                    value={(row.amount + (row.amount * row.vatRate / 100)).toFixed(2)}
                    className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                    displayType="text"
                    thousandSeparator={true}
                    prefix="£"
                  />
                </td>
                      <td className="px-6 py-3">
                        <button type="button" onClick={() => removeRow(index)}>
                          <FcCancel className="w-6 h-6" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="my-5">
              <button
                className="flex items-center bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
                type="button"
                onClick={addRow}
              >
                <FaPlus className="w-4 h-4 fill-white-bg" />
                <span className="text-white-bg mx-2">Add Line</span>
              </button>
            </div>
            <div className="w-1/2 ml-auto space-y-2 my-5 p-6">
              {" "}
              {/* Container aligned to the right */}
              <div className="flex justify-between items-center">
                <label className="text-sm font-semibold dark:text-white-bg">
                  Invoice Amount
                </label>
                <div className="flex items-center">
                  <span className="text-sm font-semibold dark:text-white-bg mx-1">
                    :
                  </span>
                  <p className="text-sm dark:text-white-bg text-right">£{invoiceAmount.toFixed(2)}</p>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <label className="text-sm font-semibold dark:text-white-bg">
                  VAT
                </label>
                <div className="flex items-center">
                  <span className="text-sm font-semibold dark:text-white-bg mx-1">
                    :
                  </span>
                  <p className="text-sm dark:text-white-bg text-right">£{vatAmount.toFixed(2)}</p>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <label className="text-sm font-semibold dark:text-white-bg">
                  Total Amount
                </label>
                <div className="flex items-center">
                  <span className="text-sm font-semibold dark:text-white-bg mx-1">
                    :
                  </span>
                  <p className="text-sm dark:text-white-bg text-right">£{totalAmount.toFixed(2)}</p>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <label className="text-sm font-bold dark:text-white-bg">
                  Balance Due
                </label>
                <div className="flex items-center">
                  <span className="text-sm font-bold dark:text-white-bg mx-1">
                    :
                  </span>
                  <p className="text-sm font-bold dark:text-white-bg text-right">
                  £{totalAmount.toFixed(2)}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-col w-full gap-3 my-5">
              <Heading title={"Invoice Notice"} />

              <div className="">
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
          Invoice Notes Title
        </label>
        <SelectBox
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
          indicator={
            <svg
              width="15"
              className="fill-current mr-2"
              height="15"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
            </svg>
          }
          isMulti={false}
          name="frameFiftySix"
          isSearchable={true}
          options={invoiceNoticeLists}
          value={selectedInvoiceNoteTitle}
          placeholder="Select"
          shape="round"
          color="indigo_50"
          size="xs"
          onChange={handleInvoiceNoteTitleChange} // Update the change handler
        />
      </div>
      <div className="px-2 space-y-2 overflow-y-auto dark:text-white-bg">
        <div className="relative">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
            Invoice Notes
          </label>
          <textarea
            className={`bg-gray-100 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white-bg`}
            placeholder="Invoice Notes"
            {...register("invoiceNotes")}
            value={selectedInvoiceNote} // Bind value to the textarea
            onChange={(e) => setSelectedInvoiceNote(e.target.value)} // Update the textarea on change
          ></textarea>
        </div>

                <div className="">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                    Invoice Notice Title
                  </label>
                  <SelectBox
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                    indicator={
                      <svg
                        width="15"
                        className="fill-current mr-2"
                        height="15"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                      </svg>
                    }
                    isMulti={false}
                    name="frameFiftySix"
                    isSearchable={true}
                    options={invoiceNoticeLists}
                    value={selectedInvoiceNoticeTitle}
                    placeholder="Select"
                    shape="round"
                    color="indigo_50"
                    size="xs"
                    onChange={handleInvoiceNoticeTitleChange}
                  />
                </div>
                <div className="relative">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                    Invoice Notice
                  </label>
                  <textarea
                    className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                    placeholder="Invoice Notice"
                    {...register("invoiceNotices")}
                    value={selectedInvoiceNotice} // Bind value to the textarea
                    onChange={(e) => setSelectedInvoiceNotice(e.target.value)}
                  ></textarea>
                </div>
              </div>

              <div className="">
                <div className="flex items-center space-x-4">
                  <label className="w-32 text-sm font-medium text-gray-900 dark:text-white-bg">
                    Add to Ledger
                  </label>
                  <input
                    type="text"
                    placeholder="To Ledger Search"
                    aria-label="Search"
                    className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md p-3 w-[25%] dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                      errors.toLedger ? "is-invalid" : ""
                    }`}
                    value={fromBankAccount}
                    {...register("toLedger")}
                  />
                  <button
                    onClick={toggleFromBankAccountModal}
                    className="z-[2] inline-block rounded-e border-2 border-primary px-6 pb-[6px] pt-2 text-xs font-medium leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-accent-300 hover:bg-primary-50/50 hover:text-primary-accent-300 focus:border-primary-600 focus:bg-primary-50/50 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:text-primary-500 bg-hover_bg text-white-bg border-hover_bg"
                    data-twe-ripple-init
                    data-twe-ripple-color="white"
                    type="button"
                  >
                    Search
                  </button>
                  {openFromBankAccountModal && (
                <SearchAccount
                openDrawer={openFromBankAccountModal}
                Close={toggleFromBankAccountModal}
                onAccountSelect={handleFromAccountSelect} type="Office" fkAccountTypeIds="2,8"// Pass the callback function to child
              />
            )}
                </div>
                <div className="font-medium mt-2 text-xs text-red-600">
                  {errors?.toLedger?.message?.toString()}
                </div>
                <div className="flex items-center space-x-4 mt-4">
                  <label className="w-32 text-sm font-medium text-gray-900 dark:text-white-bg">
                    Notes
                  </label>
                  <span className="w-[calc(25%+112px)]">
                    {" "}
                    {/* Reduced the width to half */}
                    <textarea
                      className={`bg-gray-100 border-2 border-gray-300 border-solid h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                      placeholder="Invoice Notice"
                      {...register("notes")}
                    ></textarea>
                  </span>
                </div>
              </div>

              <div className="flex col-span-2 gap-2 justify-end px-10">
                <button
                  type="button"
                  onClick={() => {
                    Close();
                  }}
                  className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export { AddFixedFeeInvoice };
