import { SearchInput } from "../Filters/SearchInput";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { FaEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { MdOutlineRefresh } from "react-icons/md";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { fetchAllFixedFeeInvoice } from "../../store/slice/Accounting/OfficeAccounts/OfficePostings/FixedFeeInvoiceSlice";
import { toast } from "react-toastify";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { AddFixedFeeInvoice } from "../Modal/AddOfficeAccounts/AddOfficePostings/AddFixedFeeInvoice";
import { Pagination } from "../Pagination";


function FixedFeeInvoice() {
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [fixedFeeInvoiceDatas, setFixedFeeInvoiceDatas] = useState<any>([]);
    const [fixedFeeInvoiceGetData, setFixedFeeInvoiceGetData] = useState<any[]>([]);
    const dispatch = useAppDispatch();
    const [initialLoading, setInitialLoading] = useState(true);
    const fixedFeeInvoiceData = useSelector((state: RootState) => state.fixedFeeInvoice.fixedFeeInvoiceData);
    const [totalPages, setTotalPages] = useState(0);
    const [currentFixedFeeInvoice, setCurrentFixedFeeInvoice] = useState<any[]>([]);
    const itemsPerPage = 10;
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [currentPage, setCurrentPage] = useState(1);



    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
            dispatch(fetchAllFixedFeeInvoice())
                .then(() => setInitialLoading(false))
                .catch(() => setInitialLoading(false));
            effectServiceCall.current = true;
        }
    }, [dispatch]);

    useEffect(() => {
        if (fixedFeeInvoiceData && fixedFeeInvoiceData.listingData) {
            setFixedFeeInvoiceGetData(fixedFeeInvoiceData.listingData);
        }
    }, [fixedFeeInvoiceData]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        if (fixedFeeInvoiceGetData != null && fixedFeeInvoiceGetData.length > 0) {
            const filteredByFixedFeeInvoice = filterFixedFeeInvoiceBySearchQuery();
            const indexOfLastFixedFeeInvoice = currentPage * itemsPerPage;
            const indexOfFirstFixedFeeInvoice = indexOfLastFixedFeeInvoice - itemsPerPage;
            const currentFixedFeeInvoices = filteredByFixedFeeInvoice.slice(indexOfFirstFixedFeeInvoice, indexOfLastFixedFeeInvoice);
            const totalPages = Math.ceil(fixedFeeInvoiceGetData.length / itemsPerPage);
            setTotalPages(totalPages)
            setCurrentFixedFeeInvoice(currentFixedFeeInvoices)
        }

    }, [fixedFeeInvoiceGetData])


    const filterFixedFeeInvoiceBySearchQuery = () => {

        return fixedFeeInvoiceGetData.filter((fixedFeeInvoice: any) =>
            fixedFeeInvoice?.accountName?.toLowerCase().includes(searchQuery.toLowerCase()));
    };

    const toggleDrawer = () => {
        setIsEdit(false);
        setOpenModal(!openModal);
    };

    if (openModal) {
        return (
            <AddFixedFeeInvoice openModal={openModal} Close={toggleDrawer} isEdit={isEdit} fixedFeeInvoice={fixedFeeInvoiceData} />
        )
    }
    return (
        <>
            <div className="">
                <div className="mb-5">
                    <SubHeaderTitle title="Fixed Fee Invoice" buttonName="Invoice" isAdd={true} onClickAdd={toggleDrawer} />
                </div>
                <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
                    <SearchInput title="Keyword" />
                    <div>
                        <div className="flex gap-4 md:gap-1 w-full">
                            <CheckBoxFilter title="Status" />
                        </div>
                    </div>
                </div>
                <div className="relative overflow-x-auto sm:rounded-lg">
                    <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                        {initialLoading ? <MainAnimation /> : ""}
                        <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <th scope="col" className="px-6 py-3">
                                    Date
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Invoicee (s)
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Invoice
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Total
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Status
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentFixedFeeInvoice.map((fixedFeeInvoice: any, index) => (
                                <tr key={index} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    <td className="px-6 py-3">
                                        {new Date(fixedFeeInvoice?.invoiceDate).toISOString().split('T')[0]}
                                    </td>
                                    <td className="px-6 py-3">
                                        {fixedFeeInvoice.invoiceeName}
                                    </td>
                                    <td className="px-6 py-3">
                                        <a href="" className="hover:text-blue-500">{fixedFeeInvoice.fileName}	</a>
                                    </td>
                                    <td className="text-right px-6 py-3">
                                        {fixedFeeInvoice.totalAmount}.00
                                    </td>
                                    <td className="px-6 py-3">
                                        {fixedFeeInvoice.status}
                                    </td>
                                    <td className="cursor-pointer px-6 py-3">
                                        <div className="flex gap-3 w-full">
                                            <FaEye className="fill-gray-500 w-6 h-6" />
                                            <FaEdit className="fill-blue-500 w-6 h-6" />
                                            <RiDeleteBin5Fill className="fill-red-600 w-6 h-6" />
                                            <MdOutlineRefresh className="fill-blue-800 w-6 h-6" />
                                        </div>
                                    </td>

                                </tr>
                            ))}
                        </tbody>

                    </table>
                    {currentFixedFeeInvoice.length === 0 && (
                        <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
                            <p className="text-red-800">No Fixed Fee Invoice Details found.</p>
                        </div>
                    )}
                </div>
                {currentFixedFeeInvoice.length > 0 && (
                    <div className="w-full flex flex-col pb-[100px]">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={(page) => setCurrentPage(page)}
                        />
                    </div>
                )}
            </div>
        </>
    )
}

export { FixedFeeInvoice };