import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import SuppliersBillEntryService from "../../../../../services/Accounting/OfficeAccount/Suppliers/SuppliersBillEntryService";
import { PaginationResponse } from "../../../../model/Pagination/paginationClass";

export interface SuppliersBillEntry{
    listingData: ListingData[];
    totalRecords: number;
}
export interface ListingData {
    billEntryId: number;
    billEntryNumber: number;
    vatNominalId: number;
    grossAmount: number;
    paymentTerm: number;
    vatAmount: number;
    details: string;
    accountBalance: string;
    netAmount: number;
    supplierId: number;
   // nominalIds: number[];
    billLines: OfficeSupplierBillEntryLine[];
    invoiceNumber: string;
    invoiceDate: Date;
    dueDate: Date;
    attachmentName: string;
    attachmentFile: string; // Byte array in C# is typically represented as Uint8Array in TypeScript
    
 


}
  
  // OfficeSupplierBillEntryLine.ts
  export interface OfficeSupplierBillEntryLine {
    accountId: number;
    description: string;
    netAmount: number;
    vatId: number;
    vat: number;
    vatAdjustment: number;
    grossAmount: number;

    
  }
  export interface SuppliersBillEntryById{
    supplierBillEntryId: number;
    billEntryNumber: number;
    fkTransactionId: number;
    fkSupplierId: number;
    invoiceNumber: string;
    temporaryInvoiceNumber: string;
    isTemporaryInvoice: boolean;
    paymentTerm: number;
    invoiceDate: string;
    dueDate: string;
    grossAmount: number;
    vat: number;
    netAmount: number;
    fkNominalGrossId: number;
    fkNominalVatId: number;
    fkNominalNetId: number;
    attachmentName: string;
    attachmentFile: string;
    details: string;
    isDeleted: boolean;
    supplierBillPaymentId: number;
    supplierCreditNoteId: number;
    accountNameGross: string | null;
    accountNameNet: string | null;
    accountNameVat: string | null;
    postDateTime: string;
    transactionDetails: string;
    supplierId: number;
    supplierName: string;
    servicesProducts: string;
    fkNominalAccountId: number;
    supplierVatNumber: string;
    supplierUtrn: string;
    supplierCompanyRegNumber: string;
    fkContactPersonTitleId: number;
    supplierIsActive: boolean;
    dateDifference: number;
    billEntryLines: BillEntryLine[];

  }

  interface BillEntryLine {
    lineId: number;
    accountId: number;
    lineOrder: number;
  }
// Define the state type for Checklist
type SuppliersBillEntryState = {
    suppliersBillEntryById: SuppliersBillEntryById;
    suppliersBillEntries: PaginationResponse<SuppliersBillEntry> | null;
    officeSupplierBillEntryLines: PaginationResponse<OfficeSupplierBillEntryLine> | null;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: SuppliersBillEntryState = {
    suppliersBillEntryById: null! , 
    suppliersBillEntries: null,
    officeSupplierBillEntryLines: null,
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllSuppliersBillEntry = createAsyncThunk(
    "fetchAllSuppliersBillEntry",
    async (data: any, thunkAPI) => {
        try {
            const response = await SuppliersBillEntryService.GetAllSuppliersBillEntry(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchAllSuppliersBillEntryId = createAsyncThunk(
    "fetchAllSuppliersBillEntryId",
    async (billentryId: any, thunkAPI) => {
        try {
            const response = await SuppliersBillEntryService.GetAllSuppliersBillEntryId(billentryId);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addSuppliersBillEntry = createAsyncThunk(
    "addSuppliersBillEntry",
    async (data: any, thunkAPI) => {
        try {
            const response = await SuppliersBillEntryService.PostSuppliersBillEntry(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateSuppliersBillEntry = createAsyncThunk(
    "updateSuppliersBillEntry",
    async ({ id, data }: { id: number, data: SuppliersBillEntry }, thunkAPI) => {
        try {
            const response = await SuppliersBillEntryService.PutSuppliersBillEntry(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteSuppliersBillEntry = createAsyncThunk(
    "deleteSuppliersBillEntry",
    async (id: number, thunkAPI) => {
        try {
            await SuppliersBillEntryService.deleteSuppliersBillEntry(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const SuppliersBillEntrySlice = createSlice({
    name: "suppliersBillEntry",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllSuppliersBillEntry.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllSuppliersBillEntry.fulfilled, (state, action: PayloadAction<PaginationResponse<SuppliersBillEntry>>) => {
                state.status = "idle";
                state.suppliersBillEntries = action.payload;
            })
            .addCase(fetchAllSuppliersBillEntry.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(fetchAllSuppliersBillEntryId.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllSuppliersBillEntryId.fulfilled, (state, action: PayloadAction<SuppliersBillEntryById>) => {
                state.status = "idle";
                state.suppliersBillEntryById = action.payload;
            })
            .addCase(fetchAllSuppliersBillEntryId.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addSuppliersBillEntry.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addSuppliersBillEntry.fulfilled, (state, action: PayloadAction<PaginationResponse<SuppliersBillEntry>>) => {
                state.status = "idle";
                //state.suppliersBillEntries.push(action.payload);
            })
            .addCase(addSuppliersBillEntry.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateSuppliersBillEntry.pending, (state) => {
                state.status = "loading";
            })
            // .addCase(updateSuppliersBillEntry.fulfilled, (state, action: PayloadAction<SuppliersBillEntry>) => {
            //     state.status = "idle";
            //     const index = state.suppliersBillEntries.findIndex(t => t.billEntryId === action.payload.billEntryId);
            //     if (index !== -1) {
            //         state.suppliersBillEntries[index] = action.payload;
            //     }
            // })
            .addCase(updateSuppliersBillEntry.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteSuppliersBillEntry.pending, (state) => {
                state.status = "loading";
            })
            // .addCase(deleteSuppliersBillEntry.fulfilled, (state, action: PayloadAction<number>) => {
            //     state.status = "idle";
            //     state.suppliersBillEntries = state.suppliersBillEntries.filter(t => t.billEntryId !== action.payload);
            // })
            .addCase(deleteSuppliersBillEntry.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default SuppliersBillEntrySlice.reducer;
