import { useEffect, useRef, useState } from "react";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SearchInput } from "../Filters/SearchInput";
import { AddTimeEntryUnitPrices } from "../Modal/AccountSetup/AddTimeEntryUnitPrices";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { toast } from "react-toastify";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { fetchAllTimeEntryUnitPrice } from "../../store/slice/Accounting/AccountSetup/TimeEntryUnitPriceSlice";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { Pagination } from "../Pagination";

function TimeEntryUnitPrices() {
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const dispatch = useAppDispatch();
    const [initialLoading, setInitialLoading] = useState(true);
    const { timeEntryUnitPrices, status, error } = useSelector((state: RootState) => state.timeEntryUnitPrice);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [timeEntryUnitPriceData, setTimeEntryUnitPriceData] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    // const filterTimeEntryUnitPriceBySearchQuery = () => {
    //     return timeEntryUnitPrices.filter((timeEntryUnitPrice: any) =>
    //         timeEntryUnitPrice?.unitPrice?.toString().toLowerCase().includes(searchQuery.toLowerCase())
    //     );
    // };

    // const filteredByTimeEntryUnitPrice = filterTimeEntryUnitPriceBySearchQuery();

    const indexOfLastTimeEntryUnitPrice = currentPage * itemsPerPage;
    const indexOfFirstTimeEntryUnitPrice = indexOfLastTimeEntryUnitPrice - itemsPerPage;
    const currentTimeEntryUnitPrice = timeEntryUnitPrices.slice(indexOfFirstTimeEntryUnitPrice, indexOfLastTimeEntryUnitPrice);


    // Calculate the total number of pages
    const totalPages = Math.ceil(timeEntryUnitPrices.length / itemsPerPage);

    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
            dispatch(fetchAllTimeEntryUnitPrice())
                .then(() => setInitialLoading(false))
                .catch(() => setInitialLoading(false));
            effectServiceCall.current = true;
        }

        if (status === 'failed' && !errorShownRef.current) {
            // toast.error(error);
            errorShownRef.current = true;
        }
    }, [dispatch, status, error]);

    if (initialLoading) {
        return <MainAnimation />;
    }

    const toggleDrawer = () => {
        setIsEdit(false);
        setOpenModal(!openModal);
    };




    return (
        <>
            <div className="">
                <div className="mb-5">
                    <SubHeaderTitle title="Time Entry Unit Prices" buttonName="Time Entry Unit Price" isAdd={true} onClickAdd={toggleDrawer} />
                    {(openModal) &&
                        <AddTimeEntryUnitPrices openDrawer={openModal} Close={toggleDrawer} isEdit={isEdit} timeEntryUnitPrice={timeEntryUnitPriceData} />
                    }
                    <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
                        <SearchInput title="Search TimeEntryUnitPrice" value={searchQuery} onChange={handleSearchChange} />
                        <div>
                            <div className="flex gap-4 md:gap-1 w-full">
                                <CheckBoxFilter title="Role" />
                            </div>
                        </div>
                    </div>
                    <div className="relative overflow-x-auto sm:rounded-lg my-5">
                        <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                            <thead className="text-sm bg-gray-50 dark:bg-gray-800 dark:text-gray-400">
                                <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    <th scope="col" className="px-6 py-3">
                                        Role
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Time Entry Type
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Unit Price (£)
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Action
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                {currentTimeEntryUnitPrice.map((timeEntryUnitPrice: any) => (
                                    <tr key={timeEntryUnitPrice.roleTimeEntryPriceId} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        <td className="px-6 py-3">
                                            {timeEntryUnitPrice.userRoleName}
                                        </td>
                                        <td className="px-6 py-3">
                                            {timeEntryUnitPrice.timeEntryType}
                                        </td>
                                        <td className="px-6 py-3">
                                            {timeEntryUnitPrice.unitPrice}
                                        </td>
                                        <td className="px-6 py-3 cursor-pointer">
                                            <div className="flex gap-3 w-full">
                                                <svg className="fill-blue-500 w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" /></svg>
                                                <svg className="fill-red-600 w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z" /></svg>
                                            </div>

                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        {currentTimeEntryUnitPrice.length === 0 && (
                            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
                                <p className="text-red-800">No TimeEntryUnitPrice found.</p>
                            </div>
                        )}
                    </div>
                    {currentTimeEntryUnitPrice.length > 0 && (
                        <div className="w-full flex flex-col pb-[100px]">
                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onPageChange={(page) => setCurrentPage(page)}
                            />
                        </div>
                    )}

                </div>
            </div>
        </>
    )
}

export { TimeEntryUnitPrices };