import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import TrialBalanceService from "../../../../../services/Accounting/OfficeAccount/OfficeReports/TrialBalanceService";





export interface TrialBalance {
    //creditNoteId: number;
   
};
// Define the state type for Checklist
type TrialBalanceState = {
    trialBalances: TrialBalance[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: TrialBalanceState = {
    trialBalances: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllTrialBalance = createAsyncThunk(
    "fetchAllTrialBalance",
    async (_, thunkAPI) => {
        try {
            const response = await TrialBalanceService.GetAllTrialBalance();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


// Slice definition
const TrialBalanceSlice = createSlice({
    name: "trialBalance",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllTrialBalance.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllTrialBalance.fulfilled, (state, action: PayloadAction<TrialBalance[]>) => {
                state.status = "idle";
                state.trialBalances = action.payload;
            })
            .addCase(fetchAllTrialBalance.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
           
    }
});

export default TrialBalanceSlice.reducer;
