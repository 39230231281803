import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object } from "yup";
import { useEffect, useRef, useState } from "react";
import { SelectBox } from "../../../../SelectBox";
import { useAppDispatch } from "../../../../../store/hooks/redux-hooks";
import { addClientToOffice } from "../../../../../store/slice/Accounting/ClientAccounts/ClientPosting/ClientToOfficeSlice";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { getValue } from "@testing-library/user-event/dist/utils";

function AddClientToOffice({
  openDrawer,
  Close,
  isEdit,
  clientToOffice,
}: {
  openDrawer: boolean;
  Close: any;
  isEdit: boolean;
  clientToOffice: any;
}) {
  const [title] = useState(
    !isEdit ? "Add Client to Office" : "Edit Client to Office"
  );
  const dispatch = useAppDispatch();
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [modalPlacement, setModalPlacement] = useState("center");
  const [isLoading, setIsLoading] = useState(false);
  const [existingClientToOffice, setExistingClientToOffice] =
    useState(clientToOffice);

  const { clientPaymentTypes } = useSelector(
    (state: RootState) => state.clientPaymentType
  );
  const [clientPaymentTypeLists, setclientPaymentTypeLists] = useState<any[]>(
    []
  );

  const { authourisedClients } = useSelector(
    (state: RootState) => state.authorisedClient
  );
  const [authorisedClientLists, setAuthorisedClientLists] = useState<any[]>([]);

  const { clientAccounts } = useSelector(
    (state: RootState) => state.clientAccount
  );
  const [clientAccountLists, setClientAccountLists] = useState<any[]>([]);

  const { officeBanks } = useSelector((state: RootState) => state.officeBank);
  const [officeBankLists, setOfficeBankLists] = useState<any[]>([]);

  useEffect(() => {
    if (clientPaymentTypes.length > 0) {
      let data = clientPaymentTypes.map((PaymentType: any, index) => ({
        value: PaymentType.paymentTypeId,
        label: PaymentType.paymentType,
      }));
      setclientPaymentTypeLists(data);
    }
  }, [clientPaymentTypes]);

  useEffect(() => {
    if (authourisedClients.length > 0) {
      let data = authourisedClients.map((authorisedClient: any, index) => ({
        value: authorisedClient.fkClientId,
        label: `${authorisedClient.firstName} -${authorisedClient.lastName}`,
      }));
      setAuthorisedClientLists(data);
    }
  }, [authourisedClients]);

  useEffect(() => {
    if (clientAccounts.length > 0) {
      let data = clientAccounts.map((clientAccount: any, index) => ({
        value: clientAccount.fkClientBankId,
        label: `${clientAccount.institution} - ${clientAccount.bankAccountType}`,
      }));
      setClientAccountLists(data);
    }
  }, [clientAccounts]);

  useEffect(() => {
    if (officeBanks.length > 0) {
      let data = officeBanks.map((officeBank: any, index) => ({
        value: officeBank.bankAccountId,
        label: `${officeBank.institution} - ${officeBank.bankAccountType}`,
      }));
      setOfficeBankLists(data);
    }
  }, [officeBanks]);

  const transferMethod = [
    { label: "Cheque", value: 1 },
    { label: "Electronic Transfer", value: 2 },
  ];

  const mappedTransferMethod = transferMethod.map((type: any) => ({
    label: type.label,
    value: type.value,
  }));

  const authorisedBy = [
    { label: "Cheque", value: 1 },
    { label: "Electronic Transfer", value: 2 },
  ];

  const mappedauthorisedBy = authorisedBy.map((type: any) => ({
    label: type.label,
    value: type.value,
  }));
  const handleEditClick = () => {
    setIsDisabled(!isDisabled);
  };

  const [isDisabled, setIsDisabled] = useState(true);

  const validationSchema = object().shape({
    fkClientBankId: string().required("Client Bank Account is required"),
    transactionDate: string().required("Transaction Date is required"),
    entryDate: string().required("Entry Date is required"),
    // caseReference: string()
    //     .required('Case Reference is required'),
    officeBank: string().required("Office Bank is required"),
    comments: string(),
    authorisedBy: string().required("Authorised By is required"),
    referenceNumber: string().required("Reference Number is required"),
    transferMethod: string().required("Transfer Method is required"),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: any) => {
    console.log(JSON.stringify(data, null, 2));
    setIsLoading(true);
    try {
      await dispatch(addClientToOffice(data)).unwrap();
      Close();
      toast.success("Successfully Added client to office details");
    } catch (error: any) {
      toast.error(error.toString());
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isEdit && clientToOffice) {
      setExistingClientToOffice(clientToOffice);
      reset(clientToOffice);
    }
  }, [clientToOffice, isEdit, reset, setValue]);
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="mx-auto">
        <div className="flex items-end justify-end">
          <button
            type="button"
            onClick={() => {
              Close();
            }}
            className="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-2 rounded"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="grid grid-cols-4 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 gap-y-1 w-full">
          <div className="">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
              Client Bank Account <span className="text-red-500">*</span>
            </label>
            <SelectBox
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
              indicator={
                <svg
                  width="15"
                  className="fill-current mr-2"
                  height="15"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                </svg>
              }
              isMulti={false}
              name="frameFiftySix"
              isSearchable={true}
              options={clientAccountLists}
              placeholder="Select"
              value={getValues().fkClientBankId}
              shape="round"
              color="indigo_50"
              size="xs"
              onChange={(selectedOption) => {
                if (selectedOption) {
                  setValue("fkClientBankId", selectedOption);
                }
              }}
            />
            <div className="font-medium mt-2 text-xs text-red-600">
              {errors?.fkClientBankId?.message?.toString()}
            </div>
          </div>
          <div className="">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
              Office Bank <span className="text-red-500">*</span>
            </label>
            <SelectBox
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
              indicator={
                <svg
                  width="15"
                  className="fill-current mr-2"
                  height="15"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                </svg>
              }
              isMulti={false}
              name="frameFiftySix"
              isSearchable={true}
              options={officeBankLists}
              value={getValues().officeBank}
              placeholder="Select"
              shape="round"
              color="indigo_50"
              size="xs"
              onChange={(selectedOption) => {
                if (selectedOption) {
                  setValue("officeBank", selectedOption);
                }
              }}
            />
            <div className="font-medium mt-2 text-xs text-red-600">
              {errors?.officeBank?.message?.toString()}
            </div>
          </div>
          <div className="relative">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
              Transaction Date <span className="text-red-500">*</span>
            </label>
            <input
              type="date"
              className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                errors.transactionDate ? "is-invalid" : ""
              }`}
              {...register("transactionDate")}
              placeholder="Enter Transaction Date"
            />
            <div className="font-medium mt-2 text-xs text-red-600">
              {errors?.transactionDate?.message?.toString()}
            </div>
          </div>

          <div className="relative">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
              Entry Date <span className="text-red-500">*</span>
            </label>
            <input
              type="date"
              className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                errors.entryDate ? "is-invalid" : ""
              }`}
              {...register("entryDate")}
              placeholder="Enter Date"
            />
            <div className="font-medium mt-2 text-xs text-red-600">
              {errors?.entryDate?.message?.toString()}
            </div>
          </div>
          <div className="">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
              Authorised By <span className="text-red-500">*</span>
            </label>
            <SelectBox
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
              indicator={
                <svg
                  width="15"
                  className="fill-current mr-2"
                  height="15"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                </svg>
              }
              isMulti={false}
              name="frameFiftySix"
              isSearchable={true}
              options={authorisedClientLists}
              value={getValues().authorisedBy}
              placeholder="Select"
              shape="round"
              color="indigo_50"
              size="xs"
              onChange={(selectedOption) => {
                if (selectedOption) {
                  setValue("authorisedBy", selectedOption);
                }
              }}
            />
            <div className="font-medium mt-2 text-xs text-red-600">
              {errors?.authorisedBy?.message?.toString()}
            </div>
          </div>
          <div className="">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
              Transfer Method <span className="text-red-500">*</span>
            </label>
            <SelectBox
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
              indicator={
                <svg
                  width="15"
                  className="fill-current mr-2"
                  height="15"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                </svg>
              }
              isMulti={false}
              name="frameFiftySix"
              isSearchable={true}
              options={mappedTransferMethod}
              placeholder="Select"
              shape="round"
              color="indigo_50"
              size="xs"
              onChange={(selectedOption) => {
                if (selectedOption) {
                  setValue("transferMethod", selectedOption);
                }
              }}
            />
            <div className="font-medium mt-2 text-xs text-red-600">
              {errors?.transferMethod?.message?.toString()}
            </div>
          </div>
          <div className="relative">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
              Reference Number <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                errors.referenceNumber ? "is-invalid" : ""
              }`}
              {...register("referenceNumber")}
              placeholder="Enter Reference Number"
            />
            <div className="font-medium mt-2 text-xs text-red-600">
              {errors?.referenceNumber?.message?.toString()}
            </div>
          </div>
          <div className="relative">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
              Comments
            </label>
            <textarea
              className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
              placeholder="Comments"
              {...register("comments")}
            ></textarea>
          </div>
          
        </div>
        <div className="flex md:flex-col gap-2 flex-row justify-start w-full my-5">
            <div className="flex flex-row gap-2 items-end justify-end w-full">
              <button
                type="button"
                onClick={() => {
                  reset();
                  Close();
                }}
                className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={isLoading}
                className={`cursor - pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[120px] ${
                  isLoading ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                {isLoading ? (
                  <svg
                    className="animate-spin h-5 w-5 text-white-bg mx-auto"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8v8H4z"
                    ></path>
                  </svg>
                ) : (
                  "Save"
                )}
              </button>
            </div>
        </div>
        <div className="relative overflow-x-auto sm:rounded-lg">
                    <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                        <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <th scope="col" className="px-6 py-3">
                                Reference
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Case Reference
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Details
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Amount
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                                <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    <td className="px-6 py-3">
                                        Reference
                                    </td>
                                    <td className="px-6 py-3">
                                        
                                    </td>
                                    <td className="px-6 py-3">
                                        
                                    </td>
                                    <td className="px-6 py-3">
                                        
                                    </td>
                                    <td className="cursor-pointer px-6 py-3">
                                        <div className="flex gap-3 w-full">
                                            {/* <FaEye className="fill-gray-500 w-6 h-6" />
                                            <MdOutlineRefresh className="fill-blue-800 w-6 h-6" /> */}
                                        </div>
                                    </td>

                                </tr>
                        </tbody>
                    </table>
                </div>
      </form>
    </>
  );
}

export { AddClientToOffice };
