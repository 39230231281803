import { array, object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useEffect, useRef, useState } from "react";
import { addVATExIncomeAccount, fetchAllVATExIncomeAccount } from "../../store/slice/Accounting/AccountSetup/VATExIncomeAccountSlice";
import { toast } from "react-toastify";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { fetchAllAccountSearchNominalAccount } from "../../store/slice/Accounting/OfficeAccounts/AccountSearchNominalAccountsSlice";

function VATExIncomeAccounts({ Close, isEdit, vATExIncomeAccount }: { Close: any, isEdit: boolean, vATExIncomeAccount: any }) {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [existingVATExIncomeAccount, setExistingFeeType] = useState(vATExIncomeAccount);
    const { vATExIncomeAccounts, status, error } = useSelector((state: RootState) => state.vATExIncomeAccount);
    const [vatIncomList, setVatIncomList] = useState<any[]>([]);
    const {accountSearchNominalAccounts }= useSelector((state: RootState) => state.accountSearchNominalAccount);
    const [accountLists, setAccountLists] = useState<any[]>([]);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedvatAccountIds, setSelectedvatAccountIds] = useState<string[]>([]);

    const validationSchema = object().shape({
        fkAccountId: array().of(string()),
    })

    const { register, handleSubmit, reset, setValue, } = useForm({
        resolver: yupResolver(validationSchema),

    });


    const effectServiceCall = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
            let data = {
                keyword: '',
                BankAccountType: 'Client',
                fkAccountTypeIds: null,
            }
            dispatch(fetchAllAccountSearchNominalAccount(data))
                .then(() => setIsLoading(false))
                .catch(() => setIsLoading(false));
                
            dispatch(fetchAllVATExIncomeAccount())
                .then(() => setIsLoading(false))
                .catch(() => setIsLoading(false));
            effectServiceCall.current = true;
        }
    }, [dispatch]);


    useEffect(()=>{
        if(vATExIncomeAccounts!=null && vATExIncomeAccounts.length > 0){
            //setVatIncomList(vATExIncomeAccounts);
            let ids = vATExIncomeAccounts.map((item: any) => {
                return item.fkAccountId;
            });
            setSelectedvatAccountIds(ids);
        }
    },[vATExIncomeAccounts])

    useEffect(() => {
        if (accountSearchNominalAccounts != null && accountSearchNominalAccounts.accounts.length > 0) {
            setAccountLists(accountSearchNominalAccounts.accounts);
        }
    }, [accountSearchNominalAccounts]);

        const handleSelectAll = () => {
            if (selectAll) {
                setSelectedvatAccountIds([]);
            } else {
            let allIds: string[] = [];
            allIds = accountLists.map((item: any) => {
                return item.accountId;
            });
            setSelectedvatAccountIds(allIds);
            }
            setSelectAll(!selectAll);
        };

    const onSubmit = async (data: any) => {
        if(selectedvatAccountIds.length == 0){
            return;
        }

        setIsLoading(true);
        try {
            let request = selectedvatAccountIds.map((item: any)=>{
                return {
                    VatExemptIncomeAccountId: 0,
                    fkAccountId:item
                }
            })
            await dispatch(addVATExIncomeAccount(request)).unwrap();
            toast.success("Successfully Added Income Accounts");
        } catch (error: any) {
            toast.error(error.toString());
        } finally {
            setIsLoading(false);
        }
        
    };

    const toggleSelection = (obj: any) => {
        const accountId = obj.accountId;
        let accountIds = [...selectedvatAccountIds];
    
        if (selectedvatAccountIds.includes(accountId)) {
            accountIds = accountIds.filter((id) => id !== accountId);
        } else {
            accountIds.push(accountId);
        }
        setSelectedvatAccountIds(accountIds);
      };
    


    useEffect(() => {
        if (isEdit && vATExIncomeAccount) {
            setExistingFeeType(vATExIncomeAccount);
            reset(vATExIncomeAccount);
        }
    }, [vATExIncomeAccount, isEdit, reset, setValue]);

    return (
        <>
            <div className="">
                <div className="mb-5">
                    <SubHeaderTitle title="VAT Ex Income Accounts" />
                </div>
                <form onSubmit={handleSubmit(onSubmit)} >
                    <div className="relative overflow-x-auto sm:rounded-lg my-5">

                        <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                            <thead className="text-sm bg-gray-50 dark:bg-gray-800 dark:text-gray-400">
                                <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    <th scope="col" className="px-6 py-3">
                                        Account Code
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Account Name
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        <input type="checkbox" value="" className={`w-4 h-4 text-blue-600 bg-gray-100 border-2 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600`} 
                                         checked={selectAll}
                                         onChange={handleSelectAll}/>
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                {accountLists.map((fkAccountId: any, index) => (
                                    <tr key={fkAccountId.accountCode} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        <td className="px-6 py-3">
                                            {fkAccountId.accountCode}
                                        </td>
                                        <td className="px-6 py-3 cursor-pointer">
                                            {fkAccountId.accountName}
                                        </td>
                                        <td className="px-6 py-3 cursor-pointer">
                                            <input type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" 
                                            //value={fkAccountId.accountId} {...register('fkAccountId')}
                                             onChange={() => toggleSelection(fkAccountId)} checked={selectedvatAccountIds.includes(fkAccountId.accountId)}
                                             placeholder="Enter Billing Description" />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
                        <div className="flex flex-row gap-2 items-end justify-end w-full">
                            <button
                                type='button'
                                onClick={() => {
                                    reset();
                                }}
                                className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                            >
                                Cancel
                            </button>
                            <button
                                type='submit'
                                className="bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </form>

            </div>
        </>
    )
}

export { VATExIncomeAccounts };