import { useEffect, useRef, useState } from "react";
import { SearchInput } from "../Filters/SearchInput";
import { FaEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { AddOfficeJournalEntry } from "../Modal/AddOfficeAccounts/AddOfficePostings/AddOfficeJournalEntry";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { fetchAllOfficeJournalEntry } from "../../store/slice/Accounting/OfficeAccounts/OfficePostings/OfficeJournalEntrySlice";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { Pagination } from "../Pagination";

function OfficeJournalEntry() {
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [officeJournalEntryData, setOfficeJournalEntryData] = useState<any>([]);
  const [officeJournalEntryGetData, setOfficeJournalEntryGetData] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const officeJournalEntrieData = useSelector((state: RootState) => state.officeJournalEntry.officeJournalEntrieData);
  const [totalPages, setTotalPages] = useState(0);
  const [currentOfficeJournalEntry, setCurrentOfficeJournalEntry] = useState<any[]>([]);
  const itemsPerPage = 10;
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);

  const effectServiceCall = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current) {
      dispatch(fetchAllOfficeJournalEntry())
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
    }
  }, [dispatch]);

  useEffect(() => {
    if (officeJournalEntrieData && officeJournalEntrieData.listingData) {
      setOfficeJournalEntryGetData(officeJournalEntrieData.listingData);
    }
  }, [officeJournalEntrieData]);

  const filterOfficeJournalEntryBySearchQuery = () => {
    return officeJournalEntryGetData.filter((officeJournalEntry: any) =>
      officeJournalEntry?.reference?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };
  useEffect(() => {
    if (officeJournalEntryGetData != null && officeJournalEntryGetData.length > 0) {
      console.log(officeJournalEntryGetData);
      const filteredByOfficeJournalEntry = filterOfficeJournalEntryBySearchQuery();
      const indexOfLastOfficeJournalEntry = currentPage * itemsPerPage;
      const indexOfFirstOfficeJournalEntry = indexOfLastOfficeJournalEntry - itemsPerPage;
      const currentOfficeJournalEntrys = filteredByOfficeJournalEntry.slice(indexOfFirstOfficeJournalEntry, indexOfLastOfficeJournalEntry);
      const totalPages = Math.ceil(officeJournalEntryGetData.length / itemsPerPage);
      setTotalPages(totalPages);
      setCurrentOfficeJournalEntry(currentOfficeJournalEntrys);
    }
  }, [officeJournalEntryGetData]);

  const toggleDrawer = () => {
    setOpenModal(!openModal);
  };

  return (
    <>
      <div className="">
        <div className="mb-5">
          <SubHeaderTitle
            title="Journal Entry"
            buttonName="Journal Entry"
            isAdd={true}
            onClickAdd={toggleDrawer}
          />
          {
            (openModal) &&
            <AddOfficeJournalEntry
              openDrawer={openModal}
              Close={toggleDrawer}
              isEdit={isEdit}
              officeJournalEntry={officeJournalEntryData}
            />
          }

        </div>
        <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
          <SearchInput title="Keyword" />
        </div>
        <div className="relative overflow-x-auto sm:rounded-lg">
          <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
            {initialLoading ? (<MainAnimation />) : ""}
            <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <th scope="col" className="px-6 py-3">
                  Journal Entry Id
                </th>
                <th scope="col" className="px-6 py-3">
                  Date
                </th>
                <th scope="col" className="px-6 py-3">
                  Number of Accounts
                </th>
                <th scope="col" className="px-6 py-3">
                  Total
                </th>
                <th scope="col" className="px-6 py-3">
                  Staff
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {currentOfficeJournalEntry.map((officeJournalEntry: any, index) => (
                <tr key={index} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  <td className="px-6 py-3"> JE{officeJournalEntry?.offJournalEntryId}</td>
                  <td className="px-6 py-3">{new Date(officeJournalEntry?.entryDate).toISOString().split('T')[0]}</td>
                  <td className="px-6 py-3">{officeJournalEntry?.totalEntries}</td>
                  <td className="px-6 py-3">£{officeJournalEntry?.total}</td>
                  <td className="px-6 py-3">{officeJournalEntry?.staff}</td>
                  <td className="cursor-pointer px-6 py-3">
                    <div className="flex gap-3 w-full">
                      <FaEye className="fill-gray-500 w-6 h-6" />
                      <FaEdit className="fill-blue-500 w-6 h-6" />
                      <RiDeleteBin5Fill className="fill-red-600 w-6 h-6" />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {currentOfficeJournalEntry.length === 0 && (
            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
              <p className="text-red-800">No Journal Entry found.</p>
            </div>
          )}
        </div>
        {currentOfficeJournalEntry.length > 0 && (
          <div className="w-full flex flex-col pb-[100px]">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        )}
      </div>

    </>
  );
}

export { OfficeJournalEntry };
