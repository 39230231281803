import { Modal } from 'flowbite-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object, number } from 'yup';
import { useEffect, useRef, useState } from 'react';
import { SelectBox } from '../../../../SelectBox';
import { useAppDispatch } from '../../../../../store/hooks/redux-hooks';
import { addFixedFeeInvoicePayment } from '../../../../../store/slice/Accounting/OfficeAccounts/OfficePostings/FixedFeeInvoicePaymentSlice';
import { toast } from 'react-toastify';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';


function AddFixedFeeInvoicePayment({ openDrawer, Close, isEdit, fixedFeeInvoicePayment }: { openDrawer: boolean, Close: any, isEdit: boolean, fixedFeeInvoicePayment: any }) {
    const [title] = useState((!isEdit ? 'Add Fixed Fee Invoice Payment' : 'Edit Fixed Fee Invoice Payment'))
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [modalPlacement] = useState('center');

    const [isLoading, setIsLoading] = useState(false);
    const [setExistingFixedFeeInvoicePayment] = useState(fixedFeeInvoicePayment);
    const dispatch = useAppDispatch();
    const { clientPaymentTypes } = useSelector((state: RootState) => state.clientPaymentType);
    const [clientPaymentTypeLists, setclientPaymentTypeLists] = useState<any[]>([]);

    const { officeBanks } = useSelector((state: RootState) => state.officeBank);
    const [officeBankLists, setOfficeBankLists] = useState<any[]>([]);

    const { invoiceNotices } = useSelector((state: RootState) => state.invoiceNotice);
    const [invoiceNoticeLists, setInvoiceNoticeLists] = useState<any[]>([]);



    useEffect(() => {
        if (clientPaymentTypes.length > 0) {
            // console.log(clientPaymentTypes);
            let data = clientPaymentTypes.map((PaymentType: any, index) => ({
                value: PaymentType.paymentTypeId,
                label: PaymentType.paymentType,
            }));
            setclientPaymentTypeLists(data);

        }
    }, [clientPaymentTypes]);

    useEffect(() => {
        if (officeBanks.length > 0) {
            let data = officeBanks.map((officeBank: any, index) => ({
                value: officeBank.bankAccountId,
                label: `${officeBank.institution} - ${officeBank.bankAccountType}`,
            }));
            setOfficeBankLists(data);
        }
    }, [officeBanks]);

    useEffect(() => {
        if (invoiceNotices.length > 0) {
            console.log(invoiceNotices);
            let data = invoiceNotices.map((invoiceNotice: any, index) => ({
                value: invoiceNotice.invoiceNoticeId,
                label: `${invoiceNotice.title}`
                ,
            }));
            setInvoiceNoticeLists(data);

        }
    }, [invoiceNotices]);





    const invoice = [
        { label: "NetWast office", value: 1 },

    ];

    const mappedInvoice = invoice.map((type: any) => ({
        label: type.label,
        value: type.value,
    }));




    const paymentType = [
        { label: "NetWast office", value: 1 },

    ];

    const mappedPaymentType = paymentType.map((type: any) => ({
        label: type.label,
        value: type.value,
    }));

    const validationSchema = object().shape({
        invoicePaymentDate: string()
            .required('Transaction Date is required')
            .transform((value, originalValue) => {
                if (originalValue === "" || value === null) return null;
                return new Date(value).toISOString().split('T')[0];
            }),
        PaymentType: number()
            .required('Payment Type is required'),
        officeBankAccountId: number()
            .required('Office Bank Account is required'),
        fixedFeeInvoiceId: number()
            .required('Invoice is required'),
        paymentReference: string(),
        invoiceNotes: string(),
        invoiceNotice: string(),
        paymentNotes: string(),
        invoiceNotesTitleId: number(),
        invoiceNoticeTitleId: number(),
        invoicePaymentAmount: string()
            .required('Transaction Amount is required')
    });

    const { register, handleSubmit, reset, setValue, getValues, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = async (data: any) => {
        console.log(JSON.stringify(data, null, 2));
        setIsLoading(true);
        try {
            await dispatch(addFixedFeeInvoicePayment(data)).unwrap();
            Close();
            toast.success("Successfully Added FixedFeeInvoicePayment");
        } catch (error: any) {
            toast.error(error.toString());
        } finally {
            setIsLoading(false);
        }
    };


    const onError = (errors: any) => {
        console.log("Form submission errors", errors);
    };


    useEffect(() => {
        if (isEdit && fixedFeeInvoicePayment) {
            setExistingFixedFeeInvoicePayment(fixedFeeInvoicePayment);
            reset(fixedFeeInvoicePayment);
        }
    }, [fixedFeeInvoicePayment, isEdit, reset, setValue]);


    return (
        <Modal
            className={`backdrop-blur-sm items-center justify-center`}
            show={openDrawer}
            position={modalPlacement}
            onClose={() => { reset(); Close(); }}
            initialFocus={emailInputRef}
            size="3xl"
        >
            <Modal.Header className="bg-white-bg dark:bg-dark_bg p-5">
                <span className="text-blue_gray-900 dark:text-white-bg text-xl w-auto">{title}</span>
            </Modal.Header>
            <Modal.Body className="bg-white-bg dark:bg-dark_bg">

                <form onSubmit={handleSubmit(onSubmit, onError)} className="mx-auto" >


                    <div className="grid grid-cols-3 md:grid-cols-1 gap-x-4 gap-y-1 w-full">
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Office Bank Account <span className="text-red-500">*</span>
                            </label>
                            <SelectBox
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                indicator={
                                    <svg
                                        width="15"
                                        className="fill-current mr-2"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                    </svg>
                                }
                                isMulti={false}
                                name="frameFiftySix"
                                isSearchable={true}
                                options={officeBankLists}
                                value={getValues().officeBankAccountId}
                                placeholder="Select"
                                shape="round"
                                color="indigo_50"
                                size="xs"
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setValue('officeBankAccountId', selectedOption);
                                    }
                                }}
                            />
                            <div className="font-medium mt-2 text-xs text-red-600" >{errors?.officeBankAccountId?.message?.toString()}</div>
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Invoice <span className="text-red-500">*</span>
                            </label>
                            <SelectBox
                                className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                indicator={
                                    <svg
                                        width="15"
                                        className="fill-current mr-2"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                    </svg>
                                }
                                isMulti={false}
                                name="frameFiftySix"
                                isSearchable={true}
                                options={mappedInvoice}
                                placeholder="Select"
                                shape="round"
                                color="indigo_50"
                                size="xs"
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setValue('fixedFeeInvoiceId', selectedOption);
                                    }
                                }}
                            />
                            <div className="font-medium mt-2 text-xs text-red-600" >{errors?.fixedFeeInvoiceId?.message?.toString()}</div>
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Invoice Notes Title <span className="text-red-500">*</span>
                            </label>
                            <SelectBox
                                className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                indicator={
                                    <svg
                                        width="15"
                                        className="fill-current mr-2"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                    </svg>
                                }
                                isMulti={false}
                                name="frameFiftySix"
                                isSearchable={true}
                                options={invoiceNoticeLists}
                                value={getValues().invoiceNotesTitleId}
                                placeholder="Select"
                                shape="round"
                                color="indigo_50"
                                size="xs"
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setValue('invoiceNotesTitleId', selectedOption);
                                    }
                                }}
                            />
                            <div className="font-medium mt-2 text-xs text-red-600" >{errors?.invoiceNotesTitleId?.message?.toString()}</div>
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Invoice Notice Title <span className="text-red-500">*</span>
                            </label>
                            <SelectBox
                                className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                indicator={
                                    <svg
                                        width="15"
                                        className="fill-current mr-2"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                    </svg>
                                }
                                isMulti={false}
                                name="frameFiftySix"
                                isSearchable={true}
                                options={invoiceNoticeLists}
                                value={getValues().invoiceNoticeTitleId}
                                placeholder="Select"
                                shape="round"
                                color="indigo_50"
                                size="xs"
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setValue("invoiceNoticeTitleId", selectedOption); // Set the selected option
                                        setValue("invoiceNotes", selectedOption.notes); // Set the corresponding notes
                                    }
                                }}
                            />
                            <div className="font-medium mt-2 text-xs text-red-600" >{errors?.invoiceNoticeTitleId?.message?.toString()}</div>
                        </div>
                        <div className="relative">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Transaction Date <span className="text-red-500">*</span>
                            </label>
                            <input type="date" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.invoicePaymentDate ? 'is-invalid' : ''}`} {...register('invoicePaymentDate')}
                                max={new Date().toISOString().split('T')[0]}
                                placeholder="Enter Transaction Date"
                            />
                            <div className="font-medium mt-2 text-xs text-red-600">{errors?.invoicePaymentDate?.message?.toString()}</div>
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Payment Type <span className="text-red-500">*</span>
                            </label>
                            <SelectBox
                                className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                indicator={
                                    <svg
                                        width="15"
                                        className="fill-current mr-2"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                    </svg>
                                }
                                isMulti={false}
                                name="frameFiftySix"
                                isSearchable={true}
                                options={clientPaymentTypeLists}
                                value={getValues().PaymentType}
                                placeholder="Select"
                                shape="round"
                                color="indigo_50"
                                size="xs"
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setValue('PaymentType', selectedOption);
                                    }
                                }}
                            />
                            <div className="font-medium mt-2 text-xs text-red-600" >{errors?.PaymentType?.message?.toString()}</div>
                        </div>
                        <div className="relative">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Transaction Amount <span className="text-red-500">*</span>
                            </label>
                            <NumericFormat decimalScale={2} className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.invoicePaymentAmount ? 'is-invalid' : ''} `} {...register('invoicePaymentAmount')}
                                placeholder="Enter Transaction Amount"
                                onValueChange={(values) => {
                                    setValue('invoicePaymentAmount', values.value)
                                }}
                            />
                            <div className="font-medium mt-2 text-xs text-red-600">{errors?.invoicePaymentAmount?.message?.toString()}</div>
                        </div>
                        <div className="relative">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Payment Reference
                            </label>
                            <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('paymentReference')}
                                placeholder="Enter Payment Reference"
                            />
                        </div>
                        <div className="relative col-span-1 col-start-1">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Invoice Notes
                            </label>
                            <textarea
                                className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                placeholder="Invoice Notes" {...register('invoiceNotes')}
                            ></textarea>
                        </div>
                        <div className="relative col-span-1">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Invoice Notice
                            </label>
                            <textarea
                                className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                placeholder="Invoice Notice" {...register('invoiceNotice')}
                            ></textarea>
                        </div>
                        <div className="relative col-span-1">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Notes
                            </label>
                            <textarea
                                className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                placeholder="Notes" {...register('paymentNotes')}
                            ></textarea>
                        </div>
                    </div>
                    <div className="flex col-span-2 gap-2 justify-end my-5">
                        <button
                            type="button"
                            onClick={() => {
                                Close();
                                reset();
                            }}
                            className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            disabled={isLoading}
                            className={` cursor - pointer bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]  ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        >
                            {isLoading ? (
                                <svg className="animate-spin h-5 w-5 text-white-bg mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                                </svg>
                            ) : (
                                'Save'
                            )}
                        </button>
                    </div>

                </form>

            </Modal.Body>
        </Modal>
    );
}

export { AddFixedFeeInvoicePayment };
