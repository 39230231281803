import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import ChecklistService from "../../../services/SystemMaintenance/ChecklistService";


export interface Checklist {
    checklistId: number;
    checklistName: string;
    fkAreasOfPracticeId: number;
    fkCaseTypeId: number;
    fkCaseSubTypeId: number;
    checklistIsActive: boolean;
};
// Define the state type for Checklist
type ChecklistState = {
    checklists: Checklist[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: ChecklistState = {
    checklists: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllCheckList = createAsyncThunk(
    "fetchAllCheckList",
    async (_, thunkAPI) => {
        try {
            const response = await ChecklistService.GetAllCheckList();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addCheckList = createAsyncThunk(
    "addCheckList",
    async (data: any, thunkAPI) => {
        try {
            const response = await ChecklistService.PostCheckList(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateCheckList = createAsyncThunk(
    "updateCheckList",
    async ({ id, data }: { id: number, data: Checklist }, thunkAPI) => {
        try {
            const response = await ChecklistService.PutCheckList(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteCheckList = createAsyncThunk(
    "deleteCheckList",
    async (ChecklistId: number, thunkAPI) => {
        try {
            await ChecklistService.deleteCheckList(ChecklistId);
            return ChecklistId;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const ChecklistSlice = createSlice({
    name: "checklist",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllCheckList.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllCheckList.fulfilled, (state, action: PayloadAction<Checklist[]>) => {
                state.status = "idle";
                state.checklists = action.payload;
            })
            .addCase(fetchAllCheckList.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addCheckList.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addCheckList.fulfilled, (state, action: PayloadAction<Checklist>) => {
                state.status = "idle";
                state.checklists.push(action.payload);
            })
            .addCase(addCheckList.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateCheckList.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateCheckList.fulfilled, (state, action: PayloadAction<Checklist>) => {
                state.status = "idle";
                const index = state.checklists.findIndex(t => t.checklistId === action.payload.checklistId);
                if (index !== -1) {
                    state.checklists[index] = action.payload;
                }
            })
            .addCase(updateCheckList.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteCheckList.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteCheckList.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.checklists = state.checklists.filter(t => t.checklistId !== action.payload);
            })
            .addCase(deleteCheckList.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default ChecklistSlice.reducer;
