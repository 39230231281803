import { useEffect, useRef, useState } from "react";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SearchInput } from "../Filters/SearchInput";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { fetchAllAuditTrail } from "../../store/slice/Accounting/OfficeAccounts/OfficeReports/AuditTrailSlice";
import { toast } from "react-toastify";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { Pagination } from "flowbite-react";
function AuditTrail() {
    const [isEdit, setIsEdit] = useState(false);
    const [auditTrailData, setAuditTrailData] = useState<any>([]);
    const dispatch = useAppDispatch();
    const [initialLoading, setInitialLoading] = useState(true);
    const { auditTrails, status, error } = useSelector((state: RootState) => state.auditTrail);
    const [totalPages, setTotalPages] = useState(0);
    const [currentAuditTrail, setCurrentAuditTrail] = useState<any[]>([]);
    const itemsPerPage = 10;
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [currentPage, setCurrentPage] = useState(1);



    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
             dispatch(fetchAllAuditTrail())
                .then(() => setInitialLoading(false))
                .catch(() => setInitialLoading(false));
            effectServiceCall.current = true;
        }

        if (status === 'failed' && !errorShownRef.current) {
            toast.error(error);
            errorShownRef.current = true;
        }
    }, [dispatch, status, error]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        if(auditTrails!=null && auditTrails.length > 0){
            const filteredByAuditTrail = filterAuditTrailBySearchQuery();
            const indexOfLastAuditTrail = currentPage * itemsPerPage;
            const indexOfFirstAuditTrail = indexOfLastAuditTrail - itemsPerPage;
            const currentAuditTrail = filteredByAuditTrail.slice(indexOfFirstAuditTrail, indexOfLastAuditTrail);
            const totalPages = Math.ceil(auditTrails.length / itemsPerPage);
            setTotalPages(totalPages)
            setCurrentAuditTrail(currentAuditTrail)
        }

    },[auditTrails])


    const filterAuditTrailBySearchQuery = () => {

        return auditTrails.filter((auditTrail: any) =>    
            auditTrail?.accountName?.toLowerCase().includes(searchQuery.toLowerCase()));
    };

    return (
        <>
            <div className="">
                <div className="mb-5">
                    <SubHeaderTitle title="Audit Trail" />
                </div>
                <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
                    <SearchInput title="Keyword" />
                    <div>
                        <div className="flex gap-4 md:gap-1 w-full">
                            <CheckBoxFilter title="Transaction" />
                            <div className="w-px h-5 bg-gray-300"></div>
                            <CheckBoxFilter title="User" />
                        </div>
                    </div>
                </div>
                <div className="relative overflow-x-auto sm:rounded-lg">
                    <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                    {initialLoading ? <MainAnimation /> : ""}
                        <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <th scope="col" className="px-6 py-3">
                                Post Date
                                </th>
                                <th scope="col" className="px-6 py-3">
                                Trn. Date
                                </th>
                                <th scope="col" className="px-6 py-3">
                                Transaction
                                </th>
                                <th scope="col" className="px-6 py-3">
                                Type
                                </th>
                                <th scope="col" className="px-6 py-3">
                                Details
                                </th>
                                <th scope="col" className="text-right px-6 py-3">
                                Amount
                                </th>
                                <th scope="col" className="px-6 py-3">
                                user
                                </th>
                                <th scope="col" className="px-6 py-3">
                                
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        {currentAuditTrail.map((auditTrail: any, index) => (
                            <tr key={index} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <td className="px-6 py-3">
                                {auditTrail.postDate}
                                </td>
                                <td className="px-6 py-3">
                                {auditTrail.trnDate}
                                </td>
                                <td className="px-6 py-3">
                                {auditTrail.transaction}
                                </td>
                                <td className="px-6 py-3">
                                {auditTrail.type}
                                </td>
                                <td className="px-6 py-3">
                                {auditTrail.details}
                                </td>
                                <td className="text-right px-6 py-3">
                                {auditTrail.amount}
                                </td>
                                <td className="px-6 py-3">
                                {auditTrail.user}
                                </td>
                                <td className="cursor-pointer px-6 py-3">
                                    <button
                                        className="text-xs py-1 px-3 font-medium text-white-bg bg-green-500 rounded hover:bg-green-600">
                                        View
                                    </button>
                                </td>
                            </tr>
                        ))}
                           
                        </tbody>

                    </table>
                    {currentAuditTrail.length === 0 && (
                            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
                                <p className="text-red-800">No Audit Trail Details found.</p>
                            </div>
                        )}
                </div>
                {currentAuditTrail.length > 0 && (
                    <div className="w-full flex flex-col pb-[100px]">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={(page) => setCurrentPage(page)}
                        />
                    </div>
                )}
                </div>
            
        </>
    )
}

export { AuditTrail };