import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object, number, array } from "yup";
import { useEffect, useRef, useState } from "react";
import { addJournalEntry, fetchJournalEntryNumber, JournalEntry } from "../../../../../store/slice/Accounting/ClientAccounts/ClientPosting/JournalEntrySlice";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../../../../store/hooks/redux-hooks";
import { FaPlus } from "react-icons/fa6";
// import { SearchContact } from '../../../SearchContact';
import { SearchAccountType } from "../../../SearchAccountType";
import { RootState } from "../../../../../store/store";
import { useSelector } from "react-redux";

interface JournalLine {
  account: string;
  description: string;
  debitCurrency: number;
  creditCurrency: number;
}

function AddJournalEntry({
  openDrawer,
  Close,
  isEdit,
  journalEntry,
}: {
  openDrawer: boolean;
  Close: any;
  isEdit: boolean;
  journalEntry: any;
}) {
  const [title] = useState(
    !isEdit ? "Add Journal Entry" : "Edit Journal Entry"
  );
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [modalPlacement] = useState("center");
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [openAccountTypeModal, setOpenAccountTypeModal] = useState(false);
  const [existingJournalEntry, setExistingJournalEntry] = useState(journalEntry);
  const [openModals, setOpenModals] = useState<boolean[]>([]);
  const receiptNumbers = useSelector((state: RootState) => state.journalEntry.receiptNumber);
  const [autoReceiptNumber, setAutoReceiptNumber] = useState<number>();
  const [initialLoading, setInitialLoading] = useState(true);
  const [journalLines, setJournalLines] = useState<JournalLine[]>([]);

  const effectServiceCall = useRef(false);
  const errorShownRef = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current) {
      const fetchPromises = [
        dispatch(fetchJournalEntryNumber()),
      ];
      Promise.all(fetchPromises)
        .then(() => {
          setInitialLoading(false);
        })
        .catch((error) => {
          if (!errorShownRef.current) {
            toast.error("Error fetching data: " + error.message);
            errorShownRef.current = true;
          }
          setInitialLoading(false);
        });
      effectServiceCall.current = true;
    }
  }, [dispatch]);

  useEffect(() => {
    if (receiptNumbers !== null) {
      setValue('journalEntry', receiptNumbers);
      setAutoReceiptNumber(receiptNumbers);
    }
  })

  const handleEditClick = () => {
    setIsDisabled(!isDisabled);
  };

  const [isDisabled, setIsDisabled] = useState(true);

  const toggleAccountTypeModal = (index: number) => {
    setOpenModals((prev) => {
      const newModals = [...prev];
      newModals[index] = !newModals[index];
      return newModals;
    });
  };


  const handleAddStepClick = () => {

    //  if (validateRows()) {

    setJournalLines([...journalLines, { account: '', description: '', creditCurrency: 0, debitCurrency: 0 }]);
    //  }
  };

  const handleSaveClick = () => {
    var validateState = true;
    journalLines.forEach((journalLine) => {
      if (journalLine.account == "" || journalLine.debitCurrency == 0 || journalLine.creditCurrency == 0) {
        validateState = false;
      }
    })

  };

  const setFieldValue = (index: number, field: string, value: string) => {
    const updatedLines = journalLines.map((line, i) =>
      i === index ? { ...line, [field]: value } : line
    );
    setJournalLines(updatedLines);
  }



  const validationSchema = object().shape({
    journalEntry: number(),
    date: string(),
    description: string(),
    journalLine: array().of(
      object().shape({
        account: string().required('Account is required'),
        description: string().required('Description is required'),
        debitCurrency: number().required('Debit Currency is required').typeError('Debit Currency must be a number'),
        creditCurrency: number().required('Credit Currency is required').typeError('Credit Currency must be a number'),
      })
    ).min(1, 'At least one journal line is required')
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors: formErrors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      journalEntry: autoReceiptNumber
    }
  });

  const onSubmit = async (data: any) => {

    const journalEntry: JournalEntry = {
      journalEntry: data.journalEntry,
      date: data.date,
      description: data.description,
      journalLine: journalLines.map(journal => ({
        account: journal.account,
        description: journal.description,
        debitCurrency: journal.debitCurrency,
        creditCurrency: journal.creditCurrency,
      })),
    };

    console.log(journalEntry);

    setIsLoading(true);
    try {
      await dispatch(addJournalEntry(journalEntry)).unwrap();
      Close();
      toast.success("Successfully Added journalEntry details");
    } catch (error: any) {
      toast.error(error.toString());
    } finally {
      setIsLoading(false);
    }
  };
  const onError = (errors: any) => {
    console.log("Form submission errors", errors);
  };

  useEffect(() => {
    if (isEdit && journalEntry) {
      setExistingJournalEntry(journalEntry);
      reset(journalEntry);
    }
  }, [journalEntry, isEdit, reset, setValue]);

  const handleAccountTypeSelection = (SelectedAccountType: any, index: number) => {
    setJournalLines((prev) =>
      prev.map((line, i) =>
        i === index
          ? {
            ...line,
            account: (SelectedAccountType.accountTypes == "bank") ? SelectedAccountType?.accountName + SelectedAccountType?.bankAccountCode : SelectedAccountType.caseReferenceAuto + SelectedAccountType.caseName,
            accountId: (SelectedAccountType.accountTypes == "bank") ? SelectedAccountType.bankAccountId : SelectedAccountType.caseId,
            isDisabled: false,
          }
          : line
      )
    );
    setOpenAccountTypeModal(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onError)} className="mx-auto">
        <div className="grid grid-cols-4 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-x-10 gap-y-1 w-full my-3">
          <div className="relative">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
              Journal Entry
            </label>
            <input
              type="text"
              className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
              value={autoReceiptNumber}
              // disable
              placeholder="Journal Entry Id"
            />
          </div>
          <div className="relative">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
              Date
            </label>
            <input
              type="date"
              className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
              {...register("date")}
              placeholder="Enter Date"
            />
          </div>
          <div className="relative">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
              Description
            </label>
            <input
              type="text"
              className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
              {...register("description")}
              placeholder="Enter Description"
            />
          </div>
        </div>
        <div className="mx-auto">

          <div className="">
            <button
              className="flex items-center bg-blue-500 text-white-bg px-3 py-1 rounded hover:bg-blue-600 transition duration-300"
              type="button"
              onClick={handleAddStepClick}
            >
              <FaPlus className="w-4 h-4 fill-white-bg" />
              <span className="text-white-bg mx-2">Add A Line</span>
            </button>
          </div>


          <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400 my-5">
            <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <th scope="col" className="px-6 py-3">Account</th>
                <th scope="col" className="px-6 py-3">Description</th>
                <th scope="col" className="px-6 py-3">Debit</th>
                <th scope="col" className="px-6 py-3">Credit</th>
              </tr>
            </thead>
            {journalLines.length > 0 &&
              journalLines.map((journalLine, index) => (
                <tbody key={index}>
                  <tr>
                    <td>
                      <input
                        type="text"
                        placeholder="account"
                        aria-label="Search"
                        value={journalLine.account}
                        onChange={(e) => setFieldValue(index, "account", e.target.value)}
                        className="border border-gray-300 p-2 rounded mb-2"
                        disabled={journalLine.account ? false : true}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={journalLine.description}
                        onChange={(e) => setFieldValue(index, "description", e.target.value)}
                        placeholder="description"
                        className="border border-gray-300 p-2 rounded mb-2"
                        disabled={!journalLine.account}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={journalLine.debitCurrency}
                        onChange={(e) => setFieldValue(index, "debitCurrency", e.target.value)}
                        placeholder="0.00"
                        className="border border-gray-300 p-2 rounded mb-2"
                        disabled={!journalLine.account}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={journalLine.creditCurrency}
                        onChange={(e) => setFieldValue(index, "creditCurrency", e.target.value)}
                        placeholder="0.00"
                        className="border border-gray-300 p-2 rounded mb-2"
                        disabled={!journalLine.account} // Disable if account is not set
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <button
                        type="button"
                        onClick={() => toggleAccountTypeModal(index)} // Pass index to identify which row
                        className="flex items-center bg-blue-500 text-white-bg px-3 py-1 rounded hover:bg-blue-600 transition duration-300 mb-2"
                      >
                        Add / Update
                      </button>
                      {openModals[index] && ( // Conditionally render the modal for this row
                        <SearchAccountType
                          openDrawer={openModals[index]}
                          Close={() => toggleAccountTypeModal(index)} // Close the modal for this row
                          onSelectAccountType={(selected) => handleAccountTypeSelection(selected, index)} // Pass index to handle account type selection
                          title="Search Accounts"
                          type="Client"
                        />
                      )}
                    </td>
                  </tr>
                </tbody>
              ))}
          </table>

        </div>
        <div className="flex col-span-2 gap-2 justify-end">
          <button
            type="button"
            onClick={() => {
              Close();
            }}
            className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
          >
            Cancel
          </button>
          <button
            type="submit"
            onClick={handleSaveClick}
            className="bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]"
          >
            {isLoading ? (
              <svg
                className="animate-spin h-5 w-5 text-white-bg mx-auto"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8v8H4z"
                ></path>
              </svg>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </form>


    </>
  );
}

export { AddJournalEntry };
