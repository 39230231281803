import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object, number } from "yup";
import { useEffect, useRef, useState } from "react";
import { SelectBox } from "../../../../SelectBox";
import { FaLock } from "react-icons/fa";
import { FaLockOpen } from "react-icons/fa";
import { SearchCaseReference } from "../../../SearchCaseReference";
import { addLedgerToLedger, fetchNextLedgerToLedgerNumber } from "../../../../../store/slice/Accounting/ClientAccounts/ClientPosting/LedgerToLedgerSlice";
import { useAppDispatch } from "../../../../../store/hooks/redux-hooks";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { NumericFormat } from "react-number-format";

function AddLedgerToLedger({
  openDrawer,
  Close,
  isEdit,
  ledgerToLedger,
}: {
  openDrawer: boolean;
  Close: any;
  isEdit: boolean;
  ledgerToLedger: any;
}) {

  const [title] = useState(!isEdit ? "Add Ledger to Ledger" : "Edit Ledger to Ledger");
  const dispatch = useAppDispatch();
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [modalPlacement, setModalPlacement] = useState("center");
  const [openFromCaseAccountModal, setOpenFromCaseAccountModal] = useState(false);
  const [openToCaseAccountModal, setOpenToCaseAccountModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [existingLedgerToLedger, setExistingLedgerToLedger] =
    useState(ledgerToLedger);
  const [selectedFromCaseReference, setSelectedFromCaseReference] = useState<any>();
  const [selectedToCaseReference, setSelectedToCaseReference] = useState<any>();
  const [openModal, setOpenModal] = useState(false);
  const { authourisedClients } = useSelector((state: RootState) => state.authorisedClient);
  const [authorisedClientLists, setAuthorisedClientLists] = useState<any[]>([]);
  const receiptNumbers = useSelector((state: RootState) => state.ledgerToLedger.receiptNumber);
  const [autoReceiptNumber, setAutoReceiptNumber] = useState<number>();
  const [initialLoading, setInitialLoading] = useState(true);

  const effectServiceCall = useRef(false);
  const errorShownRef = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current) {
      const fetchPromises = [
        dispatch(fetchNextLedgerToLedgerNumber()),
      ];
      Promise.all(fetchPromises)
        .then(() => {
          setInitialLoading(false);
        })
        .catch((error) => {
          if (!errorShownRef.current) {
            toast.error("Error fetching data: " + error.message);
            errorShownRef.current = true;
          }
          setInitialLoading(false);
        });
      effectServiceCall.current = true;
    }
  }, [dispatch]);

  useEffect(() => {
    if (receiptNumbers !== null) {
      setValue('ledgerToLedgerNumber', receiptNumbers);
      setAutoReceiptNumber(receiptNumbers);
    }
  })

  useEffect(() => {
    if (authourisedClients.length > 0) {
      let data = authourisedClients.map((authorisedClient: any, index) => ({
        value: authorisedClient.userId,
        label: `${authorisedClient.firstName} -${authorisedClient.lastName}`,
      }));
      setAuthorisedClientLists(data);

    }
  }, [authourisedClients]);

  const transferMethod = [
    { label: "Cheque", value: "Cheque" },
    { label: "Electronic Transfer", value: "Electronic Transfer" },
  ];

  const mappedTransferMethod = transferMethod.map((type: any) => ({
    label: type.label,
    value: type.value,
  }));

  const handleEditClick = () => {
    setIsDisabled(!isDisabled);
  };

  const [isDisabled, setIsDisabled] = useState(true);

  const validationSchema = object().shape({
    fkFromLedgerId: number(),
    // .required('From Ledger is required'),
    ledgerToLedgerNumber: number()
      .required('Reference number is required'),
    transactionDate: string().required('Transaction Date is required')
      .nullable()
      .transform((value, originalValue) => {
        if (originalValue === "" || value === null) return null;
        return new Date(value).toISOString().split('T')[0];
      }),
    entryDate: string().required('Entry Date is required')
      .nullable()
      .transform((value, originalValue) => {
        if (originalValue === "" || value === null) return null;
        return new Date(value).toISOString().split('T')[0];
      }),
    fkToLedgerId: number(),
    // .required('To Ledger is required'),
    transactionDescription: string().required(
      "Transaction Description is required"
    ),
    amount: string().required("Amount is required"),
    comments: string(),
    authorisedBy: string().required("Authorised By is required"),
    transferReference: string().required("Reference Number is required"),
    transferMethod: string().required("Transfer Method is required"),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      ledgerToLedgerNumber: autoReceiptNumber,
    }
  });

  const onSubmit = async (data: any) => {
    console.log(JSON.stringify(data, null, 2));
    setIsLoading(true);
    try {
      await dispatch(addLedgerToLedger(data)).unwrap();
      Close();
      toast.success("Successfully Added office to Client details");
    } catch (error: any) {
      toast.error(error.toString());
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isEdit && ledgerToLedger) {
      setExistingLedgerToLedger(ledgerToLedger);
      reset(ledgerToLedger);
    }
  }, [ledgerToLedger, isEdit, reset, setValue]);

  const toggleDrawer = () => {
    // setIsEdit(false);
    setOpenModal(!openModal);
  };

  const toggleToCaseAccountModal = () => {
    setOpenToCaseAccountModal(!openToCaseAccountModal);
  };

  const toggleFromCaseAccountModal = () => {
    setOpenFromCaseAccountModal(!openFromCaseAccountModal);
  };

  const handleFromSelection = (selectedCase: any) => {
    setSelectedFromCaseReference(selectedCase);
    console.log("from" + selectedCase.caseId);
    setValue("fkFromLedgerId", selectedCase.caseId);
    setOpenFromCaseAccountModal(false);
  };

  const handleToSelection = (selectedCase: any) => {
    setSelectedToCaseReference(selectedCase);
    console.log("to" + selectedCase.caseId);
    setValue("fkToLedgerId", selectedCase.caseId);
    setOpenToCaseAccountModal(false);
  };

  return (
    <>
      {openDrawer === true ? (
        <form onSubmit={handleSubmit(onSubmit)} className="mx-auto">
          <div className="flex flex-row md:flex-col justify-between my-3 gap-2 w-full md:gap-3">
            <div className="relative flex">
              <input
                type="text"
                placeholder={selectedFromCaseReference?.caseReferenceAuto}
                aria-label="Search"
                value={selectedFromCaseReference?.caseReferenceAuto || "From Ledger"}
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                disabled
              />
              <button
                onClick={toggleFromCaseAccountModal}
                className="z-[2] inline-block rounded-e border-2 border-primary px-6 pb-[6px] pt-2 text-xs font-medium  leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-accent-300 hover:bg-primary-50/50 hover:text-primary-accent-300 focus:border-primary-600 focus:bg-primary-50/50 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:text-primary-500 bg-hover_bg text-white-bg border-hover_bg -ml-1"
                data-twe-ripple-init
                data-twe-ripple-color="white"
                type="button"
              >
                Search
              </button>
              {openFromCaseAccountModal && (
                <SearchCaseReference
                  openDrawer={openFromCaseAccountModal}
                  Close={toggleFromCaseAccountModal}
                  onCaseSelect={handleFromSelection}
                />
              )}
            </div>

            <div className="relative flex">
              <input
                type="text"
                placeholder={selectedToCaseReference?.caseReferenceAuto}
                aria-label="Search"
                value={selectedToCaseReference?.caseReferenceAuto || "To Ledger"}
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                disabled
              />
              <button
                onClick={toggleToCaseAccountModal}
                className="z-[2] inline-block rounded-e border-2 border-primary px-6 pb-[6px] pt-2 text-xs font-medium  leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-accent-300 hover:bg-primary-50/50 hover:text-primary-accent-300 focus:border-primary-600 focus:bg-primary-50/50 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:text-primary-500 bg-hover_bg text-white-bg border-hover_bg -ml-1"
                data-twe-ripple-init
                data-twe-ripple-color="white"
                type="button"
              >
                Search
              </button>
              {openToCaseAccountModal && (
                <SearchCaseReference
                  openDrawer={openToCaseAccountModal}
                  Close={toggleToCaseAccountModal}
                  onCaseSelect={handleToSelection}
                />
              )}
            </div>

            <div>
              <button
                type="button"
                onClick={() => {
                  Close();
                }}
                className="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-2 rounded"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="grid grid-cols-4 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 gap-y-1 w-full">
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Reference
              </label>
              <div className="flex gap-3">
                <input
                  type="text"
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg disabled:bg-gray-300 dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.ledgerToLedgerNumber ? "is-invalid" : ""
                    } `}
                  value={autoReceiptNumber}
                  disabled={isDisabled}
                />
                <div className="font-medium mt-2 text-xs text-red-600">
                  {errors?.ledgerToLedgerNumber?.message?.toString()}
                </div>
                <button
                  type="button"
                  onClick={handleEditClick}
                  className="mt-2 text-sm font-medium text-blue-600 dark:text-blue-500"
                >
                  {isDisabled ? <FaLock /> : <FaLockOpen />}
                </button>
              </div>
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Available Balance
              </label>
              <NumericFormat decimalScale={2} className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                placeholder="Enter Available Balance"
              />
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Available Balance
              </label>
              <NumericFormat decimalScale={2} className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                placeholder="Enter Available Balance"
              />
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Transaction Date <span className="text-red-500">*</span>
              </label>
              <input
                type="date"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.transactionDate ? "is-invalid" : ""
                  }`}
                max={new Date().toISOString().split('T')[0]}
                {...register("transactionDate")}
                placeholder="Enter Transaction Date"
              />
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.transactionDate?.message?.toString()}
              </div>
            </div>

            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Entry Date <span className="text-red-500">*</span>
              </label>
              <input
                type="date"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.entryDate ? "is-invalid" : ""
                  }`}
                max={new Date().toISOString().split('T')[0]}
                {...register("entryDate")}
                placeholder="Enter Date"
              />
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.entryDate?.message?.toString()}
              </div>
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Transaction Description <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.transactionDescription ? "is-invalid" : ""
                  }`}
                {...register("transactionDescription")}
                placeholder="Enter Transaction Description"
              />
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.transactionDescription?.message?.toString()}
              </div>
            </div>

            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Amount (£) <span className="text-red-500">*</span>
              </label>
              <NumericFormat decimalScale={2} className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.amount ? "is-invalid" : ""
                }`}
                onValueChange={(values) => {
                  setValue('amount', values.value)
                }}
                placeholder="Enter Amount (£)"
              />
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.amount?.message?.toString()}
              </div>
            </div>
            <div className="">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Authorised By <span className="text-red-500">*</span>
              </label>
              <SelectBox
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                indicator={
                  <svg
                    width="15"
                    className="fill-current mr-2"
                    height="15"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                }
                isMulti={false}
                name="frameFiftySix"
                options={authorisedClientLists}
                isSearchable={true}
                value={getValues().authorisedBy}
                placeholder="Select"
                shape="round"
                color="indigo_50"
                size="xs"
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setValue("authorisedBy", selectedOption);
                  }
                }}
              />
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.authorisedBy?.message?.toString()}
              </div>
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Reference Number <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.transferReference ? "is-invalid" : ""
                  }`}
                {...register("transferReference")}
                placeholder="Enter Reference Number"
              />
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.transferReference?.message?.toString()}
              </div>
            </div>
            <div className="">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Transfer Method <span className="text-red-500">*</span>
              </label>
              <SelectBox
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                indicator={
                  <svg
                    width="15"
                    className="fill-current mr-2"
                    height="15"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                }
                isMulti={false}
                name="frameFiftySix"
                options={mappedTransferMethod}
                isSearchable={true}
                placeholder="Select"
                shape="round"
                color="indigo_50"
                size="xs"
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setValue("transferMethod", selectedOption);
                  }
                }}
              />
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.transferMethod?.message?.toString()}
              </div>
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Comments
              </label>
              <textarea
                className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                placeholder="Comments"
                {...register("comments")}
              ></textarea>
            </div>
          </div>
          <div className="flex col-span-2 gap-2 justify-end my-5">
            <button
              type="button"
              onClick={() => {
                Close();
              }}
              className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]"
            >
              {isLoading ? (
                <svg
                  className="animate-spin h-5 w-5 text-white-bg mx-auto"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v8H4z"
                  ></path>
                </svg>
              ) : (
                "Save"
              )}
            </button>
          </div>
        </form>
      ) : null}
    </>
  );
}

export { AddLedgerToLedger };
