import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { boolean, object, string } from "yup";
import { FaRegCopy } from "react-icons/fa";
import { ImCheckboxChecked } from "react-icons/im";
import { FiRefreshCcw } from "react-icons/fi";
import { GetAllAreasOfPractice } from "../../../store/slice/Administrator/AreasOfPracticeSlice";
import { useAppDispatch } from "../../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { generateRandomPassword } from "../../../utils/generate-key.utilis";

function AddUserStepThree({
  onSubmitStep,
  initialData,
  onCancel,
  onPreviousStep,
  isLoading,
  branchData
}: any) {
  const dispatch = useAppDispatch();
  const {
    areasOfPractice,
    status: areasStatus,
    error: areasError,
  } = useSelector((state: RootState) => state.areasOfPractice);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedAreaOfPractices, setSelectedAreaOfPractices] = useState<
    number[]
  >([]);
  const [selectedBranches, setSelectedBranches] = useState<number[]>([]);
  const [password, setPassword] = useState("");
  const [copied, setCopied] = useState(false);

  const validationSchema = object().shape({
    userName: string().required("User Name is required"),
    password: string().required("Password is required"),
    isActive: boolean(),
    //fkBranchId: array().of(number()),
    //fkAreasOfPracticeId: array().of(string())
  });

  const effectServiceCall = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current) {
      dispatch(GetAllAreasOfPractice());
    }
    return () => {
      effectServiceCall.current = true;
    };
  }, [dispatch]);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      ...initialData,
      isActive: true,
    },
  });

  const handleFormSubmit = (data: any) => {
    console.log(JSON.stringify(data, null, 2));
    data.fkAreasOfPracticeId = selectedAreaOfPractices.join(", ");
    // data.fkBranchId = ""
    onSubmitStep(data);
  };

  const onError = (errors: any) => {
    console.log("Form submission errors", errors);
  };

  const toggleAreaofPracticeSelection = (obj: any) => {
    const areaId = obj.areasOfPracticeId;
    let updatedAreas = [...selectedAreaOfPractices];

    if (selectedAreaOfPractices.includes(areaId)) {
      updatedAreas = updatedAreas.filter((id) => id !== areaId);
    } else {
      updatedAreas.push(areaId);
    }
    setSelectedAreaOfPractices(updatedAreas);
  };

  const toggleBranchSelection = (branch: any) => {
    const branchId = branch.branchId;
    let updatedBranch = [...selectedBranches];

    if (selectedBranches.includes(branchId)) {
      updatedBranch = updatedBranch.filter((id) => id !== branchId);
    } else {
      updatedBranch.push(branchId);
    }
    setSelectedBranches(updatedBranch);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedAreaOfPractices([]);
    } else {
      let allIds: number[] = [];
      allIds = areasOfPractice.map((item: any) => {
        return item.areasOfPracticeId;
      });
      setSelectedAreaOfPractices(allIds);
    }
    setSelectAll(!selectAll);
  };

  const generatePassword = () => {
    let value = generateRandomPassword();
    setValue("password", value);
    setPassword(value);
    setCopied(false);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(password);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <form className="w-full" onSubmit={handleSubmit(handleFormSubmit, onError)}>
      <h3 className="text-lg  font-bold text-left text-gray-900 dark:text-white-bg mb-5">
        Login Details
      </h3>
      <div className="grid grid-cols-4 md:grid-cols-1 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
        <div className="">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
            User Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
              errors.userName ? "is-invalid" : ""
            }`}
            {...register("userName")}
          />
          <div className="font-medium mt-2 text-xs text-left text-red-600">
            {errors?.userName?.message?.toString()}
          </div>
        </div>
        <div className="">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
            Password <span className="text-red-500">*</span>
          </label>
          <div className="flex items-center space-x-2">
            <input
              type="password"
              className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                errors.password ? "is-invalid" : ""
              }`}
              {...register("password")}
            />
            <button
              type="button"
              onClick={copyToClipboard}
              disabled={!password}
            >
              {copied ? (
                <ImCheckboxChecked className="h-4 w-4" />
              ) : (
                <FaRegCopy className="h-4 w-4" />
              )}
            </button>
          </div>

          <div className="mt-2 text-xs text-gray-500">
            {" "}
            Leave password blank if dont want to change{" "}
          </div>
          <button
            type="button"
            onClick={generatePassword}
            className="flex items-center text-xs bg-blue-500 text-white-bg mt-2 px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
          >
            <FiRefreshCcw className="mr-2 h-4 w-4" /> Generate Password
          </button>
        </div>
        <div>
          <div className="flex flex-row space-x-10">
            <label className="text-sm font-medium text-gray-900 dark:text-white-bg">
              Is Active
            </label>
            <div>
              <input
                type="radio"
                value="true"
                checked={watch("isActive") === true}
                onChange={() => setValue("isActive", true)}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                Yes
              </label>
            </div>

            <div>
              <input
                type="radio"
                value="false"
                checked={watch("isActive") === false}
                onChange={() => setValue("isActive", false)}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                No
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-4 md:grid-cols-1 gap-5">
        <div className="col-span-1">
          <h3 className="text-lg font-bold text-left text-gray-900 dark:text-white-bg mb-5">
            Branch(es)
          </h3>
          <div className="dark:text-white-bg my-5">
            <div>
              {branchData.map((branch: any) => (
                <div
                  key={branch.branchId}
                  className="flex items-center justify-between w-full p-2 text-sm font-medium rtl:text-right border border-gray-200 dark:focus:ring-blue-800 dark:border-gray-700 dark:text-gray-400 hover:bg-sky-100 dark:hover:bg-gray-800 gap-3"
                >
                  <span>{branch.branchName}</span>
                  {/* checked={selectedBranches.includes(branch.branchId)}  onChange={() => toggleBranchSelection(branch)} */}
                  <input
                          type="checkbox"
                          onChange={() => setValue("fkBranchId", branch.branchId)}
                    value={branch.branchId}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-span-3">
          <div>
            <div className="flex items-center justify-between mb-5">
              <h3 className="text-lg font-bold text-left text-gray-900 dark:text-white-bg">
                Area of Practice
              </h3>
              <div className="">
                {/* <SearchInput title="Search Permission" /> */}
                <div className="">
                  <label className="ml-2 text-sm font-semibold dark:text-gray-400">
                    Select All{" "}
                  </label>
                  <input
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-4 md:grid-cols-1 gap-2 dark:text-white-bg my-5">
            {areasOfPractice != null &&
              areasOfPractice.length > 0 &&
              areasOfPractice.map((area: any) => {
                return (
                  <>
                    <h2 key={area.areasOfPracticeId}>
                      <button
                        type="button"
                        className="flex items-center justify-between w-full p-2 text-sm font-medium rtl:text-right border border-gray-200 dark:focus:ring-blue-800 dark:border-gray-700 dark:text-gray-400 hover:bg-sky-100 dark:hover:bg-gray-800 gap-3"
                      >
                        <span>{area.area}</span>
                        <input
                          type="checkbox"
                          checked={selectedAreaOfPractices.includes(
                            area.areasOfPracticeId
                          )}
                          onChange={() => toggleAreaofPracticeSelection(area)}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                      </button>
                    </h2>
                  </>
                );
              })}
          </div>
        </div>
      </div>

      <div className="flex flex-row gap-2 items-start justify-between w-full">
        <button
          type="button"
          onClick={onPreviousStep}
          className="flex items-center gap-2 px-8 cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[140px]"
        >
          <svg
            className="w-6 h-6 fill-white-bg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
          </svg>
          Previous
        </button>
        <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
          <div className="flex flex-row gap-2 items-end justify-end w-full">
            <button
              type="button"
              onClick={onCancel}
              className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isLoading}
              className={`cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[120px] ${
                isLoading ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {isLoading ? (
                <svg
                  className="animate-spin h-5 w-5 text-white-bg mx-auto"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v8H4z"
                  ></path>
                </svg>
              ) : (
                "Save"
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

export { AddUserStepThree };
