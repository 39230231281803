import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import AuditTrailService from "../../../../../services/Accounting/OfficeAccount/OfficeReports/AuditTrailService";





export interface AuditTrail {
    //creditNoteId: number;
   
};
// Define the state type for Checklist
type AuditTrailState = {
    auditTrails: AuditTrail[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: AuditTrailState = {
    auditTrails: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllAuditTrail = createAsyncThunk(
    "fetchAllAuditTrail",
    async (_, thunkAPI) => {
        try {
            const response = await AuditTrailService.GetAllAuditTrail();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


// Slice definition
const AuditTrailSlice = createSlice({
    name: "auditTrail",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllAuditTrail.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllAuditTrail.fulfilled, (state, action: PayloadAction<AuditTrail[]>) => {
                state.status = "idle";
                state.auditTrails = action.payload;
            })
            .addCase(fetchAllAuditTrail.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
           
    }
});

export default AuditTrailSlice.reducer;
