import { Modal } from 'flowbite-react';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object } from 'yup';
import { useAppDispatch } from '../../../../store/hooks/redux-hooks';

function AddCaseClosure({ openDrawer, Close }: { openDrawer: boolean, Close: any }) {
    const dispatch = useAppDispatch();
    const [openModal, setOpenModal] = useState(true);
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [modalPlacement, setModalPlacement] = useState('center')


    const validationSchema = object().shape({
        caseClosureArea: string()
            .required('Case Closure Area is required'),
        position: string()
            
    });

    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = (data: any) => {
        console.log(JSON.stringify(data, null, 2));
        // dispatch(addTask(data));
        Close();
    };


    return (
        <Modal
            className={`backdrop-blur-sm items-center justify-center`}
            show={openDrawer}
            position={modalPlacement}
            onClose={() => { reset(); setOpenModal(Close); }}
            initialFocus={emailInputRef}
        >
            <Modal.Header className="bg-white-bg dark:bg-black p-5">
                <span className="text-blue_gray-900 dark:text-white-bg text-xl w-auto">Add New Case Closure</span>
            </Modal.Header>
            <Modal.Body className="bg-white-bg dark:bg-black">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="space-y-6 w-full">
                        <div className="mx-auto">
                        <div className="mb-5">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Case Closure Area <span className="text-red-500">*</span>
                                </label>
                                <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.caseClosureArea ? 'is-invalid' : ''}`} {...register('caseClosureArea')} />
                                <div className="font-medium mt-2 text-xs text-red-600" >{errors?.caseClosureArea?.message?.toString()}</div>
                            </div>
                            <div className="mb-5">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Position
                                </label>
                                <input type="text" className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('position')} />
                            </div>
                            <div>
                            </div>
                        </div>
                        
                        <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
                            <div className="flex flex-row gap-2 items-end justify-end w-full">
                                <button
                                    onClick={() => {
                                        reset();
                                        setOpenModal(Close);
                                    }}
                                    type='button'
                                    className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                                >
                                    Cancel
                                </button>
                                <button
                                    type='submit'
                                    className="bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export { AddCaseClosure };
