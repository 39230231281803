import { Modal } from 'flowbite-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object, number } from 'yup';
import { useEffect, useRef, useState } from 'react';
import { SelectBox } from '../../../../SelectBox';
import { FaLock } from "react-icons/fa";
import { FaLockOpen } from "react-icons/fa";
import { useAppDispatch } from '../../../../../store/hooks/redux-hooks';
import { RootState } from '../../../../../store/store';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { addCACreditNoteRefund, fetchNextCreditNoteRefundNumber } from '../../../../../store/slice/Accounting/ClientAccounts/Invoice/CARefundCreditNoteSlice';
import { fetchAllClientPaymentType } from '../../../../../store/slice/Accounting';
import { fetchCreditNoteSearch } from '../../../../../store/slice/Accounting/ClientAccounts/Invoice/CACreditNoteSlice';


function AddRefundCreditNote({ openDrawer, Close, modalType, }: { openDrawer: boolean, Close: any, modalType: string }) {
    const [title] = useState((modalType === 'Add' ? 'Add Refund Credit Note' : 'Edit Refund Credit Note'))
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [modalPlacement, setModalPlacement] = useState('center')
    const dispatch = useAppDispatch();
    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);
    const [initialLoading, setInitialLoading] = useState(true);
    const receiptAutoNumber = useSelector((state: RootState) => state.carefundCreditNote.receiptNumber);
    const { clientAccounts } = useSelector((state: RootState) => state.clientAccount);
    const { officeBanks } = useSelector((state: RootState) => state.officeBank);
    const { clientPaymentTypes } = useSelector((state: RootState) => state.clientPaymentType);
    const cacreditNoteData = useSelector((state: RootState) => state.clientCreditNote.cacreditNoteData);
    const { invoiceNotices } = useSelector((state: RootState) => state.invoiceNotice);
    const [autoReceiptNumber, setAutoReceiptNumber] = useState<number>();
    const [cNoteId, setCNoteId] = useState<number>();
    const [clientAccountLists, setClientAccountLists] = useState<any[]>([]);
    const [creditNoteLists, setCreditNoteLists] = useState<any[]>([]);
    const [creditNoteById, setCreditNoteById] = useState<any>({});
    const [invoiceNoticeLists, setInvoiceNoticeLists] = useState<any[]>([]);
    const [officeAccountLists, setOfficeAccountLists] = useState<any[]>([]);
    const [paymentLists, setPaymentLists] = useState<any[]>([]);
    const [selectedInvoiceNotice, setSelectedInvoiceNotice] = useState<any>("");
    const [selectedInvoiceNote, setSelectedInvoiceNote] = useState<any>();


    useEffect(() => {
        if (!effectServiceCall.current) {
            const fetchPromises = [
                dispatch(fetchNextCreditNoteRefundNumber()),
                dispatch(fetchCreditNoteSearch()),
            ];
            Promise.all(fetchPromises)
                .then(() => {
                    setInitialLoading(false);
                })
                .catch((error) => {
                    if (!errorShownRef.current) {
                        toast.error("Error fetching data: " + error.message);
                        errorShownRef.current = true;
                    }
                    setInitialLoading(false);
                });

            effectServiceCall.current = true;
        }
    }, [dispatch]);

    useEffect(() => {
        if (clientAccounts.length > 0) {
            let data = clientAccounts.map((clientAccount: any, index) => ({
                value: clientAccount.bankAccountId,
                label: `${clientAccount.institution} - ${clientAccount.bankAccountType}`
                ,
            }));
            setClientAccountLists(data);

        }
    }, [clientAccounts]);

    useEffect(() => {
        if (cacreditNoteData && cacreditNoteData.creditNoteResult) {
            let data = cacreditNoteData.creditNoteResult.map((cacreditNote: any) => ({
                value: cacreditNote.creditNoteId,
                label: `Credit Note ${cacreditNote.creditNoteId} (${cacreditNote.caseName})`,
            }));
            setCreditNoteLists(data);
        }
    }, [cacreditNoteData]);

    useEffect(() => {
        if (cNoteId !== undefined) {
            const selectedData = cacreditNoteData.creditNoteResult.find(
                (creditNote: any) => creditNote.creditNoteId === cNoteId
            );

            if (selectedData) {
                setCreditNoteById(selectedData);
            }
        }
    }, [cNoteId, cacreditNoteData]);

    useEffect(() => {
        if (creditNoteById !== null) {
            setValue("remainingCredit", creditNoteById.totalAmount)
            setValue("refundAmount", creditNoteById.totalAmount)
            setValue("caseReference", creditNoteById.caseReferenceAuto)
        }
    }, [creditNoteById])

    useEffect(() => {
        if (invoiceNotices.length > 0) {
            let data = invoiceNotices.map((invoiceNotice: any, index) => ({
                value: invoiceNotice.invoiceNoticeId,
                label: `${invoiceNotice.title}`,
            }));
            setInvoiceNoticeLists(data);
        }
    }, [invoiceNotices]);

    const handleNoteDropdownChange = (selectedOption: number) => {
        const selectedNotice = invoiceNotices.find((notice: any) => notice.invoiceNoticeId === selectedOption);
        if (selectedNotice !== null) {
            setSelectedInvoiceNote(selectedNotice);
            setValue("creditNoteRefundNotes", selectedNotice?.invoiceNotice);
        };
    };

    const handleNoticeDropdownChange = (selectedOption: number) => {
        const selectedNotice = invoiceNotices.find((notice: any) => notice.invoiceNoticeId === selectedOption);
        if (selectedNotice !== null) {
            setSelectedInvoiceNotice(selectedNotice);
            setValue("creditNoteRefundNotice", selectedNotice?.invoiceNotice);
        };
    };


    useEffect(() => {
        if (clientPaymentTypes.length > 0) {
            let data = clientPaymentTypes.map((payment: any, index) => ({
                value: payment.paymentTypeId,
                label: payment.paymentType,
            }));
            setPaymentLists(data);

        }
    }, [clientPaymentTypes]);

    useEffect(() => {
        if (officeBanks.length > 0) {
            let data = officeBanks.map((officeAccount: any, index) => ({
                value: officeAccount.bankAccountId,
                label: `${officeAccount.institution} - ${officeAccount.bankAccountType}`
                ,
            }));
            setOfficeAccountLists(data);

        }
    }, [officeBanks]);

    useEffect(() => {
        if (receiptAutoNumber !== null) {
            setValue("creditNoteRefundNumber", receiptAutoNumber)
            setAutoReceiptNumber(receiptAutoNumber)
        }

    }, [receiptAutoNumber])


    const handleEditClick = () => {
        setIsDisabled(!isDisabled);
    };

    const [isDisabled, setIsDisabled] = useState(true);


    const validationSchema = object().shape({
        creditNoteRefundNumber: number()
            .required('Credit Note Refund is required'),
        creditNoteId: number()
            .required('Credit Note is required'),
        remainingCredit: string()
            .required('Credit Remaining is required'),
        transactionDetails: string(),
        refundReason: string(),
        refundDate: string()
            .required('Refund Date is required'),
        refundAmount: string()
            .required('Refund Amount is required'),
        refundAccountId: string()
            .required('Office Bank Account is required'),
        refundMethodId: string()
            .required('Payment Type is required'),
        client: string(),
        caseReference: string(),
        refundNotes: string(),
        creditNoteRefundNotice: string(),
        refundReference: string(),
        creditNoteRefundNotes: string(),
        invoiceNoteId: number(),
        invoiceNoticeId: number(),
        clientBankId: string()
            .required('Client Bank Account is required'),
    });
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            creditNoteRefundNumber: autoReceiptNumber,
        }
    });

    const onSubmit = (data: any) => {
        console.log(JSON.stringify(data, null, 2));
        dispatch(addCACreditNoteRefund(data))
            .unwrap()
            .then(() => {
                Close();
            })
            .catch((error: any) => {
                console.error("Failed to add Refund CreditNote:", error);
            });
    };
    const onError = (errors: any) => {
        console.log("Form submission errors", errors);
    };


    return (
        <form onSubmit={handleSubmit(onSubmit, onError)} className="mx-auto" >
            <div className="flex float-right flex-row md:flex-col my-5 gap-2 w-full md:gap-3">
                <div>
                    <button
                        type="button"
                        onClick={() => {
                            Close();
                        }}
                        className="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-2 rounded">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>

            </div >
            <div className="grid md:grid-cols-1 grid-cols-3 gap-x-4 gap-y-1 w-full">
                <div >
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                        Credit Note Refund <span className="text-red-500">*</span>
                    </label>
                    <div className="flex gap-3">
                        <input
                            type="text"
                            value={autoReceiptNumber}
                            className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg disabled:bg-gray-300 dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.creditNoteRefundNumber ? 'is-invalid' : ''}`}
                            {...register('creditNoteRefundNumber')}
                            disabled={isDisabled}
                        />
                        <button
                            type="button"
                            onClick={handleEditClick}
                            className="mt-2 text-sm font-medium text-blue-600 dark:text-blue-500"
                        >
                            {isDisabled ? <FaLock /> : <FaLockOpen />}
                        </button>
                    </div>
                    <div className="font-medium mt-2 text-xs text-red-600">{errors?.creditNoteRefundNumber?.message?.toString()}</div>
                </div>
                <div className="">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                        Credit Note <span className="text-red-500">*</span>
                    </label>
                    <SelectBox
                        className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                        indicator={
                            <svg
                                width="15"
                                className="fill-current mr-2"
                                height="15"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512">
                                <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                            </svg>
                        }
                        isMulti={false}
                        name="frameFiftySix"
                        isSearchable={true}
                        options={creditNoteLists}
                        placeholder="Select"
                        shape="round"
                        color="indigo_50"
                        size="xs"
                        onChange={(selectedOption) => {
                            if (selectedOption) {
                                setValue('creditNoteId', selectedOption);
                                setCNoteId(selectedOption)
                            }
                        }}
                    />
                    <div className="font-medium mt-2 text-xs text-red-600" >{errors?.creditNoteId?.message?.toString()}</div>
                </div>
                <div className="relative">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                        Credit Remaining <span className="text-red-500">*</span>
                    </label>
                    <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.remainingCredit ? 'is-invalid' : ''}`} {...register('remainingCredit')}
                        placeholder="Enter Credit Remaining"
                        value={creditNoteById.totalAmount}
                        disabled
                    />
                    <div className="font-medium mt-2 text-xs text-red-600">{errors?.remainingCredit?.message?.toString()}</div>
                </div>
                <div className="relative">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                        Transaction Description
                    </label>
                    <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('transactionDetails')}
                        placeholder="Enter Transaction Description"
                    />
                </div>
                <div className="relative">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                        Refund Reason
                    </label>
                    <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('refundReason')}
                        placeholder="Enter Refund Reason"
                    />
                </div>
                <div className="relative">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                        Refund Date <span className="text-red-500">*</span>
                    </label>
                    <input type="date" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.refundDate ? 'is-invalid' : ''}`} {...register('refundDate')}
                        placeholder="Enter Transaction Date"
                    />
                    <div className="font-medium mt-2 text-xs text-red-600">{errors?.refundDate?.message?.toString()}</div>
                </div>
                <div className="relative">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                        Refund Amount <span className="text-red-500">*</span>
                    </label>
                    <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.refundAmount ? 'is-invalid' : ''}`} {...register('refundAmount')}
                        placeholder="Enter Date"
                        value={creditNoteById.totalAmount}
                    />
                    <div className="font-medium mt-2 text-xs text-red-600">{errors?.refundAmount?.message?.toString()}</div>
                </div>
                <div className="">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                        Office Bank Account <span className="text-red-500">*</span>
                    </label>
                    <SelectBox
                        className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                        indicator={
                            <svg
                                width="15"
                                className="fill-current mr-2"
                                height="15"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512">
                                <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                            </svg>
                        }
                        isMulti={false}
                        name="frameFiftySix"
                        options={officeAccountLists}
                        isSearchable={true}
                        placeholder="Select"
                        shape="round"
                        color="indigo_50"
                        size="xs"
                        onChange={(selectedOption) => {
                            if (selectedOption) {
                                setValue('refundAccountId', selectedOption);
                            }
                        }}
                    />
                    <div className="font-medium mt-2 text-xs text-red-600" >{errors?.refundAccountId?.message?.toString()}</div>
                </div>
                <div className="">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                        Payment Type <span className="text-red-500">*</span>
                    </label>
                    <SelectBox
                        className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                        indicator={
                            <svg
                                width="15"
                                className="fill-current mr-2"
                                height="15"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512">
                                <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                            </svg>
                        }
                        isMulti={false}
                        name="frameFiftySix"
                        options={paymentLists}
                        isSearchable={true}
                        placeholder="Select"
                        shape="round"
                        color="indigo_50"
                        size="xs"
                        onChange={(selectedOption) => {
                            if (selectedOption) {
                                setValue('refundMethodId', selectedOption);
                            }
                        }}
                    />
                    <div className="font-medium mt-2 text-xs text-red-600" >{errors?.refundMethodId?.message?.toString()}</div>
                </div>
                <div className="">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                        Client Bank Account <span className="text-red-500">*</span>
                    </label>
                    <SelectBox
                        className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                        indicator={
                            <svg
                                width="15"
                                className="fill-current mr-2"
                                height="15"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512">
                                <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                            </svg>
                        }
                        isMulti={false}
                        name="frameFiftySix"
                        options={clientAccountLists}
                        isSearchable={true}
                        placeholder="Select"
                        shape="round"
                        color="indigo_50"
                        size="xs"
                        onChange={(selectedOption) => {
                            if (selectedOption) {
                                setValue('clientBankId', selectedOption);
                            }
                        }}
                    />
                    <div className="font-medium mt-2 text-xs text-red-600" >{errors?.clientBankId?.message?.toString()}</div>
                </div>
                <div className="relative">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                        Refund Reference
                    </label>
                    <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('refundReference')}
                        placeholder="Enter Refund Reference"
                    />
                </div>
                <div className="relative">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                        Notes
                    </label>
                    <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('refundNotes')}
                        placeholder="Enter Notes"
                    />
                </div>
                <div className="relative">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                        Client
                    </label>
                    <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('client')}
                        placeholder="Enter Client"
                        disabled
                    />
                </div>
                <div className="relative">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                        Case Reference
                    </label>
                    <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('client')}
                        placeholder="Enter Case Reference"
                        value={creditNoteById.caseReferenceAuto}
                        disabled
                    />
                </div>
                <div className="">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                        Refund Notes Title
                    </label>
                    <SelectBox
                        className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                        indicator={
                            <svg
                                width="15"
                                className="fill-current mr-2"
                                height="15"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512">
                                <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                            </svg>
                        }
                        isMulti={false}
                        name="frameFiftySix"
                        isSearchable={true}
                        placeholder="Select"
                        options={invoiceNoticeLists}
                        shape="round"
                        color="indigo_50"
                        size="xs"
                        onChange={(selectedOption) => {
                            if (selectedOption) {
                                handleNoteDropdownChange(selectedOption);
                                setValue('invoiceNoteId', selectedOption);
                            }
                        }}
                    />
                </div>
                <div className="">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                        Invoice Notice Title
                    </label>
                    <SelectBox
                        className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                        indicator={
                            <svg
                                width="15"
                                className="fill-current mr-2"
                                height="15"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512">
                                <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                            </svg>
                        }
                        isMulti={false}
                        name="frameFiftySix"
                        isSearchable={true}
                        placeholder="Select"
                        options={invoiceNoticeLists}
                        shape="round"
                        color="indigo_50"
                        size="xs"
                        onChange={(selectedOption) => {
                            if (selectedOption) {
                                handleNoticeDropdownChange(selectedOption);
                                setValue('invoiceNoticeId', selectedOption);
                            }
                        }}
                    />
                </div>
                <div className="relative col-span-1">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                        Refund Notes <span className="text-red-500">*</span>
                    </label>
                    <textarea
                        className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                        placeholder="Refund Notes" {...register('creditNoteRefundNotes')}
                        value={selectedInvoiceNote}
                    ></textarea>
                    <div className="font-medium mt-2 text-xs text-red-600" >{errors?.creditNoteRefundNotes?.message?.toString()}</div>
                </div>
                <div className="relative col-span-1">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                        Invoice Notice <span className="text-red-500">*</span>
                    </label>
                    <textarea
                        className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                        placeholder="Invoice Notice" {...register('creditNoteRefundNotice')}
                        value={selectedInvoiceNotice}
                    ></textarea>
                    <div className="font-medium mt-2 text-xs text-red-600" >{errors?.creditNoteRefundNotice?.message?.toString()}</div>
                </div>
            </div>
            <div className="flex col-span-2 gap-2 justify-end my-5">
                <button
                    type="button"
                    onClick={() => {
                        Close();
                    }}
                    className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    className="bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]"
                >
                    Save
                </button>
            </div>
        </form>
    );
}

export { AddRefundCreditNote };
