import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { SelectBox } from "../SelectBox";
import { boolean, number, object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { addFixedFeeInvoiceFormat, fetchAllFixedFeeInvoiceFormat } from "../../store/slice/Accounting/AccountSetup/FixedFeeInvoiceFormatSlice";
import { toast } from "react-toastify";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

function FixedFeeInvoiceFormat() {

    const dispatch = useAppDispatch();
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [modalPlacement, setModalPlacement] = useState('center');
    const [isLoading, setIsLoading] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);
    const { fixedFeeInvoiceFormats, status, error } = useSelector((state: RootState) => state.fixedFeeInvoiceFormat);
    const [existingFixedFeeInvoiceFormat, setExistingFixedFeeInvoiceFormat] = useState<any | null>();

    const separator = [
        { label: 'Slash - "/"', value: '/' },
        { label: 'Hyphen - "-"', value: '-' },
    ];

    const mappedseparator = separator.map((type: any) => ({
        label: type.label,
        value: type.value
    }));

    const dateFormat = [
        { label: 'YYYY-MM-DD', value: 'Y-m-d' },
        { label: 'YY-MM-DD', value: 'y-m-d' },
        { label: 'DD-MM-YYYY', value: 'd-m-Y' },
        { label: 'DD-MM-YY', value: 'd-m-y' }
    ];

    const mappeddateFormat = dateFormat.map((type: any) => ({
        label: type.label,
        value: type.value
    }));

    const effectServiceCall = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
            dispatch(fetchAllFixedFeeInvoiceFormat())
                .then(() => setInitialLoading(false))
                .catch(() => setInitialLoading(false));
            effectServiceCall.current = true;
        }

    }, [dispatch, status, error]);

    const validationSchema = object().shape({
        prefix: string()
            .required('prefix is required'),
        separator: string(),
        dateFormat: string(),
        nextInvoiceNumber: number(),
        isPrefixIncluded: boolean(),
        isSeparator: boolean(),
        isDateFormatIncluded: boolean()
    });

    const { register, handleSubmit, reset, setValue, watch, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            isPrefixIncluded: true,
            isSeparator: true,
            isDateFormatIncluded: true
        }


    });


    const onSubmit = (data: any) => {
        console.log(JSON.stringify(data, null, 2));

        dispatch(addFixedFeeInvoiceFormat(data))
            .unwrap()
            .then(() => {
                reset();
                toast.success("Successfully Added Case SubType");
            })
            .catch((error) => {
                toast.error(error)
            });
    };

    useEffect(() => {
        if (fixedFeeInvoiceFormats!=null) {
            setExistingFixedFeeInvoiceFormat(fixedFeeInvoiceFormats);
            reset(fixedFeeInvoiceFormats);
        }
    }, [fixedFeeInvoiceFormats, reset]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="mx-auto" >
                <div className="">
                    <div className="mb-5">
                    <SubHeaderTitle title="Fixed Fee Invoice Number Format" />
                    </div>
                    <div className="grid md:grid-cols-1 grid-cols-3 w-full gap-5 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35">
                        <div className="flex items-center gap-3">
                            <label className="block dark:text-white-bg">
                                Is Prefix Included
                            </label>
                            <div className='flex gap-5'>
                                <div>
                                    <input
                                        type="radio"
                                        value="true"
                                        checked={watch('isPrefixIncluded') === true}
                                        onChange={() => setValue('isPrefixIncluded', true)}
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Yes</label>
                                </div>
                                <div>
                                    <input
                                        type="radio"
                                        value="false"
                                        checked={watch('isPrefixIncluded') === false}
                                        onChange={() => setValue('isPrefixIncluded', false)}
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">No</label>
                                </div>
                            </div>
                        </div>

                        <div className="flex items-baseline">
                            <div className="flex items-center gap-3">
                                <label className="block dark:text-white-bg">
                                    Is Separator Included
                                </label>
                                <div className='flex gap-5'>
                                    <div>
                                        <input
                                            type="radio"
                                            value="true"
                                            checked={watch('isSeparator') === true}
                                            onChange={() => setValue('isSeparator', true)}
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Yes</label>
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            value="false"
                                            checked={watch('isSeparator') === false}
                                            onChange={() => setValue('isSeparator', false)}
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">No</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex items-baseline">
                            <div className="flex items-center gap-3">
                                <label className="block dark:text-white-bg">
                                    Is Date Format Included
                                </label>
                                <div className='flex gap-5'>
                                    <div>
                                        <input
                                            type="radio"
                                            value="true"
                                            checked={watch('isDateFormatIncluded') === true}
                                            onChange={() => setValue('isDateFormatIncluded', true)}
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Yes</label>
                                    </div>
                                    <div>
                                        <input
                                            type="radio"
                                            value="false"
                                            checked={watch('isDateFormatIncluded') === false}
                                            onChange={() => setValue('isDateFormatIncluded', false)}
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">No</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="relative ">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Prefix
                            </label>
                            <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}{...register('prefix')}
                                placeholder="LAIT"
                            />
                        </div>

                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Date Format
                            </label>
                            <SelectBox
                                className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                indicator={
                                    <svg
                                        width="15"
                                        className="fill-current mr-2"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                    </svg>
                                }
                                isMulti={false}
                                name="frameFiftySix"
                                options={mappeddateFormat}
                                isSearchable={true}
                                placeholder="DD-MM-YY"
                                shape="round"
                                color="indigo_50"
                                size="xs"
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setValue('dateFormat', selectedOption);
                                    }
                                }}

                                
                            />

                        </div>

                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Separator
                            </label>
                            <SelectBox
                                className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                indicator={
                                    <svg
                                        width="15"
                                        className="fill-current mr-2"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                    </svg>
                                }
                                isMulti={false}
                                name="frameFiftySix"
                                options={mappedseparator}
                                isSearchable={true}
                                placeholder="Slash"
                                shape="round"
                                color="indigo_50"
                                size="xs"
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setValue('separator', selectedOption);
                                    }
                                }}
                            />

                        </div>

                        <div className="relative ">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Next Invoice Number
                            </label>
                            <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}{...register('nextInvoiceNumber')}
                                placeholder="1000"
                            />
                        </div>
                    </div>


                </div>

                <div className="flex md:flex-col gap-2 flex-row justify-start w-full my-5">
                    <div className="flex flex-row gap-2 items-end justify-end w-full">
                        <button
                            type='button'
                            onClick={() => {
                                reset();

                            }}
                            className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                        >
                            Cancel
                        </button>
                        <button
                            type='submit'
                            disabled={isLoading}
                            className="bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]"
                        >
                            {isLoading ? (
                                <svg className="animate-spin h-5 w-5 text-white-bg mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                                </svg>
                            ) : (
                                'Save'
                            )}
                        </button>
                    </div>
                </div>



            </form>

        </>
    )
}

export { FixedFeeInvoiceFormat };