import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginationResponse } from "../../../model/Pagination/paginationClass";
import LexcelCqsColpCofaGdprService from "../../../../services/OfficeManagement/OfficeInformation/LexcelCqsColpCofaGdprService";

export interface LEXCELOfficeManual {

  lEXCELOfficeManualId: number;
}
// Define the state type for Checklist
type LEXCELOfficeManualState = {
    lEXCELOfficeManuals: PaginationResponse<LEXCELOfficeManual> | null;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: LEXCELOfficeManualState = {
    lEXCELOfficeManuals: null,
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllLEXCELOfficeManual = createAsyncThunk(
    "fetchAllLEXCELOfficeManual",
    async (data: any, thunkAPI) => {
        try {
            const response = await LexcelCqsColpCofaGdprService.GetAllLexcelCqsColpCofaGdpr(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addLEXCELOfficeManual = createAsyncThunk(
    "addLEXCELOfficeManual",
    async (data: any, thunkAPI) => {
        try {
            const response = await LexcelCqsColpCofaGdprService.PostLexcelCqsColpCofaGdpr(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateLEXCELOfficeManual = createAsyncThunk(
    "updateLEXCELOfficeManual",
    async ({ id, data }: { id: number, data: LEXCELOfficeManual }, thunkAPI) => {
        try {
            const response = await LexcelCqsColpCofaGdprService.PutLexcelCqsColpCofaGdpr(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteLEXCELOfficeManual = createAsyncThunk(
    "deleteLEXCELOfficeManual",
    async (id: number, thunkAPI) => {
        try {
            await LexcelCqsColpCofaGdprService.deleteLexcelCqsColpCofaGdpr(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const LEXCELOfficeManualSlice = createSlice({
    name: "lEXCELOfficeManual",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllLEXCELOfficeManual.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllLEXCELOfficeManual.fulfilled, (state, action: PayloadAction<PaginationResponse<LEXCELOfficeManual>>) => {
                state.status = "idle";
                state.lEXCELOfficeManuals = action.payload;
            })
            .addCase(fetchAllLEXCELOfficeManual.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addLEXCELOfficeManual.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addLEXCELOfficeManual.fulfilled, (state, action: PayloadAction<LEXCELOfficeManual>) => {
                state.status = "idle";
               // state.lEXCELOfficeManuals.push(action.payload);
            })
            .addCase(addLEXCELOfficeManual.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateLEXCELOfficeManual.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateLEXCELOfficeManual.fulfilled, (state, action: PayloadAction<LEXCELOfficeManual>) => {
                state.status = "idle";
                // const index = state.lEXCELOfficeManuals.findIndex(t => t.lEXCELOfficeManualId === action.payload.lEXCELOfficeManualId);
                // if (index !== -1) {
                //     state.lEXCELOfficeManuals[index] = action.payload;
                // }
            })
            .addCase(updateLEXCELOfficeManual.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteLEXCELOfficeManual.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteLEXCELOfficeManual.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
               // state.lEXCELOfficeManuals = state.lEXCELOfficeManuals.filter(t => t.lEXCELOfficeManualId !== action.payload);
            })
            .addCase(deleteLEXCELOfficeManual.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default LEXCELOfficeManualSlice.reducer;
