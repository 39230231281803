import { useEffect, useRef, useState } from "react";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SearchInput } from "../Filters/SearchInput";
import { FaEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { MdOutlineRefresh } from "react-icons/md";
import { AddLedgerToLedger } from "../Modal/AddClientAccounts/AddClientPostings/AddLedgerToLedger";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { fetchAllLedgerToLedger } from "../../store/slice/Accounting/ClientAccounts/ClientPosting/LedgerToLedgerSlice";
import { toast } from "react-toastify";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { Pagination } from "../Pagination";
function LedgerToLedger() {
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const ledgerToLedgers = useSelector(
    (state: RootState) => state.ledgerToLedger.ledgerToLedgerData
  );
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [currentLedger, setCurrentLedger] = useState<any[]>([]);
  const [ledgerToLedgerData, setledgerToLedgerData] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState(0);

  const effectServiceCall = useRef(false);
  const errorShownRef = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current) {
      dispatch(fetchAllLedgerToLedger())
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
    }
  }, [dispatch]);

  useEffect(() => {
    if (ledgerToLedgers && ledgerToLedgers.ledgerToLedgerResult) {
      setledgerToLedgerData(ledgerToLedgers.ledgerToLedgerResult);
    }
  }, [ledgerToLedgers]);

  const filterLedgerToLedgerBySearchQuery = () => {
    return ledgerToLedgerData.filter((ledgerToLedger: any) =>
      ledgerToLedger?.transactionDetails
        ?.toLowerCase()
        .includes(searchQuery?.toLowerCase())
    );
  };

  useEffect(() => {
    if (ledgerToLedgerData != null && ledgerToLedgerData.length > 0) {
      const filteredByLedgerToLedger = filterLedgerToLedgerBySearchQuery();
      const indexOfLastLedgerToLedger = currentPage * itemsPerPage;
      const indexOfFirstLedgerToLedger =
        indexOfLastLedgerToLedger - itemsPerPage;
      const currentLedgerToLedger = filteredByLedgerToLedger.slice(
        indexOfFirstLedgerToLedger,
        indexOfLastLedgerToLedger
      );
      const totalPages = Math.ceil(ledgerToLedgerData.length / itemsPerPage);
      setTotalPages(totalPages);
      setCurrentLedger(currentLedgerToLedger);
    }
  }, [ledgerToLedgerData]);

  const toggleDrawer = () => {
    setOpenModal(!openModal);
  };
  if (openModal) {
    return (
      <AddLedgerToLedger
        openDrawer={openModal}
        Close={toggleDrawer}
        isEdit={isEdit}
        ledgerToLedger={ledgerToLedgerData}
      />
    );
  }

  return (
    <>
      <div className="-ml-0">
        <div className="mb-5">
          <SubHeaderTitle
            title="Ledger to Ledger"
            buttonName="Ledger to Ledger"
            isAdd={true}
            onClickAdd={toggleDrawer}
          />
        </div>
        <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
          <SearchInput title="Keyword" />
          <div>
            <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-2 md:gap-1 w-full">
              <CheckBoxFilter title="From Ledger" />
              <CheckBoxFilter title="To Ledger" />
            </div>
          </div>
        </div>
        <div className="relative w-full overflow-x-auto sm:rounded-lg">
          {initialLoading ? <MainAnimation /> : ""}
          <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
            <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <th scope="col" className="px-6 py-3">
                  Transaction Ref
                </th>
                <th scope="col" className="px-6 py-3">
                  Transaction Date
                </th>
                <th scope="col" className="px-6 py-3">
                  From Ledger Card
                </th>
                <th scope="col" className="px-6 py-3">
                  To Ledger Card
                </th>
                <th scope="col" className="px-6 py-3">
                  Amount
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {currentLedger.map((ledgerToLedger: any, index) => (
                <tr
                  key={index}
                  className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  <td className="px-6 py-3">
                    L2L {ledgerToLedger.ledgerToLedgerNumber}
                  </td>
                  <td className="px-6 py-3">
                    {
                      new Date(ledgerToLedger.transactionDate)
                        .toISOString()
                        .split("T")[0]
                    }
                  </td>
                  <td className="px-6 py-3">
                    <p className=" font-bold">
                      {ledgerToLedger.individualNames}
                    </p>
                    <br />
                    <p className="">{ledgerToLedger.caseReferenceAuto}</p>
                  </td>
                  <td className="px-6 py-3">
                    <p className=" font-bold">
                      {ledgerToLedger.toIndividualNames}
                    </p>
                    <br />
                    <p className="">{ledgerToLedger.toCaseReferenceAuto}</p>
                  </td>
                  <td className="px-6 py-3">{ledgerToLedger.amount}</td>
                  <td className="cursor-pointer px-6 py-3">
                    <div className="flex gap-3 w-full">
                      <FaEye className="fill-gray-500 w-6 h-6" />
                      <FaEdit className="fill-blue-500 w-6 h-6" />
                      <RiDeleteBin5Fill className="fill-red-600 w-6 h-6" />
                      <MdOutlineRefresh className="fill-blue-800 w-6 h-6" />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {currentLedger.length === 0 && (
            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
              <p className="text-red-800">No Bank New Payment found.</p>
            </div>
          )}
        </div>
        {currentLedger.length > 0 && (
          <div className="w-full flex flex-col pb-[100px]">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        )}
      </div>
    </>
  );
}

export { LedgerToLedger };
