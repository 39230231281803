import { useEffect, useState } from "react";
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RightDrawer } from "../RightDrawer";
import AuthService from "../../services/auth.service";

function Header({ onMobileSidebar, openAdminDrawer }: { onMobileSidebar: any, openAdminDrawer:any }) {
    // const [openDrawer, setOpenDrawer] = useState(false); 
    const [branchName, setBranchName] = useState<string>('');

    useEffect(()=>{
        setBranchName(AuthService.getBranchName())
    })


    return (
        <>
            <div className="w-full shadow-sm bg-white-bg dark:bg-white-bg py-1.5 fixed h-fit">
                <div className="flex flex-row item-start justify-start w-full">
                    <button className="hidden md:flex bg-hover_bg left-0 pr-1 h-10 items-center rounded-r-lg justify-center md:mt-4"
                    onClick={onMobileSidebar}
                    >
                        <FontAwesomeIcon className="w-5 h-5 text-white-bg" icon={faAnglesRight} />
                    </button>
                    <div className="flex flex-row item-start justify-between w-full px-3">
                        <div className="flex flex-row items-center justify-start">
                            <a href="#!" className="flex md:flex md:flex-col md:items-start items-center space-x-3">
                                <img src="/images/header/Lait-Legal-logo.png" alt="logo" className="w-32 h-14 my-2 md:w-28 md:h-12" />
                                <span className=" md:items-start text-sm font-semibold whitespace-nowrap">{branchName}</span>
                            </a>
                        </div>
                        <div className="flex flex-row items-center justify-end gap-3">
                            <div className="flex item-center justify-center cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                                    className="w-6 h-6 fill-current">
                                    <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" /></svg>
                            </div>
                            <div className="flex item-center justify-center cursor-pointer" onClick={() => { openAdminDrawer(); }}>
                                <div className="relative">
                                    <img className="w-9 h-9 rounded-full" src="/images/header/user_profile.png" alt="user" />
                                    <span className="top-0 left-7 absolute  w-3.5 h-3.5 bg-green-400 border-2 border-white dark:border-gray-800 rounded-full"></span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}
export { Header };