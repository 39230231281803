import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object, number } from "yup";
import { useEffect, useRef, useState } from "react";
import { SelectBox } from "../../../../SelectBox";
import { FaLock } from "react-icons/fa";
import { FaLockOpen } from "react-icons/fa";
import { useAppDispatch } from "../../../../../store/hooks/redux-hooks";
import {
  addBankReceipt,
  fetchNextBankReceiptNumber,
} from "../../../../../store/slice/Accounting/ClientAccounts/ClientPosting/BankReceiptSlice";
import { toast } from "react-toastify";

import { SearchCaseReference } from "../../../SearchCaseReference";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import {
  fetchAllClientAccount,
  fetchCaseClient,
} from "../../../../../store/slice/Accounting";
import { NumericFormat } from "react-number-format";
import { MainAnimation } from "../../../../loadingAnimation/MainAnimation";

function AddBankReceipt({
  openDrawer,
  Close,
  isEdit,
  bankReceipt,
}: {
  openDrawer: boolean;
  Close: any;
  isEdit: boolean;
  bankReceipt: any;
}) {
  //const [title] = useState((!isEdit ? 'Add Bank Receipt' : 'Edit Bank Receipt'))

  const dispatch = useAppDispatch();
  const effectServiceCall = useRef(false);
  const errorShownRef = useRef(false);
  const [autoReceiptNumber, setAutoReceiptNumber] = useState<number>();
  const [initialLoading, setInitialLoading] = useState(true);
  const [openCaseAccountModal, setOpenCaseAccountModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCaseReference, setSelectedCaseReference] = useState<any>();
  const receiptAutoNumber = useSelector(
    (state: RootState) => state.bankReceipt.receiptNumber
  );
  const { clientPaymentTypes } = useSelector(
    (state: RootState) => state.clientPaymentType
  );
  const [clientPaymentTypeLists, setclientPaymentTypeLists] = useState<any[]>(
    []
  );
  const { authourisedClients } = useSelector(
    (state: RootState) => state.authorisedClient
  );
  const [authorisedClientLists, setAuthorisedClientLists] = useState<any[]>([]);
  const { clientAccounts } = useSelector(
    (state: RootState) => state.clientAccount
  );
  const [clientAccountLists, setClientAccountLists] = useState<any[]>([]);
  const { caseClient } = useSelector((state: RootState) => state.case);
  const [caseClientLists, setcaseClientLists] = useState<any[]>([]);

  useEffect(() => {
    if (!effectServiceCall.current) {
      const fetchPromises = [dispatch(fetchNextBankReceiptNumber())];

      Promise.all(fetchPromises)
        .then(() => {
          setInitialLoading(false);
        })
        .catch((error) => {
          if (!errorShownRef.current) {
            toast.error("Error fetching data: " + error.message);
            errorShownRef.current = true;
          }
          setInitialLoading(false);
        });

      effectServiceCall.current = true;
    }
  }, [dispatch]);

  useEffect(() => {
    if (receiptAutoNumber != null) {
      setValue("receiptNumber", receiptAutoNumber);
      setAutoReceiptNumber(receiptAutoNumber);
    }
  }, [receiptAutoNumber]);

  useEffect(() => {
    if (clientPaymentTypes.length > 0) {
      console.log(clientPaymentTypes);
      let data = clientPaymentTypes.map((PaymentType: any, index) => ({
        value: PaymentType.paymentTypeId,
        label: PaymentType.paymentType,
      }));
      setclientPaymentTypeLists(data);
    }
  }, [clientPaymentTypes]);

  useEffect(() => {
    // console.log(authourisedClients);
    if (authourisedClients.length > 0) {
      let data = authourisedClients.map((authorisedClient: any) => ({
        value: authorisedClient.userId,
        label: `${authorisedClient.firstName} -${authorisedClient.lastName}`,
      }));
      setAuthorisedClientLists(data);
    }
  }, [authourisedClients]);

  useEffect(() => {
    if (clientAccounts.length > 0) {
      console.log(clientAccounts);
      let data = clientAccounts.map((clientAccount: any, index) => ({
        value: clientAccount.bankAccountId,
        label: `${clientAccount.institution} - ${clientAccount.bankAccountType}`,
      }));
      setClientAccountLists(data);
    }
  }, [clientAccounts]);

  useEffect(() => {
    if (caseClient !== null && caseClient.caseClients.length > 0) {
      let data = caseClient.caseClients.map((client: any) => ({
        value: client.individualClientId,
        label: `${client.givenNames} - ${client.lastName}`,
      }));
      setcaseClientLists(data);
    }
  }, [caseClient]);

  const handleEditClick = () => {
    setIsDisabled(!isDisabled);
  };

  const [isDisabled, setIsDisabled] = useState(true);

  const validationSchema = object().shape({
    clientBankAccount: number().required("Client Bank Account is required"),
    receiptNumber: number().required("Receipt Number is required"),
    receiptDate: string()
      .required("Receipt Date is required")
      .nullable()
      .transform((value, originalValue) => {
        if (originalValue === "" || value === null) return null;
        return new Date(value).toISOString().split("T")[0];
      }),
    entryDate: string()
      .required("Entry Date is required")
      .nullable()
      .transform((value, originalValue) => {
        if (originalValue === "" || value === null) return null;
        return new Date(value).toISOString().split("T")[0];
      }),
    fkCaseId: string().required("Case Reference is required"),
    availableBalance: string().required("Available Balance is required"),
    receivedFromOther: string().required("Received From is required"),
    transactionDescription: string().required(
      "Transaction Description is required"
    ),
    amount: string()
      .typeError("Amount must be a number")
      .required("Amount is required"),
    comments: string(),
    authorisedBy: string().required("Authorised By is required"),
    paymentReference: string().required("Payment Reference is required"),
    paymentTypeId: number().required("Payment Type is required"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      receiptNumber: autoReceiptNumber,
    },
  });

  const onSubmit = async (data: any) => {
    console.log(errors);
    // console.log(JSON.stringify(data, null, 2));

    setIsLoading(true);
    try {
      await dispatch(addBankReceipt(data)).unwrap();
      Close();
      toast.success("Successfully Added Bank Receipt");
    } catch (error: any) {
      toast.error(error.toString());
    } finally {
      setIsLoading(false);
    }
  };

  const onError = (errors: any) => {
    console.log("Form submission errors", errors);
  };

  const toggleCaseAccountModal = () => {
    setOpenCaseAccountModal(!openCaseAccountModal);
  };
  const handleCaseSelection = (selectedCase: any) => {
    setSelectedCaseReference(selectedCase);
    setValue("fkCaseId", selectedCase.caseId);
    dispatch(fetchCaseClient(selectedCase.caseId));
    setOpenCaseAccountModal(false);
  };

  return (
    <>
      {openDrawer === true ? (
        <form onSubmit={handleSubmit(onSubmit, onError)} className="mx-auto">
          {initialLoading ? <MainAnimation /> : ""}
          <div className="flex flex-row md:flex-col justify-between mb-5 gap-2 w-full md:gap-3">
            <div className="relative flex">
              <input
                type="text"
                placeholder={selectedCaseReference?.caseReferenceAuto}
                aria-label="Search"
                value={selectedCaseReference?.caseReferenceAuto || ""}
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                  errors.fkCaseId ? "is-invalid" : ""
                }`}
                {...register("fkCaseId")}

                //  disabled
              />
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.fkCaseId?.message?.toString()}
              </div>
              <button
                onClick={toggleCaseAccountModal}
                className="z-[2] inline-block rounded-e border-2 border-primary px-6 pb-[6px] pt-2 text-xs font-medium  leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-accent-300 hover:bg-primary-50/50 hover:text-primary-accent-300 focus:border-primary-600 focus:bg-primary-50/50 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:text-primary-500 bg-hover_bg text-white-bg border-hover_bg -ml-1"
                data-twe-ripple-init
                data-twe-ripple-color="white"
                type="button"
              >
                Search
              </button>
              {openCaseAccountModal && (
                <SearchCaseReference
                  openDrawer={openCaseAccountModal}
                  Close={toggleCaseAccountModal}
                  onCaseSelect={handleCaseSelection}
                />
              )}
            </div>
            <div>
              <button
                type="button"
                onClick={() => {
                  Close();
                }}
                className="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-2 rounded"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="grid grid-cols-4 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 gap-y-1 w-full">
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Receipt Number
              </label>
              <div className="flex gap-3">
                <input
                  type="text"
                  value={autoReceiptNumber}
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg disabled:bg-gray-300 dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                  disabled={isDisabled}
                />
                <button
                  type="button"
                  onClick={handleEditClick}
                  className="mt-2 text-sm font-medium text-blue-600 dark:text-blue-500"
                >
                  {isDisabled ? <FaLock /> : <FaLockOpen />}
                </button>
              </div>
            </div>
            <div className="">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Client Bank Account <span className="text-red-500">*</span>
              </label>
              <SelectBox
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                indicator={
                  <svg
                    width="15"
                    className="fill-current mr-2"
                    height="15"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                }
                isMulti={false}
                name="frameFiftySix"
                options={clientAccountLists}
                value={getValues().clientBankAccount}
                isSearchable={true}
                placeholder="Select"
                shape="round"
                color=""
                size="xs"
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setValue("clientBankAccount", selectedOption);
                  }
                }}
              />
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.clientBankAccount?.message?.toString()}
              </div>
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Transaction Date <span className="text-red-500">*</span>
              </label>
              <input
                type="date"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                  errors.receiptDate ? "is-invalid" : ""
                }`}
                {...register("receiptDate")}
                max={new Date().toISOString().split("T")[0]}
                placeholder="Enter Transaction Date"
              />
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.receiptDate?.message?.toString()}
              </div>
            </div>

            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Entry Date <span className="text-red-500">*</span>
              </label>
              <input
                type="date"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                  errors.entryDate ? "is-invalid" : ""
                }`}
                {...register("entryDate")}
                max={new Date().toISOString().split("T")[0]}
                placeholder="Enter Date"
              />
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.entryDate?.message?.toString()}
              </div>
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Available Balance <span className="text-red-500">*</span>
              </label>
              <NumericFormat
                decimalScale={2}
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                  errors.availableBalance ? "is-invalid" : ""
                }`}
                {...register("availableBalance")}
                name="availableBalance"
                onValueChange={(values) => {
                  setValue("availableBalance", values.value);
                }}
                placeholder="Enter Available Balance"
              />
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.availableBalance?.message?.toString()}
              </div>
            </div>
            <div className="">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Received From <span className="text-red-500">*</span>
              </label>
              <SelectBox
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                indicator={
                  <svg
                    width="15"
                    className="fill-current mr-2"
                    height="15"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                }
                isMulti={false}
                name="frameFiftySix"
                options={caseClientLists}
                isSearchable={true}
                value={getValues().receivedFromOther}
                placeholder="Select"
                shape="round"
                color="indigo_50"
                size="xs"
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setValue("receivedFromOther", selectedOption);
                  }
                }}
              />
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.receivedFromOther?.message?.toString()}
              </div>
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Transaction Description <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                  errors.transactionDescription ? "is-invalid" : ""
                }`}
                {...register("transactionDescription")}
                placeholder="Enter Transaction Description"
              />
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.transactionDescription?.message?.toString()}
              </div>
            </div>

            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Amount (£) <span className="text-red-500">*</span>
              </label>
              <NumericFormat
                decimalScale={2}
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                  errors.amount ? "is-invalid" : ""
                }`}
                {...register("amount")}
                placeholder="Enter Amount (£)"
                onValueChange={(values) => {
                  setValue("amount", values.value);
                }}
              />
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.amount?.message?.toString()}
              </div>
            </div>
            <div className="">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Authorised By <span className="text-red-500">*</span>
              </label>
              <SelectBox
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                indicator={
                  <svg
                    width="15"
                    className="fill-current mr-2"
                    height="15"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                }
                isMulti={false}
                name="frameFiftySix"
                options={authorisedClientLists}
                isSearchable={true}
                value={getValues().authorisedBy}
                placeholder="Select"
                shape="round"
                color="indigo_50"
                size="xs"
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setValue("authorisedBy", selectedOption);
                  }
                }}
              />
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.authorisedBy?.message?.toString()}
              </div>
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Payment Reference <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                  errors.paymentReference ? "is-invalid" : ""
                }`}
                {...register("paymentReference")}
                placeholder="Enter Payment Reference"
              />
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.paymentReference?.message?.toString()}
              </div>
            </div>
            <div className="">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Payment Type <span className="text-red-500">*</span>
              </label>
              <SelectBox
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                indicator={
                  <svg
                    width="15"
                    className="fill-current mr-2"
                    height="15"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                }
                isMulti={false}
                name="frameFiftySix"
                options={clientPaymentTypeLists}
                isSearchable={true}
                value={getValues().paymentTypeId}
                placeholder="Select"
                shape="round"
                color="indigo_50"
                size="xs"
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setValue("paymentTypeId", selectedOption);
                  }
                }}
              />
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.paymentTypeId?.message?.toString()}
              </div>
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Comments
              </label>
              <textarea
                className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                placeholder="Comments"
                {...register("comments")}
              ></textarea>
            </div>
          </div>
          

          <div className="flex md:flex-col gap-2 flex-row justify-start w-full my-5">
          <div className="flex flex-row gap-2 items-end justify-end w-full">
            <button
              type="button"
              onClick={() => {
                Close();
              }}
              className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isLoading}
              className={` cursor - pointer bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]  ${
                isLoading ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {isLoading ? (
                <svg
                  className="animate-spin h-5 w-5 text-white-bg mx-auto"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v8H4z"
                  ></path>
                </svg>
              ) : (
                "Save"
              )}
            </button>
          </div>
          </div>
        </form>
      ) : null}
    </>
  );
}

export { AddBankReceipt };
