import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import RoleManagementServices from "../../../services/Administrator/RoleManagementServices";


export interface Role  {
  userRoleId: number;
  userRoleName: string;
  description: string;
  rank: number;
  isAreasOfPracticeInvolved: boolean;
  fkMenuId: string;
  fkOperationId: string;
  isActive: boolean;
  isDeleted: boolean;
};
// Define the state type for roles
type RoleState = {
  roles: Role[];
  status: "idle" | "loading" | "failed";
  error: string | null;
};

// Initial state
const initialState: RoleState = {
  roles: [],
  status: "idle",
  error: null
};

// Async thunks for CRUD operations
export const fetchAllRole = createAsyncThunk(
  "fetchAllRole",
  async (_, thunkAPI) => {
    try {
      const response = await RoleManagementServices.fetchAllRoleData();
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const addRole = createAsyncThunk(
  "addRole",
  async (data: any, thunkAPI) => {
    try {
      const response = await RoleManagementServices.addRoleData(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updateRole = createAsyncThunk(
  "updateRole",
  async ({ id, data }: { id: number, data: Role }, thunkAPI) => {
    try {
      const response = await RoleManagementServices.updateRoleData(id, data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const deleteRole = createAsyncThunk(
  "deleteRole",
  async (roleId: number, thunkAPI) => {
    try {
      await RoleManagementServices.deleteRoleData(roleId);
      return roleId;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Slice definition
const RoleManagementSlice = createSlice({
  name: "role",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllRole.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllRole.fulfilled, (state, action: PayloadAction<Role[]>) => {
        state.status = "idle";
        state.roles = action.payload;
      })
      .addCase(fetchAllRole.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(addRole.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addRole.fulfilled, (state, action: PayloadAction<Role>) => {
        state.status = "idle";
        state.roles.push(action.payload);
      })
      .addCase(addRole.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(updateRole.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateRole.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = "idle";
        if (action.payload != null) {
          state.roles = action.payload || [];
        }
      })
      .addCase(updateRole.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(deleteRole.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteRole.fulfilled, (state, action: PayloadAction<number>) => {
        state.status = "idle";
      })
      .addCase(deleteRole.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      });
  }
});

export default RoleManagementSlice.reducer;
