import { SearchInput } from "../Filters/SearchInput";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { AddSuppliers } from "./SuppliersDetails/AddSuppliers";
import { useEffect, useRef, useState } from "react";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { fetchAllOfficeSupplier } from "../../store/slice/Accounting/OfficeAccounts/Suppliers/OfficeSupplierSlice";
import { toast } from "react-toastify";
import { Pagination } from "../Pagination";
function Suppliers() {
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const dispatch = useAppDispatch();
    const [initialLoading, setInitialLoading] = useState(true);
    const officeSupplierData = useSelector((state: RootState) => state.officeSupplier.officeSupplierData);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [officeSupplierDatas, setOfficeSupplierDatas] = useState<any>([]);
    const [officeSupplierGetData, setOfficeSupplierGetData] = useState<any[]>([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentOfficeSupplier, setCurrentOfficeSupplier] = useState<any[]>([]);
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);


    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
            dispatch(fetchAllOfficeSupplier())
                .then(() => setInitialLoading(false))
                .catch(() => setInitialLoading(false));
            effectServiceCall.current = true;
        }
    }, [dispatch]);

    useEffect(() => {
        if (officeSupplierData && officeSupplierData.listingData) {
            setOfficeSupplierGetData(officeSupplierData.listingData);
        }
    }, [officeSupplierData]);

    useEffect(() => {
        if (officeSupplierGetData != null && officeSupplierGetData.length > 0) {
            const filteredByOfficeSupplier = filterOfficeSupplierBySearchQuery();
            const indexOfLastOfficeSupplier = currentPage * itemsPerPage;
            const indexOfFirstOfficeSupplier = indexOfLastOfficeSupplier - itemsPerPage;
            const currentOfficeSuppliers = filteredByOfficeSupplier.slice(indexOfFirstOfficeSupplier, indexOfLastOfficeSupplier);
            const totalPages = Math.ceil(officeSupplierGetData.length / itemsPerPage);
            setTotalPages(totalPages)
            setCurrentOfficeSupplier(currentOfficeSuppliers)
        }

    }, [officeSupplierGetData])


    const filterOfficeSupplierBySearchQuery = () => {

        return officeSupplierGetData.filter((officeSupplier: any) =>
            officeSupplier?.accountName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            officeSupplier?.accountType.toLowerCase().includes(searchQuery.toLowerCase()));
    };




    const toggleDrawer = () => {
        setIsEdit(false);
        setOpenModal(!openModal);
    };

    if (openModal) {
        return (
            <AddSuppliers openModal={openModal} Close={toggleDrawer} isEdit={isEdit} officeSupplier={officeSupplierDatas} />
        )
    }
    return (
        <>
            <div className="">
                <div className="mb-5">
                    <SubHeaderTitle title="Suppliers" buttonName="Suppliers" isAdd={true} onClickAdd={toggleDrawer} />
                </div>
                <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
                    <SearchInput title="Keyword" />
                </div>
                <div className="relative overflow-x-auto sm:rounded-lg">
                    <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                        {initialLoading ? (<MainAnimation />) : ""}
                        <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <th scope="col" className="px-6 py-3">
                                    Supplier
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Service / Product
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentOfficeSupplier.map((officeSupplier: any, index) => (
                                <tr key={index} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    <td className="px-6 py-3">
                                        {officeSupplier.supplierName}
                                    </td>
                                    <td className="px-6 py-3">
                                        {officeSupplier.servicesProducts}
                                    </td>
                                    <td className="cursor-pointer px-6 py-3">
                                        <div className="flex gap-3 w-full">
                                            <FaEdit className="fill-blue-500 w-6 h-6" />
                                            <RiDeleteBin5Fill className="fill-red-600 w-6 h-6" />
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {currentOfficeSupplier.length === 0 && (
                        <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
                            <p className="text-red-800">No Office supplier Details found.</p>
                        </div>
                    )}
                </div>
                {currentOfficeSupplier.length > 0 && (
                    <div className="w-full flex flex-col pb-[100px]">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={(page) => setCurrentPage(page)}
                        />
                    </div>
                )}
            </div>
        </>
    )
}

export { Suppliers };