import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import UserManagementServices from "../../../services/Administrator/UseruanagementService";

export interface User {
  userId: number;
  fkTitleId: number;
  firstName: string;
  middleName: string;
  lastName: string;
  userCode: string;
  photo: string;
  dob: string;
  sex: boolean;
  fkMaritalStatusId: number;
  qualifications: string;
  address: string;
  email: string;
  homePhone: string;
  mobile: string;
  fkUserRoleId: number;
  designation: string;
  niNumber: string;
  signature: string;
  signatureText: string;
  isAccessTokenActive: boolean;
  accessToken: string;
  notes: string;
  userName: string;
  password: string;
  isActive: boolean;
  fkBranchId: number[];
  // fkAreasOfPracticeId: number[];
};

export interface BranchUsers {
  userId: number;
  title: string;
  firstName: string;
  lastName: string;
  designation: string;
  displayName: string;
};

// Define the state type for users
type UserState = {
  users: User[];
  branchUsers: BranchUsers[];
  status: "idle" | "loading" | "failed";
  error: string | null;
};

// Initial state
const initialState: UserState = {
  users: [],
  branchUsers: [],
  status: "idle",
  error: null
};

// Async thunks for CRUD operations
export const fetchUsersDataByBranch = createAsyncThunk(
  "fetchUsersDataByBranch",
  async (_, thunkAPI) => {
    try {
      const response = await UserManagementServices.GetUsersDataByBranch();
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchAllUser = createAsyncThunk(
  "fetchAllUser",
  async (_, thunkAPI) => {
    try {
      const response = await UserManagementServices.GetAllUser();
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const addUser = createAsyncThunk(
  "addUser",
  async (data: any, thunkAPI) => {
    try {
      //data.fkBranchId = data.fkBranchId.toString();
      // data.fkAreasOfPracticeId = data.fkAreasOfPracticeId.toString();
      const response = await UserManagementServices.addUserData(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// export const updateUser = createAsyncThunk(
//   "updateUser",
//   async (updatedUser: User, thunkAPI) => {
//     try {
//       const response = await UserManagementServices.updateUserData(updatedUser);
//       return response;
//     } catch (error: any) {
//       return thunkAPI.rejectWithValue(error.message);
//     }
//   }
// );

// export const deleteUser = createAsyncThunk(
//   "deleteUser",
//   async (userId: number, thunkAPI) => {
//     try {
//       await UserManagementServices.deleteUserData(userId);
//       return userId;
//     } catch (error: any) {
//       return thunkAPI.rejectWithValue(error.message);
//     }
//   }
// );

// Slice definition
const UserManagementSlice = createSlice({
  name: "userManage",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllUser.fulfilled, (state, action: PayloadAction<User[]>) => {
        state.status = "idle";
        state.users = action.payload;
      })
      .addCase(fetchAllUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(fetchUsersDataByBranch.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUsersDataByBranch.fulfilled, (state, action: PayloadAction<BranchUsers[]>) => {
        state.status = "idle";
        state.branchUsers = action.payload;
      })
      .addCase(fetchUsersDataByBranch.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(addUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addUser.fulfilled, (state, action: PayloadAction<User>) => {
        state.status = "idle";
        state.users.push(action.payload);
      })
      .addCase(addUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
    // .addCase(updateUser.pending, (state) => {
    //   state.status = "loading";
    // })
    // .addCase(updateUser.fulfilled, (state, action: PayloadAction<User>) => {
    //   state.status = "idle";
    //   const index = state.users.findIndex(t => t.id === action.payload.id);
    //   if (index !== -1) {
    //     state.users[index] = action.payload;
    //   }
    // })
    // .addCase(updateUser.rejected, (state, action) => {
    //   state.status = "failed";
    //   state.error = action.payload as string;
    // })
    // .addCase(deleteUser.pending, (state) => {
    //   state.status = "loading";
    // })
    // .addCase(deleteUser.fulfilled, (state, action: PayloadAction<number>) => {
    //   state.status = "idle";
    //   state.users = state.users.filter(t => t.id !== action.payload);
    // })
    // .addCase(deleteUser.rejected, (state, action) => {
    //   state.status = "failed";
    //   state.error = action.payload as string;
    // });
  }
});

export default UserManagementSlice.reducer;
