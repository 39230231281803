import { Modal } from 'flowbite-react';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object, boolean, number } from 'yup';
import { useAppDispatch } from '../../../../store/hooks/redux-hooks';
import { addCheckList } from '../../../../store/slice/SystemMaintenance/ChecklistSlice';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { GetAllAreasOfPractice } from '../../../../store/slice/Administrator/AreasOfPracticeSlice';
import { fetchAllCaseType } from '../../../../store/slice/SystemMaintenance/CaseTypeSlice';
import { fetchAllCaseSubType } from '../../../../store/slice/SystemMaintenance/CaseSubTypeSlice';
import { SelectBox } from '../../../SelectBox';
import { toast } from 'react-toastify';
import Select, { Props as SelectProps } from "react-select";


function AddCheckList({ openDrawer, Close, checkList, isEdit }: { openDrawer: boolean, Close: any, checkList?: any, isEdit: boolean }) {
    const [title] = useState((!isEdit ? 'Add New Checklist' : 'Edit Checklist'))
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [modalPlacement, setModalPlacement] = useState('center');
    const [existingCheckList, setExistingCheckList] = useState(checkList);

    const { areasOfPractice } = useSelector((state: RootState) => state.areasOfPractice);
    const { casetypes } = useSelector((state: RootState) => state.caseType);
    const { CaseSubTypes } = useSelector((state: RootState) => state.caseSubType);

    const [filteredCaseTypes, setFilteredCaseTypes] = useState<any[]>([]);
    const [filteredCaseSubTypes, setFilteredCaseSubTypes] = useState<any[]>([]);
    const [areaOptionsLists, setAreaOptionsLists] = useState<any[]>([]);
    const [caseTypeOptionsLists, setCaseTypeOptionsLists] = useState<any[]>([]);
    const [subCaseOptionsLists, setSubCaseOptionsLists] = useState<any[]>([]);

    const effectServiceCall = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
            dispatch(GetAllAreasOfPractice());
            dispatch(fetchAllCaseType());
            dispatch(fetchAllCaseSubType());
        }
        return () => { effectServiceCall.current = true; };
    }, [dispatch]);


    useEffect(() => {
        if (areasOfPractice.length > 0) {
            let data = areasOfPractice.map((area: any, index) => ({
                value: area.areasOfPracticeId,
                label: area.area,
            }));
            setAreaOptionsLists(data);

        }

        if (isEdit && checkList) {
            setFilteredCaseTypes(casetypes.filter((type: any) => type.fkAreasOfPracticeId === checkList.fkAreasOfPracticeId));
            setFilteredCaseSubTypes(CaseSubTypes.filter((subType: any) => subType.fkCaseTypeId === checkList.fkCaseTypeId));
        }

    }, [areasOfPractice, casetypes]);

    useEffect(() => {
        if (filteredCaseTypes != null && filteredCaseTypes.length > 0) {
            let data = filteredCaseTypes.map((casetype: any) => ({
                value: casetype.caseTypeId,
                label: casetype.caseType,
            }));
            setCaseTypeOptionsLists(data);
        }
    }, [filteredCaseTypes]);

    useEffect(() => {
        if (filteredCaseSubTypes != null && filteredCaseSubTypes.length > 0) {
            let data = filteredCaseSubTypes.map((subcase: any) => ({
                value: subcase.caseSubTypeId,
                label: subcase.subCaseType,
            }));
            setSubCaseOptionsLists(data);
        }

    }, [filteredCaseSubTypes]);



    const validationSchema = object().shape({
        checklistName: string().required('Checklist Name is required'),
        fkAreasOfPracticeId: number().required('Areas of Practice is required'),
        fkCaseTypeId: number().required('Case Type is required'),
        fkCaseSubTypeId: string(),
        checklistIsActive: boolean(),

    });

    const { register, handleSubmit, reset, getValues, setValue, formState: { errors }, watch } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            checklistIsActive: true,
        }
    });


    const onSubmit = (data: any) => {
        dispatch(addCheckList(data))
            .unwrap()
            .then(() => {
                Close();
                navigate("/systemmanagement/systemmaintenance/checklist");
                toast.success("Successfully Added Checklist");
            })
            .catch((error) => {
                toast.error(error)
            });
    };

    const handleAreaChange = (selectedOption: any) => {
        if (selectedOption) {
            setValue('fkAreasOfPracticeId', selectedOption);
            setFilteredCaseTypes(casetypes.filter((type: any) => type.fkAreasOfPracticeId === selectedOption));
            setFilteredCaseSubTypes([]);
        }
    };

    const handleCaseTypeChange = (selectedOption: any) => {
        if (selectedOption) {
            setValue('fkCaseTypeId', selectedOption);
            setFilteredCaseSubTypes(CaseSubTypes.filter((subType: any) => subType.fkCaseTypeId === selectedOption));
            setValue('fkCaseSubTypeId', undefined);
        }

    };

    const handleSubCaseTypeChange = (selectedOption: any) => {
        setValue('fkCaseSubTypeId', selectedOption);
    };

    useEffect(() => {
        if (isEdit && checkList) {
            setExistingCheckList(checkList);
            reset(checkList);

            // handleAreaChange(checkList.fkAreasOfPracticeId)
            // handleCaseTypeChange(checkList.fkCaseTypeId);
        }
    }, [checkList, isEdit, reset, setValue]);

    return (
        <Modal
            className={`backdrop-blur-sm items-center justify-center`}
            show={openDrawer}
            position={modalPlacement}
            onClose={() => { reset(); Close(); }}
            initialFocus={emailInputRef}
        >
            <Modal.Header className="bg-white-bg dark:bg-dark_bg p-5">
                <span className="dark:text-white-bg text-xl w-auto">{title}</span>
            </Modal.Header>
            <Modal.Body className="bg-white-bg dark:bg-dark_bg">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="space-y-6 w-full">
                        <div className="mx-auto">
                            <div className="relative mb-5">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Name <span className="text-red-500">*</span>
                            </label>
                            <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.checklistName ? 'is-invalid' : ''}`} {...register('checklistName')}
                                placeholder="Enter Checklist Name"
                            />
                            <div className="font-medium mt-2 text-xs text-red-600" >{errors?.checklistName?.message?.toString()}</div>
                        </div>
                            <div className="mb-5">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Area of Practice <span className="text-red-500">*</span>
                                </label>
                                <SelectBox
                                    className="bg-gray-50 border  border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                    indicator={
                                        <svg
                                            width="15"
                                            className="fill-current mr-2"
                                            height="15"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512">
                                            <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                        </svg>
                                    }
                                    isMulti={false}
                                    name="areaotionList"
                                    options={areaOptionsLists}
                                    isSearchable={true}
                                    value={getValues().fkAreasOfPracticeId}
                                    placeholder="Area of Practice"
                                    shape="round"
                                    color="indigo_50"
                                    size="xs"
                                    onChange={handleAreaChange}
                                />
                                <div className="font-medium mt-2 text-xs text-red-600" >{errors?.fkAreasOfPracticeId?.message?.toString()}</div>
                            </div>
                            <div className="mb-5">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Case Type <span className="text-red-500">*</span>
                                </label>
                                <SelectBox
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                    indicator={
                                        <svg
                                            width="15"
                                            className="fill-current mr-2"
                                            height="15"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512">
                                            <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                        </svg>
                                    }
                                    isMulti={false}
                                    name="caseTypeOptionList"
                                    options={caseTypeOptionsLists}
                                    value={getValues().fkCaseTypeId}
                                    isSearchable={true}
                                    placeholder="Case Type"
                                    shape="round"
                                    color="indigo_50"
                                    size="xs"
                                    onChange={handleCaseTypeChange}
                                />
                                <div className="font-medium mt-2 text-xs text-red-600" >{errors?.fkCaseTypeId?.message?.toString()}</div>
                            </div>
                            <div className="mb-5">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Case Sub Type
                                </label>
                                <SelectBox
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                    indicator={
                                        <svg
                                            width="15"
                                            className="fill-current mr-2"
                                            height="15"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512">
                                            <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                        </svg>
                                    }
                                    isMulti={false}
                                    name="subCaseTypeOptionList"
                                    options={subCaseOptionsLists}
                                    value={getValues().fkCaseSubTypeId}
                                    isSearchable={true}
                                    placeholder="Case Sub Type"
                                    shape="round"
                                    color="indigo_50"
                                    size="xs"
                                    onChange={handleSubCaseTypeChange}
                                />
                            </div>
                            <div className="relative max-w-full flex gap-6">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Is Active
                                </label>
                                <div className='flex gap-5'>
                                    <div>
                                        <input
                                            type="radio"
                                            value="true"
                                            checked={watch('checklistIsActive') === true}
                                            onChange={() => setValue('checklistIsActive', true)}
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                        <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-white-bg">Yes</label>
                                    </div>
                                    <div>
                                        <input
                                            id="bordered-radio-1"
                                            type="radio"
                                            value="false"
                                            checked={watch('checklistIsActive') === false}
                                            onChange={() => setValue('checklistIsActive', false)}
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                        <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-white-bg">No</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
                            <div className="flex flex-row gap-2 items-end justify-end w-full">
                                <button
                                    onClick={() => {
                                        reset();
                                        Close();
                                    }}
                                    type='button'
                                    className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                                >
                                    Cancel
                                </button>
                                <button
                                    type='submit'
                                    className="bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export { AddCheckList };

