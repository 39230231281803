import { Modal } from "flowbite-react";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object } from "yup";
import { useAppDispatch } from "../../../../../store/hooks/redux-hooks";

function AddFormsAndPrecedents({
  openDrawer,
  Close,
}: {
  openDrawer: boolean;
  Close: any;
}) {
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState(true);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [modalPlacement, setModalPlacement] = useState("center");

  const validationSchema = object().shape({
    
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data: any) => {
    console.log(JSON.stringify(data, null, 2));
    // dispatch(AddFormsAndPrecedents(data));
    Close();
  };

  return (
    <Modal
      className={`backdrop-blur-sm items-center justify-center`}
      show={openDrawer}
      position={modalPlacement}
      onClose={() => {
        reset();
        Close();
      }}
      initialFocus={emailInputRef}
      size="2xl"
    >
      <Modal.Header className="bg-white-bg dark:bg-dark_bg p-5">
        <span className="text-blue_gray-900 dark:text-white-bg text-xl w-auto">
        Add Forms and Precedents
        </span>
      </Modal.Header>
      <Modal.Body className="bg-white-bg dark:bg-dark_bg">
        <form onSubmit={handleSubmit(onSubmit)} className="mx-auto">
          <div className="space-y-3 w-full">
            
            <div>

            </div>
            
            <div className="flex md:flex-col gap-2 flex-row justify-start w-full my-5">
              <div className="flex flex-row gap-2 items-end justify-end w-full">
                <button
                  onClick={() => {
                    reset();
                    setOpenModal(Close);
                  }}
                  className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export { AddFormsAndPrecedents };
