import { useEffect, useRef, useState } from "react";
import { SearchInput } from "../Filters/SearchInput";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { FaEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { AddClientPayment } from "../Modal/AddOfficeAccounts/AddOfficePostings/AddClientPayment";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { fetchAllClientPayment } from "../../store/slice/Accounting/OfficeAccounts/OfficePostings/ClientPaymentSlice";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { Pagination } from "../Pagination";

function ClientPayment() {
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [clientPaymentDatas, setClientPaymentDatas] = useState<any>([]);
    const [clientPaymentGetData, setClientPaymentGetData] = useState<any[]>([]);
    const dispatch = useAppDispatch();
    const [initialLoading, setInitialLoading] = useState(true);
    const clientPaymentData = useSelector((state: RootState) => state.clientPayment.clientPaymentData);
    const [totalPages, setTotalPages] = useState(0);
    const [currentClientPayment, setCurrentClientPayment] = useState<any[]>([]);
    const itemsPerPage = 10;
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [currentPage, setCurrentPage] = useState(1);


    const effectServiceCall = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
             dispatch(fetchAllClientPayment())
                .then(() => setInitialLoading(false))
                .catch(() => setInitialLoading(false));
            effectServiceCall.current = true;
        }
    }, [dispatch]);

    useEffect(() => {
        if (clientPaymentData && clientPaymentData.listingData) {
            setClientPaymentGetData(clientPaymentData.listingData);
        }
      }, [clientPaymentData]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        if(clientPaymentGetData!=null && clientPaymentGetData.length > 0){
            const filteredByClientPayment = filterClientPaymentBySearchQuery();
            const indexOfLastClientPayment = currentPage * itemsPerPage;
            const indexOfFirstClientPayment = indexOfLastClientPayment - itemsPerPage;
            const currentClientPayment = filteredByClientPayment.slice(indexOfFirstClientPayment, indexOfLastClientPayment);
            const totalPages = Math.ceil(clientPaymentGetData.length / itemsPerPage);
            setTotalPages(totalPages)
            setCurrentClientPayment(currentClientPayment)
        }

    },[clientPaymentGetData])


    const filterClientPaymentBySearchQuery = () => {

        return clientPaymentGetData.filter((clientPayment: any) =>    
            clientPayment?.accountName?.toLowerCase().includes(searchQuery.toLowerCase()));
    };

    const toggleDrawer = () => {
        setIsEdit(false);
        setOpenModal(!openModal);
    };

    if (openModal) {
        return (
            <AddClientPayment openDrawer={openModal} Close={toggleDrawer} isEdit={isEdit} clientPayment={clientPaymentDatas}/>
        )
    }
    return (
        <>
            <div className="">
                <div className="mb-5">
                    <SubHeaderTitle title="Client Payment" buttonName="Client Payment" isAdd={true} onClickAdd={toggleDrawer} />
                </div>
                <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
                    <SearchInput title="Keyword" />
                    <div>
                        <div className="flex gap-4 md:gap-1 w-full">
                            <CheckBoxFilter title="Office Bank" />
                        </div>
                    </div>
                </div>
                <div className="relative overflow-x-auto sm:rounded-lg">
                    <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                    {initialLoading ? <MainAnimation /> : ""}
                        <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <th scope="col" className="px-6 py-3">
                                    Date
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Client and Case Details
                                </th>
                                <th scope="col" className="px-6 py-3">
                                Office Bank
                                </th>
                                <th scope="col" className="px-6 py-3">
                                Description
                                </th>
                                <th scope="col" className="px-6 py-3">
                                Amount	
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>

                        {currentClientPayment.map((clientPayment: any, index) => (
                            <tr key={index} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <td className="px-6 py-3">
                               {new Date(clientPayment.transactionDate).toISOString().split('T')[0]}
                                </td>
                                <td className="px-6 py-3">
                                    <p className="font-semibold">{clientPayment.individualNames}</p>
                                    <p>{clientPayment.caseReferenceAuto}</p>
                                </td>
                                <td className="px-6 py-3">
                                {clientPayment.bankAccountCode} {clientPayment.accountName}
                                </td>
                                <td className="px-6 py-3">
                                {clientPayment.description}
                                </td>
                                <td className="text-right px-6 py-3">
                                £ {clientPayment.amount}
                                </td>
                                <td className="cursor-pointer px-6 py-3">
                                    <div className="flex gap-3 w-full">
                                        <FaEye className="fill-gray-500 w-6 h-6" />
                                        <FaEdit className="fill-blue-500 w-6 h-6" />
                                        <RiDeleteBin5Fill className="fill-red-600 w-6 h-6" />
                                    </div>
                                </td>
                            </tr>
                               ))}
                           
                        </tbody>
                    </table>
                    {currentClientPayment.length === 0 && (
                            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
                                <p className="text-red-800">No Client Payment Details found.</p>
                            </div>
                        )}
                </div>
                {currentClientPayment.length > 0 && (
                    <div className="w-full flex flex-col pb-[100px]">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={(page) => setCurrentPage(page)}
                        />
                    </div>
                )}
                </div>
           
        </>
    )
}

export { ClientPayment };