import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";


const GetAllBalanceSheet = async () => {
    try {
        const response = await axiosInstance.get("BalanceSheet");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}




const BalanceSheetService = {
    GetAllBalanceSheet,
   
};


export default BalanceSheetService;
