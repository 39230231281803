import { SearchInput } from "../Filters/SearchInput";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { FaEye } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";
import { AddSuppliersBillEntry } from "./SuppliersBillEntryDetails/AddSuppliersBillEntry";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { fetchAllSuppliersBillEntry } from "../../store/slice/Accounting/OfficeAccounts/Suppliers/SuppliersBillEntrySlice";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { Pagination } from "flowbite-react";
function SuppliersBillEntry() {
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const dispatch = useAppDispatch();
    const [initialLoading, setInitialLoading] = useState(true);
    const {suppliersBillEntries, status, error } = useSelector((state: RootState) => state.suppliersBillEntry);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [suppliersBillEntryData, setSuppliersBillEntryData] = useState<any>([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentSuppliersBillEntry, setCurrentSuppliersBillEntry] = useState<any[]>([]);
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);

    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
            let data = {
                  keyword: ''
            }
            dispatch(fetchAllSuppliersBillEntry(data))
                .then(() => setInitialLoading(false))
                .catch(() => setInitialLoading(false));
            effectServiceCall.current = true;
        }

        if (status === 'failed' && !errorShownRef.current) {
            // toast.error(error);
            errorShownRef.current = true;
        }
    }, [dispatch, status, error]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    // useEffect(() => {
    //     if(suppliersBillEntries!=null && suppliersBillEntries.length > 0){
    //         const filteredBySuppliersBillEntry = filterSuppliersBillEntryBySearchQuery();
    //         const indexOfLastSuppliersBillEntry = currentPage * itemsPerPage;
    //         const indexOfFirstSuppliersBillEntry = indexOfLastSuppliersBillEntry - itemsPerPage;
    //         const currentSuppliersBillEntry = filteredBySuppliersBillEntry.slice(indexOfFirstSuppliersBillEntry, indexOfLastSuppliersBillEntry);
    //         const totalPages = Math.ceil(suppliersBillEntries.length / itemsPerPage);
    //         setTotalPages(totalPages)
    //         setCurrentSuppliersBillEntry(currentSuppliersBillEntry)
    //     }

    // },[suppliersBillEntries])

   

        useEffect(() => {
            if(suppliersBillEntries!=null && (suppliersBillEntries.listingData!=null && suppliersBillEntries.listingData.length > 0)){
               const totalPages = suppliersBillEntries.totalRecords;
                setTotalPages(totalPages)
                setCurrentSuppliersBillEntry(suppliersBillEntries.listingData)
            }
        
        },[suppliersBillEntries]);


    // const filterSuppliersBillEntryBySearchQuery = () => {

    //     return suppliersBillEntries.filter((suppliersBillEntry: any) =>    
    //         suppliersBillEntry?.supplier?.toLowerCase().includes(searchQuery.toLowerCase()));
    // };

    
    const toggleDrawer = () => {
        setOpenModal(!openModal);
    };

    if (openModal) {
        return (
            <AddSuppliersBillEntry openModal={openModal} Close={toggleDrawer} isEdit={isEdit} suppliersBillEntry={suppliersBillEntryData}/>
        )
    }

    return (
        <>
            <div className="">
                <div className="mb-5">
                    <SubHeaderTitle title="Suppliers Bill Entry" buttonName="Bill Entry" isAdd={true} onClickAdd={toggleDrawer} />
                </div>
                <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
                    <SearchInput title="Keyword" />
                    <div>
                        <div className="flex gap-4 md:gap-1 w-full">
                            <CheckBoxFilter title="Supplier" />
                        </div>
                    </div>
                </div>
                <div className="relative overflow-x-auto sm:rounded-lg">
                    <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                    {initialLoading ? (<MainAnimation />) : ""}
                         <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <th scope="col" className="px-6 py-3">
                                Bill Entry Number
                                </th>
                                <th scope="col" className="px-6 py-3">
                                Supplier
                                </th>
                                <th scope="col" className="px-6 py-3">
                                Invoice Number
                                </th>
                                <th scope="col" className="px-6 py-3">
                                Due Date
                                </th>
                                <th scope="col" className="px-6 py-3">
                                Gross Amount	
                                </th>
                                <th scope="col" className="px-6 py-3">
                                VAT	
                                </th>
                                <th scope="col" className="px-6 py-3">
                                Net Amount	
                                </th>
                                <th scope="col" className="px-6 py-3">
                                Action	
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        {currentSuppliersBillEntry.map((suppliersBillEntry: any, index) => (
                            <tr key={index} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <td className="px-6 py-3">
                                Bill{suppliersBillEntry.billEntryNumber}
                                </td>
                                <td className="px-6 py-3">
                                {suppliersBillEntry.supplierName}
                                </td>
                                <td className="px-6 py-3">
                                {suppliersBillEntry.temporaryInvoiceNumber}
                                </td>
                                <td className="px-6 py-3">
                                {suppliersBillEntry.dueDate.split('T')[0]}
                                </td>
                                <td className="text-right px-6 py-3">
                                {suppliersBillEntry.grossAmount}
                                </td>
                                <td className="text-right px-6 py-3">
                                {suppliersBillEntry.vat}
                                </td>
                                <td className="text-right px-6 py-3">
                                £{suppliersBillEntry.netAmount}
                                </td>
                                <td className="cursor-pointer px-6 py-3">
                                    <div className="flex gap-3 w-full">
                                        <FaEye className="fill-gray-500 w-6 h-6" />
                                        <FaEdit className="fill-blue-500 w-6 h-6" />
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>

                    </table>
                    {currentSuppliersBillEntry.length === 0 && (
                            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
                                <p className="text-red-800">No supplier Bill Entry Details found.</p>
                            </div>
                        )}
                </div>
                {/* {currentSuppliersBillEntry.length > 0 && (
                    <div className="w-full flex flex-col pb-[100px]">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={(page) => setCurrentPage(page)}
                        />
                    </div>
                )} */}
                </div>
        </>
    )
}

export { SuppliersBillEntry };