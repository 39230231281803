import { Modal } from 'flowbite-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object, number } from 'yup';
import { useEffect, useRef, useState } from 'react';
import { SelectBox } from '../../../../SelectBox';
import { FaLock } from "react-icons/fa";
import { FaLockOpen } from "react-icons/fa";
import { HeaderTitle } from '../../../../HeaderTitle';
import { useAppDispatch } from '../../../../../store/hooks/redux-hooks';
import { addOfficeBankDeposit, OfficeBankDeposit } from '../../../../../store/slice/Accounting/OfficeAccounts/OfficePostings/BankDepositSlice';
import { toast } from 'react-toastify';
import { InputBox } from '../../../../InputBox';
import { NumericFormat } from 'react-number-format';
import { FcCancel, FcPlus } from 'react-icons/fc';
import { AddInvoiceProfessionalFees } from '../../../AddClientAccounts/AddClientInvoice/AddQuickInvoice/AddInvoiceProfessionalFees';
import { FaPlus } from "react-icons/fa6";
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';
import { fetchNextReceiptNumber } from '../../../../../store/slice/Accounting/OfficeAccounts/OfficeBankAccountSlice';
import { OfficeAccountConstant } from '../../../../../store/model/Accounting/OfficeAccount/officeaccountconst';
import { MainAnimation } from '../../../../loadingAnimation/MainAnimation';
import { SearchAccount } from '../../../SearchAccount';

function AddBankDeposit({ openDrawer, Close, isEdit, officeBankDeposit }: { openDrawer: boolean, Close: any, isEdit: boolean, officeBankDeposit: any }) {
    const [title] = useState((!isEdit ? 'Add Bank Deposit' : 'Edit Bank Deposit'))
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [modalPlacement] = useState('center')
    const [isLoading, setIsLoading] = useState(false);
    const [setExistingOfficeBankDeposit] = useState(officeBankDeposit);
    const dispatch = useAppDispatch();
    const { clientPaymentTypes } = useSelector((state: RootState) => state.clientPaymentType);
    const [clientPaymentTypeLists, setclientPaymentTypeLists] = useState<any[]>([]);
    const { authourisedClients } = useSelector((state: RootState) => state.authorisedClient);
    const [authorisedClientLists, setAuthorisedClientLists] = useState<any[]>([]);
    const { receiptNumber } = useSelector((state: RootState) => state.officeBankAccount);
    const [initialLoading, setInitialLoading] = useState(true);
    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);
    const [autoReceiptNumber, setAutoReceiptNumber] = useState<number>();
    const [openFromBankAccountModal, setOpenFromBankAccountModal] = useState(false);

    const { officeBanks } = useSelector((state: RootState) => state.officeBank);
    const [officeBankLists, setOfficeBankLists] = useState<any[]>([]);
    const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
    const [addBankDeposits, setAddBankDeposits] = useState([
        {
            account: "",
            bankAccountId: "",
            description: "",
            amount: "",

        },
    ]);


    useEffect(() => {
        console.log(officeBanks);
        if (officeBanks.length > 0) {
            console.log(officeBanks);
            let data = officeBanks.map((officeBank: any, index) => ({
                value: officeBank.bankAccountId,
                label: `${officeBank.accountName}`,
            }));
            setOfficeBankLists(data);
        }
    }, [officeBanks]);


    useEffect(() => {
        if (clientPaymentTypes.length > 0) {
            console.log(clientPaymentTypes);
            let data = clientPaymentTypes.map((PaymentType: any, index) => ({
                value: PaymentType.paymentTypeId,
                label: PaymentType.paymentType,
            }));
            setclientPaymentTypeLists(data);

        }
    }, [clientPaymentTypes]);

    useEffect(() => {
        // console.log(authourisedClients);
        if (authourisedClients.length > 0) {
            let data = authourisedClients.map((authorisedClient: any) => ({
                value: authorisedClient.userId,
                label: `${authorisedClient.firstName} -${authorisedClient.lastName}`,
            }));
            setAuthorisedClientLists(data);

        }
    }, [authourisedClients]);

    const toggleFromBankAccountModal = (index: number) => {
        setSelectedRowIndex(index);
        setOpenFromBankAccountModal(!openFromBankAccountModal);
    };

    const handleAccountSelect = (selectedAccountType: any) => {
        if (selectedRowIndex !== null) {
            const updatedRows = [...addBankDeposits];
            updatedRows[selectedRowIndex].account = `${selectedAccountType.accountType} -${selectedAccountType.financialStatement}`; // Assuming accountType is returned from SearchAccount
            updatedRows[selectedRowIndex].bankAccountId = selectedAccountType.accountId; // Save the bankAccountId
            setAddBankDeposits(updatedRows);
        }
        setOpenFromBankAccountModal(false);
    };







    const handleEditClick = () => {
        setIsDisabled(!isDisabled);
    };

    const [isDisabled, setIsDisabled] = useState(true);

    // const toggleDescriptionModal = () => {
    //     setOpenDescriptionModal(!openDescriptionModal);
    // };
    //   const [openDescriptionModal, setOpenDescriptionModal] = useState(false);



    const validationSchema = object().shape({
        bankDepositNumber: number(),
        transactionDate: string()
            .required('Transaction Date is required')
            .transform((value, originalValue) => {
                if (originalValue === "" || value === null) return null;
                return new Date(value).toISOString().split("T")[0];
            }),
        depositToBankAccountId: string()
            .required('Deposit To is required'),
        entryDate: string()
            .transform((value, originalValue) => {
                if (originalValue === "" || value === null) return null;
                return new Date(value).toISOString().split("T")[0];
            }),
        balance: string(),
        receivedFrom: string(),
        comments: string(),
        authorisedBy: string()
            .required('Authorised By is required'),
        paymentMethodId: string()
            .required('Transfer Method is required'),
        paymentReference: string()
            .required('Reference Number is required'),
    });

    const { register, handleSubmit, reset, setValue, getValues, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const addRow = () => {
        setAddBankDeposits([...addBankDeposits, { account: "", bankAccountId: "", description: "", amount: "" }]);
    };

    // Handle input change for table rows
    const handleInputChange = (index: number, field: string, value: any) => {
        const updatedRows = [...addBankDeposits];
        updatedRows[index] = { ...updatedRows[index], [field]: value };
        setAddBankDeposits(updatedRows);
    };

    // Function to remove a row
    const removeRow = (index: number) => {
        setAddBankDeposits(addBankDeposits.filter((_, i) => i !== index));
    };

    // Handle form submission
    const onSubmit = async (data: any) => {
        // Combine the form data and rows data
        const combinedData = {
            ...data, // Form validation schema values
            addBankDeposits, // Table data
        };

        const BankDeposits: OfficeBankDeposit = {
            receivedFrom: data.receivedFrom,
            bankDepositNumber: data.bankDepositNumber,
            depositToBankAccountId: data.depositToBankAccountId,
            authorisedBy: data.authorisedBy,
            paymentMethodId: data.paymentMethodId,
            paymentReference: data.paymentReference,
            transactionDate: data.transactionDate,
            comments: data.comments,
            amounts: addBankDeposits.map(depo => ({
                accountId: depo.bankAccountId,
                description: depo.description,
                amount: depo.amount,
            })),
        };

        console.log(JSON.stringify(BankDeposits, null, 2));
        setIsLoading(true);
        try {
            await dispatch(addOfficeBankDeposit(BankDeposits)).unwrap();
            Close();
            toast.success("Successfully Added Fee Type");
        } catch (error: any) {
            toast.error(error.toString());
        } finally {
            setIsLoading(false);
        }
    };

    const onError = (errors: any) => {
        console.log("Form submission errors", errors);
    };

    useEffect(() => {
        if (!effectServiceCall.current) {
            const fetchPromises = [dispatch(fetchNextReceiptNumber(OfficeAccountConstant.BankDeposit))];

            Promise.all(fetchPromises)
                .then(() => {
                    setInitialLoading(false);
                })
                .catch((error) => {
                    if (!errorShownRef.current) {
                        toast.error("Error fetching data: " + error.message);
                        errorShownRef.current = true;
                    }
                    setInitialLoading(false);
                });

            effectServiceCall.current = true;
        }
    }, [dispatch]);

    useEffect(() => {
        if (receiptNumber != null) {
            setValue("bankDepositNumber", receiptNumber)
            setAutoReceiptNumber(receiptNumber)
        }
    }, [receiptNumber]);


    useEffect(() => {
        if (isEdit && officeBankDeposit) {
            setExistingOfficeBankDeposit(officeBankDeposit);
            reset(officeBankDeposit);
        }
    }, [officeBankDeposit, isEdit, reset, setValue]);

    return (
        <Modal
            className={`backdrop-blur-sm items-center justify-center`}
            show={openDrawer}
            position={modalPlacement}
            onClose={() => { reset(); Close(); }}
            initialFocus={emailInputRef}
            size="3xl"
        >
            <Modal.Header className="bg-white-bg dark:bg-dark_bg p-5">
                <span className="text-blue_gray-900 dark:text-white-bg text-xl w-auto">{title}</span>
            </Modal.Header>
            <Modal.Body className="bg-white-bg dark:bg-dark_bg">

                <form onSubmit={handleSubmit(onSubmit, onError)} className="mx-auto" >
                    {initialLoading ? (<MainAnimation />) : ""}
                    <div className=''>
                        <div className="grid grid-cols-3 md:grid-cols-1 gap-x-4 gap-y-1 w-full">
                            <div >
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Bank Deposit Number
                                </label>
                                <div className="flex gap-3">
                                    <input
                                        type="text"
                                        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg disabled:bg-gray-300 dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                                        {...register('bankDepositNumber')}
                                        disabled={isDisabled}
                                    />
                                    <button
                                        type="button"
                                        onClick={handleEditClick}
                                        className="mt-2 text-sm font-medium text-blue-600 dark:text-blue-500"
                                    >
                                        {isDisabled ? <FaLock /> : <FaLockOpen />}
                                    </button>
                                </div>
                            </div>
                            <div className="">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Deposite To <span className="text-red-500">*</span>
                                </label>
                                <SelectBox
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                    indicator={
                                        <svg
                                            width="15"
                                            className="fill-current mr-2"
                                            height="15"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512">
                                            <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                        </svg>
                                    }
                                    isMulti={false}
                                    name="frameFiftySix"
                                    isSearchable={true}
                                    options={officeBankLists}
                                    value={getValues().depositToBankAccountId}
                                    placeholder="Select"
                                    shape="round"
                                    color="indigo_50"
                                    size="xs"
                                    onChange={(selectedOption) => {
                                        if (selectedOption) {
                                            setValue('depositToBankAccountId', selectedOption);
                                        }
                                    }}
                                />
                                <div className="font-medium mt-2 text-xs text-red-600" >{errors?.depositToBankAccountId?.message?.toString()}</div>
                            </div>
                            <div className="relative">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Balance
                                </label>
                                <NumericFormat decimalScale={2} className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('balance')}
                                    placeholder="Enter Balance" onValueChange={(values) => {
                                        setValue('balance', values.value)
                                    }}
                                    disabled
                                />
                            </div>
                            <div className="relative">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Transaction Date <span className="text-red-500">*</span>
                                </label>
                                <input type="date" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.transactionDate ? 'is-invalid' : ''}`} {...register('transactionDate')}
                                    max={new Date().toISOString().split("T")[0]}
                                />
                                <div className="font-medium mt-2 text-xs text-red-600">{errors?.transactionDate?.message?.toString()}</div>
                            </div>

                            <div className="relative">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Entry Date
                                </label>
                                <input type="date" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('entryDate')}
                                    max={new Date().toISOString().split("T")[0]}
                                />
                            </div>

                            <div className="">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Authorised By <span className="text-red-500">*</span>
                                </label>
                                <SelectBox
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                    indicator={
                                        <svg
                                            width="15"
                                            className="fill-current mr-2"
                                            height="15"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512">
                                            <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                        </svg>
                                    }
                                    isMulti={false}
                                    name="frameFiftySix"
                                    isSearchable={true}
                                    options={authorisedClientLists}
                                    value={getValues().authorisedBy}
                                    placeholder="Select"
                                    shape="round"
                                    color="indigo_50"
                                    size="xs"
                                    onChange={(selectedOption) => {
                                        if (selectedOption) {
                                            setValue('authorisedBy', selectedOption);
                                        }
                                    }}
                                />
                                <div className="font-medium mt-2 text-xs text-red-600" >{errors?.authorisedBy?.message?.toString()}</div>
                            </div>
                            <div className="">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Transfer Method <span className="text-red-500">*</span>
                                </label>
                                <SelectBox
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                    indicator={
                                        <svg
                                            width="15"
                                            className="fill-current mr-2"
                                            height="15"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512">
                                            <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                        </svg>
                                    }
                                    isMulti={false}
                                    name="frameFiftySix"
                                    isSearchable={true}
                                    options={clientPaymentTypeLists}
                                    value={getValues().paymentMethodId}
                                    placeholder="Select"
                                    shape="round"
                                    color="indigo_50"
                                    size="xs"
                                    onChange={(selectedOption) => {
                                        if (selectedOption) {
                                            setValue('paymentMethodId', selectedOption);
                                        }
                                    }}
                                />
                                <div className="font-medium mt-2 text-xs text-red-600" >{errors?.paymentMethodId?.message?.toString()}</div>
                            </div>
                            <div className="relative">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Reference Number <span className="text-red-500">*</span>
                                </label>
                                <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.paymentReference ? 'is-invalid' : ''}`} {...register('paymentReference')}
                                    placeholder="Enter Reference Number"
                                />
                                <div className="font-medium mt-2 text-xs text-red-600">{errors?.paymentReference?.message?.toString()}</div>
                            </div>
                            <div className="relative">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Received From
                                </label>
                                <textarea
                                    className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                    placeholder="Received From" {...register('receivedFrom')}
                                ></textarea>
                            </div>
                            <div className="relative">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Comments
                                </label>
                                <textarea
                                    className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                    placeholder="Comments" {...register('comments')}
                                ></textarea>
                            </div>

                        </div>



                        <div className="mb-5">
                            <button
                                className="flex items-center bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
                                type="button"
                                onClick={addRow}
                            >
                                <FaPlus className="w-4 h-4 fill-white-bg" />
                                <span className="text-white-bg mx-2">Add Line</span>
                            </button>
                        </div>
                        <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400 my-5">
                            <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                                <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    <th scope="col" className="px-6 py-3">
                                        From Account
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Description
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Amount
                                    </th>
                                    <th scope="col" className="px-6 py-3">

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {addBankDeposits.map((row, index) => (
                                    <tr
                                        key={index}
                                        className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                                    >
                                        <td className="cursor-pointer px-6 py-3">
                                            {/* Use Flexbox to Align Button and Input */}
                                            <div className="flex items-center space-x-2">
                                                <button type="button" onClick={() => toggleFromBankAccountModal(index)}>
                                                    <FcPlus />
                                                </button>

                                                {/* Modal for Selecting Description */}

                                                {openFromBankAccountModal && (
                                                    <SearchAccount
                                                        openDrawer={openFromBankAccountModal}
                                                        Close={() => setOpenFromBankAccountModal(false)}
                                                        onAccountSelect={handleAccountSelect} type="Office" fkAccountTypeIds="1" // Pass the function to handle account selection
                                                    />
                                                )}

                                                <InputBox
                                                    dataType={"text"}
                                                    placeholder={"Account"}
                                                    value={row.account}
                                                    onChange={(e) =>
                                                        handleInputChange(index, "account", e.target.value)
                                                    }
                                                />
                                            </div>


                                        </td>

                                        <td className="px-6 py-3">
                                            <InputBox
                                                dataType={"text"}
                                                placeholder={"Description"}
                                                value={row.description}
                                                onChange={(e) =>
                                                    handleInputChange(index, "description", e.target.value)
                                                }
                                            />
                                        </td>

                                        <td className="px-6 py-3">
                                            <NumericFormat
                                                value={row.amount}
                                                onValueChange={(values) => handleInputChange(index, 'amount', values.floatValue)}
                                                decimalScale={2}
                                                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                                            />
                                        </td>

                                        <td className="px-6 py-3">
                                            <button type="button" onClick={() => removeRow(index)}>
                                                <FcCancel className="w-6 h-6" />
                                            </button>
                                        </td>
                                    </tr>
                                ))}

                                {/* Row for Total Calculation */}
                                <tr>
                                    <td></td>
                                    <td className="px-6 py-3 text-right font-semibold">Total Amount Deposited</td>
                                    {/* amount */}
                                    <td className="px-6 py-3 text-right font-semibold">
                                        £
                                        {addBankDeposits
                                            .reduce((acc, row) => acc + (parseFloat(row.amount) || 0), 0)
                                            .toFixed(2)}
                                    </td>
                                    <td className="px-6 py-3"></td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="flex col-span-2 gap-2 justify-end">
                            <button
                                type="button"
                                onClick={() => {
                                    Close();
                                    reset();
                                }}
                                className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                            >
                                Cancel
                            </button>
                            <button
                                type='submit'
                                disabled={isLoading}
                                className={`cursor - pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[120px] ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                            >{isLoading ? (
                                <svg className="animate-spin h-5 w-5 text-white-bg mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                                </svg>
                            ) : (
                                'Save'
                            )}
                            </button>
                        </div>
                    </div>


                </form>

            </Modal.Body>
        </Modal>
    );
}

export { AddBankDeposit };
