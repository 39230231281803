import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import TimeEntryTypeService from "../../../../services/Accounting/AccountSetup/TimeEntryTypeService";



export interface TimeEntryType {
    timeEntryTypeId: number;
    timeEntryCode: string;
    unitsInMinutes: number;
    timeEntryType: string;
    billingDescription: string;
    isVatInclude: boolean;
    fkVatTypeId: number;
    isActive: boolean;
};
// Define the state type for Checklist
type TimeEntryTypeState = {
    timeEntryTypes: TimeEntryType[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: TimeEntryTypeState = {
    timeEntryTypes: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllTimeEntryType = createAsyncThunk(
    "fetchAllTimeEntryType",
    async (_, thunkAPI) => {
        try {
            const response = await TimeEntryTypeService.GetAllTimeEntryType();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addTimeEntryType = createAsyncThunk(
    "addTimeEntryType",
    async (data: any, thunkAPI) => {
        try {
            const response = await TimeEntryTypeService.PostTimeEntryType(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateTimeEntryType = createAsyncThunk(
    "updateTimeEntryType",
    async ({ id, data }: { id: number, data: TimeEntryType }, thunkAPI) => {
        try {
            const response = await TimeEntryTypeService.PutTimeEntryType(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteTimeEntryType = createAsyncThunk(
    "deleteTimeEntryType",
    async (id: number, thunkAPI) => {
        try {
            await TimeEntryTypeService.deleteTimeEntryType(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const TimeEntryTypeSlice = createSlice({
    name: "timeEntryType",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllTimeEntryType.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllTimeEntryType.fulfilled, (state, action: PayloadAction<TimeEntryType[]>) => {
                state.status = "idle";
                state.timeEntryTypes = action.payload;
            })
            .addCase(fetchAllTimeEntryType.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addTimeEntryType.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addTimeEntryType.fulfilled, (state, action: PayloadAction<TimeEntryType>) => {
                state.status = "idle";
                state.timeEntryTypes.push(action.payload);
            })
            .addCase(addTimeEntryType.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateTimeEntryType.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateTimeEntryType.fulfilled, (state, action: PayloadAction<TimeEntryType>) => {
                state.status = "idle";
                const index = state.timeEntryTypes.findIndex(t => t.timeEntryTypeId === action.payload.timeEntryTypeId);
                if (index !== -1) {
                    state.timeEntryTypes[index] = action.payload;
                }
            })
            .addCase(updateTimeEntryType.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteTimeEntryType.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteTimeEntryType.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.timeEntryTypes = state.timeEntryTypes.filter(t => t.timeEntryTypeId !== action.payload);
            })
            .addCase(deleteTimeEntryType.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default TimeEntryTypeSlice.reducer;
