import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import ClientPaymentTypeService from "../../../../services/Accounting/ClientAccounts/ClientPaymentTypeService";


export interface ClientPaymentType {
    paymentTypeId : number;
     clientPaymentType: string;
   
};
// Define the state type for Checklist
type ClientPaymentTypeState = {
    clientPaymentTypes: ClientPaymentType[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: ClientPaymentTypeState = {
    clientPaymentTypes: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllClientPaymentType = createAsyncThunk(
    "fetchAllClientPaymentType",
    async (_, thunkAPI) => {
        try {
            const response = await ClientPaymentTypeService.GetAllClientPaymentType();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

const ClientPaymentTypeSlice = createSlice({
    name: "clientPaymentType",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchAllClientPaymentType.pending, (state) => {
            state.status = "loading";
        })
        .addCase(fetchAllClientPaymentType.fulfilled, (state, action: PayloadAction<any[]>) => {
            state.status = "idle";
            state.clientPaymentTypes = action.payload;
        })
        .addCase(fetchAllClientPaymentType.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.payload as string;
        })

    }
});

export default ClientPaymentTypeSlice.reducer;