import { Modal } from 'flowbite-react';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object, boolean, number } from 'yup';
import { useAppDispatch } from '../../../../store/hooks/redux-hooks';
import { SelectBox } from '../../../SelectBox';
import { toast } from 'react-toastify';
import { addTimeEntryType } from '../../../../store/slice/Accounting/AccountSetup/TimeEntryTypeSlice';

function AddTimeEntryTypes({ openDrawer, Close, isEdit, timeEntryType }: { openDrawer: boolean, Close: any, isEdit: boolean, timeEntryType: any }) {
    const [title] = useState((!isEdit ? 'Add New Time Entry Type' : 'Edit Time Entry Type'))
    const dispatch = useAppDispatch();
    const [openModal, setOpenModal] = useState(true);
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [modalPlacement, setModalPlacement] = useState('center');
    const [isLoading, setIsLoading] = useState(false);
    const [existingTimeEntryType, setExistingTimeEntryType] = useState(timeEntryType);


    const vatType = [
        { label: 'Standard (VAT on Expenses) - 20%', value: 1 },
        { label: 'Reduced (VAT on Expenses) - 5%', value: 2 },
        { label: 'Exempt - 0%', value: 3 },
        { label: 'No Vat - 0%', value: 4 },
    ];

    const mappedVatType = vatType.map((type: any) => ({
        label: type.label,
        value: type.value
    }));
    const validationSchema = object().shape({
        timeEntryCode: string()
            .required('Time Entry Code is required'),
        timeEntryType: string()
            .required('Time Entry Type is required'),
        billingDescription: string(),
        unitsInMinutes: number(),
        fkVatTypeId: number(),
        isActive: boolean()
    });

    const { register, handleSubmit, reset, setValue, watch, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            isActive: true,
        }
    });


    const onSubmit = async (data: any) => {

        setIsLoading(true);
        try {
            await dispatch(addTimeEntryType(data)).unwrap();
            Close();
            toast.success("Successfully Added Time Entry Type");
        } catch (error: any) {
            toast.error(error.toString());
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        if (isEdit && timeEntryType) {
            setExistingTimeEntryType(timeEntryType);
            reset(timeEntryType);
        }
    }, [timeEntryType, isEdit, reset, setValue]);

    return (
        <Modal
            className={`backdrop-blur-sm items-center justify-center`}
            show={openDrawer}
            position={modalPlacement}
            onClose={() => { reset(); setOpenModal(Close); }}
            initialFocus={emailInputRef}
        >
            <Modal.Header className="bg-white-bg dark:bg-dark_bg p-5">
                <span className="dark:text-white-bg text-xl w-auto">Add Time Entry Types</span>
            </Modal.Header>
            <Modal.Body className="bg-white-bg dark:bg-dark_bg">
                <form onSubmit={handleSubmit(onSubmit)} className="mx-auto" >
                    <div className="space-y-3 w-full">

                        <div className="relative max-w-full col-span-2">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Time Entry Code <span className="text-red-500">*</span>
                            </label>
                            <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.timeEntryCode ? 'is-invalid' : ''}`} {...register('timeEntryCode')}
                                placeholder="Enter Time Entry Code"
                            />
                            <div className="font-medium mt-2 text-xs text-red-600" >{errors?.timeEntryCode?.message?.toString()}</div>
                        </div>
                        <div className="relative max-w-full col-span-2">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Time Entry Type  <span className="text-red-500">*</span>
                            </label>
                            <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.timeEntryType ? 'is-invalid' : ''}`} {...register('timeEntryType')}
                                placeholder="Enter Time Entry Type"
                            />
                            <div className="font-medium mt-2 text-xs text-red-600" >{errors?.timeEntryType?.message?.toString()}</div>
                        </div>
                        <div className="relative max-w-full col-span-2">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Billing Description
                            </label>
                            <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('billingDescription')}
                                placeholder="Enter Billing Description"
                            />
                        </div>
                        <div className="relative max-w-full col-span-2">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Units in Minutes
                            </label>
                            <input type="number" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('unitsInMinutes')}
                                placeholder="Enter Units in Minutes "
                            />
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                VAT Type
                            </label>
                            <SelectBox
                                className={`col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                indicator={
                                    <svg
                                        width="15"
                                        className="fill-current mr-2"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                    </svg>
                                }
                                isMulti={false}
                                name="frameFiftySix"
                                options={mappedVatType}
                                isSearchable={true}
                                placeholder="Select"
                                shape="round"
                                color="indigo_50"
                                size="xs"
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setValue('fkVatTypeId', selectedOption);
                                    }
                                }
                                }
                            />
                        </div>
                        <div className="flex items-center gap-3">
                            <label className="block dark:text-white-bg">
                                Is Active
                            </label>
                            <div className='flex gap-5'>
                                <div>
                                    <input
                                        type="radio"
                                        value="true"
                                        checked={watch('isActive') === true}
                                        onChange={() => setValue('isActive', true)}
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Yes</label>
                                </div>
                                <div>
                                    <input
                                        type="radio"
                                        value="false"
                                        checked={watch('isActive') === false}
                                        onChange={() => setValue('isActive', false)}
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">No</label>
                                </div>
                            </div>
                        </div>



                        <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
                            <div className="flex flex-row gap-2 items-end justify-end w-full">
                                <button
                                    type='button'
                                    onClick={() => {
                                        reset();
                                        Close();
                                    }}
                                    className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                                >
                                    Cancel
                                </button>
                                <button
                                    type='submit'
                                    disabled={isLoading}
                                    className="cursor - pointer bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]"
                                >
                                    {isLoading ? (
                                        <svg className="animate-spin h-5 w-5 text-white-bg mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                                        </svg>
                                    ) : (
                                        'Save'
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export { AddTimeEntryTypes };
