import axiosInstance from '../axiosInstance';
import { ErrorResult } from '../../store/model/errorResult';



const GetAllAccount = async () => {
    try {
        const response = await axiosInstance.get("AccountSetUp");
        return response.data;
    } catch (error: any) {
        console.error('Failed to fetch Account:', error);
        throw new Error(error.response?.data?.message || error.message || "Failed to fetch Account");
    }
}


const PostAccount = async (data: any) => {
    try {
        const response = await axiosInstance.post("AccountSetUp", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutAccount = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`/Account/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteAccount = async (accountId: number) => {
    try {
        await axiosInstance.delete(`/Account/${accountId}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}



const SearchJournalAccounts = async (data: any) => {
    try {
        const response = await axiosInstance.post("accounting/searchjournal", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const AccountService = {
    GetAllAccount,
    PostAccount,
    PutAccount,
    deleteAccount,
    SearchJournalAccounts
};


export default AccountService;
