import { Modal } from 'flowbite-react';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { useAppDispatch } from '../../../store/hooks/redux-hooks';
import { SearchInput } from '../../Filters/SearchInput';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { fetchAllClients } from '../../../store/slice/CaseManagement/ClientSlice';
import { fetchAllContacts } from '../../../store/slice/CaseManagement/ContactSlice';

function SearchContact({ openDrawer, Close, onSelectContact  }: { openDrawer: boolean, Close: any,   onSelectContact: (selectedContact: any) => void;}) {
    const dispatch = useAppDispatch();
    const [openModal, setOpenModal] = useState(true);
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [modalPlacement, setModalPlacement] = useState('center')
    const contactDetails = useState<any[]>([]);
    const [contactDetailsLists, setContactDetailsLists] = useState<any[]>([]);
    const [selectedContact, setSelectedContact] = useState<any[]>([]);
 
    
    const [initialLoading, setInitialLoading] = useState(true);
    const validationSchema = object().shape({

    });

    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });
    
    useEffect(() => {
        dispatch(fetchAllContacts())
            .then((response: any) => {
                setContactDetailsLists(response.payload); // Assuming payload contains contacts
                setInitialLoading(false);
            })
            .catch(() => setInitialLoading(false));
    }, [dispatch]);
console.log(contactDetails);

//     const effectServiceCall = useRef(false);
//   useEffect(() => {
//       if (!effectServiceCall.current) {
//           dispatch(fetchAllContacts())
//               .then(() => setInitialLoading(false))
//               .catch(() => setInitialLoading(false));
//           effectServiceCall.current = true;
//       }
//   }, [dispatch]);

  const handleRadioContactChange = (contact: any) => {
    setSelectedContact(contact);
  };



    const onSubmit = (data: any) => {
        console.log(JSON.stringify(data, null, 2));
        if (selectedContact != null) {
            onSelectContact(selectedContact);
            
          }
          Close(); 
          //console.log(selectedCase);
        };
      
  


    return (
        <Modal
            className={`backdrop-blur-sm items-center justify-center`}
            show={openDrawer}
            position={modalPlacement}
            onClose={() => { reset(); setOpenModal(Close); }}
            initialFocus={emailInputRef}
        >
            <Modal.Header className="bg-white-bg p-5">
                <span className="text-blue_gray-900 text-xl w-auto">Search Contacts For Account</span>
            </Modal.Header>
            <Modal.Body className="bg-white-bg">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="space-y-6 w-full">
                        <div className="relative overflow-x-auto sm:rounded-lg">
                            <div className='flex mb-5'>
                                <SearchInput title={'Keyword'} />
                            </div>
                            <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                                <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        <th scope="col" className="px-6 py-3">

                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Name
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Details
                                        </th>
                                    </tr>
                                </thead>
                                {/* <tbody>
                                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        <td className="px-6 py-3">
                                            <input type="radio" />
                                        </td>
                                        <td className="px-6 py-3">
                                            Foxton (ESTATE AGENT)
                                        </td>
                                        <td className="px-6 py-3">
                                            <p className="font-semibold">50, Green Wood Lane</p>
                                            <p>Startford</p>
                                            <p>IG7 5DE</p>
                                        </td>
                                    </tr>
                                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        <td className="px-6 py-3">
                                            <input type="radio" />
                                        </td>
                                        <td className="px-6 py-3">
                                            Home Office (UK BORDER AGENCY (HOME OFFICE))
                                        </td>
                                        <td className="px-6 py-3">
                                            <p className="font-semibold">Indefinite Leave to Remain</p>
                                            <p>PO Box 591</p>
                                            <p>DH1 9FS</p>
                                        </td>
                                    </tr>
                                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        <td className="px-6 py-3">
                                            <input type="radio" />
                                        </td>
                                        <td className="px-6 py-3">
                                            Halifax Bank Ltd (LENDERS)
                                        </td>
                                        <td className="px-6 py-3">
                                            <p className="font-semibold">101-102, Market Street</p>
                                            <p>Stratford</p>
                                            <p>N25 3RS</p>
                                        </td>
                                    </tr>
                                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        <td className="px-6 py-3">
                                            <input type="radio" />
                                        </td>
                                        <td className="px-6 py-3">
                                        MoveInSoon (ESTATE AGENT)
                                        </td>
                                        <td className="px-6 py-3">
                                            <p className="font-semibold">507, Wilfred Close</p>
                                            <p>Forest View Street</p>
                                            <p>NG2 3DE</p>
                                        </td>
                                    </tr>
                                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        <td className="px-6 py-3">
                                            <input type="radio" />
                                        </td>
                                        <td className="px-6 py-3">
                                        Samson and Walker Ltd (SURVEYORS)
                                        </td>
                                        <td className="px-6 py-3">
                                            <p className="font-semibold">55, Albert Place</p>
                                            <p>Nortwood Road</p>
                                            <p>HA2 6DE</p>
                                        </td>
                                    </tr>
                                    <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                        <td className="px-6 py-3">
                                            <input type="radio" />
                                        </td>
                                        <td className="px-6 py-3">
                                        James and Smith (SOLICITORS)
                                        </td>
                                        <td className="px-6 py-3">
                                            <p className="font-semibold">101, Andrew's Avenue</p>
                                            <p>St Laurence Road</p>
                                            <p>N20 5SE</p>
                                        </td>
                                    </tr>
                                </tbody> */}
                                 <tbody>
                  {contactDetailsLists && contactDetailsLists.map((contact: any, index: number) =>
                  <tr
                     key={index}
                    className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                  >
                    <td className="px-6 py-3">
                                                <input
                                                    type="radio"
                                                    name="selectedContact"
                                                    onChange={() => handleRadioContactChange(contact)}
                                                />
                                            </td>
                                            <td className="px-6 py-3">
                                            {(() => {
                                                if (contact.fkContactTypeId === 1) {
                                                    return <p className="font-semibold pl-6 dark:text-white-bg">{contact.givenNames} {contact.lastName}</p>;
                                                } else if (contact.fkContactTypeId === 2) {
                                                    return <p className="font-semibold pl-6 dark:text-white-bg">{contact.name}</p>;
                                                } else if (contact.fkContactTypeId === 3) {
                                                    return <p className="font-semibold pl-6 dark:text-white-bg">{contact.companyName}</p>;
                                                } else {
                                                    return null;
                                                }
                                            })()}
                                                {contact.contactPersonal?.givenNames} {contact.contactPersonal?.lastName}
                                            </td>
                                            <td className="px-6 py-3">
                                                <p className="font-semibold">{contact.contactPersonal?.personalContAddress1}</p>
                                                <p>{contact.contactPersonal?.personalContCity}</p>
                                                <p>{contact.contactPersonal?.personalContCounty}</p>
                                            </td>
                  </tr>
                   )} 
                </tbody>
                            </table>
                        </div>
                        <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
                            <div className="flex flex-row gap-2 items-end justify-end w-full">
                                <button
                                    onClick={() => {
                                        reset();
                                        setOpenModal(Close);
                                    }}
                                    type='button'
                                    className="cursor-pointer h-10 bg-white-A700 rounded-lg text-center border border-deep_purple-A400 text-deep_purple-A400 text-xs w-[140px] hover:text-white-A700 hover:bg-deep_purple-A400"
                                >
                                    Cancel
                                </button>
                                <button
                                    type='submit'
                                    className="bg-green-700 text-white-bg cursor-pointer h-10 bg-deep_purple-A400 rounded-lg text-center border border-deep_purple-A400 text-white-A700 text-sm w-[120px] hover:text-deep_purple-A400 hover:bg-white-A700"
                                >
                                    Select Account
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export { SearchContact };
