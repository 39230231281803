import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import ContactServices from "../../../services/CaseManagement/ContactServices";

export interface Contact {
    contactType: string;
    fkContactTypeId: number;
    contactPersonal: PersonalContact | null;
    contactCompany: CompanyContact | null;
    contactOrganisation: ContactOrganization | null;
}
export type PersonalContact = {
    personalContactsId: number;
    fkContactId: number;
    fkContactTypeId: number;
    fkPersonalContactsCategoryId: number;
    fkTitleIdPersonal: number;
    givenNames: String;
    lastName: string;
    dateOfBirth: string;
    sex: string;
    fkMaritalStatusId: number;
    fkCountryIdPersonal: number;
    fkIdentificationTypeId: number;
    identificationNumber: string;
    contactMethod: string;
    personalNote: string;

    personalContAddress1: string;
    personalContAddress2: string;
    personalContCity: string;
    personalContCounty: string;
    personalContPostCode: string;
    personalContEmail: string;
    personalContHomePhone: string;
    personalContMobile: string;

    employmentContJobTitle: string;
    employmentContCompanyName: string;
    employmentContAddress1: string;
    employmentContAddress2: string;
    employmentContCity: string;
    employmentContCounty: string;
    employmentContPostCode: string;
    employmentContEmail: string;
    employmentContOfficePhone: string;
    employmentContFax: string;


}

export type CompanyContact = {
    fkCompanyContactsCategoryId: number;
    companyName: string;
    companyNature: string;
    companyEmail: string;
    companyFax: string;
    companyWeb: string;
    companyVat: string;
    companyUtrn: string;
    companyRegNo: string;
    companyContactPhone1: string;
    companyContactPhone2: string;
    companyContactFax: string;

    companyAddressLine1: string;
    companyAddressLine2: string;
    companyCity: string;
    companyCounty: string;
    companyPostcode: string;
    companyPoboxNumber: string;
    companyPoboxCounty: string;
    companyPoboxTown: string;
    companyPoboxPostcode: string;

    companyContact1Title: string;
    companyContact1GivenNames: string;
    companyContact1LastName: string;
    companyContact1JobTitle: string;
    companyContact1Email: string;
    companyContact1DirectLine: string;
    companyContact1Extension: string;
    companyContact1OfficePhone: string;
    companyContact1Mobile: string;

    companyContact2Title: string;
    companyContact2GivenNames: string;
    companyContact2LastName: string;
    companyContact2JobTitle: string;
    companyContact2Email: string;
    companyContact2DirectLine: string;
    companyContact2Extension: string;
    companyContact2OfficePhone: string;
    companyContact2Mobile: string;
    companyNotes: string
}

export type ContactOrganization = {
    name: string;
    nature: string;
    fkOrganisationContactsCategoryId: number;

    address1: string;
    address2: string;
    city: string;
    countyOrganization: string;
    fkCountryIdOrganization: number;
    postCode: string;
    email: string;
    phone: string;
    fax: string;
    website: string;
    orgDxNumber: string;
    orgDxExchange: string;
    orgPoboxNumber: string;
    orgPoboxCounty: string;
    orgPoboxTown: string;
    orgPoboxPostCode: string;

    contPerson1Title :string;
    contPerson1GivenName  :string;
    contPerson1LastName  :string;
    contPerson1JobTitle  :string;
    contPerson1Email  :string;
    contPerson1DirectLine  :string;
    contPerson1OfficePhone  :string;
    contPerson1Mobile  :string;
    contPerson1IncludeCompanyName  :string;
    contPerson1IncludeJobTitle  :string;

    contPerson2Title :string;
    contPerson2GivenName  :string;
    contPerson2LastName  :string;
    contPerson2JobTitle  :string;
    contPerson2Email  :string;
    contPerson2DirectLine  :string;
    contPerson2OfficePhone  :string;
    contPerson2Mobile  :string;
    contPerson2IncludeCompanyName  :string;
    contPerson2IncludeJobTitle  :string;

    contPerson3Title :string;
    contPerson3GivenName  :string;
    contPerson3LastName  :string;
    contPerson3JobTitle  :string;
    contPerson3Email  :string;
    contPerson3DirectLine  :string;
    contPerson3OfficePhone  :string;
    contPerson3Mobile  :string;
    contPerson3IncludeCompanyName  :string;
    contPerson3IncludeJobTitle  :string;

    organisationNote: string;
}

export interface ContactDetailsList  {
    contactDetailsList: any[];
    totalRecords: number;
  }


type ContactsState = {
    contacts: Contact[];
    contactDetailsLists: ContactDetailsList;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: ContactsState = {
    contacts: [],
    contactDetailsLists: null!,
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllContacts = createAsyncThunk(
    "fetchAllContacts",
    async (_, thunkAPI) => {
        try {
            const response = await ContactServices.GetAllContacts();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addContact = createAsyncThunk(
    "addContact",
    async (data: any, thunkAPI) => {
        try {
            const response = await ContactServices.PostContact(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateContact = createAsyncThunk(
    "updateContact",
    async ({ id, data }: { id: number, data: Contact }, thunkAPI) => {
        try {
            const response = await ContactServices.PutContact(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteContact = createAsyncThunk(
    "deleteContact",
    async (contactId: number, thunkAPI) => {
        try {
            await ContactServices.deleteContact(contactId);
            return contactId;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const contactSlice = createSlice({
    name: "contact",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllContacts.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllContacts.fulfilled, (state, action: PayloadAction<ContactDetailsList>) => {
                state.status = "idle";
                state.contactDetailsLists = action.payload;
            })
            .addCase(fetchAllContacts.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addContact.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addContact.fulfilled, (state, action: PayloadAction<Contact>) => {
                state.status = "idle";
                state.contacts.push(action.payload);
            })
            .addCase(addContact.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateContact.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateContact.fulfilled, (state, action: PayloadAction<Contact>) => {
                state.status = "idle";
                // const index = ;
                // state.contacts.findIndex(t => t.personalContactsId === action.payload.personalContactsId);
                // if (index !== -1) {
                //     state.contacts[index] = action.payload;
                // }
            })
            .addCase(updateContact.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteContact.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteContact.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                // state.contacts = state.contacts.filter(t => t.personalContactsId !== action.payload);
            })
            .addCase(deleteContact.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default contactSlice.reducer;
