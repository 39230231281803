import { useEffect, useRef, useState } from "react";
import { Heading } from "../AccountSetupHeading/Heading";
import { SelectBox } from "../SelectBox";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { addNominalLedger, fetchAllNominalLedger, fetchAllNominalLedgerByTypes } from "../../store/slice/Accounting/AccountSetup/NominalLedgerSlice";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { NominalLedgerAc } from "../../store/model/Accounting/AccountSetup/NominalLedgerAc";
import { toast } from "react-toastify";

function NominalLedgerSetup() {
    const dispatch = useAppDispatch();
    const { nominalLedgerTypeRes, nominalLedgerAcList } = useSelector((state: RootState) => state.nominalLedger);
    const [accountReceibables, setaccountReceibables] = useState<any[]>([]);
    const [disbursementAccounts, setdisbursementAccounts] = useState<any[]>([]);
    const [profitAndLostAccounts, setprofitAndLostAccounts] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [suspenseAccounts, setsuspenseAccounts] = useState<any[]>([]);
    const [vatOutputAccounts, setvatOutputAccounts] = useState<any[]>([]);
    const [nominalLedgerOb, setNominalLedgerOb] = useState<NominalLedgerAc>({
        "vatOutputAccount": 0,
        "vatInputAccount": 0,
        "vatControlAccount": 0,
        "deferredVatOutputAccount": 0,
        "deferredVatInputAccount": 0,
        "disbursementAccount": 0,
        "suspenseAccount": 0,
        "supplierAccount": 0,
        "receivableAccount": 0,
        "fixedFeeIncomeAccount": 0,
        "profitLossAccount": 0
    })


    
    const effectServiceCall = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
            dispatch(fetchAllNominalLedgerByTypes());
            dispatch(fetchAllNominalLedger());
        }
        return () => { effectServiceCall.current = true; };
    }, [dispatch]);


    useEffect(()=>{
        if(nominalLedgerTypeRes!=null){
            if(nominalLedgerTypeRes.accountReceibables!=null){
                let data = nominalLedgerTypeRes.accountReceibables.map((item: any) => ({
                    value: item.accountId,
                    label: item.accountName,
                }));
                setaccountReceibables(data);
            }

            if(nominalLedgerTypeRes.disbursementAccounts!=null){
                let data = nominalLedgerTypeRes.disbursementAccounts.map((item: any) => ({
                    value: item.accountId,
                    label: item.accountName,
                }));
                setdisbursementAccounts(data);
            }

            if(nominalLedgerTypeRes.profitAndLostAccounts!=null){
                let data = nominalLedgerTypeRes.profitAndLostAccounts.map((item: any) => ({
                    value: item.accountId,
                    label: item.accountName,
                }));
                setprofitAndLostAccounts(data);
            }

            if(nominalLedgerTypeRes.suspenseAccounts!=null){
                let data = nominalLedgerTypeRes.suspenseAccounts.map((item: any) => ({
                    value: item.accountId,
                    label: item.accountName,
                }));
                setsuspenseAccounts(data);
            }

            if(nominalLedgerTypeRes.vatOutputAccounts!=null){
                let data = nominalLedgerTypeRes.vatOutputAccounts.map((item: any) => ({
                    value: item.accountId,
                    label: item.accountName,
                }));
                setvatOutputAccounts(data);
            }

        }
    },[nominalLedgerTypeRes])

    useEffect(()=>{
          if(nominalLedgerAcList!=null){
            setNominalLedgerOb(nominalLedgerAcList)
          }  

    }, [nominalLedgerAcList])


    const submitNominalLedger =async () =>{
        if(nominalLedgerOb ==null || typeof nominalLedgerOb === 'undefined'){
            toast.error("Please select the nominal Ledger");
            return;
        }
        setIsLoading(true);
        try {
            
            await dispatch(addNominalLedger(nominalLedgerOb)).unwrap();
            toast.success("Successfully Added Case Type Ledger Accounts");
        } catch (error: any) {
            toast.error(error.toString());
        } finally {
            setIsLoading(false);
        }
    }

    const handleNominalLedgerChange = (selectedOption: any, type: string) => {
        if (selectedOption) {
            switch (type) {
                case 'vatOutputAccount':
                  setNominalLedgerOb((prevState) => ({
                    ...prevState,
                    vatOutputAccount: selectedOption,
                  }));
                  break;
                case 'vatInputAccount':
                    setNominalLedgerOb((prevState) => ({
                    ...prevState,
                    vatInputAccount: selectedOption,
                  }));
                  break;
                case 'vatControlAccount':
                    setNominalLedgerOb((prevState) => ({
                    ...prevState,
                    vatControlAccount: selectedOption,
                  }));
                  break;

                  case 'disbursementAccount':
                    setNominalLedgerOb((prevState) => ({
                    ...prevState,
                    disbursementAccount: selectedOption,
                  }));
                  break;


                  case 'deferredVatOutputAccount':
                    setNominalLedgerOb((prevState) => ({
                    ...prevState,
                    deferredVatOutputAccount: selectedOption,
                  }));
                  break;
                  case 'suspenseAccount':
                    setNominalLedgerOb((prevState) => ({
                    ...prevState,
                    suspenseAccount: selectedOption,
                  }));
                  break;
                  case 'supplierAccount':
                    setNominalLedgerOb((prevState) => ({
                    ...prevState,
                    supplierAccount: selectedOption,
                  }));
                  break;
                  case 'fixedFeeIncomeAccount':
                    setNominalLedgerOb((prevState) => ({
                    ...prevState,
                    fixedFeeIncomeAccount: selectedOption,
                  }));
                  break;
                  case 'profitLossAccount':
                    setNominalLedgerOb((prevState) => ({
                    ...prevState,
                    profitLossAccount: selectedOption,
                  }));
                  break;

                  case 'receivableAccount':
                    setNominalLedgerOb((prevState) => ({
                    ...prevState,
                    receivableAccount: selectedOption,
                  }));
                  break;
                default:
                  break;
              }
           
        }
    };

    return (
        <>
            <div className="">
                <div className="mb-5">
                    <SubHeaderTitle title="Nominal Ledger Setup" />
                </div>
                <Heading title="Case Details" />
                <div className="grid md:grid-cols-1 grid-cols-3 w-full gap-5 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <div className="">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Vat Output Account
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            options={vatOutputAccounts}
                            value={nominalLedgerOb?.vatOutputAccount}
                            isSearchable={true}
                            placeholder="Vat Output"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                            onChange={(e)=>{handleNominalLedgerChange(e, 'vatOutputAccount')}}
                        />

                    </div>

                    <div className="">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Vat Input Account
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            options={vatOutputAccounts}
                            value={nominalLedgerOb?.vatInputAccount}
                            isSearchable={true}
                            placeholder="Vat Input"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                            onChange={(e)=>{handleNominalLedgerChange(e,'vatInputAccount')}}
                        />

                    </div>

                    <div className="">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Vat Control Account
                        </label>
                        <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            options={vatOutputAccounts}
                            value={nominalLedgerOb?.vatControlAccount}
                            isSearchable={true}
                            placeholder="Vat Control"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                            onChange={(e)=>{handleNominalLedgerChange(e, 'vatControlAccount')}}
                        />

                    </div>
                </div>


                <div className="grid md:grid-cols-1 grid-cols-3 w-full gap-5 my-5">
                    <div>
                        <Heading title="Disbursement Accounts" />
                        <div className="border p-6 dark:border dark:border-gray-400 dark:border-opacity-35">
                            <div className="">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Disbursement Account <span className="text-red-500">*</span>
                                </label>
                                <SelectBox
                                    className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                    indicator={
                                        <svg
                                            width="15"
                                            className="fill-current mr-2"
                                            height="15"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512">
                                            <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                        </svg>
                                    }
                                    isMulti={false}
                                    name="frameFiftySix"
                                    options={disbursementAccounts}
                                    value={nominalLedgerOb?.disbursementAccount}
                                    isSearchable={true}
                                    placeholder="Disbursement"
                                    shape="round"
                                    color="indigo_50"
                                    size="xs"
                                    onChange={(e)=>{handleNominalLedgerChange(e, 'disbursementAccount')}}
                                />

                            </div>
                        </div>
                    </div>

                    <div>
                        <Heading title="Suspense Accounts" />
                        <div className="border p-6 dark:border dark:border-gray-400 dark:border-opacity-35">
                            <div className="">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Suspense Accounts <span className="text-red-500">*</span>
                                </label>
                                <SelectBox
                                    className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                    indicator={
                                        <svg
                                            width="15"
                                            className="fill-current mr-2"
                                            height="15"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512">
                                            <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                        </svg>
                                    }
                                    isMulti={false}
                                    name="frameFiftySix"
                                    options={suspenseAccounts}
                                    value={nominalLedgerOb?.suspenseAccount}
                                    isSearchable={true}
                                    placeholder="Suspense"
                                    shape="round"
                                    color="indigo_50"
                                    size="xs"
                                    onChange={(e)=>{handleNominalLedgerChange(e,'suspenseAccount')}}
                                />

                            </div>
                        </div>
                    </div>

                    <div>
                        <Heading title="Supplier Accounts" />
                        <div className="border p-6 dark:border dark:border-gray-400 dark:border-opacity-35">
                            <div className="">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Supplier Accounts <span className="text-red-500">*</span>
                                </label>
                                <SelectBox
                                    className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                    indicator={
                                        <svg
                                            width="15"
                                            className="fill-current mr-2"
                                            height="15"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512">
                                            <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                        </svg>
                                    }
                                    isMulti={false}
                                    name="frameFiftySix"
                                    options={vatOutputAccounts}
                                    value={nominalLedgerOb?.supplierAccount}
                                    isSearchable={true}
                                    placeholder="Accounts Payable"
                                    shape="round"
                                    color="indigo_50"
                                    size="xs"
                                    onChange={(e)=>{handleNominalLedgerChange(e,'supplierAccount')}}
                                />

                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid md:grid-cols-1 grid-cols-3 w-full gap-5 my-5">
                    <div>
                        <Heading title="Account Receivable" />
                        <div className="border p-6 dark:border dark:border-gray-400 dark:border-opacity-35">
                            <div className="">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Account Receivable <span className="text-red-500">*</span>
                                </label>
                                <SelectBox
                                    className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                    indicator={
                                        <svg
                                            width="15"
                                            className="fill-current mr-2"
                                            height="15"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512">
                                            <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                        </svg>
                                    }
                                    isMulti={false}
                                    name="frameFiftySix"
                                    options={accountReceibables}
                                    value={nominalLedgerOb?.receivableAccount}
                                    isSearchable={true}
                                    placeholder="Account Receivable"
                                    shape="round"
                                    color="indigo_50"
                                    size="xs"
                                    onChange={(e)=>{handleNominalLedgerChange(e,'receivableAccount')}}
                                />

                            </div>
                        </div>
                    </div>

                    <div>
                        <Heading title="Fixed Fee Invoice" />
                        <div className="border p-6 dark:border dark:border-gray-400 dark:border-opacity-35">
                            <div className="">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Fixed Fee Invoice Default Income Account
                                </label>
                                <SelectBox
                                    className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                    indicator={
                                        <svg
                                            width="15"
                                            className="fill-current mr-2"
                                            height="15"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512">
                                            <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                        </svg>
                                    }
                                    isMulti={false}
                                    name="frameFiftySix"
                                    options={disbursementAccounts}
                                    value={nominalLedgerOb?.fixedFeeIncomeAccount}
                                    isSearchable={true}
                                    placeholder="Miscellaneous"
                                    shape="round"
                                    color="indigo_50"
                                    size="xs"
                                    onChange={(e)=>{handleNominalLedgerChange(e,'fixedFeeIncomeAccount')}}
                                />

                            </div>
                        </div>
                    </div>

                    <div>
                        <Heading title="Main Account" />
                        <div className="border p-6 dark:border dark:border-gray-400 dark:border-opacity-35">
                            <div className="">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                    Profit and Loss Account
                                </label>
                                <SelectBox
                                    className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                    indicator={
                                        <svg
                                            width="15"
                                            className="fill-current mr-2"
                                            height="15"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 448 512">
                                            <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                        </svg>
                                    }
                                    isMulti={false}
                                    name="frameFiftySix"
                                    options={profitAndLostAccounts}
                                    value={nominalLedgerOb?.profitLossAccount}
                                    isSearchable={true}
                                    placeholder="Profit and Loss Account"
                                    shape="round"
                                    color="indigo_50"
                                    size="xs"
                                    onChange={(e)=>{handleNominalLedgerChange(e,'profitLossAccount')}}
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
                <div className="flex flex-row gap-2 items-end justify-end w-full">
                    <button
                        className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                    >
                        Cancel
                    </button>
                    <button
                        type='button'
                        className="bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]"
                    onClick={submitNominalLedger}>
                        Save
                    </button>
                </div>
            </div>
        </>
    )
}

export { NominalLedgerSetup };