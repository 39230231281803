import { useEffect, useRef, useState } from "react";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SearchInput } from "../Filters/SearchInput";
import { AddPartnershipAccounts } from "../Modal/AccountSetup/AddPartnershipAccounts";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { fetchAllPartnerShipAccount } from "../../store/slice/Accounting/AccountSetup/PartnerShipAccountSlice";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { toast } from "react-toastify";
import { FaEdit } from "react-icons/fa";
import { Pagination } from "../Pagination";
import { SubHeaderTitle } from "../SubHeaderTitle";


function PartnershipAccounts() {
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const dispatch = useAppDispatch();
    const [initialLoading, setInitialLoading] = useState(true);
    const { partnerShipAccounts, status, error } = useSelector((state: RootState) => state.partnerShipAccount);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [partnerShipAccountData, setPartnerShipAccountData] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;


    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };



    // const filterPartnerShipAccountBySearchQuery = () => {
    //     return partnerShipAccounts.filter((partnerShipAccount: any) =>
    //         partnerShipAccount?.partnerName.toLowerCase().includes(searchQuery.toLowerCase())
    //     );
    // };

    // const filteredByPartnerShipAccount = filterPartnerShipAccountBySearchQuery();


    // pagination
    const indexOfLastPartnerShipAccount = currentPage * itemsPerPage;
    const indexOfFirstPartnerShipAccount = indexOfLastPartnerShipAccount - itemsPerPage;
    const currentPartnerShipAccount = partnerShipAccounts.slice(indexOfFirstPartnerShipAccount, indexOfLastPartnerShipAccount);


    // Calculate the total number of pages

    const totalPages = Math.ceil(partnerShipAccounts.length / itemsPerPage);

    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);

    useEffect(() => {
        if (!effectServiceCall.current) {
            dispatch(fetchAllPartnerShipAccount())
                .then(() => setInitialLoading(false))
                .catch(() => setInitialLoading(false));
            effectServiceCall.current = true;
        }

        if (status === 'failed' && !errorShownRef.current) {
            // toast.error(error);
            errorShownRef.current = true;
        }
    }, [dispatch, status, error]);

    if (initialLoading) {
        return <MainAnimation />;
    }

    const toggleDrawer = () => {
        setIsEdit(false);
        setOpenModal(!openModal);
    };

    return (
        <>
            <div className="">
                <div className="mb-5">
                    <SubHeaderTitle title="Partnership Accounts" buttonName="Partnership Account" isAdd={true} onClickAdd={toggleDrawer} />
                    {(openModal) &&
                        <AddPartnershipAccounts openDrawer={openModal} Close={toggleDrawer} isEdit={isEdit} partnerShipAccount={partnerShipAccountData} />
                    }
                </div>
                <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
                    <SearchInput title="Search Partner ShipAccount" value={searchQuery} onChange={handleSearchChange} />
                    <div>
                        <div className="flex gap-4 md:gap-1 w-full">
                            <CheckBoxFilter title="Is Active" />
                        </div>
                    </div>
                </div>
                <div className="relative overflow-x-auto sm:rounded-lg my-5">
                    <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                        <thead className="text-sm bg-gray-50 dark:bg-gray-800 dark:text-gray-400">
                            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <th scope="col" className="px-6 py-3">
                                    Partner Name
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Percentage
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Is Active
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentPartnerShipAccount.map((partnerShipAccount: any, index) => (
                                <tr key={index} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    <td className="px-6 py-3">
                                        {partnerShipAccount.partnerName}
                                    </td>
                                    <td className="px-6 py-3">
                                        {partnerShipAccount.partnershipPercentage}
                                    </td>
                                    <td className="px-6 py-3">
                                        {partnerShipAccount.isActive == 1 ? "Active" : "InActive"}
                                    </td>
                                    <td className="px-6 py-3">
                                        <FaEdit className="fill-blue-500 w-6 h-6"
                                            onClick={() => { setOpenModal(true); setIsEdit(true); setPartnerShipAccountData(partnerShipAccount) }} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>

                    </table>
                    {currentPartnerShipAccount.length === 0 && (
                        <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
                            <p className="text-red-800">No PartnerShip Accounts found.</p>
                        </div>
                    )}
                </div>
                {currentPartnerShipAccount.length > 0 && (
                    <div className="w-full flex flex-col pb-[100px]">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={(page) => setCurrentPage(page)}
                        />
                    </div>
                )}
            </div>
        </>
    )
}

export { PartnershipAccounts };