import { useForm } from 'react-hook-form';
import { FaPlus } from "react-icons/fa";
import { FcCancel } from "react-icons/fc";
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object, boolean, number } from 'yup';
import { useEffect, useRef, useState } from 'react';
import { SelectBox } from '../../../../SelectBox';
import { FaLock } from "react-icons/fa";
import { FaLockOpen } from "react-icons/fa";
import { InputBox } from '../../../../InputBox';
import { useAppDispatch } from '../../../../../store/hooks/redux-hooks';
import { addCACreditNote, fetchNextCreditNoteNumber } from '../../../../../store/slice/Accounting/ClientAccounts/Invoice/CACreditNoteSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';
import { toast } from 'react-toastify';
import { MainAnimation } from '../../../../loadingAnimation/MainAnimation';
import { SearchCaseReferenceDetails } from '../../../SearchCaseReferenceDetails';
import { fetchAllClientQuickInvoices, fetchAllQuickInvoicesByCaseId, fetchAllQuickInvoicesById, fetchDisbursementAccountCombo, fetchVatAccountCombo } from '../../../../../store/slice/Accounting/ClientAccounts/ClientQuickInvoicesSlice';
import { NumericFormat } from 'react-number-format';
import { mergeSameTypeArrays } from '../../../../../utils/arraymerge';
import { QuickInvoicesById } from '../../../../../store/model/Accounting/ClientAccount/QuickInvoicesInterFace';
import { fetchAllNominalLedgerByTypes } from '../../../../../store/slice/Accounting/AccountSetup/NominalLedgerSlice';

export interface CACreditNoteLineItem {
    lineId: number | 0;
    creditNoteItem: string;
    amount: number;
    vatRateId: number;
    vat: number;
    totalAmount: number;
    lineType: string;
}

export interface CreateClientAccountCreditNoteRequest {
    creditNoteId: number;
    creditNoteNumber: number;
    invoiceNumber?: number;
    invoiceId?: number;
    caseId: number;
    remainingCredit: number;
    creditAmount: number;
    vatAmount: number;
    totalAmount: number;
    balanceDue: number;
    incomeAccountId: number;
    disburseAccountId: number;
    vatOutputAccountId: number;
    description: string;
    reason: string;
    memo: string;
    invoiceNoticeId: number;
    creditNoteNotice: string;
    incomeAmount: number;
    disburseAmount: number;
    creditNoteDate: Date;
    lineItems: CACreditNoteLineItem[];
}


function AddCreditNote({ openDrawer, Close, modalType, }: { openDrawer: boolean, Close: any, modalType: string }) {
    const [title] = useState((modalType === 'Add' ? 'Add Credit Note' : 'Edit Credit Note'))
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [modalPlacement, setModalPlacement] = useState('center')
    const [openCaseAccountModal, setOpenCaseAccountModal] = useState(false)
    const [selectedCaseReference, setSelectedCaseReference] = useState<any>();
    const [selectedCaseId, setSelectedCaseId] = useState<number>();
    const [selectedInvoiceId, setSelectedInvoiceId] = useState<number>();
    const [selectedInvoiceObj, setSelectedInvoiceObj] = useState<any>();
    const [autoReceiptNumber, setAutoReceiptNumber] = useState<number>();
    const dispatch = useAppDispatch();
    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);
    const [initialLoading, setInitialLoading] = useState(true);
    const receiptAutoNumber = useSelector((state: RootState) => state.clientCreditNote.receiptNumber);
    const quickInvoicesByCase = useSelector((state: RootState) => state.clientQuickInvoices.quickInvoicesByCase);
    const { invoiceNotices } = useSelector((state: RootState) => state.invoiceNotice);
    const quickInvoicesById = useSelector((state: RootState) => state.clientQuickInvoices.quickInvoicesById);
    const { vatAccountCombo, disbursementAccountCombo } =useSelector((state: RootState) => state.clientQuickInvoices);
    const { nominalLedgerTypeRes } = useSelector((state: RootState) => state.nominalLedger);
    const [creditnoterows, setCreditNoteRows] = useState([
        { selectedTypeId: null, description: "", amount: 0, vatRate: 0 },
    ]);
    const { officeVatRates } = useSelector((state: RootState) => state.officeVatRate);
    const [officeVatRateLists, setOfficeVatRateLists] = useState<any[]>([]);
    const [invoiceDataByCaseLists, setInvoiceDataByCaseLists] = useState<any[]>([]);
    const [invoiceDataByIdData, setInvoiceDataByIdData] = useState<QuickInvoicesById | null>(null);
    const [invoiceData, setInvoiceData] = useState<any[]>([]);
    const [totalVat, setTotalVat] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [remainingCredit, setRemainingCredit] = useState(0);
    const [lessCreditInvoice, setLessCreditInvoice] = useState(0);
    const [invoiceNoticeLists, setInvoiceNoticeLists] = useState<any[]>([]);
    const [selectedInvoiceNotice, setSelectedInvoiceNotice] = useState<any>("");

    const [linesDisbursements, setLinesDisbursements] = useState<CACreditNoteLineItem[]>([])
    const [linesProfessionalfess, setLinesProfessionalfess] = useState<CACreditNoteLineItem[]>([])
    const [linesTimeEntries, setLinesTimeEntries] = useState<CACreditNoteLineItem[]>([])
    const [vatOutAccountId, setVatOutAccountId] = useState<number>();
    const [icomeAccountId, setIncomeAccountId] = useState<number>();
    const [disburseAccountId, setDisbursementAccountId] = useState<number>();

    const [disbursementAccounts, setdisbursementAccounts] = useState<any[]>([]);
    const [disbursementAccountsCombo, setdisbursementAccountsCombo] = useState<any[]>([]);
    const [vataccountCombo, setvataccountCombo] = useState<any[]>([]);

    useEffect(() => {
        if (!effectServiceCall.current) {
            const fetchPromises = [
                dispatch(fetchAllNominalLedgerByTypes()),
                dispatch(fetchDisbursementAccountCombo()),
                dispatch(fetchVatAccountCombo()),
                dispatch(fetchNextCreditNoteNumber()),
            ];

            Promise.all(fetchPromises)
                .then(() => {
                    setInitialLoading(false);
                })
                .catch((error) => {
                    if (!errorShownRef.current) {
                        toast.error("Error fetching data: " + error.message);
                        errorShownRef.current = true;
                    }
                    setInitialLoading(false);
                });

            effectServiceCall.current = true;
        }
    }, [dispatch]);

    const handleEditClick = () => {
        setIsDisabled(!isDisabled);
    };

    const [isDisabled, setIsDisabled] = useState(true);


    const validationSchema = object().shape({
        clientBankAccount: string(),
        creditNoteNumber: number(),
        creditNoteDate: string()
            .required('Transaction Date is required')
            .transform((value, originalValue) => {
                if (originalValue === "" || value === null) return null;
                return new Date(value).toISOString().split("T")[0];
            }),
        fkInvoiceId: number()
            .required('invoice is required'),
        fkCaseId: number()
            .required('case is required'),
        clinvcnTotalPaid: number()
            .required('Total Paid is required'),
        creditNoteNotice: string(),
        clinvcnTotalCreditNote: number()
            .required('Credit Notes is required'),
        clinvcnClientName: string(),
        clinvcnGrossAmount: number()
            .required('Gross Amount is required'),
        clinvcnBalanceDue: number()
            .required('Balance Due is required'),
        invoiceNoticeId: number()
            .required('Invoice Notice Title is required'),
        description: string(),
        reason: string(),
        memo: string(),
        disburseAccountId: number(),
        incomeAccountId: number(),
        vatOutputAccountId: number(),

    });

    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            creditNoteNumber: autoReceiptNumber,
        }
    });

    const onSubmit = (data: any) => {
        console.log(JSON.stringify(data, null, 2));

        const creditNoteRequest: CreateClientAccountCreditNoteRequest = {
            creditNoteId: 0,
            creditNoteNumber: data.creditNoteNumber,
            invoiceNumber: selectedInvoiceObj.invoiceNumber,
            invoiceId: selectedInvoiceId,
            caseId: data.fkCaseId,
            remainingCredit: lessCreditInvoice,
            creditAmount: 75.25,
            vatAmount: totalVat,
            totalAmount: totalAmount,
            balanceDue: data.clinvcnBalanceDue,
            incomeAccountId: 1,
            disburseAccountId: 1,
            vatOutputAccountId: 1,
            description: data.description,
            reason: data.reason,
            memo: data.memo,
            invoiceNoticeId: data.invoiceNoticeId,
            creditNoteNotice: data.creditNoteNotice,
            incomeAmount: 500,
            disburseAmount: 0,
            creditNoteDate: data.creditNoteDate,
            lineItems: mergeSameTypeArrays([linesProfessionalfess, linesDisbursements, linesTimeEntries])
        };
        dispatch(addCACreditNote(creditNoteRequest))
            .unwrap()
            .then(() => {
                Close();
                // navigate("/systemmanagement/systemmaintenance/casetype");
            })
            .catch((error: any) => {
                console.error("Failed to add case type:", error);
            });
    };

    const onError = (errors: any) => {
        console.log("Form submission errors", errors);
    };

    const toggleCaseAccountModal = () => {
        setOpenCaseAccountModal(!openCaseAccountModal);
    }
    const handleCaseSelection = (caseReference: any) => {
        setValue("fkCaseId", caseReference.caseId)
        setSelectedCaseId(caseReference.caseId)
        setSelectedCaseReference(caseReference);
    };

    useEffect(() => {
        if (receiptAutoNumber !== null) {
            setValue("creditNoteNumber", receiptAutoNumber)
            setAutoReceiptNumber(receiptAutoNumber)
        }

    }, [receiptAutoNumber])

    useEffect(() => {
        if (selectedCaseId) {
            dispatch(fetchAllQuickInvoicesByCaseId(selectedCaseId))
        }
    }, [selectedCaseId, dispatch])

    useEffect(() => {
        if ((quickInvoicesByCase !== null && quickInvoicesByCase.listingData!=null )  && quickInvoicesByCase.listingData.length > 0) {
            setInvoiceDataByCaseLists(quickInvoicesByCase.listingData)
            setDisbursementAccountId(quickInvoicesByCase.disburseAccountId);
            setVatOutAccountId(quickInvoicesByCase.vatAccountId);
            setIncomeAccountId(quickInvoicesByCase.incomeAccountId);
        }
    });

    useEffect(() => {
        if (nominalLedgerTypeRes?.disbursementAccounts) {
          const data = nominalLedgerTypeRes.disbursementAccounts.map(
            (item: any) => ({
              value: item.accountId,
              label: item.accountName,
            })
          );
          setdisbursementAccounts(data);
        }
      }, [nominalLedgerTypeRes]);

      useEffect(() => {
        if (vatAccountCombo != null && vatAccountCombo.length > 0) {
          let data = vatAccountCombo.map((item: any) => ({
            value: item.accountId,
            label: item.accountName,
          }));
          setvataccountCombo(data);
        }
    
        if (
          disbursementAccountCombo != null &&
          disbursementAccountCombo.length > 0
        ) {
          let data = disbursementAccountCombo.map((item: any) => ({
            value: item.accountId,
            label: item.accountName,
          }));
          setdisbursementAccountsCombo(data);
        }
      }, [vatAccountCombo, disbursementAccountCombo]);

    useEffect(() => {
        if (quickInvoicesById !== null) {
            console.log(quickInvoicesById);

            setInvoiceDataByIdData(quickInvoicesById);
            if (quickInvoicesById.linesDisbursements != null && quickInvoicesById.linesDisbursements.length > 0) {
                setLinesDisbursements(quickInvoicesById.linesDisbursements);
            }

            if (quickInvoicesById.linesProfessionCharges != null && quickInvoicesById.linesProfessionCharges.length > 0) {
                let presult = quickInvoicesById.linesProfessionCharges.map((item: any) => {
                    let resdata: CACreditNoteLineItem = {
                        lineId: 0,
                        amount: item.amount,
                        creditNoteItem: item.billingDesc,
                        totalAmount: item.total,
                        vatRateId: item.fkTaxId,
                        vat: item.tax,
                        lineType: "Professional Fee"
                    }
                    return resdata
                })
                setLinesProfessionalfess([...linesProfessionalfess, ...presult]);
            }

            if (quickInvoicesById.linesTimeEntries != null && quickInvoicesById.linesTimeEntries.length > 0) {
                setLinesTimeEntries(quickInvoicesById.linesTimeEntries);
            }

            // setValue("clinvcnClientName", invoiceDataByIdData?.caseClients?.givenNames);
            // setValue("clinvcnGrossAmount", invoiceDataByIdData?.invoiceDetails?.total)
            // setValue("clinvcnTotalPaid", invoiceDataByIdData?.totalPaid)
            // setValue("clinvcnTotalCreditNote", invoiceDataByIdData?.totalCreditNotes)
            // setValue("clinvcnBalanceDue", invoiceDataByIdData?.balanceDue)

        }
    }, [quickInvoicesById]);

    useEffect(() => {
        if (invoiceDataByIdData !== null) {
            setValue("clinvcnClientName", invoiceDataByIdData?.caseClients?.givenNames);
            setValue("clinvcnGrossAmount", invoiceDataByIdData?.invoiceDetails?.total)
            setValue("clinvcnTotalPaid", invoiceDataByIdData?.totalPaid)
            setValue("clinvcnTotalCreditNote", invoiceDataByIdData?.totalCreditNotes)
            setValue("clinvcnBalanceDue", invoiceDataByIdData?.balanceDue)

            setLessCreditInvoice(invoiceDataByIdData?.invoiceDetails?.total);
            setRemainingCredit(invoiceDataByIdData?.totalPaid);
        }
    }, [invoiceDataByIdData])

    useEffect(() => {
        if (invoiceDataByCaseLists.length > 0) {
            let data = invoiceDataByCaseLists.map((invoice: any, index) => ({
                value: invoice.invoiceId,
                label: `INV${invoice.invoiceNumber} (${invoice.total})`,
            }));
            setInvoiceData(data);
        }
    }, [invoiceDataByCaseLists]);

    useEffect(() => {
        if (selectedInvoiceId) {
            let obj = invoiceDataByCaseLists.find(x => x.invoiceId === selectedInvoiceId);
            setSelectedInvoiceObj(obj);
            dispatch(fetchAllQuickInvoicesById(selectedInvoiceId))
        }
    }, [selectedInvoiceId, dispatch])

    useEffect(() => {
        if (officeVatRates != null && officeVatRates.length > 0) {
            let data = officeVatRates.map((officeVatRate: any, index) => ({
                value: officeVatRate.vatTypeId,
                label: `${officeVatRate.vatType}`
            }));
            setOfficeVatRateLists(data);
        }
    }, [officeVatRates]);

    useEffect(() => {
        if (invoiceNotices.length > 0) {
            let data = invoiceNotices.map((invoiceNotice: any, index) => ({
                value: invoiceNotice.invoiceNoticeId,
                label: `${invoiceNotice.title}`,
            }));
            setInvoiceNoticeLists(data);
        }
    }, [invoiceNotices]);

    const handleDropdownChange = (selectedOption: number) => {
        const selectedNotice = invoiceNotices.find((notice: any) => notice.invoiceNoticeId === selectedOption);
        if (selectedNotice !== null) {
            setSelectedInvoiceNotice(selectedNotice);
            setValue("creditNoteNotice", selectedNotice?.invoiceNotice)
        };
    };

    const handleRowChange = (index: number, field: string, value: any) => {
        const updatedRows = [...creditnoterows];
        updatedRows[index] = { ...updatedRows[index], [field]: value };
        setCreditNoteRows(updatedRows);
    };

    const addRow = (type: string) => {
        let resdata: CACreditNoteLineItem = {
            lineId: 0,
            amount: 0,
            creditNoteItem: '',
            totalAmount: 0,
            vatRateId: 0,
            vat: 0,
            lineType: type
        }
        if (type === 'Professional Fee') {
            setLinesProfessionalfess([
                ...linesProfessionalfess,
                resdata]);
        }

        if (type === 'Disbursement') {
            setLinesDisbursements([
                ...linesDisbursements,
                resdata]);
        }

        if (type === 'Time Entry') {
            setLinesTimeEntries([
                ...linesTimeEntries,
                resdata]);
        }
    };


    const removeRow = (index: number, type: string) => {
        if (type === 'Professional Fee') {
            const updatedRows = linesProfessionalfess.filter((_, i) => i !== index);
            setLinesProfessionalfess(updatedRows);
        }

        if (type === 'Disbursement') {
            const updatedRows = linesDisbursements.filter((_, i) => i !== index);
            setLinesDisbursements(updatedRows);
        }

        if (type === 'Time Entry') {
            const updatedRows = linesTimeEntries.filter((_, i) => i !== index);
            setLinesTimeEntries(updatedRows);
        }

    };

    const handleVatRateChange = (index: number, selectedOption: any) => {
        const updatedRows = [...creditnoterows];

        updatedRows[index] = {
            ...updatedRows[index],
            vatRate: selectedOption.percentage,
            selectedTypeId: selectedOption.vatTypeId,
        };

        setCreditNoteRows(updatedRows);
    };

    useEffect(() => {
        const mergedArray = mergeSameTypeArrays([linesProfessionalfess, linesDisbursements, linesTimeEntries]);

        const newTotalVat = mergedArray.reduce(
            (acc, row) => acc + (row.vat), 0);

        const newTotalAmount = mergedArray.reduce(
            (acc, row) => acc + row.amount + (row.vat),
            0
        );
        const newSubTotal = mergedArray.reduce(
            (acc, row) => acc + row.amount,
            0
        );
        // const newLessCreditInvoice = mergedArray.reduce(
        //     (acc, row) => acc - 0,
        //     0
        // );

        // const newRemainingCredit = mergedArray.reduce((acc, row) => acc - 0, 0);

        setSubTotal(newSubTotal);
        setTotalVat(newTotalVat);
        setTotalAmount(newTotalAmount);
    }, [linesProfessionalfess, linesDisbursements, linesTimeEntries]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit, onError)} className="mx-auto mb-5" >
                {initialLoading ? (<MainAnimation />) : ""}
                <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
                    <div className="relative flex">
                        <input type="search" className="relative m-0 -me-0.5 block flex-auto rounded-s border border-solid border-neutral-200 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-surface outline-none transition duration-200 ease-in-out placeholder:text-neutral-500 focus:z-[3] focus:border-primary focus:shadow-inset focus:outline-none motion-reduce:transition-none dark:border-white/10 dark:text-white dark:placeholder:text-neutral-200 dark:autofill:shadow-autofill dark:focus:border-primary disabled:bg-gray-300" placeholder="Case Reference" aria-label="Search" disabled
                            value={selectedCaseReference?.caseReferenceAuto || ""} />
                        <button
                            onClick={toggleCaseAccountModal}
                            className="z-[2] inline-block rounded-e border-2 border-primary px-6 pb-[6px] pt-2 text-xs font-medium  leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-accent-300 hover:bg-primary-50/50 hover:text-primary-accent-300 focus:border-primary-600 focus:bg-primary-50/50 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:text-primary-500 bg-hover_bg text-white-bg border-hover_bg"
                            data-twe-ripple-init
                            data-twe-ripple-color="white"
                            type="button">
                            Search
                        </button>
                        {openCaseAccountModal &&
                            <SearchCaseReferenceDetails
                                openDrawer={openCaseAccountModal}
                                Close={toggleCaseAccountModal}
                                onCaseSelectDetail={handleCaseSelection}
                            />
                        }
                    </div>
                    <div>
                        <button
                            type="button"
                            onClick={() => {
                                Close();
                            }}
                            className="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-2 rounded">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>

                </div >
                <div className="grid grid-cols-4 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 gap-y-1 w-full">
                    <div >
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Credit Note Number
                        </label>
                        <div className="flex gap-3">
                            <input
                                type="text"
                                value={autoReceiptNumber}
                                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg disabled:bg-gray-300 dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                                disabled={isDisabled}
                            />
                            <button
                                type="button"
                                onClick={handleEditClick}
                                className="mt-2 text-sm font-medium text-blue-600 dark:text-blue-500"
                            >
                                {isDisabled ? <FaLock /> : <FaLockOpen />}
                            </button>
                        </div>
                    </div>
                    <div className="">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Invoice <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            isSearchable={true}
                            options={invoiceData}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                            onChange={(selectedOption) => {
                                if (selectedOption) {
                                    setValue('fkInvoiceId', selectedOption);
                                    setSelectedInvoiceId(selectedOption)
                                }
                            }}
                        />
                        <div className="font-medium mt-2 text-xs text-red-600" >{errors?.fkInvoiceId?.message?.toString()}</div>
                    </div>
                    <div className="relative">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Client <span className="text-red-500">*</span>
                        </label>
                        <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.clinvcnClientName ? 'is-invalid' : ''}`}
                            placeholder="Enter Client"
                            value={invoiceDataByIdData?.caseClients?.givenNames}
                            disabled
                        />
                        <div className="font-medium mt-2 text-xs text-red-600" >{errors?.clinvcnClientName?.message?.toString()}</div>
                    </div>
                    <div className="relative">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Gross Amount <span className="text-red-500">*</span>
                        </label>
                        <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.clinvcnGrossAmount ? 'is-invalid' : ''}`}
                            value={quickInvoicesById?.invoiceDetails?.total}
                            placeholder="Enter Gross Amount"
                            disabled
                        />
                        <div className="font-medium mt-2 text-xs text-red-600" >{errors?.clinvcnGrossAmount?.message?.toString()}</div>
                    </div>
                    <div className="relative">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Total Paid <span className="text-red-500">*</span>
                        </label>
                        <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.clinvcnTotalPaid ? 'is-invalid' : ''}`}
                            value={invoiceDataByIdData?.totalPaid}
                            placeholder="Enter Total Paid"
                            disabled
                        />
                        <div className="font-medium mt-2 text-xs text-red-600" >{errors?.clinvcnTotalPaid?.message?.toString()}</div>
                    </div>
                    <div className="relative">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Credit Notes <span className="text-red-500">*</span>
                        </label>
                        <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.clinvcnTotalCreditNote ? 'is-invalid' : ''}`}
                            value={invoiceDataByIdData?.totalCreditNotes}
                            placeholder="Enter Credit Notes"
                            disabled
                        />
                        <div className="font-medium mt-2 text-xs text-red-600" >{errors?.clinvcnTotalCreditNote?.message?.toString()}</div>
                    </div>
                    <div className="relative">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Balance Due <span className="text-red-500">*</span>
                        </label>
                        <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.clinvcnBalanceDue ? 'is-invalid' : ''}`}
                            value={invoiceDataByIdData?.balanceDue}
                            placeholder="Enter Balance Due"
                            disabled
                        />
                        <div className="font-medium mt-2 text-xs text-red-600" >{errors?.clinvcnBalanceDue?.message?.toString()}</div>
                    </div>
                    <div className="relative">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Transaction Description
                        </label>
                        <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('description')}
                            placeholder="Enter Transaction Description"
                        />
                    </div>
                    <div className="relative">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Transaction Date <span className="text-red-500">*</span>
                        </label>
                        <input type="date" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.creditNoteDate ? 'is-invalid' : ''}`} {...register('creditNoteDate')}
                            max={new Date().toISOString().split("T")[0]}
                            placeholder="Enter Transaction Date"
                        />
                        <div className="font-medium mt-2 text-xs text-red-600">{errors?.creditNoteDate?.message?.toString()}</div>
                    </div>
                    <div className="relative">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Reason
                        </label>
                        <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('reason')}
                            placeholder="Enter Reason"
                        />
                    </div>
                    <div className="relative">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Memo
                        </label>
                        <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('memo')}
                            placeholder="Enter Memo"
                        />
                    </div>
                    <div className="">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Invoice Notice Title
                        </label>
                        <SelectBox
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            isSearchable={true}
                            options={invoiceNoticeLists}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                            onChange={(selectedOption) => {
                                if (selectedOption) {
                                    handleDropdownChange(selectedOption);
                                    setValue('invoiceNoticeId', selectedOption);
                                }
                            }}
                        />
                    </div>
                    <div className="relative">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Invoice Notice
                        </label>
                        <textarea
                            className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                            value={selectedInvoiceNotice}
                            placeholder="Invoice Notice" {...register('creditNoteNotice')}
                        ></textarea>
                    </div>
                </div>

                <div className="relative h-auto sm:rounded-lg mt-5">
                    <table className="w-full text-xs text-black text-left rtl:text-right dark:text-gray-400">
                        <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <th scope="col" className="px-6 py-3">
                                    Item Description
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Amount
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    VAT Rate
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Vat
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Total
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan={5}>
                                    <strong>Professional Charges</strong>
                                </td>
                            </tr>
                        </tbody>
                        <tbody>
                            {linesProfessionalfess && linesProfessionalfess.map((row, index) => (
                                <tr
                                    key={index}
                                    className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                                >
                                    <td className="px-6 py-3">
                                        <input
                                            type="text"
                                            placeholder="Enter title"
                                            className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                                            value={row.creditNoteItem}
                                            onChange={(e) =>
                                                handleRowChange(
                                                    index,
                                                    "creditNoteItem",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </td>
                                    <td className="px-6 py-3">
                                        <NumericFormat
                                            value={row.amount}
                                            className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                                            placeholder="Amount"
                                            decimalScale={2}
                                            onValueChange={(values) =>
                                                handleRowChange(
                                                    index,
                                                    "amount",
                                                    values.floatValue || 0
                                                )
                                            }
                                        />
                                    </td>
                                    <td className="px-6 py-3">
                                        <SelectBox
                                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                            indicator={
                                                <svg
                                                    width="15"
                                                    className="fill-current mr-2"
                                                    height="15"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 448 512"
                                                >
                                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5 0 45.3l160 160z" />
                                                </svg>
                                            }
                                            isMulti={false}
                                            name={`vatRate-${index}`}
                                            isSearchable={true}
                                            options={officeVatRateLists}
                                            value={row.vatRateId}
                                            placeholder="Select VAT Type"
                                            shape="round"
                                            color="indigo_50"
                                            size="xs"
                                            onChange={(selectedOption) => handleVatRateChange(index, selectedOption)}
                                        />
                                    </td>
                                    <td className="px-6 py-3">
                                        <NumericFormat
                                            value={row.vat}
                                            className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                                            displayType="text"
                                            thousandSeparator={true}
                                            prefix="£"
                                        />
                                    </td>
                                    <td className="px-6 py-3">
                                        <NumericFormat
                                            value={row.totalAmount}
                                            className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                                            displayType="text"
                                            thousandSeparator={true}
                                            prefix="£"
                                        />
                                    </td>
                                    <td className="px-6 py-3">
                                        <button type="button" onClick={() => removeRow(index, 'Professional Fee')}>
                                            <FcCancel />
                                        </button>
                                    </td>
                                </tr>
                            ))}


                        </tbody>
                        <tbody>
                            <tr>
                                <td colSpan={5}>
                                    <div className="mb-5">
                                        <button
                                            className="flex items-center bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
                                            type="button"
                                            onClick={() => addRow('Professional Fee')}
                                        >
                                            <FaPlus className="w-4 h-4 fill-white-bg" />
                                            <span className="text-white-bg mx-2">Add Line</span>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>

                        <tbody>
                            <tr>
                                <td colSpan={5}>
                                    <strong>Disbursements</strong>
                                </td>
                            </tr>
                        </tbody>

                        <tbody>

                            {linesDisbursements && linesDisbursements.map((row, index) => (
                                <tr
                                    key={index}
                                    className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                                >
                                    <td className="px-6 py-3">
                                        <input
                                            type="text"
                                            placeholder="Enter title"
                                            className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                                            value={row.creditNoteItem}
                                            onChange={(e) =>
                                                handleRowChange(
                                                    index,
                                                    "creditNoteItem",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </td>
                                    <td className="px-6 py-3">
                                        <NumericFormat
                                            value={row.amount}
                                            className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                                            placeholder="Amount"
                                            decimalScale={2}
                                            onValueChange={(values) =>
                                                handleRowChange(
                                                    index,
                                                    "amount",
                                                    values.floatValue || 0
                                                )
                                            }
                                        />
                                    </td>
                                    <td className="px-6 py-3">
                                        <SelectBox
                                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                            indicator={
                                                <svg
                                                    width="15"
                                                    className="fill-current mr-2"
                                                    height="15"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 448 512"
                                                >
                                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5 0 45.3l160 160z" />
                                                </svg>
                                            }
                                            isMulti={false}
                                            name={`vatRate-${index}`}
                                            isSearchable={true}
                                            options={officeVatRateLists}
                                            value={row.vatRateId}
                                            placeholder="Select VAT Type"
                                            shape="round"
                                            color="indigo_50"
                                            size="xs"
                                            onChange={(selectedOption) => handleVatRateChange(index, selectedOption)}
                                        />
                                    </td>
                                    <td className="px-6 py-3">
                                        <NumericFormat
                                            value={row.vat}
                                            className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                                            displayType="text"
                                            thousandSeparator={true}
                                            prefix="£"
                                        />
                                    </td>
                                    <td className="px-6 py-3">
                                        <NumericFormat
                                            value={row.totalAmount}
                                            className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                                            displayType="text"
                                            thousandSeparator={true}
                                            prefix="£"
                                        />
                                    </td>
                                    <td className="px-6 py-3">
                                        <button type="button" onClick={() => removeRow(index, 'Disbursement')}>
                                            <FcCancel />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>

                        <tbody>
                            <tr>
                                <td colSpan={5}>
                                    <div className="mb-5">
                                        <button
                                            className="flex items-center bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
                                            type="button"
                                            onClick={() => addRow('Disbursement')}
                                        >
                                            <FaPlus className="w-4 h-4 fill-white-bg" />
                                            <span className="text-white-bg mx-2">Add Line</span>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>

                        <tbody>
                            <tr>
                                <td colSpan={5}>
                                    <strong>Time Entries</strong>
                                </td>
                            </tr>
                        </tbody>
                        <tbody>
                            {linesTimeEntries && linesTimeEntries.map((row, index) => (
                                <tr
                                    key={index}
                                    className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                                >
                                    <td className="px-6 py-3">
                                        <input
                                            type="text"
                                            placeholder="Enter title"
                                            className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                                            value={row.creditNoteItem}
                                            onChange={(e) =>
                                                handleRowChange(
                                                    index,
                                                    "creditNoteItem",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </td>
                                    <td className="px-6 py-3">
                                        <NumericFormat
                                            value={row.amount}
                                            className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                                            placeholder="Amount"
                                            decimalScale={2}
                                            onValueChange={(values) =>
                                                handleRowChange(
                                                    index,
                                                    "amount",
                                                    values.floatValue || 0
                                                )
                                            }
                                        />
                                    </td>
                                    <td className="px-6 py-3">
                                        <SelectBox
                                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                            indicator={
                                                <svg
                                                    width="15"
                                                    className="fill-current mr-2"
                                                    height="15"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 448 512"
                                                >
                                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5 0 45.3l160 160z" />
                                                </svg>
                                            }
                                            isMulti={false}
                                            name={`vatRate-${index}`}
                                            isSearchable={true}
                                            options={officeVatRateLists}
                                            value={row.vatRateId}
                                            placeholder="Select VAT Type"
                                            shape="round"
                                            color="indigo_50"
                                            size="xs"
                                            onChange={(selectedOption) => handleVatRateChange(index, selectedOption)}
                                        />
                                    </td>
                                    <td className="px-6 py-3">
                                        <NumericFormat
                                            value={row.vat}
                                            className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                                            displayType="text"
                                            thousandSeparator={true}
                                            prefix="£"
                                        />
                                    </td>
                                    <td className="px-6 py-3">
                                        <NumericFormat
                                            value={row.totalAmount}
                                            className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                                            displayType="text"
                                            thousandSeparator={true}
                                            prefix="£"
                                        />
                                    </td>
                                    <td className="px-6 py-3">
                                        <button type="button" onClick={() => removeRow(index, 'Time Entry')}>
                                            <FcCancel />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>


                        <tbody>
                            <tr>
                                <td colSpan={5}>
                                    <div className="mb-5">
                                        <button
                                            className="flex items-center bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
                                            type="button"
                                            onClick={() => addRow('Time Entry')}
                                        >
                                            <FaPlus className="w-4 h-4 fill-white-bg" />
                                            <span className="text-white-bg mx-2">Add Line</span>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>



                <div className="space-y-2 my-5 border-2 p-6">
                    <div className="flex items-center">
                        <label className="w-1/2 text-sm font-semibold dark:text-white-bg">
                            Sub Total{" "}
                        </label>
                        <span className="mx-2 text-sm font-semibold dark:text-white-bg">
                            :
                        </span>
                        <p className="w-1/2 text-sm dark:text-white-bg text-right">
                            £{subTotal.toFixed(2)}
                        </p>
                    </div>
                    <div className="flex items-center">
                        <label className="w-1/2 text-sm font-semibold dark:text-white-bg">
                            VAT
                        </label>
                        <span className="mx-2 text-sm font-semibold dark:text-white-bg">
                            :
                        </span>
                        <p className="w-1/2 text-sm dark:text-white-bg text-right">
                            £{totalVat.toFixed(2)}
                        </p>
                    </div>
                    <div className="flex items-center">
                        <label className="w-1/2 text-sm font-semibold dark:text-white-bg">
                            Total Amount
                        </label>
                        <span className="mx-2 text-sm font-semibold dark:text-white-bg">
                            :
                        </span>
                        <p className="w-1/2 text-sm dark:text-white-bg text-right">
                            £{totalAmount.toFixed(2)}
                        </p>
                    </div>
                    <div className="flex items-center">
                        <label className="w-1/2 text-sm font-semibold dark:text-white-bg">
                            Less Credit to Invoice {selectedInvoiceObj?.invoiceNumber}
                        </label>
                        <span className="mx-2 text-sm font-semibold dark:text-white-bg">
                            :
                        </span>
                        <p className="w-1/2 text-sm dark:text-white-bg text-right">
                            £{lessCreditInvoice.toFixed(2)}
                        </p>
                    </div>
                    <div className="flex items-center border-t-2 pt-2">
                        <label className="w-1/2 text-sm font-bold dark:text-white-bg">
                            Remaining Credit{" "}
                        </label>
                        <span className="mx-2 text-sm font-bold dark:text-white-bg">
                            :
                        </span>
                        <p className="w-1/2 text-sm font-bold dark:text-white-bg text-right">
                            £{remainingCredit.toFixed(2)}
                        </p>
                    </div>
                </div>

                <div className="flex gap-x-10">
                    <div className="space-y-2">
                        <InputBox title="Income Account" placeholder="£ 0.00" />
                        <div className="">
                            <SelectBox
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                indicator={
                                    <svg
                                        width="15"
                                        className="fill-current mr-2"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                    </svg>
                                }
                                isMulti={false}
                                name="frameFiftySix"
                                isSearchable={true}
                                options={disbursementAccounts}
                                value={icomeAccountId}
                                placeholder="Select"
                                shape="round"
                                color="indigo_50"
                                size="xs"
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setValue('incomeAccountId', selectedOption);
                                    }
                                }}
                            />
                        </div>
                    </div>

                    <div className="space-y-2">
                        <InputBox title="Disbursement Account" placeholder="£ 0.00" />
                        <div className="">
                            <SelectBox
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                indicator={
                                    <svg
                                        width="15"
                                        className="fill-current mr-2"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                    </svg>
                                }
                                isMulti={false}
                                name="frameFiftySix"
                                options={disbursementAccountsCombo}
                                value={disburseAccountId}
                                isSearchable={true}
                                placeholder="Select"
                                shape="round"
                                color="indigo_50"
                                size="xs"
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setValue('disburseAccountId', selectedOption);
                                    }
                                }}
                            />
                        </div>
                    </div>

                    <div className="space-y-2">
                        <InputBox title="VAT Output Account" placeholder="£ 0.00" />
                        <div className="">
                            <SelectBox
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                indicator={
                                    <svg
                                        width="15"
                                        className="fill-current mr-2"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                    </svg>
                                }
                                isMulti={false}
                                name="frameFiftySix"
                                options={vataccountCombo}
                                value={vatOutAccountId}
                                isSearchable={true}
                                placeholder="Select"
                                shape="round"
                                color="indigo_50"
                                size="xs"
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setValue('vatOutputAccountId', selectedOption);
                                    }
                                }}
                            />
                        </div>
                    </div>


                </div>

                <div className="flex col-span-2 gap-2 justify-end my-5">
                    <button
                        type="button"
                        onClick={() => {
                            Close();
                        }}
                        className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]"
                    >
                        Save
                    </button>
                </div>



            </form>
        </>
    );
}

export { AddCreditNote };
