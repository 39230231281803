import { Modal } from 'flowbite-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object } from 'yup';
import { useRef, useState } from 'react';


function PreviewOnlyCustomFileds({ openDrawer, Close, modalType,  }: { openDrawer: boolean, Close: any, modalType: string }) {
    const [title] = useState((modalType === 'Add' ? 'Add New Low Society' : 'Edit Low Society'))
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [modalPlacement, setModalPlacement] = useState('center')


    const validationSchema = object().shape({
        year : string()
            .required('Year is required'),
        commentsOnApplication: string(),
        
    });

    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = (data: any) => {
        console.log(JSON.stringify(data, null, 2));
        // dispatch(addCaseType(data))
        //     .unwrap()
        //     .then(() => {
        //         Close();
        //         navigate("/systemmanagement/systemmaintenance/casetype");
        //     })
        //     .catch((error) => {
        //         console.error("Failed to add case type:", error);
        //     });
    };


    return (
        <Modal
            className={`backdrop-blur-sm items-center justify-center`}
            show={openDrawer}
            position={modalPlacement}
            onClose={() => { reset(); Close(); }}
            initialFocus={emailInputRef}
            size="2xl"
        >
            <Modal.Header className="bg-white-bg dark:bg-dark_bg p-5">
                <span className="text-blue_gray-900 dark:text-white-bg text-xl w-auto">Custom Fields Required for Case Types and Case Sub-Types</span>
            </Modal.Header>
            <Modal.Body className="bg-white-bg dark:bg-dark_bg">

                <form onSubmit={handleSubmit(onSubmit)} className="mx-auto" >
                    <div className="grid grid-cols-2 gap-y-3 w-full">
                        <div className="relative max-w-full col-span-2">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Year
                            </label>
                            <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.year ? 'is-invalid' : ''}`} {...register('year')}
                                placeholder="Enter year"
                            />
                        </div>
                        
                        
                        {/* <div className="flex md:flex-col gap-2 flex-row justify-start w-full my-5">
                            <div className="flex flex-row gap-2 items-end justify-end w-full">
                                <button
                                    type="button"
                                    onClick={() => {
                                        Close();
                                    }}
                                    className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                                >
                                    Cancel
                                </button>
                                <button
                                    type='submit'
                                    className="bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]"
                                >
                                    Save
                                </button>
                            </div>
                        </div> */}
                    </div>
                </form>

            </Modal.Body>
        </Modal>
    );
}

export { PreviewOnlyCustomFileds };
