import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import ClientQuickInvoicesService from "../../../../services/Accounting/ClientAccounts/ClientQuickInvoicesService";
import { DisbursementAccountCombo, QuickInvoices, QuickInvoicesByCase, QuickInvoicesById, QuickInvoicesDataByCase, QuickInvoicesSaveData, VatAccountCombo } from "../../../model/Accounting/ClientAccount/QuickInvoicesInterFace";


// Define the state type for Checklist
type QuickInvoicesState = {
    quickInvoicesById: QuickInvoicesById;
    quickInvoicesByCase: QuickInvoicesByCase;
    quickInvoicesSaveData: QuickInvoicesSaveData[];
    quickInvoicesDataByCase: QuickInvoicesDataByCase;
    quickInvoices: QuickInvoices;
    disbursementAccountCombo: DisbursementAccountCombo[];
    vatAccountCombo: VatAccountCombo[];
    status: "idle" | "loading" | "failed";
    error: string | null;
    receiptNumber: number | null;
};

// Initial state
const initialState: QuickInvoicesState = {
    quickInvoicesById: null!,
    quickInvoicesByCase: null!,
    quickInvoicesSaveData: [],
    quickInvoices: null!,
    status: "idle",
    error: null,
    receiptNumber: null,
    disbursementAccountCombo: null!,
    quickInvoicesDataByCase: null!,
    vatAccountCombo: null!,
};

// Async thunks for CRUD operations
export const fetchInvoiceNextNumber = createAsyncThunk(
    "fetchInvoiceNextNumber",
    async (_, thunkAPI) => {
        try {
            const response = await ClientQuickInvoicesService.GetInvoiceNextNumber();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchAllClientQuickInvoices = createAsyncThunk(
    "fetchAllClientQuickInvoices",
    async (data: any = {}, thunkAPI) => {
        try {
            const response = await ClientQuickInvoicesService.GetAllClientQuickInvoices(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchAllQuickInvoicesByCaseId = createAsyncThunk(
    "fetchAllQuickInvoicesByCaseId",
    async (id: any, thunkAPI) => {
        try {
            const response = await ClientQuickInvoicesService.GetQuickInvoiceByCaseId(id);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchQuickInvoicesDataByCaseId = createAsyncThunk(
    "fetchQuickInvoicesDataByCaseId",
    async (id: any, thunkAPI) => {
        try {
            const response = await ClientQuickInvoicesService.GetQuickInvoiceDataByCaseId(id);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchDisbursementAccountCombo = createAsyncThunk(
    "fetchDisbursementAccountCombo",
    async (_, thunkAPI) => {
        try {
            const response = await ClientQuickInvoicesService.GetDisbursementAccountCombo();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchVatAccountCombo = createAsyncThunk(
    "fetchVatAccountCombo",
    async (_, thunkAPI) => {
        try {
            const response = await ClientQuickInvoicesService.GetVatAccountCombo();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchAllQuickInvoicesById = createAsyncThunk(
    "fetchAllQuickInvoicesById",
    async (id: any, thunkAPI) => {
        try {
            const response = await ClientQuickInvoicesService.GetQuickInvoiceById(id);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const AddClientQuickInvoices = createAsyncThunk(
    "AddClientQuickInvoices",
    async (data: QuickInvoicesSaveData, thunkAPI) => {
        try {
            const response = await ClientQuickInvoicesService.PostClientQuickInvoices(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


const ClientQuickInvoicesSlice = createSlice({
    name: "clientQuickInvoices",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllClientQuickInvoices.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllClientQuickInvoices.fulfilled, (state, action: PayloadAction<QuickInvoices>) => {
                state.status = "idle";
                state.quickInvoices = action.payload;
            })
            .addCase(fetchAllClientQuickInvoices.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchInvoiceNextNumber.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchInvoiceNextNumber.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.receiptNumber = action.payload;
            })
            .addCase(fetchInvoiceNextNumber.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchAllQuickInvoicesByCaseId.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllQuickInvoicesByCaseId.fulfilled, (state, action: PayloadAction<QuickInvoicesByCase>) => {
                state.status = "idle";
                state.quickInvoicesByCase = action.payload;
            })
            .addCase(fetchAllQuickInvoicesByCaseId.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchQuickInvoicesDataByCaseId.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchQuickInvoicesDataByCaseId.fulfilled, (state, action: PayloadAction<QuickInvoicesDataByCase>) => {
                state.status = "idle";
                state.quickInvoicesDataByCase = action.payload;
            })
            .addCase(fetchQuickInvoicesDataByCaseId.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchDisbursementAccountCombo.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchDisbursementAccountCombo.fulfilled, (state, action: PayloadAction<DisbursementAccountCombo[]>) => {
                state.status = "idle";
                state.disbursementAccountCombo = action.payload;
            })
            .addCase(fetchDisbursementAccountCombo.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchVatAccountCombo.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchVatAccountCombo.fulfilled, (state, action: PayloadAction<VatAccountCombo[]>) => {
                state.status = "idle";
                state.vatAccountCombo = action.payload;
            })
            .addCase(fetchVatAccountCombo.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchAllQuickInvoicesById.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllQuickInvoicesById.fulfilled, (state, action: PayloadAction<QuickInvoicesById>) => {
                state.status = "idle";
                state.quickInvoicesById = action.payload;
            })
            .addCase(fetchAllQuickInvoicesById.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(AddClientQuickInvoices.pending, (state) => {
                state.status = "loading";
            })
            .addCase(AddClientQuickInvoices.fulfilled, (state, action: PayloadAction<QuickInvoicesSaveData>) => {
                state.status = "idle";
                state.quickInvoicesSaveData.push(action.payload);
            })
            .addCase(AddClientQuickInvoices.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

    }

});

export default ClientQuickInvoicesSlice.reducer;