import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import TimeEntryService from "../../../../../services/Accounting/ClientAccounts/Invoice/TimeEntryService";


export interface CATimeEntry {

}
export interface TimeEntryData {
    timerEntriesResult: [];
    totalCount: number;
    currentPage: number;
    recordPerPage: number;
}
// Define the state type for Checklist
type NewInvoiceState = {
    cATimeEntry: CATimeEntry[];
    timeEntryData: TimeEntryData;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: NewInvoiceState = {
    cATimeEntry: null!,
    timeEntryData: null!,
    status: "idle",
    error: null
};

// Async thunks for CRUD operations

export const fetchTimeEntrySearch = createAsyncThunk(
    "fetchTimeEntrySearch",
    async (_, thunkAPI) => {
        try {
            const response = await TimeEntryService.GetTimeEntryServiceSearch();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addCATimerEntries = createAsyncThunk(
    "addCATimerEntries",
    async (data: any, thunkAPI) => {
        try {
            const response = await TimeEntryService.PostTimerEntries(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const TimeEntrySlice = createSlice({
    name: "clientTimeEntry",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTimeEntrySearch.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchTimeEntrySearch.fulfilled, (state, action: PayloadAction<any>) => {
                state.status = "idle";
                state.timeEntryData = action.payload;
            })
            .addCase(fetchTimeEntrySearch.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(addCATimerEntries.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addCATimerEntries.fulfilled, (state, action: PayloadAction<CATimeEntry>) => {
                state.status = "idle";
                state.cATimeEntry.push(action.payload);
            })
            .addCase(addCATimerEntries.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
    }
});

export default TimeEntrySlice.reducer;
