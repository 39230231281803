import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";

const GetAllLexcelCqsColpCofaGdpr = async (data: any) => {
    try {
        const response = await axiosInstance.post("LexcelCqsColpCofaGdpr", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostLexcelCqsColpCofaGdpr = async (data: any) => {
    try {
        const response = await axiosInstance.post("LexcelCqsColpCofaGdpr", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutLexcelCqsColpCofaGdpr = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`LexcelCqsColpCofaGdpr/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteLexcelCqsColpCofaGdpr = async (id: number) => {
    try {
        await axiosInstance.delete(`/LexcelCqsColpCofaGdpr/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const LexcelCqsColpCofaGdprService = {
    GetAllLexcelCqsColpCofaGdpr,
    PostLexcelCqsColpCofaGdpr,
    PutLexcelCqsColpCofaGdpr,
    deleteLexcelCqsColpCofaGdpr
};


export default LexcelCqsColpCofaGdprService;
