import { ErrorResult } from "../../../../../store/model/errorResult";
import axiosInstance from "../../../../axiosInstance";




const GetAllBankNewPayment = async () => {
    try {
        const response = await axiosInstance.post("BankPaymentSearch", {});
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostBankNewPayment = async (data: any) => {
    try {
        const response = await axiosInstance.post("BankPayment", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PutBankNewPayment = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`BankNewPayment/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteBankNewPayment = async (id: number) => {
    try {
        await axiosInstance.delete(`/BankNewPayment/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const BankNewPaymentService = {
    GetAllBankNewPayment,
    PostBankNewPayment,
    PutBankNewPayment,
    deleteBankNewPayment
};


export default BankNewPaymentService;
