import axiosInstance from '../../axiosInstance';
import { ErrorResult } from '../../../store/model/errorResult';


const GetCaseCorrespondenceById = async (id: number) => {
    try {
        const response = await axiosInstance.get(`case/${id}/CaseCorrespondence`);
        return response.data;
    } catch (error) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const PostCaseCorrespondenceDate = async (data: any) => {
    try {
        const response = await axiosInstance.post(`case/CaseCorrDateSave`, data);
        return response.data;
    } catch (error) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetCaseWorkflowExists = async (id: number) => {
    try {
        const response = await axiosInstance.get(`case/${id}/CaseWorkflowExists`);
        return response.data;
    } catch (error) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const CaseCorrespondenceServices = {
    GetCaseCorrespondenceById,
    GetCaseWorkflowExists,
    PostCaseCorrespondenceDate,
};

export default CaseCorrespondenceServices;
