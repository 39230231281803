import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginationResponse } from "../../../model/Pagination/paginationClass";
import LexcelCqsColpCofaGdprService from "../../../../services/OfficeManagement/OfficeInformation/LexcelCqsColpCofaGdprService";

export interface COLPAndCOFA {

  cOLPAndCOFAId: number;
}
// Define the state type for Checklist
type COLPAndCOFAState = {
    cOLPAndCOFAs: PaginationResponse<COLPAndCOFA> | null;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: COLPAndCOFAState = {
    cOLPAndCOFAs: null,
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllCOLPAndCOFA = createAsyncThunk(
    "fetchAllCOLPAndCOFA",
    async (data: any, thunkAPI) => {
        try {
            const response = await LexcelCqsColpCofaGdprService.GetAllLexcelCqsColpCofaGdpr(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addCOLPAndCOFA = createAsyncThunk(
    "addCOLPAndCOFA",
    async (data: any, thunkAPI) => {
        try {
            const response = await LexcelCqsColpCofaGdprService.PostLexcelCqsColpCofaGdpr(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateCOLPAndCOFA = createAsyncThunk(
    "updateCOLPAndCOFA",
    async ({ id, data }: { id: number, data: COLPAndCOFA }, thunkAPI) => {
        try {
            const response = await LexcelCqsColpCofaGdprService.PutLexcelCqsColpCofaGdpr(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteCOLPAndCOFA = createAsyncThunk(
    "deleteCOLPAndCOFA",
    async (id: number, thunkAPI) => {
        try {
            await LexcelCqsColpCofaGdprService.deleteLexcelCqsColpCofaGdpr(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const COLPAndCOFASlice = createSlice({
    name: "cOLPAndCOFA",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllCOLPAndCOFA.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllCOLPAndCOFA.fulfilled, (state, action: PayloadAction<PaginationResponse<COLPAndCOFA>>) => {
                state.status = "idle";
                state.cOLPAndCOFAs = action.payload;
            })
            .addCase(fetchAllCOLPAndCOFA.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addCOLPAndCOFA.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addCOLPAndCOFA.fulfilled, (state, action: PayloadAction<COLPAndCOFA>) => {
                state.status = "idle";
               // state.cOLPAndCOFAs.push(action.payload);
            })
            .addCase(addCOLPAndCOFA.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateCOLPAndCOFA.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateCOLPAndCOFA.fulfilled, (state, action: PayloadAction<COLPAndCOFA>) => {
                state.status = "idle";
                // const index = state.cOLPAndCOFAs.findIndex(t => t.cOLPAndCOFAId === action.payload.cOLPAndCOFAId);
                // if (index !== -1) {
                //     state.cOLPAndCOFAs[index] = action.payload;
                // }
            })
            .addCase(updateCOLPAndCOFA.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteCOLPAndCOFA.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteCOLPAndCOFA.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
               // state.cOLPAndCOFAs = state.cOLPAndCOFAs.filter(t => t.cOLPAndCOFAId !== action.payload);
            })
            .addCase(deleteCOLPAndCOFA.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default COLPAndCOFASlice.reducer;
