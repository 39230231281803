import { useState, useRef, useEffect } from "react";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SearchInput } from "../Filters/SearchInput";
import { AddDisbursementTypes } from "../Modal/AccountSetup/AddDisbursementTypes";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { fetchAllDisbursementType } from "../../store/slice/Accounting/AccountSetup/DisbursementTypeSlice";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { FaEdit } from "react-icons/fa";
import { Pagination } from "../Pagination";
import { MdDelete } from "react-icons/md";
import { SubHeaderTitle } from "../SubHeaderTitle";

function DisbursementTypes() {
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const dispatch = useAppDispatch();
    const [initialLoading, setInitialLoading] = useState(true);
    const { disbursementTypes, status, error } = useSelector((state: RootState) => state.disbursementType);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [disbursementTypeData, setDisbursementTypeData] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    // const filterDisbursementTypeBySearchQuery = () => {
    //     return disbursementTypes.filter((disbursementType: any) =>
    //         disbursementType?.disbursementType.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //         disbursementType?.disbursementCode.toLowerCase().includes(searchQuery.toLowerCase())
    //     );
    // };
    // const filteredByDisbursementType = filterDisbursementTypeBySearchQuery();



    // pagination
    const indexOfLastDisbursementType = currentPage * itemsPerPage;
    const indexOfFirstDisbursementType = indexOfLastDisbursementType - itemsPerPage;
    const currentDisbursementType = disbursementTypes.slice(indexOfFirstDisbursementType, indexOfLastDisbursementType);

    const totalPages = Math.ceil(disbursementTypes.length / itemsPerPage);

    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
            dispatch(fetchAllDisbursementType())
                .then(() => setInitialLoading(false))
                .catch(() => setInitialLoading(false));
            effectServiceCall.current = true;
        }

        if (status === 'failed' && !errorShownRef.current) {
            // toast.error(error);
            errorShownRef.current = true;
        }
    }, [dispatch, status, error]);

    const toggleDrawer = () => {
        setIsEdit(false);
        setOpenModal(!openModal);
    };

    return (
        <>
            <div className="">
                <div className="mb-5">
                    <SubHeaderTitle title="Disbursement Types" buttonName="Disbursement Type" isAdd={true} onClickAdd={toggleDrawer} />
                    {(openModal) &&
                        <AddDisbursementTypes openDrawer={openModal} Close={toggleDrawer} isEdit={isEdit} disbursementType={disbursementTypeData} />
                    }

                </div>
                <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
                    <SearchInput title="Search FeeType" value={searchQuery} onChange={handleSearchChange} />
                    <div>
                        <div className="flex gap-4 md:gap-1 w-full">
                            <CheckBoxFilter title="Is Active" />
                        </div>
                    </div>
                </div>

                <div className="relative overflow-x-auto sm:rounded-lg my-5">
                    <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                        {initialLoading ? (<MainAnimation />) : ""}
                        <thead className="text-sm bg-gray-50 dark:bg-gray-800 dark:text-gray-400">
                            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <th scope="col" className="px-6 py-3">
                                    Code
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Disbursement Type
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Unit Price £
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Tax Code
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Is Active
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Edit
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Delete
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            {currentDisbursementType.map((disbursementType: any, index) => (

                                <tr key={index} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    <td className="px-6 py-3">
                                        {disbursementType.disbursementCode}
                                    </td>
                                    <td className="px-6 py-3">
                                        {disbursementType.disbursementType}
                                    </td>
                                    <td className="px-6 py-3">
                                        {disbursementType.unitPrice}
                                    </td>
                                    <td className="px-6 py-3">
                                        test
                                    </td>
                                    <td className="px-6 py-3">
                                        {disbursementType.isActive == 1 ? "Active" : "InActive"}
                                    </td>
                                    <td className="px-6 py-3 cursor-pointer">
                                        <FaEdit className="fill-blue-500 w-6 h-6"
                                            onClick={() => { setOpenModal(true); setIsEdit(true); setDisbursementTypeData(disbursementType) }} />
                                    </td>
                                    <td className="px-6 py-3 cursor-pointer">
                                        <MdDelete className="fill-red-600 w-6 h-6" />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {currentDisbursementType.length === 0 && (
                        <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
                            <p className="text-red-800">No DisbursementType found.</p>
                        </div>
                    )}
                </div>
                {currentDisbursementType.length > 0 && (
                    <div className="w-full flex flex-col pb-[100px]">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={(page) => setCurrentPage(page)}
                        />
                    </div>
                )}
            </div>
        </>
    )
}

export { DisbursementTypes };