import axios from 'axios';
import axiosInstance from '../axiosInstance';
import { ErrorResult } from '../../store/model/errorResult';



const GetAllClients = async () => {
  try {
    const response = await axiosInstance.get("Client");
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch Client: ${error}`);
  }
}

// Add a new Client
const PostClient = async (data: any) => {
  try {
    const response = await axiosInstance.post("Client", data);
    return response.data;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
}

// Update a Client
const PutClient = async (id: number, data: any) => {

  try {
    const response = await axiosInstance.put(`Client/${id}`, data);
    return response.data;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
}

// Delete a Client
const deleteClient = async (clientId: number) => {
  try {
    await axiosInstance.delete(`/Client/${clientId}`);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('Axios error:', error.response?.data || error.message);
      throw new Error(`Failed to delete Client: ${error.response?.data?.message || error.message}`);
    } else {
      console.error('Unexpected error:', error);
      throw new Error('An unexpected error occurred');
    }
  }
}

const ClientService = {
  GetAllClients,
  PostClient,
  PutClient,
  deleteClient,
};


export default ClientService;
