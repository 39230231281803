import { useEffect, useRef, useState } from "react";
import { AddTimeEntry } from "../Modal/AddClientAccounts/AddClientInvoice/AddTimeEntry";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { FaEye } from "react-icons/fa";
import { fetchTimeEntrySearch } from "../../store/slice/Accounting/ClientAccounts/Invoice/TimeEntrySlice";
import { Pagination } from "../Pagination";
import { MainAnimation } from "../loadingAnimation/MainAnimation";


function TimeEntry() {
    const [openModal, setOpenModal] = useState(false);
    const dispatch = useAppDispatch();
    const [initialLoading, setInitialLoading] = useState(true);
    const timeEntryData = useSelector((state: RootState) => state.clientTimeEntry.timeEntryData);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [getDataList, setGetDataList] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [currentFetchDataList, setCurrentFetchDataList] = useState<any[]>([]);
    const [totalPages, setTotalPages] = useState(0);

    const toggleDrawer = () => {
        setOpenModal(!openModal);
    };

    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
            dispatch(fetchTimeEntrySearch())
                .then(() => setInitialLoading(false))
                .catch(() => setInitialLoading(false));
            effectServiceCall.current = true;
        }
    }, [dispatch]);

    useEffect(() => {
        if (timeEntryData && timeEntryData.timerEntriesResult) {
            setGetDataList(timeEntryData.timerEntriesResult);
        }
    }, [timeEntryData]);

    // const filterGetDataSearchQuery = () => {
    //     return getDataList.filter(
    //         (ledger: any) =>
    //             ledger?.billingDesc.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //             ledger?.feeType.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //             ledger?.firstName.toLowerCase().includes(searchQuery.toLowerCase())
    //     );
    // };
    useEffect(() => {
        if (getDataList != null && getDataList.length > 0) {
            // const filteredByProfessionalFee = filterGetDataSearchQuery();
            // const indexOfLastData = currentPage * itemsPerPage;
            // const indexOfFirstData = indexOfLastData - itemsPerPage;
            // const currentGetData = filteredByProfessionalFee.slice(
            //     indexOfFirstData,
            //     indexOfLastData
            // );
            // const totalPages = Math.ceil(getDataList.length / itemsPerPage);
            // console.log(currentGetData)
            setTotalPages(totalPages)
            setCurrentFetchDataList(getDataList)
        }
    }, [getDataList])

    return (
        <>
            <div className="">
                <div className="mb-5">
                    <SubHeaderTitle title="Time Entry" buttonName="Edit Time Entry" isAdd={true} onClickAdd={toggleDrawer} />
                    <AddTimeEntry openDrawer={openModal} Close={toggleDrawer} modalType="Add" />
                </div>
                <div className="relative overflow-x-auto sm:rounded-lg">
                    {initialLoading ? (<MainAnimation />) : ""}
                    <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                        <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <th scope="col" className="px-6 py-3">
                                    Elapsed time
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Case Reference
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Staff
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Activity
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Units
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Rate
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Value
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    VAT(%)
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Total
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentFetchDataList.map((proFee: any, index) => (
                                <tr key={index} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    <td className="px-6 py-3">
                                        {new Date(proFee.entryDate).toISOString().split('T')[0]}
                                    </td>
                                    <td className="px-6 py-3">
                                        {proFee.caseReferenceAuto}
                                    </td>
                                    <td className="px-6 py-3">
                                        {proFee.timeEntryStaff}
                                    </td>
                                    <td className="px-6 py-3">
                                        {proFee.timeEntryType}
                                    </td>
                                    <td className="px-6 py-3">
                                        {proFee.units}
                                    </td>
                                    <td className="text-right px-6 py-3">
                                        {proFee.unitRate}
                                    </td>
                                    <td className="text-right px-6 py-3">
                                        {proFee.unitRate}
                                    </td>
                                    <td className="text-right px-6 py-3">
                                        {proFee.percentage}.00 %
                                    </td>
                                    <td className="text-right px-6 py-3">
                                        {proFee.total}
                                    </td>
                                    <td className="cursor-pointer px-6 py-3">
                                        <FaEye className="fill-gray-500 w-6 h-6" />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {currentFetchDataList.length === 0 && (
                        <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
                            <p className="text-red-800">No Ledger Cards found.</p>
                        </div>
                    )}
                </div>
                {currentFetchDataList.length > 0 && (
                    <div className="w-full flex flex-col pb-[100px]">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={(page) => setCurrentPage(page)}
                        />
                    </div>
                )}
            </div>
        </>
    )
}

export { TimeEntry };