import { configureStore } from '@reduxjs/toolkit';
import authSlice from './slice/auth-slice';
import taskSlice from './slice/CaseManagement/taskSlice';
import RoleManagementSlice from './slice/Administrator/RoleManagementSlice';
import UserManagementSlice from './slice/Administrator/UserManagementSlice';
import BranchManagementSlice from './slice/Administrator/BranchManagementSlice';
import CompanyProfileSlice from './slice/Administrator/CompanyProfileSlice';
import CaseTypeSlice from './slice/SystemMaintenance/CaseTypeSlice';
import DocumentmanagementSlice from './slice/SystemMaintenance/DocumentmanagementSlice';
import ChecklistSlice from './slice/SystemMaintenance/ChecklistSlice';
import ContactSlice from './slice/CaseManagement/ContactSlice';
import ClientSlice from './slice/CaseManagement/ClientSlice';
import CaseReferenceFormatSlice from './slice/SystemMaintenance/CaseReferenceFormatSlice';
import DocumentCategorySlice from './slice/SystemMaintenance/DocumentCategorySlice';
import CaseSubTypeSlice from './slice/SystemMaintenance/CaseSubTypeSlice';
import MergefieldSlice from './slice/SystemMaintenance/MergefieldSlice';
import AreasOfPracticeSlice from './slice/Administrator/AreasOfPracticeSlice';
import MenuPermissionSlice from './slice/Administrator/MenuPermissionSlice';
import TitleSlice from './slice/Administrator/TitleSlice';
import MaritalStatusSlice from './slice/Administrator/MaritalStatusSlice';
import CaseReferenceConstantSlice from './slice/SystemMaintenance/CaseReferenceConstantSlice';
import DocumentSubCategorySlice from './slice/SystemMaintenance/DocumentSubCategorySlice';
import ContactTypeSlice from './slice/CaseManagement/ContactTypeSlice';
import ContactCategorySlice from './slice/CaseManagement/ContactCategorySlice';
import WorkflowSetupSlice from './slice/SystemMaintenance/WorkflowSetupSlice';
import DocumentFormatSlice from './slice/SystemMaintenance/DocumentFormatSlice';
import DocumentTypeSlice from './slice/SystemMaintenance/DocumentTypeSlice';
import DocumentSourceSlice from './slice/SystemMaintenance/DocumentSourceSlice';
import CountrySlice from './slice/CaseManagement/CountrySlice';
import IdentificationTypeSlice from './slice/CaseManagement/IdentificationTypeSlice';

import CaseManagementSlice from './slice/CaseManagement/Case/CaseManagementSlice';
import AddressSlice from './slice/CaseManagement/AddressSlice';

import FeeTypeSlice from './slice/Accounting/AccountSetup/FeeTypeSlice';
import DisbursementTypeSlice from './slice/Accounting/AccountSetup/DisbursementTypeSlice';
import InvoiceNoticeSlice from './slice/Accounting/AccountSetup/InvoiceNoticeSlice';
import QuotationNoticeSlice from './slice/Accounting/AccountSetup/QuotationNoticeSlice';
import TimeEntryTypeSlice from './slice/Accounting/AccountSetup/TimeEntryTypeSlice';
import TimeEntryUnitPriceSlice from './slice/Accounting/AccountSetup/TimeEntryUnitPriceSlice';
import PartnerShipAccountSlice from './slice/Accounting/AccountSetup/PartnerShipAccountSlice';
import FixedFeeInvoiceFormatSlice from './slice/Accounting/AccountSetup/FixedFeeInvoiceFormatSlice';
import VATExIncomeAccountSlice from './slice/Accounting/AccountSetup/VATExIncomeAccountSlice';
import AccountSlice from './slice/Accounting/AccountSetup/AccountSlice';
import NominalLedgerSlice from './slice/Accounting/AccountSetup/NominalLedgerSlice';
import ClientBankAccountSlice from './slice/Accounting/ClientAccounts/ClientBankAccountSlice';
import BankReceiptSlice from './slice/Accounting/ClientAccounts/ClientPosting/BankReceiptSlice';
import BankNewPaymentSlice from './slice/Accounting/ClientAccounts/ClientPosting/BankPayment/BankNewPaymentSlice';
import AuthorizePaymentSlice from './slice/Accounting/ClientAccounts/ClientPosting/BankPayment/AuthorizePaymentSlice';
import CreatePaymentSlice from './slice/Accounting/ClientAccounts/ClientPosting/BankPayment/CreatePaymentSlice';
import ClientToOfficeSlice from './slice/Accounting/ClientAccounts/ClientPosting/ClientToOfficeSlice';
import OfficeToClientSlice from './slice/Accounting/ClientAccounts/ClientPosting/OfficeToClientSlice';
import BankToBankSlice from './slice/Accounting/ClientAccounts/ClientPosting/BankToBankSlice';
import LedgerToLedgerSlice from './slice/Accounting/ClientAccounts/ClientPosting/LedgerToLedgerSlice';
import ProtectedFundSlice from './slice/Accounting/ClientAccounts/ClientPosting/ProtectedFundSlice';
import JournalEntrySlice from './slice/Accounting/ClientAccounts/ClientPosting/JournalEntrySlice';




import ContactGroupSlice from './slice/CaseManagement/Case/ContactGroup/ContactGroupSlice';
import ContactSubGroupSlice from './slice/CaseManagement/Case/ContactGroup/ContactSubGroupSlice';
import QuotationRequestSlice from './slice/RiskManagement/RiskAssessment/QuotationRequestSlice';
import LedgerCardSlice from './slice/Accounting/ClientAccounts/LedgerCardSlice';
import NewInvoiceSlice from './slice/Accounting/ClientAccounts/Invoice/NewInvoiceSlice';
import PropertyConditionTypeSlice from './slice/RiskManagement/RiskAssessment/PropertyConditionTypeSlice';
import ConflictSearchSlice from './slice/RiskManagement/ConflictSearch/ConflictSearchSlice';
import AuthorisationSlice from './slice/Accounting/ClientAccounts/Invoice/AuthorisationSlice';
import SendDetailsSlice from './slice/Accounting/ClientAccounts/Invoice/SendDetailsSlice';
import FinalizeSlice from './slice/Accounting/ClientAccounts/Invoice/FinalizeSlice';
import AdjustSlice from './slice/Accounting/ClientAccounts/Invoice/AdjustSlice';
import ClientPaymentTypeSlice from './slice/Accounting/ClientAccounts/ClientPaymentTypeSlice';
import AuthourisedClientSlice from './slice/Accounting/ClientAccounts/AuthourisedClientSlice';
import ClientAccountSlice from './slice/Accounting/ClientAccounts/ClientAccountSlice';
import PriorityClientSlice from './slice/Accounting/ClientAccounts/PriorityClientSlice';
import OfficeBankSlice from './slice/Accounting/ClientAccounts/OfficeBankSlice';


import QuotationRequestStatusTypeSlice from './slice/RiskManagement/RiskAssessment/QuotationRequestStatusTypeSlice';
import ReconciliationSlice from './slice/Accounting/ClientAccounts/ReconciliationSlice';
import FileReviewSlice from './slice/CaseManagement/Case/FileReviewSlice';
import CaseCorrespondenceSlice from './slice/CaseManagement/Case/CaseCorrespondenceSlice';
import CorrespondenceSlice from './slice/CaseManagement/Case/Correspondence/CorrespondenceSlice';
import GeneralOfficeSlice from './slice/Accounting/OfficeAccounts/GeneralSettings/GeneralSlice';

import VatPeriodSlice from './slice/Accounting/OfficeAccounts/GeneralSettings/VatPeriodSlice';
import FinancialYearSlice from './slice/Accounting/OfficeAccounts/GeneralSettings/FinancialYearSlice';
import ChartOfAccountsSlice from './slice/Accounting/OfficeAccounts/ChartOfAccountsSlice';
import OfficeNominalLedgerSlice from './slice/Accounting/OfficeAccounts/GeneralSettings/OfficeNominalLedgerSlice';
import OfficeBankAccountSlice from './slice/Accounting/OfficeAccounts/OfficeBankAccountSlice';
import OfficeJournalEntrySlice from './slice/Accounting/OfficeAccounts/OfficePostings/OfficeJournalEntrySlice';
import OfficeFundTransferSlice from './slice/Accounting/OfficeAccounts/OfficePostings/OfficeFundTransferSlice';
import BankDepositSlice from './slice/Accounting/OfficeAccounts/OfficePostings/BankDepositSlice';
import ClientPaymentSlice from './slice/Accounting/OfficeAccounts/OfficePostings/ClientPaymentSlice';
import FixedFeeInvoicePaymentSlice from './slice/Accounting/OfficeAccounts/OfficePostings/FixedFeeInvoicePaymentSlice';
import RefundCreditNoteSlice from './slice/Accounting/OfficeAccounts/OfficePostings/RefundCreditNoteSlice';
import BillPaymentSlice from './slice/Accounting/OfficeAccounts/Suppliers/BillPaymentSlice';
import BudgetSlice from './slice/Accounting/OfficeAccounts/BudgetSlice';
import OfficeReconciliationSlice from './slice/Accounting/OfficeAccounts/OfficeReconciliationSlice';
import FixedFeeInvoiceSlice from "./slice/Accounting/OfficeAccounts/OfficePostings/FixedFeeInvoiceSlice";
import CreditNoteSlice from "./slice/Accounting/OfficeAccounts/OfficePostings/CreditNoteSlice";
import TrialBalanceSlice from './slice/Accounting/OfficeAccounts/OfficeReports/TrialBalanceSlice';
import FinancialYearEndSlice from './slice/Accounting/OfficeAccounts/OfficeReports/FinancialYearEndSlice';
import AuditTrailSlice from './slice/Accounting/OfficeAccounts/OfficeReports/AuditTrailSlice';
import AgedDebtorsReportSlice from './slice/Accounting/OfficeAccounts/OfficeReports/AgedDebtorsReportSlice';
import AgedCreditorsReportSlice from './slice/Accounting/OfficeAccounts/OfficeReports/AgedCreditorsReportSlice';
import YearEndSlice from './slice/Accounting/OfficeAccounts/YearEndSlice';
import ReportBankReconcilliationSlice from './slice/Accounting/OfficeAccounts/OfficeReports/ReportBankReconcilliationSlice';
import OfficeNominalLedgerReportSlice from './slice/Accounting/OfficeAccounts/OfficeReports/OfficeNominalLedgerReportSlice';
import ProfitAndLossStatementSlice from './slice/Accounting/OfficeAccounts/OfficeReports/ProfitAndLossStatementSlice';
import BalanceSheetSlice from './slice/Accounting/OfficeAccounts/OfficeReports/BalanceSheetSlice';
import IncomeReportSlice from './slice/Accounting/OfficeAccounts/OfficeReports/IncomeReportSlice';
import BudgetVarianceReportSlice from './slice/Accounting/OfficeAccounts/OfficeReports/BudgetVarianceReportSlice';
import OfficeSupplierSlice from './slice/Accounting/OfficeAccounts/Suppliers/OfficeSupplierSlice';
import SuppliersControlAccountSlice from './slice/Accounting/OfficeAccounts/Suppliers/SuppliersControlAccountSlice';
import SuppliersBillEntrySlice from './slice/Accounting/OfficeAccounts/Suppliers/SuppliersBillEntrySlice';
import OfficeSupplierCreditNoteSlice from './slice/Accounting/OfficeAccounts/Suppliers/OfficeSupplierCreditNoteSlice';
import ClientQuickInvoicesSlice from './slice/Accounting/ClientAccounts/ClientQuickInvoicesSlice';
import AccountSearchNominalAccountsSlice from './slice/Accounting/OfficeAccounts/AccountSearchNominalAccountsSlice';
import OfficeVatRateSlice from './slice/Accounting/OfficeAccounts/OfficeVatRateSlice';
import ProfessionalFeeSlice from './slice/Accounting/ClientAccounts/Invoice/ProfessionalFeeSlice';
import ClientReportsSlice from './slice/Accounting/ClientAccounts/ClientPosting/ClientReports/ClientReportsSlice';
import DisbursementSlice from './slice/Accounting/ClientAccounts/Invoice/DisbursementSlice';
import TimeEntrySlice from './slice/Accounting/ClientAccounts/Invoice/TimeEntrySlice';
import OfficeAccountReferenceNumberSlice from './slice/Accounting/OfficeAccounts/OfficeAccountReferenceNumberSlice';
import OfficeVATReturnReportSlice from './slice/Accounting/OfficeAccounts/OfficeVATReturnReportSlice';
import IncomingPostSlice from './slice/OfficeManagement/PostRegister/IncomingPostSlice';
import OutgoingPostSlice from './slice/OfficeManagement/PostRegister/OutgoingPostSlice';
import TrainingRecordSlice from './slice/HumanResources/HumanResources/TrainingRecordSlice';
import StaffAttendanceSlice from './slice/HumanResources/HumanResources/StaffAttendanceSlice';
import LeaveRequestSlice from './slice/HumanResources/HumanResources/LeaveRequestSlice';
import LeaveManagementSlice from './slice/HumanResources/HumanResources/LeaveManagementSlice';

import  CaseTypeIncomeLedgerDataSlice  from "./slice/Accounting/AccountSetup/CaseTypeInComeLedgerSlice";
import CreateSurveySlice from './slice/HumanResources/Surveys/CreateSurvey/CreateSurveySlice';
import CreateSurveyQuestionSlice from './slice/HumanResources/Surveys/CreateSurvey/CreateSurveyQuestionSlice';
import PreviewAndPublishSurveySlice from './slice/HumanResources/Surveys/CreateSurvey/PreviewAndPublishSurveySlice';
import SurveyQuestionOrderSlice from './slice/HumanResources/Surveys/CreateSurvey/SurveyQuestionOrderSlice';
import AnalyseSurveyResultSlice from './slice/HumanResources/Surveys/SurveyResult/AnalyseSurveyResultSlice';
import ViewSurveyResultSlice from './slice/HumanResources/Surveys/SurveyResult/ViewSurveyResultSlice';
import DashboardSlice from './slice/Dashboard/DashboardSlice';
import CACreditNoteSlice from './slice/Accounting/ClientAccounts/Invoice/CACreditNoteSlice';
import CARefundCreditNoteSlice from './slice/Accounting/ClientAccounts/Invoice/CARefundCreditNoteSlice';
import CentralDiarySlice from './slice/OfficeManagement/CentralDiary/CentralDiarySlice';
import ImportantDateAndTaskSlice from './slice/SystemMaintenance/TaskAndAssesment/ImportantDateAndTaskSlice';
import TaskRiskAssesmentSlice from './slice/SystemMaintenance/TaskAndAssesment/TaskRiskAssesmentSlice';
import LawSocietySlice from './slice/OfficeManagement/OfficeInformation/LawSocietySlice';
import IndemnityInsuranceSlice from './slice/OfficeManagement/OfficeInformation/IndemnityInsuranceSlice';
import TaskStatusSlice from './slice/CaseManagement/TaskStatusSlice';
import LEXCELOfficeManualSlice from './slice/OfficeManagement/OfficeInformation/LEXCELOfficeManualSlice';
import CQSSlice from './slice/OfficeManagement/OfficeInformation/CQSSlice';
import COLPAndCOFASlice from './slice/OfficeManagement/OfficeInformation/COLPAndCOFASlice';
import GuideToGDPRSlice from './slice/OfficeManagement/OfficeInformation/GuideToGDPRSlice';
import LeaveManagementTypesSlice from './slice/HumanResources/HumanResources/LeaveManagementTypesSlice';
import StaffAttendenceTypeSlice from './slice/HumanResources/HumanResources/StaffAttendenceTypeSlice';
import PostageMethodSlice from './slice/HumanResources/HumanResources/PostageMethodSlice';
import RiskAssessmentSearchSlice from './slice/RiskManagement/RiskAssessment/RiskAssessmentSearchSlice';



const store = configureStore({
    reducer: {
        user: authSlice,
        task: taskSlice,
        role: RoleManagementSlice,
        branch: BranchManagementSlice,
        userManage: UserManagementSlice,
        companyProfile: CompanyProfileSlice,
        caseType: CaseTypeSlice,
        document: DocumentmanagementSlice,
        checklist: ChecklistSlice,
        contact: ContactSlice,
        client: ClientSlice,
        caseReferenceFormat: CaseReferenceFormatSlice,
        documentCategory: DocumentCategorySlice,
        caseSubType: CaseSubTypeSlice,
        mergefield: MergefieldSlice,
        areasOfPractice: AreasOfPracticeSlice,
        menu: MenuPermissionSlice,
        title: TitleSlice,
        maritalStatus: MaritalStatusSlice,
        caseReferenceConstant: CaseReferenceConstantSlice,
        documentSubCategory: DocumentSubCategorySlice,
        contactType: ContactTypeSlice,
        contactCategory: ContactCategorySlice,
        workflow: WorkflowSetupSlice,
        documentFormat: DocumentFormatSlice,
        documentType: DocumentTypeSlice,
        documentSource: DocumentSourceSlice,
        country: CountrySlice,
        identificationType: IdentificationTypeSlice,
        account: AccountSlice,
        case: CaseManagementSlice,
        fileReview: FileReviewSlice,
        caseCorrespondence: CaseCorrespondenceSlice,
        correspondence: CorrespondenceSlice,
        location: AddressSlice,
        nominalLedger: NominalLedgerSlice,
        feeType: FeeTypeSlice,
        disbursementType: DisbursementTypeSlice,
        invoiceNotice: InvoiceNoticeSlice,
        quotationNotice: QuotationNoticeSlice,
        vATExIncomeAccount: VATExIncomeAccountSlice,
        timeEntryType: TimeEntryTypeSlice,
        timeEntryUnitPrice: TimeEntryUnitPriceSlice,
        partnerShipAccount: PartnerShipAccountSlice,
        fixedFeeInvoiceFormat: FixedFeeInvoiceFormatSlice,
        //Task Assesment
        importantDatesAndTask: ImportantDateAndTaskSlice,
        taskRiskAssesment: TaskRiskAssesmentSlice,
        // Client Accounting
        clientBankAccount: ClientBankAccountSlice,
        bankReceipt: BankReceiptSlice,
       clientPaymentType: ClientPaymentTypeSlice,
       authorisedClient: AuthourisedClientSlice,
       clientAccount: ClientAccountSlice,
       priorityClient: PriorityClientSlice,
       officeBank: OfficeBankSlice,
       clientQuickInvoices: ClientQuickInvoicesSlice,
      
        bankNewPayment: BankNewPaymentSlice,
        authorizePayment: AuthorizePaymentSlice,
        createPayment: CreatePaymentSlice,
        clientToOffice: ClientToOfficeSlice,
        officeToClient: OfficeToClientSlice,
        bankToBank: BankToBankSlice,
        ledgerToLedger: LedgerToLedgerSlice,
        protectedFund: ProtectedFundSlice,
        journalEntry: JournalEntrySlice,
        ledgerCard: LedgerCardSlice,
        professionalFee: ProfessionalFeeSlice,
        taskStatus: TaskStatusSlice,

        //officeAccount
        //officeAccount: OfficeAccountSlice,
        generalOffice: GeneralOfficeSlice, 
        vatPeriod: VatPeriodSlice,
        financialYear: FinancialYearSlice,
        chartOfAccount: ChartOfAccountsSlice,
        officeNominalLedger: OfficeNominalLedgerSlice,
        officeBankAccount: OfficeBankAccountSlice,
        officeJournalEntry: OfficeJournalEntrySlice,
        officeFundTransfer: OfficeFundTransferSlice,
        officeBankDeposit: BankDepositSlice,
        clientPayment: ClientPaymentSlice,
        fixedFeeInvoicePayment: FixedFeeInvoicePaymentSlice,
        refundCreditNote: RefundCreditNoteSlice,
        billPayment: BillPaymentSlice,
        budget: BudgetSlice,
        officeReconciliation: OfficeReconciliationSlice,
        fixedFeeInvoice: FixedFeeInvoiceSlice,
        creditNote: CreditNoteSlice,
        trialBalance: TrialBalanceSlice,
        financialYearEnd: FinancialYearEndSlice,
        auditTrail: AuditTrailSlice,
        agedDebtorsReport: AgedDebtorsReportSlice,
        agedCreditorsReport: AgedCreditorsReportSlice,
        yearEnd: YearEndSlice,
        reportBankReconcilliation:ReportBankReconcilliationSlice,
        officeNominalLedgerReport: OfficeNominalLedgerReportSlice,
        profitAndLossStatement: ProfitAndLossStatementSlice,
        balanceSheet: BalanceSheetSlice,
        incomeReport: IncomeReportSlice,
        budgetVarianceReport: BudgetVarianceReportSlice,
        officeVATReturnReport: OfficeVATReturnReportSlice,
        officeSupplier: OfficeSupplierSlice,
        suppliersControlAccount: SuppliersControlAccountSlice,
        suppliersBillEntry: SuppliersBillEntrySlice,
        officeSupplierCreditNote: OfficeSupplierCreditNoteSlice,
        accountSearchNominalAccount: AccountSearchNominalAccountsSlice,
        officeVatRate: OfficeVatRateSlice,
        officeAccountReferenceNumber: OfficeAccountReferenceNumberSlice,
        // VATReturn : VATReturnSli
        // // clientSection Invoice

        newInvoice: NewInvoiceSlice,
        authorisation: AuthorisationSlice,
        sendDetail: SendDetailsSlice,
        finalize: FinalizeSlice,
        adjust: AdjustSlice,
        reconciliation: ReconciliationSlice,

        // office manageMent
        // postRegister
        incomingPost: IncomingPostSlice,
        outgoingPost: OutgoingPostSlice,
        centralDiary: CentralDiarySlice,
        //office Information
        lawSociety: LawSocietySlice,
        indemnityInsurance: IndemnityInsuranceSlice,
        lEXCELOfficeManual: LEXCELOfficeManualSlice,
        cQS: CQSSlice,
        cOLPAndCOFA: COLPAndCOFASlice,
        guideToGDPR: GuideToGDPRSlice,


        // HumanResources
        trainingRecord: TrainingRecordSlice,
        staffAttendance: StaffAttendanceSlice,
        leaveRequest:LeaveRequestSlice,
        leaveManagement: LeaveManagementSlice,
        staffAttendenceType: StaffAttendenceTypeSlice,
        leaveManagementType: LeaveManagementTypesSlice,
        postageMethod: PostageMethodSlice,

        // Survey
        // create Survey
        createSurvey: CreateSurveySlice,
        createSurveyQuestion: CreateSurveyQuestionSlice,
        previewAndPublishSurvey: PreviewAndPublishSurveySlice,
        surveyQuestionOrder: SurveyQuestionOrderSlice,

        // Survey Result
        analyseSurveyResult: AnalyseSurveyResultSlice,
        viewSurveyResult: ViewSurveyResultSlice,

        
        contactGoup: ContactGroupSlice,
        contactSubGroup: ContactSubGroupSlice,
        quotationRequest: QuotationRequestSlice,
        propertyConditionType: PropertyConditionTypeSlice,
        conflictSearch: ConflictSearchSlice,
        
        quotationRequestStatusType: QuotationRequestStatusTypeSlice,
        clientReports: ClientReportsSlice,
        disbursement: DisbursementSlice,
        clientTimeEntry: TimeEntrySlice,
        caseTypeLedger:CaseTypeIncomeLedgerDataSlice,
        dashboard: DashboardSlice,
        clientCreditNote:CACreditNoteSlice,
        carefundCreditNote: CARefundCreditNoteSlice,

        RiskAssessmentData: RiskAssessmentSearchSlice,


    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;

