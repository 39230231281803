import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SearchInput } from "../Filters/SearchInput";
import { IoIosPrint } from "react-icons/io";
import { BsFileEarmarkExcelFill } from "react-icons/bs";
import { SubHeaderTitle } from "../SubHeaderTitle";
function BudgetVarianceReport() {

    return (
        <>
            <div className="">
                <div className="mb-5">
                    <SubHeaderTitle title="Budget Variance Report" />
                </div>
                <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
                    <SearchInput title="Keyword" />
                    <div>
                        <div className="flex gap-4 md:gap-1 w-full">
                            <CheckBoxFilter title="Financial Year" />
                            <div className="w-px h-5 bg-gray-300"></div>
                            <CheckBoxFilter title="Budget Name" />
                        </div>
                    </div>
                </div>
                <div className="flex gap-x-2 ietms-center justify-end">
            <button
              type="submit"
              className="flex items-center gap-x-2 bg-blue-500 hover:bg-blue-800 text-white-bg cursor-pointer px-5 py-2 rounded text-center text-sm"
            >
              <IoIosPrint className="w-5 h-5" />
              Print
            </button>
            <button
              type="submit"
              className="flex items-center gap-x-2 bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer px-3 py-2 rounded text-center border text-sm"
            >
              <BsFileEarmarkExcelFill className="w-5 h-5" />
              Export to Excel
            </button>
          </div>
                    <div className="my-5 text-center">
                        <h1 className="text-2xl font-bold">Shan & Co Solicitors</h1>
                        <p className="text-lg font-semibold">Budget Variance Report</p>
                        <p className="font-semibold">01 January 2020 - 31 December 2020</p>
                    </div>
            </div>
        </>
    )
}

export { BudgetVarianceReport };