import { ComponentType, FunctionComponent, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import {
  AddDatesAndTasks,
  BreadCrumb,
  HeaderTitle,
  MainAnimation,
  Pagination,
} from "../../../../components";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";

import { useAppDispatch } from "../../../../store/hooks/redux-hooks";
import { fetchAllImportantDatesAndTask } from "../../../../store/slice/SystemMaintenance/TaskAndAssesment/ImportantDateAndTaskSlice";

const ImportantDatesAndTasks = () => {
  const location = useLocation();
  const [openModal, setOpenModal] = useState(false);
  const [importantDatesAndTaskData, setImportantDatesAndTaskData] = useState<any[]>([]);
  const [isEdit, setIsEdit] = useState(false);
  const { importantDatesAndTasks } = useSelector((state: RootState) => state.importantDatesAndTask);
  const [totalPages, setTotalPages] = useState(0);
  const [currentImportantDatesAndTask, setCurrentImportantDatesAndTask] = useState<any[]>([]);
  const itemsPerPage = 10;
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const [importentDTData, setImportentDTData] = useState<any>();


  const effectServiceCall = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current) {
      dispatch(fetchAllImportantDatesAndTask())
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
    }
  }, [dispatch]);

  useEffect(() => {
    if (importantDatesAndTasks !== null && importantDatesAndTasks.length > 0) {
      // console.log(importantDatesAndTasks);
      setImportantDatesAndTaskData(importantDatesAndTasks);
    }
  }, [importantDatesAndTasks]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  // useEffect(() => {
  //   if (importantDatesAndTaskData != null && importantDatesAndTaskData.length > 0) {
  //     const filteredByImportantDatesAndTask = filterImportantDatesAndTaskBySearchQuery();
  //     const indexOfLastImportantDatesAndTask = currentPage * itemsPerPage;
  //     const indexOfFirstImportantDatesAndTask = indexOfLastImportantDatesAndTask - itemsPerPage;
  //     const currentImportantDatesAndTasks = filteredByImportantDatesAndTask.slice(indexOfFirstImportantDatesAndTask, indexOfLastImportantDatesAndTask);
  //     const totalPages = Math.ceil(importantDatesAndTaskData.length / itemsPerPage);
  //     setTotalPages(totalPages)
  //     setImportantDatesAndTaskData(currentImportantDatesAndTasks)
  //   }

  // }, [importantDatesAndTaskData])


  // const filterImportantDatesAndTaskBySearchQuery = () => {

  //   return importantDatesAndTaskData.filter((importantDatesAndTask: any) =>
  //     importantDatesAndTask?.importantDateType?.toLowerCase().includes(searchQuery.toLowerCase()));
  // };

  const toggleDrawer = () => {
    setIsEdit(false);
    setOpenModal(!openModal);
  };


  return (
    <div className="flex flex-col w-full px-2 md:py-1 py-0">
      <div className="">
        <BreadCrumb path={location} />
        <HeaderTitle
          title="Important Dates and Tasks"
          buttonName="Dates and Tasks"
          isAdd={true}
          onClickAdd={toggleDrawer}
        />
      </div>
      <div className="flex flex-col w-full gap-3 my-5">
        <div className="relative overflow-x-auto sm:rounded-lg">
          {initialLoading ? <MainAnimation /> : ""}
          <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
            <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <th scope="col" className="px-6 py-3">
                  Important Dates and Tasks
                </th>
                <th scope="col" className="px-6 py-3">
                  Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Edit
                </th>
                <th scope="col" className="px-6 py-3">
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              {importantDatesAndTaskData.map((importantDatesAndTask: any, index) => (
                <tr key={index} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  <td className="px-6 py-3">
                    {importantDatesAndTask.importantDateType}
                  </td>
                  <td className="px-6 py-3">
                    {(importantDatesAndTask.isActive === 1 ? "Active" : "In Active")}
                  </td>
                  <td className="cursor-pointer px-6 py-3">
                    <FaEdit className="fill-blue-500 w-6 h-6" onClick={() => { setOpenModal(true); setIsEdit(true); setImportentDTData(importantDatesAndTask); }} />
                  </td>
                  <td className="cursor-pointer px-6 py-3">
                    <RiDeleteBin5Fill className="fill-red-600 w-6 h-6" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {importantDatesAndTaskData.length === 0 && (
            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
              <p className="text-red-800">No important Dates And Task found.</p>
            </div>
          )}
        </div>
        {importantDatesAndTaskData.length > 0 && (
          <div className="w-full flex flex-col pb-[100px]">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        )}
      </div>
      {(openModal) &&
        <AddDatesAndTasks openDrawer={openModal} Close={toggleDrawer} isEdit={isEdit} importantDatesAndTask={importentDTData} />
      }
    </div>

  );
};

export default ImportantDatesAndTasks as FunctionComponent;
