import { useEffect, useRef, useState } from "react";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SearchInput } from "../Filters/SearchInput";
import { AddCreditNote } from "../Modal/AddClientAccounts/AddClientInvoice/AddCreditNote";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { FaEye } from "react-icons/fa";
import { fetchCreditNoteSearch } from "../../store/slice/Accounting/ClientAccounts/Invoice/CACreditNoteSlice";
import { Pagination } from "../Pagination";
import { MainAnimation } from "../loadingAnimation/MainAnimation";

function CreditNote() {
    const [openModal, setOpenModal] = useState(false);
    const dispatch = useAppDispatch();
    const [initialLoading, setInitialLoading] = useState(true);
    const cacreditNoteData = useSelector((state: RootState) => state.clientCreditNote.cacreditNoteData);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [getDataList, setGetDataList] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [currentFetchDataList, setCurrentFetchDataList] = useState<any[]>([]);
    const [totalPages, setTotalPages] = useState(0);

    const toggleDrawer = () => {
        setOpenModal(!openModal);
    };

    const effectServiceCall = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
            dispatch(fetchCreditNoteSearch())
                .then(() => setInitialLoading(false))
                .catch(() => setInitialLoading(false));
            effectServiceCall.current = true;
        }
    }, [dispatch]);

    useEffect(() => {
        if (cacreditNoteData && cacreditNoteData.creditNoteResult) {
            setGetDataList(cacreditNoteData.creditNoteResult);
        }
    }, [cacreditNoteData]);

    // const filterGetDataSearchQuery = () => {
    //     return getDataList.filter(
    //         (ledger: any) =>
    //             ledger?.billingDesc.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //             ledger?.disbursementType.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //             ledger?.lastName.toLowerCase().includes(searchQuery.toLowerCase())
    //     );
    // };
    useEffect(() => {
        if (getDataList != null && getDataList.length > 0) {
            const filteredByDisbursement = getDataList;
            const indexOfLastData = currentPage * itemsPerPage;
            const indexOfFirstData = indexOfLastData - itemsPerPage;
            const currentGetData = filteredByDisbursement.slice(
                indexOfFirstData,
                indexOfLastData
            );
            const totalPages = Math.ceil(getDataList.length / itemsPerPage);
            setTotalPages(totalPages)
            setCurrentFetchDataList(currentGetData)
        }
    }, [getDataList])

    if (openModal) {
        return (
            <AddCreditNote openDrawer={openModal} Close={toggleDrawer} modalType="Add" />
        )
    }

    return (
        <>
            <div className="">
                <div className="mb-5">
                {initialLoading ? <MainAnimation /> : ""}
                    <SubHeaderTitle title="Credit Note" buttonName="Credit Note" isAdd={true} onClickAdd={toggleDrawer} />
                </div>
                <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
                    <SearchInput title="Keyword" />
                    <div>
                        <div className="flex gap-4 md:gap-1 w-full">
                            <CheckBoxFilter title="Status" />
                        </div>
                    </div>
                </div>
                <div className="relative overflow-x-auto sm:rounded-lg">
                    <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                        <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <th scope="col" className="px-6 py-3">
                                    Date
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Case Reference
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Credit Amount
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Credit Note
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Invoice
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Status
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentFetchDataList.map((disb: any, index) => (
                                <tr key={index} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    <td className="px-6 py-3">
                                        {new Date(disb.creditNoteDate).toISOString().split('T')[0]}
                                    </td>
                                    <td className="px-6 py-3">
                                        {disb.caseReferenceAuto}
                                    </td>
                                    <td className="px-6 py-3">
                                    £ {disb.creditAmount}
                                    </td>
                                    <td className="px-6 py-3">
                                        CN_{disb.creditNoteId}
                                    </td>
                                    <td className="px-6 py-3">
                                        <a href="">{disb.creditNoteName}</a>
                                    </td>
                                    <td className="text-right px-6 py-3">
                                         {disb.total}
                                    </td>
                                    <td className="cursor-pointer px-6 py-3">
                                        <FaEye className="fill-gray-500 w-6 h-6" />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {currentFetchDataList.length === 0 && (
                        <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
                            <p className="text-red-800">No Ledger Cards found.</p>
                        </div>
                    )}
                </div>
                {currentFetchDataList.length > 0 && (
                    <div className="w-full flex flex-col pb-[100px]">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={(page) => setCurrentPage(page)}
                        />
                    </div>
                )}
            </div>
        </>
    )
}

export { CreditNote };