import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserTie } from "@fortawesome/free-solid-svg-icons";
import { faBook, faGear, faContactBook, faC, faPeopleArrows, faListCheck, faSuitcase, faAsterisk, faBuildingUser, faRegistered, faSquarePollVertical, faPeopleGroup, faBuildingColumns, faFileLines, faFileInvoiceDollar, faFolder, faTasks } from "@fortawesome/free-solid-svg-icons";



export interface SubSecondMenuItem {
    title?: string;
    icon?: JSX.Element;
    path: string;
    
}

interface SubMenuItem {
    title: string;
    icon?: JSX.Element;
    path?: string;
    subSecondMenuItem?: SubSecondMenuItem[];
}

export interface MenuItem {
    title: any;
    icon?: JSX.Element;
    path?: any;
    subItems?: SubMenuItem[];
}


export const SidebarData: MenuItem[] = [
    {
        title: 'Office Management',
        icon: <FontAwesomeIcon className="w-7 h-7 text-white-bg" icon={faUserTie} />,
        path: '/officemanagement',
        subItems: [
            {
                title: 'Central Diary',
                icon: <FontAwesomeIcon className="w-5 h-5" icon={faBook} />,
                path: '/officemanagement/centraldiary',
                subSecondMenuItem: []
            },
            {
                title: 'Office Information',
                icon: <FontAwesomeIcon className="w-5 h-5" icon={faBuildingUser} />,
                subSecondMenuItem: [
                    {
                        title: 'Law Society',
                        path: '/officemanagement/OfficeInformation/lawsociety'
                    },
                    {
                        title: 'Indemnity Insurance',
                        path: '/officemanagement/OfficeInformation/indemnityinsurance'
                    },
                    {
                        title: 'LEXCEL OfficeManual',
                        path: '/officemanagement/OfficeInformation/LEXCELOfficeManual'
                    },
                    {
                        title: 'CQS',
                        path: '/officemanagement/OfficeInformation/CQS'
                    },
                    {
                        title: 'COLP & COFA',
                        path: '/officemanagement/OfficeInformation/colpcofa'
                    },
                    {
                        title: 'Guide GDPR',
                        path: '/officemanagement/OfficeInformation/guidegdpr'
                    },
                    {
                        title: 'Expert Register',
                        path: '/officemanagement/OfficeInformation/expertregister'
                    },
                ]
            },
            {
                title: 'Post Register',
                icon: <FontAwesomeIcon className="w-5 h-5" icon={faRegistered} />,
                path: '',
                subSecondMenuItem: [
                    {
                        title: 'Incoming Post',
                        path: '/officemanagement/postregister/incomingpost'
                    },
                    {
                        title: 'Outgoing Post',
                        path: '/officemanagement/postregister/outgoingpost'
                    },
                ]
            },
            {
                title: 'Time Sheets',
                icon: <FontAwesomeIcon className="w-5 h-5" icon={faUserTie} />,
                path: '/officemanagement/timesheets',
                subSecondMenuItem: []
            },
        ]
    },
    {
        title: 'Case Management',
        icon: <FontAwesomeIcon className="w-7 h-7 text-white-bg" icon={faSuitcase} />,
        path: '/Case Management',
        subItems: [
            {
                title: 'Contacts',
                icon: <FontAwesomeIcon className="w-5 h-5 " icon={faContactBook} />,
                path: '/caseManagement/contacts',
                subSecondMenuItem: [
                    {
                        path: "/caseManagement/contacts/addcontact"
                    },
                ]
            },
            {
                title: 'Clients',
                icon: <FontAwesomeIcon className="w-5 h-5 " icon={faPeopleGroup} />,
                path: '/caseManagement/clients',
                subSecondMenuItem: [
                    {
                        path: "/caseManagement/clients/addclient"
                    },
                ]
            },
            {
                title: 'Cases',
                icon: <FontAwesomeIcon className="w-5 h-5 " icon={faC} />,
                subSecondMenuItem: [
                    {
                        title: 'Case Management',
                        path: '/caseManagement/case/caseManagement'
                    },
                    {
                        path: '/caseManagement/case/casemanagement/editcase/:referenceNumber/casedetails',
                    },
                    {
                        path: '/caseManagement/case/casemanagement/addcase'
                    },
                    {
                        title: 'Archived Management',
                        path: '/caseManagement/case/archivedmanagement'
                    },
                    {
                        title: 'Warehoused',
                        path: '/caseManagement/case/warehousedmanagement'
                    },
                ]
            },
            {
                title: 'Task',
                icon: <FontAwesomeIcon className="w-5 h-5" icon={faListCheck} />,
                path: '/caseManagement/case/tasks',
                subSecondMenuItem: []
            },
        ]
    },
    {
        title: 'Finance and Accounting',
        icon: <FontAwesomeIcon className="w-7 h-7 text-white-bg" icon={faBuildingColumns} />,
        path: '/FinanceandAccounting',
        subItems: [
            {
                title: 'Accountings',
                icon: <FontAwesomeIcon className="w-5 h-5" icon={faBuildingColumns} />,
                subSecondMenuItem: [
                    {
                        title: 'Accounts Setup',
                        path: '/financeandaccounting/accountings/accountssetup'
                    },
                    {
                        title: 'Client Accounts',
                        path: '/financeandaccounting/accountings/clientaccounts/clientbankaccount'
                    },
                    {
                        title: 'Office Accounts',
                        path: '/financeandaccounting/accountings/officeaccounts'
                    },
                ]
            },
            {
                title: 'Reports',
                icon: <FontAwesomeIcon className="w-5 h-5" icon={faFileLines} />,
                subSecondMenuItem: [
                    {
                        title: 'General Reports',
                        path: '/financeandaccounting/Reports/generalreports'
                    },
                    {
                        title: 'ClientAccount Reports',
                        path: '/financeandaccounting/Reports/clientaccountreports'
                    },
                ]
            },
        ]
    },
    {
        title: 'Risk Management',
        icon: <FontAwesomeIcon className="w-7 h-7 text-white-bg" icon={faAsterisk} />,
        path: '/RiskandConflictManagement',
        subItems: [
            {
                title: 'Risk Assessment',
                icon: <FontAwesomeIcon className="w-5 h-5" icon={faAsterisk} />,
                path: 'riskmanagement/riskassessment/quotationRequest',
                subSecondMenuItem: [
                    {
                        path: '/riskmanagement/riskassessment/assessment',
                    },
                    {
                        path: '/riskmanagement/riskassessment/quotationRequest',
                    },
                    {
                        path: '/riskmanagement/riskassessment/quotationgeneration',
                    },
                    {
                        path: '/riskmanagement/riskassessment/generatedquotation',
                    },
                ]
            },
            {
                title: 'Conflict Search',
                icon: <FontAwesomeIcon className="w-5 h-5" icon={faPeopleArrows} />,
                path: '/riskmanagement/conflictsearch',
            },
            {
                title: 'Quotation Management',
                icon: <FontAwesomeIcon className="w-5 h-5" icon={faFileInvoiceDollar} />,
                path: '/riskmanagement/quotationmanagement',
            },
        ]
    },
    {
        title: 'Human Resource',
        icon: <FontAwesomeIcon className="w-7 h-7 text-white-bg" icon={faPeopleGroup} />,
        path: '/Human Resource',
        subItems: [
            {
                title: 'Human Resources',
                icon: <FontAwesomeIcon className="w-5 h-5 " icon={faPeopleGroup} />,
                path: '',
                subSecondMenuItem: [
                    {
                        title: 'Training Records',
                        path: '/humanresource/humanresources/trainingrecords'
                    },
                    {
                        title: 'Staff Attendance',
                        path: '/humanresource/humanresources/staffattendance'
                    },
                    {
                        title: 'Leave Requests',
                        path: '/humanresource/humanresources/leaverequests'
                    },
                    {
                        title: 'Leave Management',
                        path: '/humanresource/humanresources/leavemanagement'
                    },
                ]
            },
            {
                title: 'Surveys',
                icon: <FontAwesomeIcon className="w-5 h-5 " icon={faSquarePollVertical} />,
                subSecondMenuItem: [
                    {
                        title: 'Create Survey',
                        path: '/humanresource/surveys/createsurvey/survey'
                    },
                    {
                        title: 'Survey Results',
                        path: '/humanresource/Surveys/surveyresults'
                    },
                ]
            },
        ]
    },
    {
        title: 'System Management',
        icon: <FontAwesomeIcon className="w-6 h-6 text-white-bg" icon={faGear} />,
        path: '/SystemManagementandMaintenance',
        subItems: [
            // {
            //     title: 'System Maintenance',
            //     icon: <FontAwesomeIcon className="w-5 h-5" icon={faGear} />,
            //     path: '',
            //     subSecondMenuItem: [
            //         {
            //             title: 'Case Type',
            //             path: '/systemmanagement/systemmaintenance/casetype'
            //         },
            //         {
            //             title: 'Case Ref Format',
            //             path: '/systemmanagement/systemmaintenance/casereferenceformat',
            //         },
            //         {
            //             path: '/systemmanagement/systemmaintenance/addcasereferenceformat'
            //         },
            //         {
            //             title: 'Case Ref Client Format',
            //             path: '/systemmanagement/systemmaintenance/casereferenceclientFormat'
            //         },
            //         {
            //             title: 'Doc Categorys',
            //             path: '/systemmanagement/systemmaintenance/documentcategorys'
            //         },
            //         {
            //             title: 'Doc Management',
            //             path: '/systemmanagement/systemmaintenance/documentmanagement',
            //         },
            //         {
            //             title: 'Checklist',
            //             path: '/systemmanagement/systemmaintenance/checklist'
            //         },
            //         {
            //             title: 'Workflow Setup',
            //             path: '/systemmanagement/systemmaintenance/workflowsetup'
            //         },
            //         {
            //             path: '/systemmanagement/systemmaintenance/addWorkflow',
            //         },
            //         {
            //             path: `/systemmanagement/systemmaintenance/updateWorkflow/`,
            //         },
            //         {
            //             title: 'Imp Dates & Tasks',
            //             path: '/systemmanagement/systemmaintenance/ImportantDatesAndTasks'
            //         },
            //         {
            //             title: 'Case Closure',
            //             path: '/systemmanagement/systemmaintenance/caseclosureareas'
            //         },
            //         {
            //             title: 'Custom Field',
            //             path: '/systemmanagement/systemmaintenance/customfieldForcaseType'
            //         },
            //         {
            //             title: 'Merge Fields',
            //             path: '/systemmanagement/systemmaintenance/mergefields'
            //         },
            //         {
            //             title: 'Correspondence Date',
            //             path: '/systemmanagement/systemmaintenance/changecorrespondancedate'
            //         },
            //         {
            //             title: 'Change Case',
            //             path: '/systemmanagement/systemmaintenance/changecase'
            //         },
            //         {
            //             title: 'Risk Assessment',
            //             path: '/systemmanagement/systemmaintenance/systemriskassessment'
            //         }
            //     ]
            // },
            {
                title: 'Case & Document',
                icon: <FontAwesomeIcon className="w-5 h-5" icon={faFolder} />,
                path: '',
                subSecondMenuItem: [
                    {
                        title: 'Case Type',
                        path: '/systemmanagement/systemmaintenance/casetype'
                    },
                    {
                        title: 'Case Ref Format',
                        path: '/systemmanagement/systemmaintenance/casereferenceformat',
                    },
                    {
                        path: '/systemmanagement/systemmaintenance/addcasereferenceformat'
                    },
                    {
                        title: 'Case Ref Client Format',
                        path: '/systemmanagement/systemmaintenance/casereferenceclientFormat'
                    },
                    {
                        title: 'Doc Categorys',
                        path: '/systemmanagement/systemmaintenance/documentcategorys'
                    },
                    {
                        title: 'Doc Management',
                        path: '/systemmanagement/systemmaintenance/documentmanagement',
                    },
                    {
                        title: 'Checklist',
                        path: '/systemmanagement/systemmaintenance/checklist'
                    },
                    {
                        title: 'Workflow Setup',
                        path: '/systemmanagement/systemmaintenance/workflowsetup'
                    },
                    {
                        path: '/systemmanagement/systemmaintenance/addWorkflow',
                    },
                    {
                        path: `/systemmanagement/systemmaintenance/updateWorkflow/`,
                    },
                    {
                        title: 'Case Closure',
                        path: '/systemmanagement/systemmaintenance/caseclosureareas'
                    },
                    {
                        title: 'Change Case',
                        path: '/systemmanagement/systemmaintenance/changecase'
                    },
                ]
            },
            {
                title: 'Task & Assessment',
                icon: <FontAwesomeIcon className="w-5 h-5" icon={faTasks} />,
                path: '',
                subSecondMenuItem: [
                    {
                        title: 'Imp Dates & Tasks',
                        path: '/systemmanagement/systemmaintenance/importantdatesandtasks'
                    },
                    
                    {
                        title: 'Custom Field',
                        path: '/systemmanagement/systemmaintenance/customfieldForcaseType'
                    },
                    {
                        title: 'Merge Fields',
                        path: '/systemmanagement/systemmaintenance/mergefields'
                    },
                    {
                        title: 'Correspondence Date',
                        path: '/systemmanagement/systemmaintenance/changecorrespondancedate'
                    },
                    
                    {
                        title: 'Risk Assessment',
                        path: '/systemmanagement/systemmaintenance/systemriskassessment'
                    }
                ]
            },
            // {
            //     title: 'Digital Applications',
            //     icon: <FontAwesomeIcon className="w-5 h-5 " icon={faDesktop} />,
            //     path: '',
            //     subSecondMenuItem: []
            // },
            // {
            //     title: 'Email Accounts',
            //     icon: <FontAwesomeIcon className="w-5 h-5" icon={faEnvelope} />,
            //     path: '',
            //     subSecondMenuItem: []
            // },
        ]
    },
    // {
    //     title: 'Dashboard',
    //     icon: <FontAwesomeIcon className="w-6 h-6 text-white-bg" icon={faUserTie} />,
    //     path: '/test1'
    // },
    // {
    //     title: 'Dashboard',
    //     icon: <FontAwesomeIcon className="w-6 h-6 text-white-bg" icon={faUserTie} />,
    //     path: '/test2'
    // },
]