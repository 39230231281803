import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import LedgerToLedgerService from "../../../../../services/Accounting/ClientAccounts/ClientPostings/LedgerToLedgerService";
import { LedgerToLedger, LedgerToLedgerData } from "../../../../model/Accounting/ClientAccount/LedgerToLedgerInterface";






// Define the state type for Checklist
type LedgerToLedgerState = {
    receiptNumber: number | null;
    ledgerToLedgers: LedgerToLedger[];
    ledgerToLedgerData: LedgerToLedgerData;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: LedgerToLedgerState = {
    receiptNumber: null!,
    ledgerToLedgerData: null!,
    ledgerToLedgers: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllLedgerToLedger = createAsyncThunk(
    "fetchAllLedgerToLedger",
    async (_, thunkAPI) => {
        try {
            const response = await LedgerToLedgerService.GetAllLedgerToLedger();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchNextLedgerToLedgerNumber = createAsyncThunk(
    "fetchNextLedgerToLedgerNumber",
    async (_, thunkAPI) => {
        try {
            const response = await LedgerToLedgerService.GetLedgerToLedgerNumber();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addLedgerToLedger = createAsyncThunk(
    "addLedgerToLedger",
    async (data: any, thunkAPI) => {
        try {
            const response = await LedgerToLedgerService.PostLedgerToLedger(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateLedgerToLedger = createAsyncThunk(
    "updateLedgerToLedger",
    async ({ id, data }: { id: number, data: LedgerToLedger }, thunkAPI) => {
        try {
            const response = await LedgerToLedgerService.PutLedgerToLedger(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteLedgerToLedger = createAsyncThunk(
    "deleteLedgerToLedger",
    async (id: number, thunkAPI) => {
        try {
            await LedgerToLedgerService.deleteLedgerToLedger(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const LedgerToLedgerSlice = createSlice({
    name: "ledgerToLedger",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllLedgerToLedger.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllLedgerToLedger.fulfilled, (state, action: PayloadAction<LedgerToLedgerData>) => {
                state.status = "idle";
                state.ledgerToLedgerData = action.payload;
            })
            .addCase(fetchAllLedgerToLedger.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })

            .addCase(fetchNextLedgerToLedgerNumber.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchNextLedgerToLedgerNumber.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.receiptNumber = action.payload;
            })
            .addCase(fetchNextLedgerToLedgerNumber.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addLedgerToLedger.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addLedgerToLedger.fulfilled, (state, action: PayloadAction<LedgerToLedger>) => {
                state.status = "idle";
                state.ledgerToLedgers.push(action.payload);
            })
            .addCase(addLedgerToLedger.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateLedgerToLedger.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateLedgerToLedger.fulfilled, (state, action: PayloadAction<LedgerToLedger>) => {
                state.status = "idle";
                const index = state.ledgerToLedgers.findIndex(t => t.ledgerToLedgerTransactionId === action.payload.ledgerToLedgerTransactionId);
                if (index !== -1) {
                    state.ledgerToLedgers[index] = action.payload;
                }
            })
            .addCase(updateLedgerToLedger.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteLedgerToLedger.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteLedgerToLedger.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.ledgerToLedgers = state.ledgerToLedgers.filter(t => t.ledgerToLedgerTransactionId !== action.payload);
            })
            .addCase(deleteLedgerToLedger.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default LedgerToLedgerSlice.reducer;
