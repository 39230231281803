import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import BillPaymentService from "../../../../../services/Accounting/OfficeAccount/Suppliers/BillPaymentService";
import { PaginationResponse } from "../../../../model/Pagination/paginationClass";


export interface BillPayment {

    listingData: ListingData[];
    
}


export interface ListingData {
    billPaymentId: number;
    billPaymentNumber: number;
    officeBank: string;
    balance: string;
    transactionDate: Date;
    entryDate: Date;
    authorisedBy: string;
    transferMethod: string;
    referenceNumber: number;
    comments: string;
 
};
// Define the state type for Checklist
type BillPaymentState = {
    billPayments: PaginationResponse<BillPayment> | null;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: BillPaymentState = {
    billPayments: null,
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllBillPayment = createAsyncThunk(
    "fetchAllBillPayment",
    async (data: any, thunkAPI) => {
        try {
            const response = await BillPaymentService.GetAllBillPayment(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addBillPayment = createAsyncThunk(
    "addBillPayment",
    async (data: any, thunkAPI) => {
        try {
            const response = await BillPaymentService.PostBillPayment(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateBillPayment = createAsyncThunk(
    "updateBillPayment",
    async ({ id, data }: { id: number, data: BillPayment }, thunkAPI) => {
        try {
            const response = await BillPaymentService.PutBillPayment(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteBillPayment = createAsyncThunk(
    "deleteBillPayment",
    async (id: number, thunkAPI) => {
        try {
            await BillPaymentService.deleteBillPayment(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const BillPaymentSlice = createSlice({
    name: "billPayment",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllBillPayment.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllBillPayment.fulfilled, (state, action: PayloadAction<PaginationResponse<BillPayment>>) => {
                state.status = "idle";
                state.billPayments = action.payload;
            })
            .addCase(fetchAllBillPayment.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addBillPayment.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addBillPayment.fulfilled, (state, action: PayloadAction<PaginationResponse<BillPayment>>) => {
                state.status = "idle";
                //state.billPayments.push(action.payload);
            })
            .addCase(addBillPayment.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateBillPayment.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateBillPayment.fulfilled, (state, action: PayloadAction<PaginationResponse<BillPayment>>) => {
                state.status = "idle";
                // const index = state.billPayments.findIndex(t => t.billPaymentId === action.payload.billPaymentId);
                // if (index !== -1) {
                //     state.billPayments[index] = action.payload;
                // }
            })
            .addCase(updateBillPayment.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteBillPayment.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteBillPayment.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                //state.billPayments = state.billPayments.filter(t => t.billPaymentId !== action.payload);
            })
            .addCase(deleteBillPayment.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default BillPaymentSlice.reducer;
