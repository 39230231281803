export const OfficeAccountConstant = {
    JournalEntry: "journalentry",
    BankDeposit: "bankdeposit",
    ClientPayment: "clientpayment",
    FixedFeeInvoice: "fixedfeeinvoice",
    FixedFeeInvoicePayment: "fixedfeeinvoicepayment",
    FundTransfer: "fundtransfer",
    OfficeCreditNote: "officecreditnote",
    RefundCreditNote: "refundcreditnote",
    SupplierBillPayment: "supplierbilpayment",
    SupplierBillEntry: "supplierbillentry",
    SupplierCreditNote : "suppliercreditnote",
} as const;
