import { useEffect, useRef, useState } from "react";
import { CheckBoxFilter } from "../../Filters/CheckBoxFilter";
import { SearchInput } from "../../Filters/SearchInput";
import { HeaderTitle } from "../../HeaderTitle";
import { useAppDispatch } from "../../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { fetchAllCreatePayment } from "../../../store/slice/Accounting/ClientAccounts/ClientPosting/BankPayment/CreatePaymentSlice";
import { toast } from "react-toastify";
import { MainAnimation } from "../../loadingAnimation/MainAnimation";
import { FaEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { MdOutlineRefresh } from "react-icons/md";
import { Pagination } from "flowbite-react";
import { SubTitle } from "../../SubTitle";

function CreatePayment() {
    const [openModal, setOpenModal] = useState(false);
    const [selectedSection, setSelectedSection] = useState('CreatePayment');
    const dispatch = useAppDispatch();
    // const [initialLoading, setInitialLoading] = useState(true);
    const { createPayments, status, error } = useSelector((state: RootState) => state.createPayment);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [BankNewPaymentData, setBankNewPaymentData] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    
    const handleButtonClick = (section: any) => {
        setSelectedSection(section);
    };

    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);
    useEffect(() => {
        // if (!effectServiceCall.current) {
        //     dispatch(fetchAllCreatePayment())
        //         .then(() => setInitialLoading(false))
        //         .catch(() => setInitialLoading(false));
        //     effectServiceCall.current = true;
        // }

        if (status === 'failed' && !errorShownRef.current) {
            toast.error(error);
            errorShownRef.current = true;
        }
    }, [dispatch, status, error]); 

    
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    // const filterBankNewPaymentBySearchQuery = () => {
    //     return createPayments.filter((createPayment: any) =>
    //         createPayment.clientBank.toLowerCase().includes(searchQuery.toLowerCase()) 
    //     );
    // };

    // const filteredByCreatePayment = filterCreatePaymentBySearchQuery();



    const toggleDrawer = () => {
        setOpenModal(!openModal);
    };

    const indexOfLastBankNewPayment = currentPage * itemsPerPage;
    const indexOfFirstBankNewPayment = indexOfLastBankNewPayment - itemsPerPage;
    const currentCreatePayment = createPayments.slice(indexOfFirstBankNewPayment, indexOfLastBankNewPayment);


    const totalPages = Math.ceil(createPayments.length / itemsPerPage);

    return (
        <>
            <div className="">
                <div className=" w-full flex flex-col gap-2">
                    <div className="mb-5">
                        <SubTitle title="Create Payment" />
                    </div>
                    <div className="flex flex-row md:flex-col justify-between my-5 gap-10 w-full md:gap-3">
                        <div>
                            <SearchInput title="Keyword" />
                        </div>
                        <div>
                            <div className="flex flex-col gap-x-4 gap-y-2 md:gap-1 w-full">
                                <div className="flex border-b-2 pb-1.5">
                                    <CheckBoxFilter title="Client Bank" />
                                    <div className="w-px h-5 mx-2 bg-gray-300"></div>
                                    <CheckBoxFilter title="Areas of Practice" />
                                    <div className="w-px h-5 mx-2 bg-gray-300"></div>
                                    <CheckBoxFilter title="Case Type" />
                                    
                                </div>
                                <div className="flex">
                                    <CheckBoxFilter title="Priority" />
                                    <div className="w-px h-5 mx-2 bg-gray-300"></div>
                                    <CheckBoxFilter title="Case Sub Type" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="relative overflow-x-auto sm:rounded-lg">
                        <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                        {/* {initialLoading ? (<MainAnimation />) : ""} */}
                            <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                                <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    <th scope="col" className="px-6 py-3">
                                    Priority
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                    Request Details
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Case Details
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Payee
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Description
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Amount
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                            {currentCreatePayment.map((createPayment: any, index) => (
                                <tr key={index} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    <td className="px-6 py-3">
                                        {createPayment.priority}
                                    </td>
                                    <td className="px-6 py-3">
                                        {createPayment. requestDetails}
                                    </td>
                                    <td className="px-6 py-3">
                                      {createPayment.caseDetails}
                                    </td>
                                    <td className="px-6 py-3">
                                        {createPayment.payee}
                                    </td>
                                    <td className="px-6 py-3">
                                        {createPayment. description}
                                    </td>
                                    <td className="px-6 py-3">
                                        {createPayment.amount}
                                    </td>
                                    <td className="cursor-pointer px-6 py-3">
                                        <div className="flex gap-3 w-full">
                                            <FaEye className="fill-gray-500 w-6 h-6" />
                                            <FaEdit className="fill-blue-500 w-6 h-6" />
                                            <RiDeleteBin5Fill className="fill-red-600 w-6 h-6" />
                                            <MdOutlineRefresh className="fill-blue-800 w-6 h-6" />
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                        </table>
                        {currentCreatePayment.length === 0 && (
                            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
                                <p className="text-red-800">No Bank  Create Payment found.</p>
                            </div>
                        )}
                    </div>
                    {currentCreatePayment.length > 0 && (
                        <div className="w-full flex flex-col pb-[100px]">
                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onPageChange={(page) => setCurrentPage(page)}
                            />
                        </div>
                    )}  
                    </div>
                </div>
            
        </>
    )
}

export { CreatePayment };