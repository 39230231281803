import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SearchInput } from "../Filters/SearchInput";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { toast } from "react-toastify";
import { AddAccounts } from "../Modal/AddAccounts";
import { fetchAllAccount } from "../../store/slice/Accounting/AccountSetup/AccountSlice";
import { SubHeaderTitle } from "../SubHeaderTitle";


const BankAccount = () => {

    const { accounts } = useSelector((state: RootState) => state.account);
    const dispatch = useAppDispatch();
    const [initialLoading, setInitialLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [searchQuery, setSearchQuery] = useState<string>('');
    // const [feeTypeData, setFeeTypeData] = useState<any>([]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    // const filteraccountBySearchQuery = () => {
    //     return accounts.filter((account: any) =>
    //         account?.accountName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //         account?.accountSortCode.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //         account?.bankAccountCode.toLowerCase().includes(searchQuery.toLowerCase())
    //     );
    // };

    // const filteredByaccount = filteraccountBySearchQuery();

    useEffect(() => {
        if (!effectServiceCall.current) {
            const fetchPromises = [
                dispatch(fetchAllAccount()),
            ];

            Promise.all(fetchPromises)
                .then(() => {
                    setInitialLoading(false);
                })
                .catch((error) => {
                    if (!errorShownRef.current) {
                        toast.error("Error fetching data: " + error.message);
                        errorShownRef.current = true;
                    }
                    setInitialLoading(false);
                });

            effectServiceCall.current = true;
        }
    }, [dispatch]);

    if (initialLoading) {
        return <MainAnimation />;
    }

    // pagination
    const indexOfLastAccount = currentPage * itemsPerPage;
    const indexOfFirstAccount = indexOfLastAccount - itemsPerPage;
    const currentAccount = accounts.slice(indexOfFirstAccount, indexOfLastAccount);

    // Calculate the total number of pages
    const totalPages = Math.ceil(accounts.length / itemsPerPage);

    const toggleDrawer = () => {
        setIsEdit(false);
        setOpenModal(!openModal);
    };

    return (
        <div className="w-full mb-5">
            <div className="">
                <SubHeaderTitle title="Bank Account" buttonName="New Bank Account" isAdd={true} onClickAdd={toggleDrawer} />
                {(openModal) &&
                    <AddAccounts openDrawer={openModal} Close={toggleDrawer} isEdit={isEdit} />
                }
            </div>
            <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
                <SearchInput title="Bank Account" value={searchQuery} onChange={handleSearchChange} />
                <div>
                    <div className="flex gap-4 md:gap-1 w-full">
                        <CheckBoxFilter title="Account Type" />
                    </div>
                </div>
            </div>
            <div className="grid md:grid-cols-1 grid-cols-2 w-full gap-5">
                {currentAccount.map((account: any, index) => (
                    <div key={index} className="border rounded-md p-4 shadow-md dark:border-gray-400 dark:border-opacity-35">
                        <div className="flex justify-between items-center bg-gray-100 dark:bg-gray-800">
                            <p className={`font-semibold px-6 dark:text-white-bg `}>Account Type - {account.bankAccountType}</p>
                            {/* ${account.bankAccountType === 'Client' ? 'bg-red-500' : 'bg-slate-300'} */}
                            <div className="flex gap-4 cursor-pointer px-6 py-3">
                                <svg className="fill-blue-500 w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" /></svg>
                                <svg className="fill-red-600 w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z" /></svg>
                            </div>
                        </div>
                        <div className="px-6 py-3 space-y-3">
                            <div className="grid grid-cols-1 gap-4 max-w-md mx-auto">
                                <div className="flex items-center">
                                    <label className="w-1/2 text-sm font-semibold dark:text-white-bg">Account Code</label>
                                    <span className="mx-2 text-sm font-semibold dark:text-white-bg">:</span>
                                    <p className="w-1/2 text-sm dark:text-white-bg">{account.bankAccountCode}</p>
                                </div>

                                <div className="flex items-center">
                                    <label className="w-1/2 text-sm font-semibold dark:text-white-bg">Account Name</label>
                                    <span className="mx-2 text-sm font-semibold dark:text-white-bg">:</span>
                                    <p className="w-1/2 text-sm dark:text-white-bg">{account.accountName}</p>
                                </div>

                                <div className="flex items-center">
                                    <label className="w-1/2 text-sm font-semibold dark:text-white-bg">Account Number</label>
                                    <span className="mx-2 text-sm font-semibold dark:text-white-bg">:</span>
                                    <p className="w-1/2 text-sm dark:text-white-bg">{account.accountNumber}</p>
                                </div>

                                <div className="flex items-center">
                                    <label className="w-1/2 text-sm font-semibold dark:text-white-bg">Short Code</label>
                                    <span className="mx-2 text-sm font-semibold dark:text-white-bg">:</span>
                                    <p className="w-1/2 text-sm dark:text-white-bg">{account.accountSortCode}</p>
                                </div>

                                <div className="flex items-center">
                                    <label className="w-1/2 text-sm font-semibold dark:text-white-bg">Active</label>
                                    <span className="mx-2 text-sm font-semibold dark:text-white-bg">:</span>
                                    <p className="w-1/2 text-sm dark:text-white-bg">{account.isActive ? 'Active' : 'Inactive'}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
export { BankAccount };