import { useEffect, useRef, useState } from "react";
import { CheckBoxFilter } from "../../Filters/CheckBoxFilter";
import { SearchInput } from "../../Filters/SearchInput";
import { FaEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { MdOutlineRefresh } from "react-icons/md";
import { AddNewPayment } from "../../Modal/AddClientAccounts/AddClientPostings/AddNewPayment";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store/hooks/redux-hooks";
import { fetchAllBankNewPayment } from "../../../store/slice/Accounting/ClientAccounts/ClientPosting/BankPayment/BankNewPaymentSlice";
import { toast } from "react-toastify";
import { MainAnimation } from "../../loadingAnimation/MainAnimation";
import { RootState } from "../../../store/store";
import { SubTitle } from "../../SubTitle";
import { BankReceiptModel } from "../../../store/model/Accounting/ClientAccount/ClientBankReceipt";
import { Pagination } from "../../Pagination";



const  NewPayment = () => {
    const [openModal, setOpenModal] = useState(false);
    const [selectedSection, setSelectedSection] = useState('NewPayment');
    const [isEdit, setIsEdit] = useState(false);
    const dispatch = useAppDispatch();
    const [initialLoading, setInitialLoading] = useState(true);
    const banNewPayments = useSelector((state: RootState) => state.bankNewPayment.banNewPayments);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [bankNewPaymentData, setBankNewPaymentData] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [currentBankNewPayment, setCurrentBankNewPayment] = useState<any[]>([]);
    const itemsPerPage = 10

    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
            dispatch(fetchAllBankNewPayment())
                .then(() => setInitialLoading(false))
                .catch(() => setInitialLoading(false));
            effectServiceCall.current = true;
        }

    }, [dispatch]);

    useEffect(() => {
        if (banNewPayments && banNewPayments.clientBankPaymentResult) {
            setBankNewPaymentData(banNewPayments.clientBankPaymentResult);
        }
    }, [banNewPayments]);

    const filterBankNewPaymentBySearchQuery = () => {
        return bankNewPaymentData.filter((paymentData: any) =>
            paymentData?.caseName?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
            paymentData?.payTo?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
            paymentData?.priority?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
            paymentData?.individualNames?.toLowerCase().includes(searchQuery?.toLowerCase())
        );
    };

    useEffect(() => {
        if (bankNewPaymentData && bankNewPaymentData.length > 0) {
            // console.log(bankNewPaymentData);
            const filteredByBankNewPayment = filterBankNewPaymentBySearchQuery();
            const indexOfLastBankNewPayment = currentPage * itemsPerPage;
            const indexOfFirstBankNewPayment = indexOfLastBankNewPayment - itemsPerPage;
            const currentBankNewPayments = filteredByBankNewPayment.slice(indexOfFirstBankNewPayment, indexOfLastBankNewPayment);
            const totalPages = Math.ceil(bankNewPaymentData.length / itemsPerPage); // Use filtered data length
            setTotalPages(totalPages);
            setCurrentBankNewPayment(currentBankNewPayments);
        } else {
            setCurrentBankNewPayment([]);
        }
    }, [bankNewPaymentData, searchQuery, currentPage]);



    const toggleDrawer = () => {
        setIsEdit(false);
        setOpenModal(!openModal);
    };
    const handleButtonClick = (section: any) => {
        setSelectedSection(section);
    };

    if (openModal) {
        return (
            <AddNewPayment openDrawer={openModal} Close={toggleDrawer} isEdit={isEdit} banNewPayment={bankNewPaymentData} />
        )
    }

    return (
        <>
            <div className=" min-w-full flex flex-col gap-2">
                <div className="">
                    <SubTitle title="New Payment" buttonName="New Payment" isAdd={true} onClickAdd={toggleDrawer} />
                </div>
                <div className="flex flex-col md:flex-col justify-between my-5 gap-7 w-full md:gap-3">
                    <div className="flex w-72">
                        <SearchInput title="Keyword" />
                    </div>
                    <div className="flex pb-1.5">
                        <CheckBoxFilter title="Client Bank" />
                        <div className="w-px h-5 mx-2 bg-gray-300"></div>
                        <CheckBoxFilter title="Areas of Practice" />
                        <div className="w-px h-5 mx-2 bg-gray-300"></div>
                        <CheckBoxFilter title="Case Type" />
                        <div className="w-px h-5 mx-2 bg-gray-300"></div>
                        <CheckBoxFilter title="Case Sub Type" />
                        <div className="w-px h-5 mx-2 bg-gray-300"></div>
                        <CheckBoxFilter title="Status" />
                    </div>
                </div>
                <div className="relative overflow-x-auto sm:rounded-lg min-h-screen overflow-auto">
                    <table className="w-[100%] text-xs text-left rtl:text-right dark:text-gray-400">
                        {initialLoading ? (<MainAnimation />) : ""}
                        <thead className="text-sm bg-gray-50 dark:bg-gray-800 dark:text-gray-400">
                            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <th scope="col" className="px-6 py-3">
                                    Date
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Reference
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Case Details
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Payee
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Amount
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Status
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentBankNewPayment.map((banNewPayment: any, index) => (
                                <tr key={index} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    <td className="px-6 py-3">
                                        {new Date(banNewPayment.authorisedDate).toISOString().split('T')[0]}
                                    </td>
                                    <td className="px-6 py-3">
                                        PAY{banNewPayment.clientPaymentId}
                                    </td>
                                    <td className="px-6 py-3">
                                        {banNewPayment.caseReferenceAuto}
                                        <br />
                                        {banNewPayment.caseName}
                                    </td>
                                    <td className="px-6 py-3">
                                        {banNewPayment.payTo}
                                    </td>
                                    <td className="px-6 py-3">
                                        {banNewPayment.amount}
                                    </td>
                                    <td className="px-6 py-3">
                                        {banNewPayment.status}
                                    </td>
                                    <td className="cursor-pointer px-6 py-3">
                                        <div className="flex gap-3 w-full">
                                            <FaEye className="fill-gray-500 w-6 h-6" />
                                            <FaEdit className="fill-blue-500 w-6 h-6" />
                                            <RiDeleteBin5Fill className="fill-red-600 w-6 h-6" />
                                            <MdOutlineRefresh className="fill-blue-800 w-6 h-6" />
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {currentBankNewPayment.length === 0 && (
                        <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
                            <p className="text-red-800">No Bank New Payment found.</p>
                        </div>
                    )}
                </div>
                {currentBankNewPayment.length > 0 && (
                    <div className="w-full flex flex-col pb-[100px]">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={(page) => setCurrentPage(page)}
                        />
                    </div>
                )}
            </div>
        </>
    )
}

export { NewPayment };