import { useEffect, useRef, useState } from "react";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SearchInput } from "../Filters/SearchInput";
import { FaEye } from "react-icons/fa";
import { AddProtectedFund } from "../Modal/AddClientAccounts/AddClientPostings/AddProtectedFund";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { fetchAllProtectedFund } from "../../store/slice/Accounting/ClientAccounts/ClientPosting/ProtectedFundSlice";
import { toast } from "react-toastify";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { Pagination } from "../Pagination";
function ProtectedFund() {
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const dispatch = useAppDispatch();
    const [initialLoading, setInitialLoading] = useState(true);
    const protectedFundGetData = useSelector((state: RootState) => state.protectedFund.protectedFundGetData);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [protectedFundData, setProtectedFundData] = useState<any>([]);
    const [currentProtectedFunds, setCurrentprotectedFunds] = useState<any[]>([]);
    const [totalPages, setTotalPages] = useState(0);


    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
            dispatch(fetchAllProtectedFund())
                .then(() => setInitialLoading(false))
                .catch(() => setInitialLoading(false));
            effectServiceCall.current = true;
        }
    }, [dispatch]);

    useEffect(() => {
        if (protectedFundGetData && protectedFundGetData.protectedFundResult) {
            setProtectedFundData(protectedFundGetData.protectedFundResult);
        }
    }, [protectedFundGetData]);

    const filterProtectedFundBySearchQuery = () => {
        return protectedFundData.filter((protectedFund: any) =>
            protectedFund?.protectedContactType?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
            protectedFund?.protectedFundType?.toLowerCase().includes(searchQuery?.toLowerCase())
        );
    };

    useEffect(() => {
        if (protectedFundData != null && protectedFundData.length > 0) {
            const filteredByProtectedFund = filterProtectedFundBySearchQuery();
            const indexOfLastProtectedFund = currentPage * itemsPerPage;
            const indexOfFirstProtectedFund = indexOfLastProtectedFund - itemsPerPage;
            const currentProtectedFunds = filteredByProtectedFund.slice(indexOfFirstProtectedFund, indexOfLastProtectedFund);
            const totalPages = Math.ceil(protectedFundData.length / itemsPerPage);
            setTotalPages(totalPages)
            setCurrentprotectedFunds(currentProtectedFunds)
        }
    }, [protectedFundData])


    const toggleDrawer = () => {
        setOpenModal(!openModal);
    };

    if (openModal) {
        return (
            <AddProtectedFund openDrawer={openModal} Close={toggleDrawer} isEdit={isEdit} protectedFund={protectedFundData} />
        )
    }
    return (
        <>
            <div className="">
                <div className="mb-5">
                    <SubHeaderTitle title="Protected Fund" buttonName="Protected Fund" isAdd={true} onClickAdd={toggleDrawer} />
                </div>
                <div className="flex flex-row md:flex-col justify-between my-5 gap-10 w-full md:gap-3">
                    <div>
                        <SearchInput title="Keyword" />
                    </div>

                    <div>
                        <div className="flex flex-col gap-x-4 gap-y-2 md:gap-1 w-full">
                            <div className="flex border-b-2 pb-1.5">
                                <CheckBoxFilter title="Areas of Practice" />
                                <div className="w-px h-5 mx-2 bg-gray-300"></div>
                                <CheckBoxFilter title="Case Type" />
                                <div className="w-px h-5 mx-2 bg-gray-300"></div>
                                <CheckBoxFilter title="Case Sub Type" />
                                <div className="w-px h-5 mx-2 bg-gray-300"></div>
                                <CheckBoxFilter title="Undertaking Type" />
                            </div>
                            <div className="flex">
                                <CheckBoxFilter title="Client Bank" />
                            </div>

                        </div>
                    </div>
                </div>
                <div className="relative overflow-x-auto sm:rounded-lg">
                    {initialLoading ? (<MainAnimation />) : ""}
                    <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                        <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <th scope="col" className="px-6 py-3">
                                    Date
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Case Reference
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Given / Taken
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Protected Funds
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Type
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Is Released
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Action
                                </th>
                                <th scope="col" className="px-6 py-3">

                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentProtectedFunds.map((protectedFund: any, index) => (
                                <tr key={index} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    <td className="px-6 py-3">
                                    {new Date(protectedFund.protectedDateTime).toISOString().split('T')[0]}
                                    </td>
                                    <td className="px-6 py-3">
                                        {protectedFund.caseReferenceAuto}
                                    </td>
                                    <td className="px-6 py-3">
                                        {protectedFund.givenOrTaken}
                                    </td>
                                    <td className="px-6 py-3 text-center">
                                   £ {protectedFund.protectedAmount}
                                    </td>
                                    <td className="px-6 py-3">
                                        {protectedFund.protectedFundType}
                                    </td>
                                    <td className="px-6 py-3 text-center">
                                        {(protectedFund.isReleased === false) ? "No" : "Yes"}
                                    </td>

                                    <td className="cursor-pointer px-6 py-3">
                                        <div className="flex gap-3 w-full">
                                            <FaEye className="fill-gray-500 w-6 h-6" />
                                        </div>
                                    </td>
                                    <td className="cursor-pointer px-6 py-3 text-center">
                                        <button
                                            className="text-xs py-1 px-3 font-medium text-white-bg bg-red-500 rounded hover:bg-red-600">
                                            Release
                                        </button>
                                    </td>

                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {currentProtectedFunds.length === 0 && (
                        <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
                            <p className="text-red-800">No ProtectedFund is not found.</p>
                        </div>
                    )}
                </div>
                {currentProtectedFunds.length > 0 && (
                    <div className="w-full flex flex-col pb-[100px]">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={(page) => setCurrentPage(page)}
                        />
                    </div>
                )}
            </div>

        </>
    )
}

export { ProtectedFund };