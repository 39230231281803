import { useEffect, useRef, useState } from "react";
import { AddQuotationNotices } from "../Modal/AccountSetup/AddQuotationNotices";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { fetchAllQuotationNotice } from "../../store/slice/Accounting/AccountSetup/QuotationNoticeSlice";
import { toast } from "react-toastify";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { FaEdit } from "react-icons/fa";
import { Pagination } from "../Pagination";
import { MdDelete } from "react-icons/md";
import { SubHeaderTitle } from "../SubHeaderTitle";

function QuotationNotices() {
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const dispatch = useAppDispatch();
    const [initialLoading, setInitialLoading] = useState(true);
    const { quotationNotices, status, error } = useSelector((state: RootState) => state.quotationNotice);
    const [quotationNoticeData, setQuotationNoticeData] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
            dispatch(fetchAllQuotationNotice())
                .then(() => setInitialLoading(false))
                .catch(() => setInitialLoading(false));
            effectServiceCall.current = true;
        }

        if (status === 'failed' && !errorShownRef.current) {
            // toast.error(error);
            errorShownRef.current = true;
        }
    }, [dispatch, status, error]);

    if (initialLoading) {
        return <MainAnimation />;
    }



    const toggleDrawer = () => {
        setIsEdit(false);
        setOpenModal(!openModal);
    };

    const indexOfLastQuotationNotice = currentPage * itemsPerPage;
    const indexOfFirstQuotationNotice = indexOfLastQuotationNotice - itemsPerPage;
    const currentQuotationNotice = quotationNotices.slice(indexOfFirstQuotationNotice, indexOfLastQuotationNotice);

    const totalPages = Math.ceil(quotationNotices.length / itemsPerPage);

    return (
        <>
            <div className="">
                <div className="mb-5">
                    <SubHeaderTitle title="Quotation Notice" buttonName="Quotation Notice" isAdd={true} onClickAdd={toggleDrawer} />
                    {(openModal) &&
                        <AddQuotationNotices openDrawer={openModal} Close={toggleDrawer} isEdit={isEdit} quotationNotice={quotationNoticeData} />
                    }
                </div>
                <div className="relative overflow-x-auto sm:rounded-lg my-5">
                    <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                        <thead className="text-sm bg-gray-50 dark:bg-gray-800 dark:text-gray-400">
                            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <th scope="col" className="px-6 py-3">
                                    Quotaion Notice Title
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Edit
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Delete
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            {currentQuotationNotice.map((quotationNotice: any, index) => (

                                <tr key={index} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    <td className="px-6 py-3">
                                        {quotationNotice.title}
                                    </td>
                                    <td className="px-6 py-3 cursor-pointer">
                                        <FaEdit className="fill-blue-500 w-6 h-6"
                                            onClick={() => { setOpenModal(true); setIsEdit(true); setQuotationNoticeData(quotationNotice) }} />
                                    </td>
                                    <td className="px-6 py-3 cursor-pointer">
                                        <MdDelete className="fill-red-600 w-6 h-6" />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {currentQuotationNotice.length === 0 && (
                        <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
                            <p className="text-red-800">No Quotation Notice found.</p>
                        </div>
                    )}
                </div>

                {currentQuotationNotice.length > 0 && (
                    <div className="w-full flex flex-col pb-[100px]">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={(page) => setCurrentPage(page)}
                        />

                    </div>
                )}

            </div>
        </>
    )
}

export { QuotationNotices };