import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import FinancialYearEndService from "../../../../../services/Accounting/OfficeAccount/OfficeReports/FinancialYearEndService";





export interface FinancialYearEnd {
    //creditNoteId: number;
   
};
// Define the state type for Checklist
type FinancialYearEndState = {
    financialYearEnds: FinancialYearEnd[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: FinancialYearEndState = {
    financialYearEnds: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllFinancialYearEnd = createAsyncThunk(
    "fetchAllFinancialYearEnd",
    async (_, thunkAPI) => {
        try {
            const response = await FinancialYearEndService.GetAllFinancialYearEnd();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


// Slice definition
const FinancialYearEndSlice = createSlice({
    name: "financialYearEnd",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllFinancialYearEnd.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllFinancialYearEnd.fulfilled, (state, action: PayloadAction<FinancialYearEnd[]>) => {
                state.status = "idle";
                state.financialYearEnds = action.payload;
            })
            .addCase(fetchAllFinancialYearEnd.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
           
    }
});

export default FinancialYearEndSlice.reducer;
