import { Modal } from 'flowbite-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object, number } from 'yup';
import { useEffect, useRef, useState } from 'react';
import { SelectBox } from '../../../../SelectBox';
import { FaLock } from "react-icons/fa";
import { FaLockOpen } from "react-icons/fa";
import { useAppDispatch } from '../../../../../store/hooks/redux-hooks';
import { addBillPayment } from '../../../../../store/slice/Accounting/OfficeAccounts/Suppliers/BillPaymentSlice';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';


function AddBillPayment({ openDrawer, Close, isEdit, billPayment }: { openDrawer: boolean, Close: any, isEdit: boolean, billPayment: any }) {
    const [title] = useState((!isEdit ? 'Add Bill Payment' : 'Edit Bill Payment'))
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [modalPlacement, setModalPlacement] = useState('center')
    const [isLoading, setIsLoading] = useState(false);
    const [setExistingBillPayment] = useState(billPayment);
    const dispatch = useAppDispatch();
    const { clientPaymentTypes } = useSelector((state: RootState) => state.clientPaymentType);
    const [clientPaymentTypeLists, setclientPaymentTypeLists] = useState<any[]>([]);
    const { authourisedClients } = useSelector((state: RootState) => state.authorisedClient);
    const [authorisedClientLists, setAuthorisedClientLists] = useState<any[]>([]);
    
  const { officeBanks } = useSelector((state: RootState) => state.officeBank);
  const [officeBankLists, setOfficeBankLists] = useState<any[]>([]);
  
  
  useEffect(() => {
    if (officeBanks.length > 0) {
        console.log(officeBanks);
      let data = officeBanks.map((officeBank: any, index) => ({
        value: officeBank.bankAccountId,
        label: `${officeBank.institution} - ${officeBank.bankAccountType}`,
      }));
      setOfficeBankLists(data);
    }
  }, [officeBanks]);


    useEffect(() => {
        if (clientPaymentTypes.length > 0) {
            console.log(clientPaymentTypes);
            let data = clientPaymentTypes.map((PaymentType: any, index) => ({
                value: PaymentType.paymentTypeId,
                label: PaymentType.paymentType,
            }));
            setclientPaymentTypeLists(data);

        }
    }, [clientPaymentTypes]);

    useEffect(() => {
        // console.log(authourisedClients);
        if (authourisedClients.length > 0) {
            let data = authourisedClients.map((authorisedClient: any) => ({
                value: authorisedClient.userId,
                label: `${authorisedClient.firstName} -${authorisedClient.lastName}`,
            }));
            setAuthorisedClientLists(data);

        }
    }, [authourisedClients]);

    const handleEditClick = () => {
        setIsDisabled(!isDisabled);
    };

    const [isDisabled, setIsDisabled] = useState(true);


    const validationSchema = object().shape({
        billPaymentNumber: number(),
        officeBank: string()
            .required('Office Bank is required'),
        balance: string(),
        transactionDate: string(),
        entryDate: string(),
        authorisedBy: string()
            .required('Authorised By is required'),
        transferMethod: string()
            .required('Transfer Method is required'),
        referenceNumber: number(),
        comments: string(),
        
    });
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = async (data: any) => {
        console.log(JSON.stringify(data, null, 2));
        setIsLoading(true);
    try {
      await dispatch(addBillPayment(data)).unwrap();
      Close();
      toast.success("Successfully Added FixedFeeInvoicePayment");
    } catch (error: any) {
      toast.error(error.toString());
    } finally {
      setIsLoading(false);
    }
    };

    useEffect(() => {
        if (isEdit && billPayment) {
          setExistingBillPayment(billPayment);
          reset(billPayment);
        }
      }, [billPayment, isEdit, reset, setValue]);


    return (
        <Modal
            className={`backdrop-blur-sm items-center justify-center`}
            show={openDrawer}
            position={modalPlacement}
            onClose={() => { reset(); Close(); }}
            initialFocus={emailInputRef}
            size="4xl"
        >
            <Modal.Header className="bg-white-bg dark:bg-dark_bg p-5">
                <span className="text-blue_gray-900 dark:text-white-bg text-xl w-auto">{title}</span>
            </Modal.Header>
            <Modal.Body className="bg-white-bg dark:bg-dark_bg">

                <form onSubmit={handleSubmit(onSubmit)} className="mx-auto" >
                    <div className="grid grid-cols-3 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 gap-y-1 w-full">
                        <div >
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Bill Payment Number
                            </label>
                            <div className="flex gap-3">
                                <input
                                    type="text"
                                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg disabled:bg-gray-300 dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                                    {...register('billPaymentNumber')}
                                    disabled={isDisabled}
                                />
                                <button
                                    type="button"
                                    onClick={handleEditClick}
                                    className="mt-2 text-sm font-medium text-blue-600 dark:text-blue-500"
                                >
                                    {isDisabled ? <FaLock /> : <FaLockOpen />}
                                </button>
                            </div>
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Office Bank <span className="text-red-500">*</span>
                            </label>
                            <SelectBox
                                className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                indicator={
                                    <svg
                                        width="15"
                                        className="fill-current mr-2"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                    </svg>
                                }
                                isMulti={false}
                                name="frameFiftySix"
                                isSearchable={true}
                                options={officeBankLists}
                                placeholder="Select"
                                shape="round"
                                color="indigo_50"
                                size="xs"
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setValue('officeBank', selectedOption);
                                    }
                                }}
                            />
                            <div className="font-medium mt-2 text-xs text-red-600" >{errors?.officeBank?.message?.toString()}</div>
                        </div>
                        <div className="relative">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Balance
                            </label>
                            <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('balance')}
                                placeholder="Enter Balance"
                            />
                        </div>
                        <div className="relative">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Transaction Date
                            </label>
                            <input type="date" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('transactionDate')}
                            />
                        </div>
                        <div className="relative">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Entry Date 
                            </label>
                            <input type="date" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('entryDate')}
                            />
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Authorised By <span className="text-red-500">*</span>
                            </label>
                            <SelectBox
                                className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                indicator={
                                    <svg
                                        width="15"
                                        className="fill-current mr-2"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                    </svg>
                                }
                                isMulti={false}
                                name="frameFiftySix"
                                isSearchable={true}
                                options={authorisedClientLists}
                                placeholder="Select"
                                shape="round"
                                color="indigo_50"
                                size="xs"
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setValue('authorisedBy', selectedOption);
                                    }
                                }}
                            />
                            <div className="font-medium mt-2 text-xs text-red-600" >{errors?.authorisedBy?.message?.toString()}</div>
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Transfer Method <span className="text-red-500">*</span>
                            </label>
                            <SelectBox
                                className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                indicator={
                                    <svg
                                        width="15"
                                        className="fill-current mr-2"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                    </svg>
                                }
                                isMulti={false}
                                name="frameFiftySix"
                                isSearchable={true}
                                options={clientPaymentTypeLists}
                                placeholder="Select"
                                shape="round"
                                color="indigo_50"
                                size="xs"
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setValue('transferMethod', selectedOption);
                                    }
                                }}
                            />
                            <div className="font-medium mt-2 text-xs text-red-600" >{errors?.transferMethod?.message?.toString()}</div>
                        </div>
                        <div className="relative">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Reference Number
                            </label>
                            <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('referenceNumber')}
                                placeholder="Enter Reference Number"
                            />
                        </div>
                        <div className="relative col-span-1">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Comments
                            </label>
                            <textarea
                                className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                placeholder="Comments" {...register('comments')}
                            ></textarea>
                            <div className="font-medium mt-2 text-xs text-red-600" >{errors?.comments?.message?.toString()}</div>
                        </div>
                        
                    </div>
                    <div className="relative overflow-x-auto sm:rounded-lg">
                    <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                        <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <th scope="col" className="px-6 py-3">
                                Supplier
                                </th>
                                <th scope="col" className="px-6 py-3">
                                Due Date
                                </th>
                                <th scope="col" className="px-6 py-3">
                                Bill
                                </th>
                                <th scope="col" className="px-6 py-3">
                                Amount	
                                </th>
                                <th scope="col" className="px-6 py-3">
                                Amount Due	
                                </th>
                                <th scope="col" className="px-6 py-3">
                                Payment Amount		
                                </th>
                                <th scope="col" className="px-6 py-3">
                                Balance Due	 	
                                </th>
                            </tr>
                        </thead>
                    </table>
                    </div>
                    <div className="flex col-span-2 gap-2 justify-end my-5">
                        <button
                        type="button"
                        onClick={() => {
                            Close();
                        }}
                            className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            disabled={isLoading}
                            className={` cursor - pointer bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]  ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        >
                                {isLoading ? (
                                        <svg className="animate-spin h-5 w-5 text-white-bg mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                                        </svg>
                                    ) : (
                                        'Save'
                                    )}
                        </button>
                    </div>

                </form>

            </Modal.Body>
        </Modal>
    );
}

export { AddBillPayment };
