import { useEffect, useRef, useState } from "react";
import { SearchInput } from "../Filters/SearchInput";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { FaEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { AddOfficeCreditNote } from "./OfficeCreditNoteDetails/AddOfficeCreditNote";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { fetchAllCreditNote } from "../../store/slice/Accounting/OfficeAccounts/OfficePostings/CreditNoteSlice";
import { toast } from "react-toastify";
import { Pagination } from "flowbite-react";
import { MainAnimation } from "../loadingAnimation/MainAnimation";

function OfficeCreditNote() {
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [creditNoteData, setCreditNoteData] = useState<any>([]);
    const dispatch = useAppDispatch();
    const [initialLoading, setInitialLoading] = useState(true);
    const { creditNotes, status, error } = useSelector((state: RootState) => state.creditNote);
    const [totalPages, setTotalPages] = useState(0);
    const [currentCreditNote, setCurrentCreditNote] = useState<any[]>([]);
    const itemsPerPage = 10;
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [currentPage, setCurrentPage] = useState(1);



    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
             dispatch(fetchAllCreditNote())
                .then(() => setInitialLoading(false))
                .catch(() => setInitialLoading(false));
            effectServiceCall.current = true;
        }

        if (status === 'failed' && !errorShownRef.current) {
            toast.error(error);
            errorShownRef.current = true;
        }
    }, [dispatch, status, error]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        if(creditNotes!=null && creditNotes.length > 0){
            const filteredByCreditNote = filterCreditNoteBySearchQuery();
            const indexOfLastCreditNote = currentPage * itemsPerPage;
            const indexOfFirstCreditNote = indexOfLastCreditNote - itemsPerPage;
            const currentCreditNote = filteredByCreditNote.slice(indexOfFirstCreditNote, indexOfLastCreditNote);
            const totalPages = Math.ceil(creditNotes.length / itemsPerPage);
            setTotalPages(totalPages)
            setCurrentCreditNote(currentCreditNote)
        }

    },[creditNotes])


    const filterCreditNoteBySearchQuery = () => {

        return creditNotes.filter((creditNote: any) =>    
            creditNote?.accountMethod?.toLowerCase().includes(searchQuery.toLowerCase()));
    };

    
    const toggleDrawer = () => {
        setIsEdit(false);
        setOpenModal(!openModal);
    };


   
    if (openModal) {
        return (
            <AddOfficeCreditNote openModal={openModal} Close={toggleDrawer} isEdit={isEdit} creditNote={creditNoteData} />
        )
    }
    return (
        <>
            <div className="">
                <div className="mb-5">
                    <SubHeaderTitle title="Credit Note" buttonName="Credit Note" isAdd={true} onClickAdd={toggleDrawer} />
                </div>
                <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
                    <SearchInput title="Keyword" />
                    <div>
                        <div className="flex gap-4 md:gap-1 w-full">
                            <CheckBoxFilter title="Status" />
                        </div>
                    </div>
                </div>
                <div className="relative overflow-x-auto sm:rounded-lg">
                    <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                    {initialLoading ? <MainAnimation /> : ""}
                        <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <th scope="col" className="px-6 py-3">
                                    Date
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Invoicee (s)
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Credit Amount
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Credit Note	
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Invoice
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Status
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        {currentCreditNote.map((creditNote: any, index) => (
  
                            <tr key={index} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <td className="px-6 py-3">
                                {creditNote.date}
                                </td>
                                <td className="px-6 py-3">
                                {creditNote.invoicee}
                                </td>
                                <td className="text-right px-6 py-3">
                                {creditNote.creditAmount}	
                                </td>
                                <td className="px-6 py-3">
                                {creditNote.creditNote}	
                                </td>
                                <td className="px-6 py-3">
                                    <a href="" className="hover:text-blue-500"> {creditNote.invoice}</a>
                                </td>
                                <td className="px-6 py-3">
                                {creditNote.status}
                                </td>
                                <td className="cursor-pointer px-6 py-3">
                                    <div className="flex gap-3 w-full">
                                        <FaEye className="fill-gray-500 w-6 h-6" />
                                        <FaEdit className="fill-blue-500 w-6 h-6" />
                                        <RiDeleteBin5Fill className="fill-red-600 w-6 h-6" />
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    {currentCreditNote.length === 0 && (
                            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
                                <p className="text-red-800">No Credit Note Details found.</p>
                            </div>
                        )}
                </div>
                {currentCreditNote.length > 0 && (
                    <div className="w-full flex flex-col pb-[100px]">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={(page) => setCurrentPage(page)}
                        />
                    </div>
                )}
                </div>
            
        </>
    )
}

export { OfficeCreditNote };