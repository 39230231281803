import { Modal } from "flowbite-react";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object } from "yup";
import { useAppDispatch } from "../../../../../store/hooks/redux-hooks";
import { SelectBox } from "../../../../SelectBox";
import { InputBox } from "../../../../InputBox";

function AddCallIn({
  openDrawer,
  Close,
}: {
  openDrawer: boolean;
  Close: any;
}) {
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState(true);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [modalPlacement, setModalPlacement] = useState("center");

  const validationSchema = object().shape({
    subject: string(),
    caller: string()
        .required('Caller is required'),
    receiver: string(),
    date: string(),
    time: string(),
    duration: string(),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data: any) => {
    console.log(JSON.stringify(data, null, 2));
    // dispatch(AddCallIn(data));
    Close();
  };

  return (
    <Modal
      className={`backdrop-blur-sm items-center justify-center`}
      show={openDrawer}
      position={modalPlacement}
      onClose={() => {
        reset();
        Close();
      }}
      initialFocus={emailInputRef}
      size="2xl"
    >
      <Modal.Header className="bg-white-bg dark:bg-dark_bg p-5">
        <span className="dark:text-white-bg text-xl w-auto">
        Add Call-In
        </span>
      </Modal.Header>
      <Modal.Body className="bg-white-bg dark:bg-dark_bg">
        <form onSubmit={handleSubmit(onSubmit)} className="mx-auto">
          <div className="space-y-3 w-full">
            <div className="bg-sky-50 dark:bg-gray-900 p-5 space-y-2">
                <div className="flex items-center">
                    <label className="w-1/4 text-sm font-semibold dark:text-white-bg">Case Type</label>
                    <span className="mx-2 text-sm font-semibold dark:text-white-bg">:</span>
                    <p className="w-3/4 text-sm dark:text-white-bg">Civil litigation {">>"} Landlord and Tenant {">>"} General</p>
                </div>
                <div className="flex items-center">
                    <label className="w-1/4 text-sm font-semibold dark:text-white-bg">Case Ref</label>
                    <span className="mx-2 text-sm font-semibold dark:text-white-bg">:</span>
                    <p className="w-3/4 text-sm dark:text-white-bg">AS/AS/CLT/150002</p>
                </div>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-1 gap-3">
            <InputBox
              title="Subject"
              dataType="text"
              placeholder="Enter Subject"
              required={false} // Show red asterisk and make it required
              {...register("subject")}
            />
            <div className="">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Caller <span className="text-red-500">*</span>
              </label>
              <SelectBox
                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                indicator={
                  <svg
                    width="15"
                    className="fill-current mr-2"
                    height="15"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                }
                isMulti={false}
                name="frameFiftySix"
                isSearchable={true}
                placeholder="Select"
                shape="round"
                color="indigo_50"
                size="xs"
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setValue("caller", selectedOption);
                  }
                }}
              />
              <div className="font-medium mt-2 text-xs text-red-600">{errors?.caller?.message?.toString()}</div>
            </div>
            <div className="">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
              Receiver
              </label>
              <SelectBox
                className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                indicator={
                  <svg
                    width="15"
                    className="fill-current mr-2"
                    height="15"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                }
                isMulti={false}
                name="frameFiftySix"
                isSearchable={true}
                placeholder="Select"
                shape="round"
                color="indigo_50"
                size="xs"
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setValue("receiver", selectedOption);
                  }
                }}
              />
            </div>
            <InputBox
              title="Date"
              dataType="Date"
              placeholder="Enter Document Title"
              required={false} // Show red asterisk and make it required
              {...register("date")}
            />
            <InputBox
              title="Time"
              dataType="time"
              required={false} 
              {...register("time")}
            />
            <InputBox
              title="Duration"
              dataType="time"
              required={false} 
              {...register("duration")}
            />
            </div>

            <div className="flex md:flex-col gap-2 flex-row justify-start w-full my-5">
              <div className="flex flex-row gap-2 items-end justify-end w-full">
                <button
                  onClick={() => {
                    reset();
                    setOpenModal(Close);
                  }}
                  className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export { AddCallIn };
