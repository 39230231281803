import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import ClientAccountService from "../../../../services/Accounting/ClientAccounts/ClientAccountService";


export interface ClientAccount {
    
bankAccountId: number;  
bankAccountType: string;
institution: string;
};
// Define the state type for Checklist
type ClientAccountState = {
    clientAccounts : ClientAccount[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: ClientAccountState = {
    clientAccounts: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllClientAccount = createAsyncThunk(
    "fetchAllClientAccount",
    async (_, thunkAPI) => {
        try {
            const response = await ClientAccountService.GetAllClientAccount();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

const ClientAccountSlice = createSlice({
    name: "clientAccount",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchAllClientAccount.pending, (state) => {
            state.status = "loading";
        })
        .addCase(fetchAllClientAccount.fulfilled, (state, action: PayloadAction<any[]>) => {
            state.status = "idle";
            state.clientAccounts = action.payload;
        })
        .addCase(fetchAllClientAccount.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.payload as string;
        })

    }
});

export default ClientAccountSlice.reducer;