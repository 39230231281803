import { useEffect, useRef, useState } from "react";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SearchInput } from "../Filters/SearchInput";
import { IoIosPrint } from "react-icons/io";
import { BsFileEarmarkExcelFill } from "react-icons/bs";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { fetchAllOfficeToClientReports } from "../../store/slice/Accounting/ClientAccounts/ClientPosting/ClientReports/ClientReportsSlice";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { Pagination } from "../Pagination";
import { MainAnimation } from "../loadingAnimation/MainAnimation";

function ReportsOfficeToClient() {
    const [openModal, setOpenModal] = useState(false);
    const dispatch = useAppDispatch();
    const [initialLoading, setInitialLoading] = useState(true);
    const [officeToClientReportsDataList, setOfficeToClientReportsDataList] = useState<any>([]);
    const officeToClientReports = useSelector((state: RootState) => state.clientReports.officeToClientReports);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [currentcurrentO2CList, setCurrentcurrentO2CList] = useState<any[]>([]);
    const [totalPages, setTotalPages] = useState(0);
    const [searchQuery, setSearchQuery] = useState<string>("");

    const toggleDrawer = () => {
        setOpenModal(!openModal);
    };
    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
            dispatch(fetchAllOfficeToClientReports())
                .then(() => setInitialLoading(false))
                .catch(() => setInitialLoading(false));
            effectServiceCall.current = true;
        }
    }, [dispatch]);

    useEffect(() => {
        if (officeToClientReports && officeToClientReports.officeToClientReportResult) {
            setOfficeToClientReportsDataList(officeToClientReports.officeToClientReportResult);
        }
    }, [officeToClientReports]);

    const filterO2CBySearchQuery = () => {
        return officeToClientReportsDataList.filter(
            (protectedFund: any) =>
                protectedFund?.accountName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                protectedFund?.caseName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                protectedFund?.toAccountName.toLowerCase().includes(searchQuery.toLowerCase())
        );
    };
    useEffect(() => {
        if (officeToClientReportsDataList != null && officeToClientReportsDataList.length > 0) {
            const filteredByO2C = filterO2CBySearchQuery();
            const indexOfLastO2C = currentPage * itemsPerPage;
            const indexOfFirstO2C = indexOfLastO2C - itemsPerPage;
            const currentO2C = filteredByO2C.slice(
                indexOfFirstO2C,
                indexOfLastO2C
            );
            const totalPages = Math.ceil(officeToClientReportsDataList.length / itemsPerPage);
            setTotalPages(totalPages)
            setCurrentcurrentO2CList(currentO2C)
        }
    }, [officeToClientReportsDataList])

    return (
        <>
            <div className="">
                <div className="mb-5">
                    <SubHeaderTitle title="Office to Client" />
                </div>
                <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
                    <SearchInput title="Keyword" />
                    <div>
                        <div className="flex gap-4 md:gap-1 w-full">
                            <CheckBoxFilter title="Areas of Practice" />
                            <div className="w-px h-5 bg-gray-300"></div>
                            <CheckBoxFilter title="Case Type" />
                            <div className="w-px h-5 bg-gray-300"></div>
                            <CheckBoxFilter title="Case Sub Type" />
                            <div className="w-px h-5 bg-gray-300"></div>
                            <CheckBoxFilter title="Client Bank" />
                        </div>
                    </div>
                </div>
                <div className="my-5">
                <div className="flex gap-x-2 ietms-center justify-end">
                        <button
                            type="submit"
                            className="flex items-center gap-x-2 bg-blue-500 hover:bg-blue-800 text-white-bg cursor-pointer px-5 py-2 rounded text-center text-sm">
                                <IoIosPrint className='w-5 h-5'/>
                            Print
                        </button>
                        <button
                            type="submit"
                            className="flex items-center gap-x-2 bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer px-3 py-2 rounded text-center border text-sm">
                            <BsFileEarmarkExcelFill className='w-5 h-5' />
                            Export to Excel
                        </button>
                    </div>
                    <div className="my-5 text-center bg-sky-50 dark:bg-gray-800 dark:text-white-bg p-6">
                        <h1 className="text-2xl font-bold">Shan & Co Solicitors</h1>
                        <p className="text-lg font-semibold">Office Bank to Client Bank Transaction Report</p>
                        <p className="font-semibold">04 Sep 2024</p>
                    </div>
                    <div className="space-y-2">
                        <div className="flex items-center">
                            <label className="w-1/5 md:w-1/2 text-sm font-semibold dark:text-white-bg">Area of Practice</label>
                            <span className="mx-2 text-sm font-semibold dark:text-white-bg">:</span>
                            <p className="w-4/5 md:w-1/2 text-sm dark:text-white-bg">All</p>
                        </div>
                        <div className="flex items-center">
                            <label className="w-1/5 md:w-1/2 text-sm font-semibold dark:text-white-bg">Case Type</label>
                            <span className="mx-2 text-sm font-semibold dark:text-white-bg">:</span>
                            <p className="w-4/5 md:w-1/2 text-sm dark:text-white-bg">All</p>
                        </div>
                        <div className="flex items-center">
                            <label className="w-1/5 md:w-1/2 text-sm font-semibold dark:text-white-bg">Case Sub Type</label>
                            <span className="mx-2 text-sm font-semibold dark:text-white-bg">:</span>
                            <p className="w-4/5 md:w-1/2 text-sm dark:text-white-bg">All</p>
                        </div>
                        <div className="flex items-center">
                            <label className="w-1/5 md:w-1/2 text-sm font-semibold dark:text-white-bg">Client Bank</label>
                            <span className="mx-2 text-sm font-semibold dark:text-white-bg">:</span>
                            <p className="w-4/5 md:w-1/2 text-sm dark:text-white-bg">All</p>
                        </div>
                        <div className="flex items-center">
                            <label className="w-1/5 md:w-1/2 text-sm font-semibold dark:text-white-bg">Date</label>
                            <span className="mx-2 text-sm font-semibold dark:text-white-bg">:</span>
                            <p className="w-4/5 md:w-1/2 text-sm dark:text-white-bg">All</p>
                        </div>
                        <div className="flex items-center">
                            <label className="w-1/5 md:w-1/2 text-sm font-semibold dark:text-white-bg">Keyword</label>
                            <span className="mx-2 text-sm font-semibold dark:text-white-bg">:</span>
                            <p className="w-4/5 md:w-1/2 text-sm dark:text-white-bg">N/A</p>
                        </div>
                    </div>
                </div>
                <div className="relative overflow-x-auto sm:rounded-lg">
                {initialLoading ? (<MainAnimation />) : ""}
                    <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                        <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <th scope="col" className="px-6 py-3">
                                    Date
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Case Reference
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    From Bank (Office)
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    To Bank (Client)
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Amount
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentcurrentO2CList.map((O2C: any, index) => (
                                <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    <td className="px-6 py-3">                                        
                                        {new Date(O2C.entryDate).toISOString().split('T')[0]}
                                    </td>
                                    <td className="px-6 py-3">
                                    {O2C.caseReferenceAuto}
                                    </td>
                                    <td className="px-6 py-3">
                                        <p className="font-semibold">{O2C.accountName} - {O2C.bankAccountType}</p>
                                        <p>{O2C.accountNumber}</p>
                                    </td>
                                    <td className="px-6 py-3">
                                        <p className="font-semibold">{O2C.toAccountName} - {O2C.toBankAccountType}</p>
                                        <p>{O2C.toAccountNumber}</p>
                                    </td>
                                    <td className="text-right px-6 py-3">
                                    {O2C.amountTransfer}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {currentcurrentO2CList.length === 0 && (
                        <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
                            <p className="text-red-800">No Office To Client Reports.</p>
                        </div>
                    )}
                </div>
                {currentcurrentO2CList.length > 0 && (
                    <div className="w-full flex flex-col pb-[100px]">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={(page) => setCurrentPage(page)}
                        />
                    </div>
                )}
            </div>
        </>
    )
}

export { ReportsOfficeToClient };