import {
  AddInvoiceTimeEntries,
  Heading,
  InputBox,
  SelectBox,
  SubHeaderTitle,
} from "../../../components";
import { useEffect, useRef, useState } from "react";
import { FcPlus } from "react-icons/fc";
import { FcCancel } from "react-icons/fc";
import { FaPlus } from "react-icons/fa6";
import { AddInvoiceProfessionalFees } from "../../Modal/AddClientAccounts/AddClientInvoice/AddQuickInvoice/AddInvoiceProfessionalFees";
import { AddInvoiceDisbursements } from "../../Modal/AddClientAccounts/AddClientInvoice/AddQuickInvoice/AddInvoiceDisbursements";
import { SearchCaseReferenceDetails } from "../../Modal/SearchCaseReferenceDetails";
import { fetchCaseClient } from "../../../store/slice/Accounting";
import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store/hooks/redux-hooks";
import { number, object, string } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import {
  AddClientQuickInvoices,
  fetchDisbursementAccountCombo,
  fetchInvoiceNextNumber,
  fetchQuickInvoicesDataByCaseId,
  fetchVatAccountCombo,
} from "../../../store/slice/Accounting/ClientAccounts/ClientQuickInvoicesSlice";
import {
  fetchAllNominalLedger,
  fetchAllNominalLedgerByTypes,
} from "../../../store/slice/Accounting/AccountSetup/NominalLedgerSlice";
import { SearchCaseClientContact } from "../../Modal/SearchCaseClientContact";
import { AddOfficeFeeTypesDisbursement } from "../../Modal/AddOfficeAccounts/AddOfficeFeeTypes&Disbursement";
import { fetchUsersDataByBranch } from "../../../store/slice/Administrator/UserManagementSlice";
import { fetchAllOfficeVatRate } from "../../../store/slice/Accounting/OfficeAccounts/OfficeVatRateSlice";
import { NumericFormat } from "react-number-format";
import {
  QuickInvoicesSaveData,
  InvoiceLineDTO,
} from "../../../store/model/Accounting/ClientAccount/QuickInvoicesInterFace";

const AddQuickInvoice = ({
  openModal,
  Close,
}: {
  openModal: boolean;
  Close: any;
}) => {
  const effectServiceCall = useRef(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const dispatch = useAppDispatch();
  const [openDescriptionModal, setOpenDescriptionModal] = useState(false);
  const [openProfessionalFeeModal, setOpenProfessionalFeeModal] =
    useState(false);
  const [openTimeEntriesModal, setOpenTimeEntriesModal] = useState(false);
  const [openCaseAccountModal, setOpenCaseAccountModal] = useState(false);
  const [openCaseClientContactModal, setOpenCaseClientContactModal] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCaseReference, setSelectedCaseReference] = useState<any>();
  const [selectedCaseId, setSelectedCaseId] = useState<number>();
  const [selectedCaseClientId, setSelectedCaseClientId] = useState<number>();
  const [selectedMatter, setSelectedMatter] = useState<any>();
  const [selectedCaseClientContact, setSelectedCaseClientContact] =
    useState<any>();
  const [selectedResponsipleName, setSelectedResponsipleName] = useState<any>();
  const { caseClient } = useSelector((state: RootState) => state.case);
  const receiptAutoNumber = useSelector(
    (state: RootState) => state.clientQuickInvoices.receiptNumber
  );
  const { quickInvoicesDataByCase, vatAccountCombo, disbursementAccountCombo } =
    useSelector((state: RootState) => state.clientQuickInvoices);

  const { nominalLedgerTypeRes } = useSelector(
    (state: RootState) => state.nominalLedger
  );
  const [caseClientLists, setcaseClientLists] = useState<any[]>([]);
  const [disbursementAccounts, setdisbursementAccounts] = useState<any[]>([]);
  const [autoReceiptNumber, setAutoReceiptNumber] = useState<number>(0);

  const [disbursementAccountsCombo, setdisbursementAccountsCombo] = useState<
    any[]
  >([]);
  const [vataccountCombo, setvataccountCombo] = useState<any[]>([]);

  const [linesDisbursements, setLinesDisbursements] = useState<
    InvoiceLineDTO[]
  >([]);
  const [linesProfessionalfess, setLinesProfessionalfess] = useState<
    InvoiceLineDTO[]
  >([]);
  const [linesTimeEntries, setLinesTimeEntries] = useState<InvoiceLineDTO[]>(
    []
  );

  const { officeVatRates } = useSelector(
    (state: RootState) => state.officeVatRate
  );
  const [officeVatRateLists, setOfficeVatRateLists] = useState<any[]>([]);

  const { branchUsers } = useSelector((state: RootState) => state.userManage);
  const [branchUserLists, setBranchUserLists] = useState<any[]>([]);
  const [feeTypeName, setFeeTypeName] = useState<string>("");
  const [disbursmentName, setDisbursmentName] = useState<string>("");
  const [timeEntryName, setTimeEntryName] = useState<string>("");
  const [timeEntryId, setTimeEntryId] = useState<number>();
  const [vatOutAccountId, setVatOutAccountId] = useState<number>();
  const [icomeAccountId, setIncomeAccountId] = useState<number>();
  const [disburseAccountId, setDisbursementAccountId] = useState<number>();
  const [caseClientType, setCaseClientType] = useState<string>("");

  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [selectedType, setSelectedType] = useState<string>();

  const [totalVal, setTotalVal] = useState<number>(0);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [subtotal, setSubtotal] = useState<number>(0);
  const [totalTaxAmount, setTotalTaxAmount] = useState<number>(0);
  const [lessCreditInvoice, setLessCreditInvoice] = useState<number>(0);

  const [totalProfessionalFees, setTotalProfessionalFees] = useState<number>(0);
  const [totalDisbursements, setTotalDisbursements] = useState<number>(0);
  const [totalTimeEntries, setTotalTimeEntries] = useState<number>(0);

  const [invoiceTo, setInvoiceTo] = useState<string>("");
  const [invoiceAddressLine1, setInvoiceAddressLine1] = useState<string>("");
  const [invoiceCity, setInvoiceCity] = useState<string>("");
  const [invoiceCoutry, setInvoiceCoutry] = useState<string>("");
  const [invoicePostCode, setInvoicePostCode] = useState<string>("");

  useEffect(() => {
    if (!effectServiceCall.current) {
      dispatch(fetchInvoiceNextNumber());
      dispatch(fetchAllNominalLedgerByTypes());
      dispatch(fetchDisbursementAccountCombo());
      dispatch(fetchVatAccountCombo());
      dispatch(fetchUsersDataByBranch());
      dispatch(fetchAllOfficeVatRate());
    }
    return () => {
      effectServiceCall.current = true;
    };
  }, [dispatch]);

  useEffect(() => {
    if (officeVatRates.length > 0) {
      let data = officeVatRates.map((officeVatRate: any, index) => ({
        value: officeVatRate,
        label: `${officeVatRate.vatType}`,
        vatPercentage: officeVatRate.percentage,
      }));
      setOfficeVatRateLists(data);
    }
  }, [officeVatRates]);

  useEffect(() => {
    if (nominalLedgerTypeRes?.disbursementAccounts) {
      const data = nominalLedgerTypeRes.disbursementAccounts.map(
        (item: any) => ({
          value: item.accountId,
          label: item.accountName,
        })
      );
      setdisbursementAccounts(data);
    }
  }, [nominalLedgerTypeRes]);

  useEffect(() => {
    if (branchUsers.length > 0) {
      let data = branchUsers.map((branchUser: any, index) => ({
        value: branchUser.userId,
        label: `${branchUser.firstName} ${branchUser.lastName} `,
      }));
      setBranchUserLists(data);
    }
  }, [branchUsers]);

  useEffect(() => {
    if (vatAccountCombo != null && vatAccountCombo.length > 0) {
      let data = vatAccountCombo.map((item: any) => ({
        value: item.accountId,
        label: item.accountName,
      }));
      setvataccountCombo(data);
    }

    if (
      disbursementAccountCombo != null &&
      disbursementAccountCombo.length > 0
    ) {
      let data = disbursementAccountCombo.map((item: any) => ({
        value: item.accountId,
        label: item.accountName,
      }));
      setdisbursementAccountsCombo(data);
    }
  }, [vatAccountCombo, disbursementAccountCombo]);

  useEffect(() => {
    if (receiptAutoNumber != null) {
      setValue("invoiceNumber", receiptAutoNumber);
      setAutoReceiptNumber(receiptAutoNumber);
    }
  }, [receiptAutoNumber]);

  const validationSchema = object().shape({
    caseId: number().required("case is required"),
    invoiceNumber: number(),
    invoiceDate: string()
      .required("Transaction Date is required")
      .transform((value, originalValue) => {
        if (originalValue === "" || value === null) return null;
        return new Date(value).toISOString().split("T")[0];
      }),
    dueDate: string().transform((value, originalValue) => {
      if (originalValue === "" || value === null) return null;
      return new Date(value).toISOString().split("T")[0];
    }),
    clientBankAccount: string(),
    receivedFromOther: string(),
    memo: string(),
    disburseAccountId: number(),
    incomeAccountId: number(),
    vatOutputAccountId: number(),
    staff: string(),
    comments: string(),
    finalizedDate: string()
      .required("Transaction Date is required")
      .transform((value, originalValue) => {
        if (originalValue === "" || value === null) return null;
        return new Date(value).toISOString().split("T")[0];
      }),
  });
  const today = new Date().toISOString().split("T")[0];
  useEffect(() => {
    setValue("invoiceDate", today);
    setValue("dueDate", today);
    setValue("finalizedDate", today);
  }, [today]);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      invoiceNumber: autoReceiptNumber,
    },
  });

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    const mergedInvoiceLines: InvoiceLineDTO[] = [
      ...linesProfessionalfess,
      ...linesDisbursements,
      ...linesTimeEntries,
    ];

    const createInvoiceRequest: QuickInvoicesSaveData = Object.assign({}, data);
    createInvoiceRequest.totalInvoiceAmount = totalAmount;
    createInvoiceRequest.totalVatAmount = totalTaxAmount;
    createInvoiceRequest.totalAmount = subtotal;

    createInvoiceRequest.balanceDue = totalAmount;

    createInvoiceRequest.invoiceTo = invoiceTo;
    createInvoiceRequest.addressLine1 = invoiceAddressLine1;
    createInvoiceRequest.city = invoiceCity;
    createInvoiceRequest.county = invoiceCoutry;
    createInvoiceRequest.postcode = invoicePostCode;

    createInvoiceRequest.incomeAmount =
      totalProfessionalFees + totalTimeEntries;
    createInvoiceRequest.vatAmount = totalDisbursements;
    createInvoiceRequest.vatAmount = totalVal;
    createInvoiceRequest.invoiceLines = mergedInvoiceLines;
    console.log(JSON.stringify(createInvoiceRequest, null, 2));
    try {
      await dispatch(AddClientQuickInvoices(createInvoiceRequest));
      Close();
      toast.success("Successfully Added QuickInvoice");
    } catch (error: any) {
      toast.error(error.toString());
    } finally {
      setIsLoading(false);
    }
  };

  const onError = (errors: any) => {
    console.log("Form submission errors", errors);
  };

  const addProfessionalFeeRow = () => {
    const newLine: InvoiceLineDTO = {
      lineId: 0,
      amount: 0,
      lineItem: "",
      totalAmount: 0,
      vatRateId: 0,
      vatRate: 0,
      selectedTypeId: 0,
      taxAmount: 0,
      lineType: "Professional Fee",
      feeType: "",
      feeTypeId: 0,
      lineDate: new Date(),
      invoiceLineId: 0,
      quantity: 0,
      staffId: 0,
      unitPrice: 0,
      timeEntryTypeId: 0,
      elapsedMinutes: 0,
      unitsInMinutes: 0,
      lineTypeId: 0,
    };

    setLinesProfessionalfess((prev) => [...prev, newLine]);
  };

  const addDisbursementRow = () => {
    const newLine: InvoiceLineDTO = {
      lineId: 0,
      amount: 0,
      lineItem: "",
      totalAmount: 0,
      vatRateId: 0,
      vatRate: 0,
      selectedTypeId: 0,
      taxAmount: 0,
      lineType: "Disbursement",
      feeType: "",
      feeTypeId: 0,
      lineDate: new Date(),
      invoiceLineId: 0,
      quantity: 0,
      staffId: 0,
      unitPrice: 0,
      timeEntryTypeId: 0,
      elapsedMinutes: 0,
      unitsInMinutes: 0,
      lineTypeId: 0,
    };
    setLinesDisbursements((prev) => [...prev, newLine]);
  };

  const addTimeEntryRow = () => {
    const newLine: InvoiceLineDTO = {
      lineId: 0,
      amount: 0,
      lineItem: "",
      totalAmount: 0,
      vatRateId: 0,
      vatRate: 0,
      selectedTypeId: 0,
      taxAmount: 0,
      lineType: "Time Entry",
      feeType: "",
      feeTypeId: 0,
      lineDate: new Date(),
      invoiceLineId: 0,
      quantity: 0,
      staffId: 0,
      unitPrice: 0,
      timeEntryTypeId: 0,
      elapsedMinutes: 0,
      unitsInMinutes: 0,
      lineTypeId: 0,
    };
    setLinesTimeEntries((prev) => [...prev, newLine]);
  };

  const toggleDescriptionModal = () => {
    setOpenDescriptionModal(!openDescriptionModal);
  };
  const toggleProfessionalFeesModal = (index: number, type: string) => {
    setOpenProfessionalFeeModal(!openProfessionalFeeModal);
    setSelectedIndex(index);
    setSelectedType(type);
  };

  const toggleTimeEntriesModal = () => {
    setOpenTimeEntriesModal(!openTimeEntriesModal);
  };

  // const removeRow = (index: number) => {
  //   setRows(rows.filter((_, i) => i !== index));
  // };

  const removeProfessionalFeeRow = (index: number) => {
    setLinesProfessionalfess((prev) => prev.filter((_, i) => i !== index));
  };

  const removeDisbursementRow = (index: number) => {
    setLinesDisbursements((prev) => prev.filter((_, i) => i !== index));
  };

  const removeTimeEntryRow = (index: number) => {
    setLinesTimeEntries((prev) => prev.filter((_, i) => i !== index));
  };

  // const handleInputChange = (index: number, field: string, value: any) => {
  //     const updatedRows = [...rows];
  //     updatedRows[index] = {
  //         ...updatedRows[index],
  //         [field]: value,
  //     };

  //     const currentRow = updatedRows[index];

  //     // Update the amount based on quantity and unitPrice
  //     if (field === "unitPrice" || field === "quantity") {
  //       const amount = (currentRow.unitPrice || 0) * (currentRow.quantity || 1);
  //       updatedRows[index].amount = parseFloat(amount.toFixed(2));
  //     }

  //     // Update the VAT amount if the VAT rate changes
  //     if (field === "vatRate" || field === "amount") {
  //       const vatAmount = (updatedRows[index].amount * (currentRow.vatRate || 0)) / 100;
  //       updatedRows[index].taxAmount = parseFloat(vatAmount.toFixed(2));
  //     }

  //     // Update the total amount (amount + VAT)
  //     updatedRows[index].totalAmount = parseFloat(
  //       (updatedRows[index].amount + updatedRows[index].taxAmount).toFixed(2)
  //     );

  //     setRows(updatedRows);
  // };

  const handleInputChange = (
    index: number,
    field: string,
    value: any,
    type: string
  ) => {
    if (type === "Professional") {
      const updatedProRows = [...linesProfessionalfess];
      let todata = { ...updatedProRows[index], [field]: value };

      todata.amount = Number((todata.quantity * todata.unitPrice).toFixed(2));
      todata.taxAmount = Number(
        ((todata.unitPrice * todata.quantity * todata.vatRate) / 100).toFixed(2)
      );
      todata.totalAmount = Number(
        (
          todata.unitPrice * todata.quantity +
          (todata.unitPrice * todata.quantity * todata.vatRate) / 100
        ).toFixed(2)
      );
      updatedProRows[index] = todata;
      setLinesProfessionalfess(updatedProRows);
      //     const updateState = (
      //         state: InvoiceLineDTO[],
      //         setState: React.Dispatch<React.SetStateAction<InvoiceLineDTO[]>>
      //       ) => {
      //         const updatedRows = [...state];
      //         updatedRows[index] = { ...updatedRows[index], [field]: value };
      //         setState(updatedRows);
      //       };

      //   updateState(linesProfessionalfess, setLinesProfessionalfess);
    } else if (type === "Disbursement") {
      const updatedDisRows = [...linesDisbursements];
      updatedDisRows[index] = { ...updatedDisRows[index], [field]: value };
      setLinesDisbursements(updatedDisRows);
      //   updateState(linesDisbursements, setLinesDisbursements);
    } else if (type === "TimeEntry") {
      const updatedTimeRows = [...linesTimeEntries];
      updatedTimeRows[index] = { ...updatedTimeRows[index], [field]: value };
      setLinesTimeEntries(updatedTimeRows);
      //   updateState(linesTimeEntries, setLinesTimeEntries);
    }
  };

  const toggleCaseAccountModal = () => {
    setOpenCaseAccountModal(!openCaseAccountModal);
  };

  const toggleCaseClientContactModal = () => {
    setOpenCaseClientContactModal(!openCaseClientContactModal);
  };

  const handleCaseSelection = (caseReference: any) => {
    setValue("caseId", caseReference.caseId);
    setSelectedCaseId(caseReference.caseId);
    setSelectedCaseReference(caseReference);
    setSelectedMatter(caseReference.caseClients);
    setSelectedResponsipleName(caseReference.personResponsibleName);
    dispatch(fetchQuickInvoicesDataByCaseId(caseReference.caseId));
  };

  const handleCaseClientContactSelection = (caseContactClient: any) => {
    setCaseClientType(caseContactClient.cType);
    setSelectedCaseClientContact(caseContactClient);
    if (caseContactClient != null) {
      if (caseContactClient.clientType == "Individual") {
        let invoiceto =
          caseContactClient.title +
          caseContactClient.givenNames +
          " " +
          caseContactClient.lastName;
        setInvoiceTo(invoiceto);
        setInvoiceAddressLine1(caseContactClient.currentAddLine1);
        setInvoiceCity(caseContactClient.currentAddCity);
        setInvoiceCoutry(caseContactClient.currentAddCounty);
        setInvoicePostCode(caseContactClient.currentAddPostCode);
      } else if (caseContactClient.clientType === "Organisation") {
        setInvoiceTo(caseContactClient.orgName);
        setInvoiceAddressLine1(caseContactClient.orgCurrentAddLine1);
        setInvoiceCity(caseContactClient.orgCurrentAddCity);
        setInvoiceCoutry(caseContactClient.orgCurrentAddCounty);
        setInvoicePostCode(caseContactClient.orgCurrentAddPostCode);
      } else {
        setInvoiceTo(caseContactClient.companyName);
        setInvoiceAddressLine1(caseContactClient.compCurrentAddLine1);
        setInvoiceCity(caseContactClient.currentAddCity);
        setInvoiceCoutry(caseContactClient.currentAddCounty);
        setInvoicePostCode(caseContactClient.currentAddPostCode);
      }
    }
    //console.log(caseContactClient);
    // console.log(caseContactClient)
    // setValue("caseId", caseContactClient.caseId)
    // setSelectedCaseClientId(caseContactClient.caseId)

    // setSelectedMatter(`${caseContactClient.caseName}`);
    //setSelectedResponsipleName(caseContactClient.personResponsibleName);
  };

  const handleTimeEntrySelection = (timeEntry: any) => {
    console.log(timeEntry);
    // setValue("caseId", caseReference.caseId)
    // setSelectedCaseId(caseReference.caseId)
    // setSelectedCaseReference(caseReference);
    if (timeEntry !== null) {
      setTimeEntryName(timeEntry.timeEntryType);
      setTimeEntryId(timeEntry.timeEntryTypeId);
    }
  };

  useEffect(() => {
    if (caseClient !== null && caseClient.caseClients.length > 0) {
      let data = caseClient.caseClients.map((client: any) => ({
        value: client.individualClientId,
        label: `${client.givenNames} - ${client.lastName}`,
      }));
      setcaseClientLists(data);
    }
  }, [caseClient]);

  const handleFeeSelect = (value: any) => {
    if (value != null && value.selectedType === "feeType") {
      const updatedProRows = [...linesProfessionalfess];
      updatedProRows[selectedIndex] = {
        ...updatedProRows[selectedIndex],
        lineItem: value.data.feeType,
        lineTypeId: value.data.feeTypeId,
      };
      setLinesProfessionalfess(updatedProRows);
    } else {
      toast.error("not selected Fee Type");
    }
  };

  const handleDisbursmentSelect = (value: any) => {
    console.log(value);
    if (value != null && value.selectedType === "disbursement") {
      const updateddisRows = [...linesDisbursements];
      updateddisRows[selectedIndex] = {
        ...updateddisRows[selectedIndex],
        lineItem: value.data.disbursementType,
        lineTypeId: value.data.disbursementTypeId,
      };
      setLinesDisbursements(updateddisRows);
    } else {
      toast.error("not selected disbursement Type");
    }
  };

  const handleVatRateChange = (
    index: number,
    selectedOption: any,
    type: string
  ) => {
    if (selectedOption) {
      switch (type) {
        case "Disbursement":
          const updateddisRows = [...linesDisbursements];
          updateddisRows[index] = {
            ...updateddisRows[index],
            vatRate: selectedOption.percentage,
            vatRateId: selectedOption.vatTypeId,
          };
          setLinesDisbursements(updateddisRows);

          break;
        case "Professional":
          const updatedRows = [...linesProfessionalfess];
          updatedRows[index] = {
            ...updatedRows[index],
            vatRate: selectedOption.percentage,
            vatRateId: selectedOption.vatTypeId,
          };

          setLinesProfessionalfess(updatedRows);

          break;
        case "TimeEntry":
          const updatedTimeRows = [...linesTimeEntries];
          updatedTimeRows[index] = {
            ...updatedTimeRows[index],
            vatRate: selectedOption.percentage,
            vatRateId: selectedOption.vatTypeId,
          };

          setLinesTimeEntries(updatedTimeRows);
          break;

        default:
          break;
      }
    }
  };

  const convertMinutesToTime = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${String(hours).padStart(2, "0")}:${String(mins).padStart(2, "0")}`;
  };

  // Converts "HH:mm" format to minutes
  const convertTimeToMinutes = (time: any) => {
    const [hours, mins] = time.split(":").map(Number);
    return hours * 60 + mins;
  };

  // Handles the time change event
  const handleTimeChange = (index: number, time: any) => {
    const minutes = convertTimeToMinutes(time);
    handleInputChange(index, "elapsedMinutes", minutes, "TimeEntry");
  };

  useEffect(() => {
    if (quickInvoicesDataByCase != null) {
      //  console.log(quickInvoicesDataByCase);
      setDisbursementAccountId(quickInvoicesDataByCase.disburseAccountId);
      setVatOutAccountId(quickInvoicesDataByCase.vatAccountId);
      setIncomeAccountId(quickInvoicesDataByCase.incomeAccountId);

      //   if (
      //     quickInvoicesDataByCase.linesProfessionCharges != null &&
      //     quickInvoicesDataByCase.linesProfessionCharges.length > 0
      //   ) {
      //     let presult = quickInvoicesDataByCase.linesProfessionCharges.map(
      //       (item: any) => {
      //         let resdata: InvoiceLineDTO = {
      //           lineId: 0,
      //           amount: item.amount,
      //           lineItem: item.billingDesc,
      //           totalAmount: item.totalAmount,
      //           vatRateId: item.fkTaxId,
      //           vatRate: item.vatRate,
      //           taxAmount: item.tax,
      //           lineType: "Professional Fee",
      //           feeType: item.feetype,
      //           feeTypeId: item.fkFeeTypeId,
      //           lineDate: item.feeDate,
      //           invoiceLineId: 0,
      //           quantity: item.quantity,
      //           staffId: item.fkStaffId,
      //          // staff: item.staff,
      //          description:item.description,
      //           unitPrice: item.unitPrice,
      //           timeEntryTypeId: item.fkFeeTypeId,
      //           elapsedMinutes: 0,
      //           unitsInMinutes: 0,
      //           lineTypeId: item.fkFeeTypeId,
      //         };
      //         return resdata;
      //       }
      //     );
      //     setLinesProfessionalfess([...linesProfessionalfess, ...presult]);
      //   }

      //   if (
      //     quickInvoicesDataByCase.linesDisbursements != null &&
      //     quickInvoicesDataByCase.linesDisbursements.length > 0
      //   ) {
      //     let presult = quickInvoicesDataByCase.linesDisbursements.map(
      //       (item: any) => {
      //         let resdata: InvoiceLineDTO = {
      //           lineId: 0,
      //           amount: item.amount,
      //           lineItem: item.billingDesc,
      //           totalAmount: item.totalAmount,
      //           vatRateId: item.fkTaxId,
      //           vatRate: item.vatRate,
      //           taxAmount: item.tax,
      //           lineType: "Professional Fee",
      //           feeType: item.feetype,
      //           feeTypeId: item.fkFeeTypeId,
      //           lineDate: item.feeDate,
      //           invoiceLineId: 0,
      //           quantity: item.quantity,
      //           staffId: item.fkStaffId,
      //          // staff: item.staff,
      //          description:item.description,
      //           unitPrice: item.unitPrice,
      //           timeEntryTypeId: item.fkFeeTypeId,
      //           elapsedMinutes: 0,
      //           unitsInMinutes: 0,
      //           lineTypeId: item.fkFeeTypeId,
      //         };
      //         return resdata;
      //       }
      //     );
      //     setLinesDisbursements([...linesDisbursements, ...presult]);
      //   }

      //   if (
      //     quickInvoicesDataByCase.linesTimeEntries != null &&
      //     quickInvoicesDataByCase.linesTimeEntries.length > 0
      //   ) {
      //     let presult = quickInvoicesDataByCase.linesTimeEntries.map(
      //       (item: any) => {
      //         let resdata: InvoiceLineDTO = {
      //           lineId: 0,
      //           amount: item.amount,
      //           lineItem: item.billingDesc,
      //           totalAmount: item.totalAmount,
      //           vatRateId: item.fkTaxId,
      //           vatRate: item.vatRate,
      //           taxAmount: item.tax,
      //           lineType: "Professional Fee",
      //           feeType: item.feetype,
      //           feeTypeId: item.fkFeeTypeId,
      //           lineDate: item.feeDate,
      //           invoiceLineId: 0,
      //           quantity: item.quantity,
      //           staffId: item.fkStaffId,
      //          // staff: item.staff,
      //          description:item.description,
      //           unitPrice: item.unitPrice,
      //           timeEntryTypeId: item.fkFeeTypeId,
      //           elapsedMinutes: 0,
      //           unitsInMinutes: 0,
      //           lineTypeId: item.fkFeeTypeId,
      //         };
      //         return resdata;
      //       }
      //     );
      //     setLinesTimeEntries([...linesTimeEntries, ...presult]);
      //   }
    }
  }, [quickInvoicesDataByCase]);

  useEffect(() => {
    const professionalFeesTotal = linesProfessionalfess.reduce(
      (sum, line) => sum + line.amount,
      0
    );
    const disbursementsTotal = linesDisbursements.reduce(
      (sum, line) => sum + line.amount,
      0
    );
    const timeEntriesTotal = linesTimeEntries.reduce(
      (sum, line) => sum + line.amount,
      0
    );

    setTotalProfessionalFees(professionalFeesTotal);
    setTotalDisbursements(disbursementsTotal);
    setTotalTimeEntries(timeEntriesTotal);

    const overallTotalVal =
      professionalFeesTotal + disbursementsTotal + timeEntriesTotal;
    setTotalVal(overallTotalVal);

    // Calculate subtotal (total amount before VAT)
    const subtotalAmount =
      linesProfessionalfess.reduce((sum, line) => sum + line.amount, 0) +
      linesDisbursements.reduce((sum, line) => sum + line.amount, 0) +
      linesTimeEntries.reduce((sum, line) => sum + line.amount, 0);

    setSubtotal(subtotalAmount);

    const totalTaxAmount =
      linesProfessionalfess.reduce((sum, line) => sum + line.taxAmount, 0) +
      linesDisbursements.reduce((sum, line) => sum + line.taxAmount, 0) +
      linesTimeEntries.reduce((sum, line) => sum + line.taxAmount, 0);

    setTotalTaxAmount(totalTaxAmount);

    // Calculate totalAmount (subtotal + VAT)
    const totalAmountWithTax =
      subtotalAmount +
      linesProfessionalfess.reduce((sum, line) => sum + line.taxAmount, 0) +
      linesDisbursements.reduce((sum, line) => sum + line.taxAmount, 0) +
      linesTimeEntries.reduce((sum, line) => sum + line.taxAmount, 0);

    setTotalAmount(totalAmountWithTax);
  }, [linesProfessionalfess, linesDisbursements, linesTimeEntries]);

  return (
    <>
      {openModal !== false && (
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="flex flex-col w-full px-[70px] pb-70 lg:p-[10px] xl:p-[20px]">
            <div className="">
              <SubHeaderTitle title="Add Quick Invoice" />
            </div>
            <div className="flex justify-between lg:gap-x-10 p-10 lg:p-0">
              <div>
                <div className="">
                  <img src="" alt="Image" />
                </div>
                <div className="flex flex-col gap-3 justify-start pt-44">
                  <div className="relative flex w-72">
                    <input
                      type="search"
                      className="relative m-0 -me-0.5 block flex-auto rounded-s border border-solid border-neutral-200 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-surface outline-none transition duration-200 ease-in-out placeholder:text-neutral-500 focus:z-[3] focus:border-primary focus:shadow-inset focus:outline-none motion-reduce:transition-none dark:border-white/10 dark:text-white dark:placeholder:text-neutral-600 dark:autofill:shadow-autofill dark:focus:border-primary disabled:bg-gray-300"
                      placeholder="Case Reference"
                      aria-label="Search"
                      disabled
                      value={selectedCaseReference?.caseReferenceAuto || ""}
                    />
                    <button
                      onClick={toggleCaseAccountModal}
                      className="z-[2] inline-block rounded-e border-2 border-primary px-6 pb-[6px] pt-2 text-xs font-medium  leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-accent-300 hover:bg-primary-50/50 hover:text-primary-accent-300 focus:border-primary-600 focus:bg-primary-50/50 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:text-primary-500 bg-hover_bg text-white-bg border-hover_bg"
                      data-twe-ripple-init
                      data-twe-ripple-color="white"
                      type="button"
                    >
                      Search
                    </button>
                    {openCaseAccountModal && (
                      <SearchCaseReferenceDetails
                        openDrawer={openCaseAccountModal}
                        Close={toggleCaseAccountModal}
                        onCaseSelectDetail={handleCaseSelection}
                      />
                    )}
                  </div>
                  <div className="relative flex gap-2">
                    <div className="relative flex w-72">
                      <input
                        type="search"
                        className="relative m-0 -me-0.5 block flex-auto rounded-s border border-solid border-neutral-200 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-surface outline-none transition duration-200 ease-in-out placeholder:text-neutral-500 focus:z-[3] focus:border-primary focus:shadow-inset focus:outline-none motion-reduce:transition-none dark:border-white/10 dark:text-white dark:placeholder:text-neutral-600 dark:autofill:shadow-autofill dark:focus:border-primary disabled:bg-gray-300"
                        placeholder="Case Client Contact"
                        aria-label="Search"
                        disabled
                        value={invoiceTo}
                      // value={
                      //   caseClientType === "client"
                      //     ? `${selectedCaseClientContact?.givenNames ?? ""} ${
                      //         selectedCaseClientContact?.lastName ?? ""
                      //       }`
                      //     : `${selectedCaseClientContact?.companyName ?? ""} ${
                      //         selectedCaseClientContact?.contactCategoryCompany ??
                      //         ""
                      //       }`
                      // }
                      />
                      <button
                        onClick={toggleCaseClientContactModal}
                        className="z-[2] inline-block rounded-e border-2 border-primary px-6 pb-[6px] pt-2 text-xs font-medium  leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-accent-300 hover:bg-primary-50/50 hover:text-primary-accent-300 focus:border-primary-600 focus:bg-primary-50/50 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:text-primary-500 bg-hover_bg text-white-bg border-hover_bg"
                        data-twe-ripple-init
                        data-twe-ripple-color="white"
                        type="button"
                      >
                        Search
                      </button>
                      {openCaseClientContactModal &&
                        selectedCaseId !== undefined && (
                          <SearchCaseClientContact
                            openDrawer={openCaseClientContactModal}
                            Close={toggleCaseClientContactModal}
                            onCaseSelectDetail={handleCaseClientContactSelection}
                            caseId={selectedCaseId}
                          />
                        )}
                    </div>
                  </div>
                  <div className="relative flex gap-2">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                      Matter
                    </label>
                    <input
                      type="text"
                      className={` border-none text-xs rounded-md block w-auto p-3 dark:bg-gray-700 dark:text-white-bg`}
                      disabled
                      value={selectedMatter}
                    />
                  </div>
                  <div className="relative flex gap-2">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                      Person Responsible
                    </label>
                    <input
                      type="text"
                      className={` border-none text-xs rounded-md block w-auto p-3 dark:bg-gray-700 dark:text-white-bg`}
                      value={selectedResponsipleName}
                      disabled
                    />
                  </div>
                  <div className="relative flex gap-2">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                      Memo
                    </label>
                    <input
                      type="text"
                      className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-72 lg:w-52 p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                      {...register("memo")}
                    />
                  </div>
                </div>
              </div>
              <div className="space-y-5 my-5">
                <div className="text-sm text-right">
                  <p className="font-medium">Shan & Co Solicitors</p>
                  <p>189 Northolt Road, Harrow, Middlesex</p>
                  <p>HA2 0LY</p>
                </div>
                <div className="space-y-2 text-right">
                  <div className="flex items-center">
                    <label className="w-1/4 text-sm font-semibold dark:text-white-bg">
                      Phone
                    </label>
                    <span className="mx-2 text-sm font-semibold dark:text-white-bg">
                      :
                    </span>
                    <p className="w-3/4 text-sm dark:text-white-bg">
                      +44 (0) 2088647070
                    </p>
                  </div>
                  <div className="flex items-center">
                    <label className="w-1/4 text-sm font-semibold dark:text-white-bg">
                      Fax
                    </label>
                    <span className="mx-2 text-sm font-semibold dark:text-white-bg">
                      :
                    </span>
                    <p className="w-3/4 text-sm dark:text-white-bg">
                      +44 (0) 18 9922 5284
                    </p>
                  </div>
                  <div className="flex items-center">
                    <label className="w-1/4 text-sm font-semibold dark:text-white-bg">
                      Email
                    </label>
                    <span className="mx-2 text-sm font-semibold dark:text-white-bg">
                      :
                    </span>
                    <p className="w-3/4 text-sm dark:text-white-bg">
                      shan@laitlegal.com
                    </p>
                  </div>
                </div>
                <div className="space-y-2 mt-3">
                  <p className="text-lg font-bold">Tax Invoice</p>
                  <div className="flex items-center">
                    <label className="w-1/2 text-sm font-semibold dark:text-white-bg">
                      VAT NO
                    </label>
                    <span className="mx-2 text-sm font-semibold dark:text-white-bg">
                      :
                    </span>
                    <p className="w-1/2 text-sm dark:text-white-bg">
                      210118825
                    </p>
                  </div>
                </div>
                <div className="space-y-2">
                  <div className="flex items-center">
                    <label className="w-1/2 text-sm font-semibold dark:text-white-bg">
                      Invoice
                    </label>
                    <span className="mx-2 text-sm font-semibold dark:text-white-bg">
                      :
                    </span>
                    <div className="w-1/2">
                      <input
                        type="text"
                        className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-28 p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                        value={autoReceiptNumber}
                      />
                    </div>
                  </div>
                  <div className="flex items-center">
                    <label className="w-1/2 text-sm font-semibold dark:text-white-bg">
                      Invoice Date
                    </label>
                    <span className="mx-2 text-sm font-semibold dark:text-white-bg">
                      :
                    </span>
                    <div className="w-1/2">
                      <input
                        type="date"
                        className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-28 p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                        max={today}
                        {...register("invoiceDate")}
                      />
                    </div>
                  </div>
                  <div className="flex items-center">
                    <label className="w-1/2 text-sm font-semibold dark:text-white-bg">
                      Due Date
                    </label>
                    <span className="mx-2 text-sm font-semibold dark:text-white-bg">
                      :
                    </span>
                    <div className="w-1/2">
                      <input
                        type="date"
                        className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-28 p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                        {...register("dueDate")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Heading title={"Professional Fees"} />

            <div className="relative overflow-x-auto h-auto sm:rounded-lg mt-2">
              <table className="w-full text-xs text-black text-left rtl:text-right dark:text-gray-400">
                <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                  <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <th scope="col" className="p-3"></th>
                    <th scope="col" className="p-3">
                      Item Description
                    </th>
                    <th scope="col" className="p-3">
                      Date
                    </th>
                    <th scope="col" className="p-3">
                      Staff
                    </th>
                    <th scope="col" className="p-3">
                      Unit Price
                    </th>
                    <th scope="col" className="p-3">
                      Qty
                    </th>
                    <th scope="col" className="p-3">
                      Amount
                    </th>
                    <th scope="col" className="p-3">
                      Tax
                    </th>
                    <th scope="col" className="p-3">
                      Tax Amount
                    </th>
                    <th scope="col" className="p-3">
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {linesProfessionalfess &&
                    linesProfessionalfess.map((row, index) => (
                      <tr
                        key={index}
                        className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                      >
                        <td className="cursor-pointer p-3">
                          <button
                            type="button"
                            onClick={() => {
                              toggleProfessionalFeesModal(
                                index,
                                "professional"
                              );
                            }}
                          >
                            <FcPlus />
                          </button>
                          {openProfessionalFeeModal && (
                            <AddOfficeFeeTypesDisbursement
                              openDrawer={openProfessionalFeeModal}
                              Close={() =>
                                toggleProfessionalFeesModal(
                                  index,
                                  "professional"
                                )
                              }
                              onFeeOrDisbursmentSelect={handleFeeSelect}
                            />
                          )}
                        </td>
                        <td className="p-3">
                          <InputBox
                            dataType={"text"}
                            placeholder={"Enter title"}
                            disabled
                            value={row.lineItem}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "lineItem",
                                e.target.value,
                                "professional"
                              )
                            }
                          />
                        </td>
                        <td className="p-3">
                          <input
                            type="date"
                            placeholder={"Enter date"}
                            className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-auto p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                            value={
                              row.lineDate
                                ? row.lineDate.toISOString().slice(0, 10)
                                : ""
                            }
                            max={today}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "lineDate",
                                e.target.value,
                                "Professional"
                              )
                            }
                          />
                        </td>
                        <td className="p-3">
                          <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md block w-28 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            isMulti={false}
                            name="staff"
                            isSearchable={true}
                            placeholder="Select"
                            options={branchUserLists}
                            value={getValues().staff}
                            onChange={(selectedOption) =>
                              handleInputChange(
                                index,
                                "staffId",
                                selectedOption,
                                "Professional"
                              )
                            }
                          />
                        </td>
                        <td className="p-3">
                          <NumericFormat
                            value={row.unitPrice}
                            className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-24 p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 text-right`}
                            placeholder="Unit Price"
                            decimalScale={2}
                            onValueChange={(values) =>
                              handleInputChange(
                                index,
                                "unitPrice",
                                values.floatValue || 0,
                                "Professional"
                              )
                            }
                          />
                        </td>
                        <td className="p-3">
                          <NumericFormat
                            value={row.quantity}
                            className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-24 p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 text-right`}
                            placeholder="Quantity"
                            decimalScale={0}
                            allowNegative={false}
                            onValueChange={(values) =>
                              handleInputChange(
                                index,
                                "quantity",
                                values.floatValue || 1,
                                "Professional"
                              )
                            }
                          />
                        </td>
                        <td className="p-3">
                          <NumericFormat
                            value={row.amount}
                            className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-24 p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 text-right`}
                            placeholder="Amount"
                            decimalScale={0}
                            allowNegative={false}
                            onValueChange={(values) => {
                              // handleInputChange(
                              //     index,
                              //     "amount",
                              //     (values.floatValue || 0),
                              //     "Professional")
                            }}
                          />
                        </td>
                        <td className="p-3">
                          <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md block w-32 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                              <svg
                                width="15"
                                className="fill-current mr-2"
                                height="15"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                              >
                                <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5 0 45.3l160 160z" />
                              </svg>
                            }
                            isMulti={false}
                            name={`vatRate-${index}`}
                            isSearchable={true}
                            options={officeVatRateLists}
                            value={
                              officeVatRateLists.find(
                                (option) => option.vatTypeId === row.vatRateId
                              ) || null
                            }
                            placeholder="Select VAT Type"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                            onChange={(selectedOption) =>
                              handleVatRateChange(
                                index,
                                selectedOption,
                                "Professional"
                              )
                            }
                          />
                        </td>
                        <td className="p-3">
                          <NumericFormat
                            value={row.taxAmount}
                            className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-24 p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 text-right`}
                            displayType="text"
                            thousandSeparator={true}
                            prefix="£"
                          // onValueChange={(values) =>
                          // //   handleInputChange(
                          // //     index,
                          // //     "taxAmount",
                          // //     values.floatValue || 0,
                          // //     "Professional"
                          // //   )
                          // }
                          />
                        </td>
                        <td className="p-3">
                          <NumericFormat
                            value={row.totalAmount}
                            className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-24 p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 text-right`}
                            displayType="text"
                            thousandSeparator={true}
                            prefix="£"
                          // onValueChange={(values) =>
                          //   handleInputChange(
                          //     index,
                          //     "totalAmount",
                          //     values.floatValue || 0,
                          //     "Professional"
                          //   )
                          // }
                          />
                        </td>

                        <td className="p-3">
                          <button
                            type="button"
                            onClick={() => removeProfessionalFeeRow(index)}
                          >
                            <FcCancel className="w-6 h-6" />
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="mb-5">
              <button
                className="flex items-center bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300 mt-1"
                type="button"
                onClick={addProfessionalFeeRow}
              >
                <FaPlus className="w-4 h-4 fill-white-bg" />
                <span className="text-white-bg mx-2">Add Line</span>
              </button>
            </div>

            <Heading title={"Disbursements"} />
            <div className="relative overflow-x-auto sm:rounded-lg mt-2">
              <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                  <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <th scope="col" className="p-3"></th>
                    <th scope="col" className="p-3">
                      Item Description
                    </th>
                    <th scope="col" className="p-3">
                      Date
                    </th>
                    <th scope="col" className="p-3">
                      Staff
                    </th>
                    <th scope="col" className="p-3">
                      Unit Price
                    </th>
                    <th scope="col" className="p-3">
                      Qty
                    </th>
                    <th scope="col" className="p-3">
                      Amount
                    </th>
                    <th scope="col" className="p-3">
                      Tax
                    </th>
                    <th scope="col" className="p-3">
                      Tax Amount
                    </th>
                    <th scope="col" className="p-3">
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {linesDisbursements &&
                    linesDisbursements.map((row, index) => (
                      <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        <td className="cursor-pointer p-3">
                          <button
                            type="button"
                            onClick={toggleDescriptionModal}
                          >
                            <FcPlus />
                          </button>
                          {openDescriptionModal && (
                            // <AddInvoiceDisbursements openDrawer={openDescriptionModal} Close={toggleDescriptionModal} />
                            <AddOfficeFeeTypesDisbursement
                              openDrawer={openDescriptionModal}
                              Close={() => toggleDescriptionModal()}
                              onFeeOrDisbursmentSelect={handleDisbursmentSelect}
                            />
                          )}
                        </td>
                        <td className="p-3">
                          <InputBox
                            dataType={"string"}
                            placeholder={"Enter title"}
                            value={row.lineItem}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "lineItem",
                                e.target.value,
                                "Disbursement"
                              )
                            }
                          />
                        </td>
                        <td className="p-3">
                          {/* <InputBox dataType={"date"} 
                          placeholder={"date"}
                          value={row.lineDate.toDateString()}
                          onChange={(e) => handleInputChange(index, 'lineDate', e.target.value, "Disbursement")}
                       /> */}
                        </td>
                        <td className="p-3">
                          <div className="">
                            <div className="mb-2"></div>
                            <SelectBox
                              className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md block w-28 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                              indicator={
                                <svg
                                  width="15"
                                  className="fill-current mr-2"
                                  height="15"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 448 512"
                                >
                                  <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                              }
                              isMulti={false}
                              name="frameFiftySix"
                              isSearchable={true}
                              placeholder="Select"
                              shape="round"
                              color="indigo_50"
                              size="xs"
                              options={branchUserLists}
                              value={getValues().staff}
                              onChange={(selectedOption) =>
                                handleInputChange(
                                  index,
                                  "staffId",
                                  selectedOption,
                                  "Disbursement"
                                )
                              }
                            />
                          </div>
                        </td>
                        <td className="p-3">
                          <NumericFormat
                            value={row.unitPrice}
                            className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-24 p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 text-right`}
                            placeholder="Unit Price"
                            decimalScale={2}
                            onValueChange={(values) =>
                              handleInputChange(
                                index,
                                "unitPrice",
                                values.floatValue || 0,
                                "Professional"
                              )
                            }
                          />
                        </td>
                        <td className="p-3">
                          <NumericFormat
                            value={row.quantity}
                            className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-24 p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 text-right`}
                            placeholder="Quantity"
                            decimalScale={0}
                            allowNegative={false}
                            onValueChange={(values) =>
                              handleInputChange(
                                index,
                                "quantity",
                                values.floatValue || 1,
                                "Professional"
                              )
                            }
                          />
                        </td>
                        <td className="p-3">
                          <NumericFormat
                            value={row.unitPrice * row.quantity}
                            className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-24 p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 text-right`}
                            placeholder="Quantity"
                            decimalScale={0}
                            allowNegative={false}
                            onValueChange={(values) =>
                              handleInputChange(
                                index,
                                "amount",
                                values.floatValue || 0,
                                "Professional"
                              )
                            }
                          />
                        </td>
                        <td className="p-3">
                          <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                              <svg
                                width="15"
                                className="fill-current mr-2"
                                height="15"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                              >
                                <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5 0 45.3l160 160z" />
                              </svg>
                            }
                            isMulti={false}
                            name={`vatRate-${index}`}
                            isSearchable={true}
                            options={officeVatRateLists}
                            value={
                              officeVatRateLists.find(
                                (option) => option.vatTypeId === row.vatRateId
                              ) || null
                            }
                            placeholder="Select VAT Type"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                            onChange={(selectedOption) =>
                              handleVatRateChange(
                                index,
                                selectedOption,
                                "Professional"
                              )
                            }
                          />
                        </td>
                        <td className="p-3">
                          <NumericFormat
                            value={(
                              (row.unitPrice * row.quantity * row.vatRate) /
                              100
                            ).toFixed(2)}
                            className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-24 p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 text-right`}
                            displayType="text"
                            thousandSeparator={true}
                            prefix="£"
                            onValueChange={(values) =>
                              handleInputChange(
                                index,
                                "taxAmount",
                                values.floatValue || 0,
                                "Professional"
                              )
                            }
                          />
                        </td>
                        <td className="p-3">
                          <NumericFormat
                            value={(
                              row.unitPrice * row.quantity +
                              (row.unitPrice * row.quantity * row.vatRate) / 100
                            ).toFixed(2)}
                            className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-24 p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 text-right`}
                            displayType="text"
                            thousandSeparator={true}
                            prefix="£"
                            onValueChange={(values) =>
                              handleInputChange(
                                index,
                                "totalAmount",
                                values.floatValue || 0,
                                "Professional"
                              )
                            }
                          />
                        </td>
                        <td className="p-3">
                          <button
                            type="button"
                            onClick={() => removeDisbursementRow(index)}
                          >
                            <FcCancel className="w-6 h-6" />
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="mb-5">
              <button
                className="flex items-center bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300 mt-1"
                type="button"
                onClick={addDisbursementRow}
              >
                <FaPlus className="w-4 h-4 fill-white-bg" />
                <span className="text-white-bg mx-2">Add Line</span>
              </button>
            </div>

            <Heading title={"Time Entries"} />
            <div className="relative overflow-x-auto sm:rounded-lg mt-2">
              <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                  <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <th scope="col" className="p-3"></th>
                    <th scope="col" className="p-3">
                      Item Description
                    </th>
                    <th scope="col" className="p-3">
                      Date
                    </th>
                    <th scope="col" className="p-3">
                      Staff
                    </th>
                    <th scope="col" className="p-3">
                      Elapsed Time
                    </th>
                    <th scope="col" className="p-3">
                      Unit Price
                    </th>
                    <th scope="col" className="p-3">
                      Units
                    </th>
                    <th scope="col" className="p-3">
                      Amount
                    </th>
                    <th scope="col" className="p-3">
                      Tax
                    </th>
                    <th scope="col" className="p-3">
                      Tax Amount
                    </th>
                    <th scope="col" className="p-3">
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {linesTimeEntries &&
                    linesTimeEntries.map((row, index) => (
                      <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                        <td className="cursor-pointer p-3">
                          <button
                            type="button"
                            onClick={toggleTimeEntriesModal}
                          >
                            <FcPlus />
                          </button>
                          {openTimeEntriesModal && (
                            <AddInvoiceTimeEntries
                              openDrawer={openTimeEntriesModal}
                              Close={toggleTimeEntriesModal}
                              onTimeEntrySelectDetail={handleTimeEntrySelection}
                            />
                          )}
                        </td>
                        <td className="p-3">
                          <InputBox
                            dataType={"string"}
                            placeholder={"Enter title"}
                            value={row.lineItem}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "lineItem",
                                e.target.value,
                                "TimeEntry"
                              )
                            }
                          />
                        </td>
                        <td className="p-3">
                          {/* <InputBox dataType={"date"} 
                          value={row.lineDate.toDateString()}
                          placeholder={"date"}
                          onChange={(e) => handleInputChange(index, 'lineDate', e.target.value, "TimeEntry")}
                       /> */}
                        </td>
                        <td className="p-3">
                          <div className="">
                            <div className="mb-2"></div>
                            <SelectBox
                              className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md block w-28 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                              indicator={
                                <svg
                                  width="15"
                                  className="fill-current mr-2"
                                  height="15"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 448 512"
                                >
                                  <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                              }
                              isMulti={false}
                              name="frameFiftySix"
                              isSearchable={true}
                              placeholder="Select"
                              shape="round"
                              color="indigo_50"
                              size="xs"
                              options={branchUserLists}
                              value={getValues().staff}
                              onChange={(selectedOption) =>
                                handleInputChange(
                                  index,
                                  "staffId",
                                  selectedOption,
                                  "TimeEntry"
                                )
                              }
                            />
                          </div>
                        </td>
                        <td className="p-3">
                          <input
                            type="time"
                            value={convertMinutesToTime(row.elapsedMinutes)}
                            className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-28 p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                            onChange={(e) =>
                              handleTimeChange(index, e.target.value)
                            }
                          />
                        </td>
                        <td className="p-3">
                          <NumericFormat
                            value={row.unitPrice}
                            className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-24 p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 text-right`}
                            placeholder="Quantity"
                            decimalScale={0}
                            allowNegative={false}
                            onValueChange={(values) =>
                              handleInputChange(
                                index,
                                "unitPrice",
                                values.floatValue || 1,
                                "Professional"
                              )
                            }
                          />
                        </td>
                        <td className="p-3">
                          <NumericFormat
                            value={row.quantity}
                            className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-24 p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 text-right`}
                            placeholder="Quantity"
                            decimalScale={0}
                            allowNegative={false}
                            onValueChange={(values) =>
                              handleInputChange(
                                index,
                                "quantity",
                                values.floatValue || 0,
                                "Professional"
                              )
                            }
                          />
                        </td>
                        <td className="p-3">
                          <NumericFormat
                            value={(row.unitPrice * row.quantity).toFixed(2)}
                            className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-24 p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 text-right`}
                            displayType="text"
                            thousandSeparator={true}
                            prefix="£"
                            onValueChange={(values) =>
                              handleInputChange(
                                index,
                                "amount",
                                values.floatValue || 0,
                                "Professional"
                              )
                            }
                          />
                        </td>
                        <td className="p-3">
                          <SelectBox
                            className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md block w-32 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                              <svg
                                width="15"
                                className="fill-current mr-2"
                                height="15"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                              >
                                <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5 0 45.3l160 160z" />
                              </svg>
                            }
                            isMulti={false}
                            name={`vatRate-${index}`}
                            isSearchable={true}
                            options={officeVatRateLists}
                            value={
                              officeVatRateLists.find(
                                (option) => option.vatTypeId === row.vatRateId
                              ) || null
                            }
                            placeholder="Select VAT Type"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                            onChange={(selectedOption) =>
                              handleVatRateChange(
                                index,
                                selectedOption,
                                "TimeEntry"
                              )
                            }
                          />
                        </td>

                        <td className="p-3">
                          <NumericFormat
                            value={(
                              (row.unitPrice * row.quantity * row.vatRate) /
                              100
                            ).toFixed(2)}
                            className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-24 p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 text-right`}
                            displayType="text"
                            thousandSeparator={true}
                            prefix="£"
                            onValueChange={(values) =>
                              handleInputChange(
                                index,
                                "taxAmount",
                                values.floatValue || 0,
                                "Professional"
                              )
                            }
                          />
                        </td>
                        <td className="p-3">
                          <NumericFormat
                            value={(
                              row.unitPrice * row.quantity +
                              (row.unitPrice * row.quantity * row.vatRate) / 100
                            ).toFixed(2)}
                            className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-24 p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 text-right`}
                            displayType="text"
                            thousandSeparator={true}
                            prefix="£"
                            onValueChange={(values) =>
                              handleInputChange(
                                index,
                                "totalAmount",
                                values.floatValue || 0,
                                "Professional"
                              )
                            }
                          />
                        </td>
                        <td className="p-3">
                          <button
                            type="button"
                            onClick={() => removeTimeEntryRow(index)}
                          >
                            <FcCancel className="w-6 h-6" />
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            <div className="mb-5">
              <button
                className="flex items-center bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300 mt-1"
                type="button"
                onClick={addTimeEntryRow}
              >
                <FaPlus className="w-4 h-4 fill-white-bg" />
                <span className="text-white-bg mx-2">Add Line</span>
              </button>
            </div>
            <div className="w-1/2 ml-auto space-y-2 my-5 p-6">
              {" "}
              {/* Container aligned to the right */}
              <div className="flex justify-between items-center">
                <label className="text-sm font-semibold dark:text-white-bg">
                  Sub Total
                </label>
                <div className="flex items-center">
                  <span className="text-sm font-semibold dark:text-white-bg mx-1">
                    :
                  </span>
                  <p className="text-sm dark:text-white-bg text-right">
                    £{subtotal.toFixed(2)}
                  </p>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <label className="text-sm font-semibold dark:text-white-bg">
                  VAT
                </label>
                <div className="flex items-center">
                  <span className="text-sm font-semibold dark:text-white-bg mx-1">
                    :
                  </span>
                  <p className="text-sm dark:text-white-bg text-right">
                    £{totalTaxAmount.toFixed(2)}
                  </p>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <label className="text-sm font-semibold dark:text-white-bg">
                  Total Amount
                </label>
                <div className="flex items-center">
                  <span className="text-sm font-semibold dark:text-white-bg mx-1">
                    :
                  </span>
                  <p className="text-sm dark:text-white-bg text-right">
                    £{totalAmount.toFixed(2)}
                  </p>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <label className="text-sm font-bold dark:text-white-bg">
                  Less Credit to Invoice
                </label>
                <div className="flex items-center">
                  <span className="text-sm font-bold dark:text-white-bg mx-1">
                    :
                  </span>
                  <p className="text-sm font-bold dark:text-white-bg text-right">
                    £{lessCreditInvoice.toFixed(2)}
                  </p>
                </div>
              </div>
              <div className="flex justify-between items-center border-t-2 pt-2">
                <label className="text-sm font-bold dark:text-white-bg">
                  Remaining Credit
                </label>
                <div className="flex items-center">
                  <span className="text-sm font-bold dark:text-white-bg mx-1">
                    :
                  </span>
                  <p className="text-sm font-bold dark:text-white-bg text-right">
                    {/* £{remainingCredit.toFixed(2)} */}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-col w-full gap-3 my-5">
              <Heading title={"Invoice Notice"} />
              <div className="space-y-2 overflow-y-auto dark:text-white-bg">
                <textarea
                  className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                  placeholder="Comments"
                ></textarea>
              </div>
              <div className="flex justify-around gap-x-10">
                <div className="space-y-2">
                  <NumericFormat
                    value={(totalProfessionalFees + totalTimeEntries).toFixed(
                      2
                    )}
                    className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-40 p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                    displayType="text"
                    thousandSeparator={true}
                    prefix="£"
                  />
                  <div className="">
                    <SelectBox
                      className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md block w-40 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                      indicator={
                        <svg
                          width="15"
                          className="fill-current mr-2"
                          height="15"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                        >
                          <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                        </svg>
                      }
                      isMulti={false}
                      options={disbursementAccounts}
                      value={icomeAccountId}
                      name="frameFiftySix"
                      isSearchable={true}
                      placeholder="Select"
                      shape="round"
                      color="indigo_50"
                      size="xs"
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          setValue("incomeAccountId", selectedOption);
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="space-y-2">
                  <NumericFormat
                    value={totalDisbursements.toFixed(2)}
                    className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-40 p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                    displayType="text"
                    thousandSeparator={true}
                    prefix="£"
                  />
                  <div className="">
                    <SelectBox
                      className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md block w-40 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                      indicator={
                        <svg
                          width="15"
                          className="fill-current mr-2"
                          height="15"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                        >
                          <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                        </svg>
                      }
                      isMulti={false}
                      name="frameFiftySix"
                      options={disbursementAccountsCombo}
                      value={disburseAccountId}
                      isSearchable={true}
                      placeholder="Select"
                      shape="round"
                      color="indigo_50"
                      size="xs"
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          setValue("disburseAccountId", selectedOption);
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="space-y-2">
                  <NumericFormat
                    value={totalTaxAmount.toFixed(2)}
                    className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-40 p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                    displayType="text"
                    thousandSeparator={true}
                    prefix="£"
                  />
                  <div className="">
                    <SelectBox
                      className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md block w-40 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                      indicator={
                        <svg
                          width="15"
                          className="fill-current mr-2"
                          height="15"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                        >
                          <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                        </svg>
                      }
                      isMulti={false}
                      name="frameFiftySix"
                      options={vataccountCombo}
                      value={vatOutAccountId}
                      isSearchable={true}
                      placeholder="Select"
                      shape="round"
                      color="indigo_50"
                      size="xs"
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          setValue("vatOutputAccountId", selectedOption);
                        }
                      }}
                    />
                  </div>
                </div>

                <div>
                  <input
                    type="date"
                    className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-40 p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                    {...register("finalizedDate")}
                  />
                  {/* <InputBox title="Finalized Date" dataType="date"  value={} /> */}
                </div>

                <div>
                  <div className="relative">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                      Comments
                    </label>
                    <textarea
                      className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-40 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                      placeholder="Comments"
                      {...register("comments")}
                    ></textarea>
                  </div>
                </div>
              </div>

              <div className="flex col-span-2 gap-2 justify-end px-10">
                <button
                  type="button"
                  onClick={() => {
                    Close();
                  }}
                  className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isLoading}
                  className={` cursor - pointer bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]  ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                  {isLoading ? (
                    <svg className="animate-spin h-5 w-5 text-white-bg mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                    </svg>
                  ) : (
                    'Save'
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export { AddQuickInvoice };
