import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object, number } from 'yup';
import { useEffect, useRef, useState } from 'react';
import { SelectBox } from '../../../../SelectBox';
import { FaLock } from "react-icons/fa";
import { FaLockOpen } from "react-icons/fa";
import { SearchCaseReference } from '../../../SearchCaseReference';
import { useAppDispatch } from '../../../../../store/hooks/redux-hooks';
import { addOfficeToClient, fetchNextOfficeToClientNumber } from '../../../../../store/slice/Accounting/ClientAccounts/ClientPosting/OfficeToClientSlice';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';
import { NumericFormat } from 'react-number-format';
import { fetchNextBankPaymentNumber } from '../../../../../store/slice/Accounting/ClientAccounts/ClientPosting/BankPayment/CreatePaymentSlice';


function AddOfficeToClient({ openDrawer, Close, isEdit, officeToClient }: { openDrawer: boolean, Close: any, isEdit: boolean, officeToClient: any }) {
    const [title] = useState((!isEdit ? 'Add Office to Client' : 'Edit Office to Client'))
    const dispatch = useAppDispatch();
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [modalPlacement, setModalPlacement] = useState('center')
    const [openCaseAccountModal, setOpenCaseAccountModal] = useState(false)
    const [initialLoading, setInitialLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [existingOfficeToClient, setExistingOfficeToClient] = useState(officeToClient);
    const [selectedCaseReference, setSelectedCaseReference] = useState<any>();
    const { authourisedClients } = useSelector((state: RootState) => state.authorisedClient);
    const [authorisedClientLists, setAuthorisedClientLists] = useState<any[]>([]);
    const { clientAccounts } = useSelector((state: RootState) => state.clientAccount);
    const receiptNumbers = useSelector((state: RootState) => state.officeToClient.receiptNumber);
    const { officeBanks } = useSelector((state: RootState) => state.officeBank);
    const [clientAccountLists, setClientAccountLists] = useState<any[]>([]);
    const [officeAccountLists, setOfficeAccountLists] = useState<any[]>([]);
    const [autoReceiptNumber, setAutoReceiptNumber] = useState<number>();

    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
            const fetchPromises = [
                dispatch(fetchNextOfficeToClientNumber()),
            ];
            Promise.all(fetchPromises)
                .then(() => {
                    setInitialLoading(false);
                })
                .catch((error) => {
                    if (!errorShownRef.current) {
                        toast.error("Error fetching data: " + error.message);
                        errorShownRef.current = true;
                    }
                    setInitialLoading(false);
                });
            effectServiceCall.current = true;
        }
    }, [dispatch]);

    useEffect(() => {
        if (receiptNumbers !== null) {
            // console.log(receiptNumbers);
            setValue('officeToClientTransferNumber', receiptNumbers);
            setAutoReceiptNumber(receiptNumbers);
        }
    })

    useEffect(() => {
        if (authourisedClients.length > 0) {
            console.log(authourisedClients)
            let data = authourisedClients.map((authorisedClient: any, index) => ({
                value: authorisedClient.userId,
                label: `${authorisedClient.firstName} -${authorisedClient.lastName}`,
            }));
            setAuthorisedClientLists(data);

        }
    }, [authourisedClients]);

    useEffect(() => {
        if (clientAccounts.length > 0) {
            // console.log(clientAccounts)
            let data = clientAccounts.map((clientAccount: any, index) => ({
                value: clientAccount.bankAccountId,
                label: `${clientAccount.institution} - ${clientAccount.bankAccountType}`
                ,
            }));
            setClientAccountLists(data);

        }
    }, [clientAccounts]);

    useEffect(() => {
        if (officeBanks.length > 0) {
            // console.log(officeBanks)
            let data = officeBanks.map((officeAccount: any, index) => ({
                value: officeAccount.bankAccountId,
                label: `${officeAccount.institution} - ${officeAccount.bankAccountType}`
                ,
            }));
            setOfficeAccountLists(data);

        }
    }, [officeBanks]);

    const clientBankAccountType = [
        { label: '01[Client Bank - Natwest]', value: 1 },
        { label: '02[Client Bank - Natwest]', value: 2 },
        { label: '03[Client Bank - Natwest]', value: 3 },

    ];

    const mappedClientBankAccountType = clientBankAccountType.map((type: any) => ({
        label: type.label,
        value: type.value
    }));

    const authorisedBy = [
        { label: 'Cheque', value: 1 },
        { label: 'Electronic Transfer', value: 2 },


    ];

    const mappedauthorisedBy = authorisedBy.map((type: any) => ({
        label: type.label,
        value: type.value
    }));

    const transferMethod = [
        { label: 'Cheque', value: "Cheque" },
        { label: 'Electronic Transfer', value: "Electronic Transfer" },


    ];

    const mappedTransferMethod = transferMethod.map((type: any) => ({
        label: type.label,
        value: type.value
    }));
    const handleEditClick = () => {
        setIsDisabled(!isDisabled);
    };

    const [isDisabled, setIsDisabled] = useState(true);


    const validationSchema = object().shape({
        fkOfficeBankId: string()
            .required('From Bank Account is required'),
        officeToClientTransferNumber: number()
            .required('Reference is required'),
        transactionDate: string().required('Transaction Date is required')
            .nullable()
            .transform((value, originalValue) => {
                if (originalValue === "" || value === null) return null;
                return new Date(value).toISOString().split('T')[0];
            }),
        entryDate: string().required('Entry Date is required')
            .nullable()
            .transform((value, originalValue) => {
                if (originalValue === "" || value === null) return null;
                return new Date(value).toISOString().split('T')[0];
            }),
        fkCaseId: number(),

        fkClientBankId: string()
            .required('To Bank Account is required'),
        transactionDescription: string()
            .required('Transaction Description is required'),
        amountTransfer: string()
            .required('Amount is required'),
        comments: string(),
        fkUserId: string()
            .required('Authorised By is required'),
        transferMethodReferenceNumber: string()
            .required('Reference Number is required'),
        transferMethod: string()
            .required('Transfer Method is required')
    });

    const { register, handleSubmit, reset, setValue, getValues, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            officeToClientTransferNumber: autoReceiptNumber
        }
    });

    const onSubmit = async (data: any) => {
        console.log(JSON.stringify(data, null, 2));
        setIsLoading(true);
        try {
            await dispatch(addOfficeToClient(data)).unwrap();
            Close();
            toast.success("Successfully Added office to Client details");
        } catch (error: any) {
            toast.error(error.toString());
        } finally {
            setIsLoading(false);
        }
    };

    const onError = (errors: any) => {
        console.log("Form submission errors", errors);
    };

    useEffect(() => {

        if (isEdit && officeToClient) {

            setExistingOfficeToClient(officeToClient);
            reset(officeToClient);
        }
    }, [officeToClient, isEdit, reset, setValue]);

    const toggleCaseAccountModal = () => {
        setOpenCaseAccountModal(!openCaseAccountModal);
    }

    const handleCaseSelection = (selectedCase: any) => {
        console.log(selectedCase)
        setSelectedCaseReference(selectedCase);
        setValue('fkCaseId', selectedCase?.caseId);
        setOpenCaseAccountModal(false);
    };


    return (
        <>
            <form onSubmit={handleSubmit(onSubmit, onError)} className="mx-auto" >
                <div className="flex flex-row md:flex-col justify-between my-3 gap-2 w-full md:gap-3">
                    <div className="relative flex">
                        <input
                            type="text"
                            placeholder={selectedCaseReference?.caseReferenceAuto}
                            aria-label="Search"
                            value={selectedCaseReference?.caseReferenceAuto || ""}
                            className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.fkCaseId ? 'is-invalid' : ''}`}

                        //  disabled
                        />
                        <button
                            onClick={toggleCaseAccountModal}
                            className="z-[2] inline-block rounded-e border-2 border-primary px-6 pb-[6px] pt-2 text-xs font-medium  leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-accent-300 hover:bg-primary-50/50 hover:text-primary-accent-300 focus:border-primary-600 focus:bg-primary-50/50 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:text-primary-500 bg-hover_bg text-white-bg border-hover_bg -ml-1"
                            data-twe-ripple-init
                            data-twe-ripple-color="white"
                            type="button">
                            Search
                        </button>
                        {(openCaseAccountModal) &&
                            <SearchCaseReference
                                openDrawer={openCaseAccountModal}
                                Close={toggleCaseAccountModal}
                                onCaseSelect={handleCaseSelection}
                            />
                        }
                        <div className="font-medium mt-2 text-xs text-red-600">{errors?.fkCaseId?.message?.toString()}</div>

                    </div>
                    <div>
                        <button
                            type="button"
                            onClick={() => {
                                Close();
                            }}
                            className="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-2 rounded">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>

                </div >
                <div className="grid grid-cols-4 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 gap-y-1 w-full">
                    <div >
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Reference <span className="text-red-500">*</span>
                        </label>
                        <div className="flex gap-3">
                            <input
                                type="text"
                                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg disabled:bg-gray-300 dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                                value={autoReceiptNumber}
                                disabled={isDisabled}
                            />
                            <button
                                type="button"
                                onClick={handleEditClick}
                                className="mt-2 text-sm font-medium text-blue-600 dark:text-blue-500"
                            >
                                {isDisabled ? <FaLock /> : <FaLockOpen />}
                            </button>
                        </div>
                        <div className="font-medium mt-2 text-xs text-red-600" >{errors?.officeToClientTransferNumber?.message?.toString()}</div>
                    </div>
                    <div className="">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            From Bank Account <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            options={officeAccountLists}
                            isSearchable={true}
                            value={getValues().fkOfficeBankId}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                            onChange={(selectedOption) => {
                                if (selectedOption) {
                                    setValue('fkOfficeBankId', selectedOption);
                                }
                            }}
                        />
                        <div className="font-medium mt-2 text-xs text-red-600" >{errors?.fkOfficeBankId?.message?.toString()}</div>
                    </div>
                    <div className="">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            To Bank Account <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            options={clientAccountLists}
                            isSearchable={true}
                            value={getValues().fkClientBankId}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                            onChange={(selectedOption) => {
                                if (selectedOption) {
                                    setValue('fkClientBankId', selectedOption);
                                }
                            }}
                        />
                        <div className="font-medium mt-2 text-xs text-red-600" >{errors?.fkClientBankId?.message?.toString()}</div>
                    </div>
                    <div className="relative">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Transaction Date <span className="text-red-500">*</span>
                        </label>
                        <input type="date" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.transactionDate ? 'is-invalid' : ''}`} {...register('transactionDate')}
                            placeholder="Enter Transaction Date"
                            max={new Date().toISOString().split('T')[0]}
                        />
                        <div className="font-medium mt-2 text-xs text-red-600">{errors?.transactionDate?.message?.toString()}</div>
                    </div>

                    <div className="relative">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Entry Date <span className="text-red-500">*</span>
                        </label>
                        <input type="date" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.entryDate ? 'is-invalid' : ''}`} {...register('entryDate')}
                            placeholder="Enter Date"
                            max={new Date().toISOString().split('T')[0]}
                        />
                        <div className="font-medium mt-2 text-xs text-red-600">{errors?.entryDate?.message?.toString()}</div>
                    </div>
                    <div className="relative">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Transaction Description <span className="text-red-500">*</span>
                        </label>
                        <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.transactionDescription ? 'is-invalid' : ''}`} {...register('transactionDescription')}
                            placeholder="Enter Transaction Description"
                        />
                        <div className="font-medium mt-2 text-xs text-red-600">{errors?.transactionDescription?.message?.toString()}</div>
                    </div>

                    <div className="relative">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Amount (£) <span className="text-red-500">*</span>
                        </label>
                        <NumericFormat decimalScale={2} className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.amountTransfer ? 'is-invalid' : ''}`}
                            onValueChange={(values) => {
                                setValue('amountTransfer', values.value)
                            }}
                            placeholder="Enter Amount (£)"
                        />
                        <div className="font-medium mt-2 text-xs text-red-600">{errors?.amountTransfer?.message?.toString()}</div>
                    </div>
                    <div className="">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Authorised By <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            options={authorisedClientLists}
                            isSearchable={true}
                            value={getValues().fkUserId}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                            onChange={(selectedOption) => {
                                if (selectedOption) {
                                    setValue('fkUserId', selectedOption);
                                }
                            }}
                        />
                        <div className="font-medium mt-2 text-xs text-red-600" >{errors?.fkUserId?.message?.toString()}</div>
                    </div>
                    <div className="">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Transfer Method <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            options={mappedTransferMethod}
                            isSearchable={true}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                            onChange={(selectedOption) => {
                                if (selectedOption) {
                                    setValue('transferMethod', selectedOption);
                                }
                            }}
                        />
                        <div className="font-medium mt-2 text-xs text-red-600" >{errors?.transferMethod?.message?.toString()}</div>
                    </div>
                    <div className="relative">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Reference Number <span className="text-red-500">*</span>
                        </label>
                        <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.transferMethodReferenceNumber ? 'is-invalid' : ''}`} {...register('transferMethodReferenceNumber')}
                            placeholder="Enter Reference Number"
                        />
                        <div className="font-medium mt-2 text-xs text-red-600">{errors?.transferMethodReferenceNumber?.message?.toString()}</div>
                    </div>
                    <div className="relative">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Comments
                        </label>
                        <textarea
                            className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                            placeholder="Comments" {...register('comments')}
                        ></textarea>
                    </div>




                </div>
                <div className="flex col-span-2 gap-2 justify-end">
                    <button
                        type="button"
                        onClick={() => {
                            Close();
                        }}
                        className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]"
                    >
                        {isLoading ? (
                            <svg className="animate-spin h-5 w-5 text-white-bg mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                            </svg>
                        ) : (
                            'Save'
                        )}
                    </button>
                </div>

            </form >
        </>
    );
}

export { AddOfficeToClient };
