import { useEffect, useRef, useState } from "react";
import { Heading } from "../AccountSetupHeading/Heading";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { SelectBox } from '../SelectBox';
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { boolean, date, number, object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { addGeneralOffice, fetchAllGeneralOffice } from "../../store/slice/Accounting/OfficeAccounts/GeneralSettings/GeneralSlice";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { fetchAllFinancialYear } from "../../store/slice/Accounting/OfficeAccounts/GeneralSettings/FinancialYearSlice";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
function General() {
    // const [openModal, setOpenModal] = useState(false);
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);
    const [generalOffice] = useState<any>([]);
    const [setExistingGeneralOffice] = useState(generalOffice);
    const { businessTypes } = useSelector((state: RootState) => state.generalOffice);
    const accountingMethods = useSelector((state: RootState) => state.generalOffice.accountingMethods);
    const generalOfficeData = useSelector((state: RootState) => state.generalOffice.generalOfficeData);
    const [businessTypeList, setBusinessTypeList] = useState<any[]>([]);
    const { vatPeriods } = useSelector((state: RootState) => state.vatPeriod);
    const [vatPeriodList, setVatPeriodList] = useState<any[]>([]);
    const { financialYears } = useSelector((state: RootState) => state.financialYear);
    const [finacialYearList, setFinancialYearList] = useState<any[]>([]);
    const [selectedAccountingMethod, setSelectedAccountingMethod] = useState<string>("");
    const [accountingMethod, setAccountingMethod] = useState<string>("");

    useEffect(() => {
        if (!effectServiceCall.current) {
            dispatch(fetchAllGeneralOffice())
                .then(() => setInitialLoading(false))
                .catch(() => setInitialLoading(false));
            effectServiceCall.current = true;
        }
    }, [dispatch]);

    useEffect(() => {
        if (generalOfficeData !== null) {
            setValue('fkFinancialYearId', generalOfficeData?.fkFinancialYearId)
            setValue('fkVatPeriodId', generalOfficeData?.fkVatPeriodId)
            setValue('fkBusinessTypeId', generalOfficeData?.fkBusinessTypeId)
            setValue('accOfficeSettingsId', generalOfficeData?.accOfficeSettingsId)
            setValue('utrn', generalOfficeData?.utrn)
        }
    })
    useEffect(() => {
        if (Array.isArray(accountingMethods) && generalOfficeData?.fkAccountingMethodId) {
            const matchedMethod = accountingMethods.find(
                method => method.accountingMethodId === generalOfficeData.fkAccountingMethodId
            );

            if (matchedMethod) {
                setAccountingMethod(matchedMethod.accountingMethod)
                setValue('fkAccountingMethodId', matchedMethod.accountingMethodId);
            }
        }
    }, [accountingMethods, generalOfficeData]);

    const validationSchema = object().shape({
        accOfficeSettingsId: number(),
        companyNumber: string()
            .required('Company Number is required'),
        fkBusinessTypeId: number()
            .required('Business Type is required'),
        utrn: string()
            .required('UTR Number is required'),
        fkFinancialYearId: number()
            .required('Financial Date is required'),
        fkAccountingMethodId: number(),
        fkVatAccountingMethodId: number(),
        isVatRegistered: boolean(),
        vATNumber: string(),
        fkVatPeriodId: number()
    });

    const { register, handleSubmit, reset, setValue, getValues, watch, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            companyNumber: generalOfficeData?.companyNumber,
            vATNumber: generalOfficeData?.vatNumber,
            utrn: generalOfficeData?.utrn,
            isVatRegistered: generalOfficeData?.isVatRegistered || false,
        }

    });

    const onSubmit = async (data: any) => {
        console.log(data);
        setIsLoading(true);
        try {
            await dispatch(addGeneralOffice(data)).unwrap();
            toast.success("Successfully Added GeneralOffice");
        } catch (error: any) {
            toast.error(error.toString());
        } finally {
            setIsLoading(false);
        }
    };

    const handleEditClick = () => {
        setIsDisabled(!isDisabled);
    };

    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        if (businessTypes != null && businessTypes.length > 0) {
            let data = businessTypes.map((item: any) => ({
                value: item.businessTypeId,
                label: item.businessType,
            }));
            setBusinessTypeList(data);
        }
    }, [businessTypes]);

    useEffect(() => {

        if (vatPeriods != null && (vatPeriods.dataList != null && vatPeriods.dataList.length > 0)) {
            console.log(vatPeriods.dataList)
            let data = vatPeriods.dataList.map((item: any) => ({
                value: item.vatPeriodId,
                label: item.vatPeriod,
            }));
            setVatPeriodList(data);
        }
    }, [vatPeriods]);

    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
            let data = {
                keyword: ''
            }
            dispatch(fetchAllFinancialYear(data))
                .then(() => setInitialLoading(false))
                .catch(() => setInitialLoading(false));
            effectServiceCall.current = true;
        }
    }, [dispatch]);

    useEffect(() => {

        if (financialYears != null && (financialYears.dataList != null && financialYears.dataList.length > 0)) {
            let data = financialYears.dataList.map((item: any) => ({
                value: item.financialYearId,
                label: `${item.startDate.split("T")[0]} - ${item.endDate.split("T")[0]}`,

            }));
            setFinancialYearList(data);
        }
    }, [financialYears]);

    const handleFinancialYearChange = (selectedOption: any) => {
        console.log(selectedOption);
        if (selectedOption && financialYears?.dataList) {
            setValue("fkFinancialYearId", selectedOption);
            const selectedYear = financialYears.dataList.find(
                (item: any) => item.financialYearId === selectedOption
            );
            console.log(selectedYear);

            if (selectedYear) {
                setSelectedAccountingMethod(selectedYear.accountingMethod);
                setValue("fkAccountingMethodId", selectedYear.accountingMethodId); 
            }
        }
    };

    const onVatPeriodSelection =(selectedOption: any) =>{
        if (selectedOption!=null && (vatPeriods!=null && vatPeriods?.dataList.length > 0)){
            setValue('fkVatPeriodId', selectedOption);
            const selectedVatPeriod = vatPeriods.dataList.find((item: any) => item.vatPeriodId === selectedOption);
            if(selectedVatPeriod){
                setValue("fkVatAccountingMethodId", selectedVatPeriod.vatPeriodId); 
            }
        }
    }



    // useEffect(() => {
    //     if (generalOffice) {
    //         setExistingGeneralOffice(generalOffice);
    //         reset(generalOffice);
    //     }
    // }, [generalOffice, reset, setValue]);
    return (
        <>
            <div className="">
                {initialLoading ? <MainAnimation /> : ""}
                <form onSubmit={handleSubmit(onSubmit)} className="mx-auto" >
                    <div className="grid grid-cols-2 md:grid-cols-1 gap-x-10 mt-5">
                        <div>
                            <Heading title={"General Settings"} />

                            <div className="grid grid-cols-2 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
                                <div className="">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                        Business Type <span className="text-red-500">*</span>
                                    </label>
                                    <SelectBox
                                        // className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                        // placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                        indicator={
                                            <svg
                                                width="15"
                                                className="fill-current mr-2"
                                                height="15"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 448 512">
                                                <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                            </svg>
                                        }
                                        isMulti={false}
                                        name="frameFiftySix"
                                        options={businessTypeList}
                                        isSearchable={true}
                                        placeholder="Select"
                                        shape="round"
                                        color="indigo_50"
                                        size="xs"
                                        value={getValues().fkBusinessTypeId}
                                        onChange={(selectedOption) => {
                                            if (selectedOption) {
                                                setValue('fkBusinessTypeId', selectedOption);
                                            }
                                        }}
                                    />
                                </div>
                                <div className="">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                        Company Number <span className="text-red-500">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        value={generalOfficeData?.companyNumber}
                                        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="09522867" required`}   {...register('companyNumber')} />
                                </div>
                                <div className="">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                        UTR Number <span className="text-red-500">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        value={generalOfficeData?.utrn}
                                        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="7733518187" required`}  {...register('utrn')} />
                                </div>
                                <div className="">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                        Financial Year <span className="text-red-500">*</span>
                                    </label>
                                    <SelectBox
                                        indicator={
                                            <svg
                                                width="15"
                                                className="fill-current mr-2"
                                                height="15"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 448 512">
                                                <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                            </svg>
                                        }
                                        isMulti={false}
                                        name="frameFiftySix"
                                        options={finacialYearList}
                                        isSearchable={true}
                                        placeholder="Select"
                                        shape="round"
                                        color="indigo_50"
                                        size="xs"
                                        value={getValues().fkFinancialYearId}
                                        onChange={handleFinancialYearChange}
                                    />
                                </div>
                                <div className="">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                        Accounting Method <span className="text-red-500">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        value={accountingMethod}
                                        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Accrual" required`} {...register('fkAccountingMethodId')}
                                        disabled={isDisabled}
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <Heading title={"VAT Settings"} />
                            <div className="grid grid-cols-2 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 my-5">
                                <div className="flex items-baseline">
                                    <input
                                        type="checkbox"
                                        className={`w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600`}
                                        {...register('isVatRegistered')}
                                    />
                                    <label className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Is VAT Registered <span className="text-red-500">*</span></label>
                                </div>
                                <div className="">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                        VAT Number <span className="text-red-500">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        value={generalOfficeData?.vatNumber}
                                        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="0210118825" required`}  {...register('vATNumber')} />
                                </div>
                                <div className="">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                        VAT Period <span className="text-red-500">*</span>
                                    </label>
                                    <SelectBox
                                        indicator={
                                            <svg
                                                width="15"
                                                className="fill-current mr-2"
                                                height="15"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 448 512">
                                                <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                            </svg>
                                        }
                                        isMulti={false}
                                        name="frameFiftySix"
                                        options={vatPeriodList}
                                        isSearchable={true}
                                        placeholder="Select"
                                        shape="round"
                                        color="indigo_50"
                                        size="xs"
                                        value={getValues().fkVatPeriodId}
                                        onChange={onVatPeriodSelection}
                                    />
                                </div>
                                <div className="">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                        Accounting Method <span className="text-red-500">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        value={accountingMethod}
                                        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Accrual" required`} {...register('fkVatAccountingMethodId')}
                                        disabled={isDisabled}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex md:flex-col gap-2 flex-row justify-start w-full mb-5">
                        <div className="flex flex-row gap-2 items-end justify-end w-full">

                            <button
                                type='button'
                                onClick={() => {
                                    reset();
                                }}
                                className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                // disabled={isLoading}
                                className={`bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px] ${isLoading} ? 'opacity-50 cursor-not-allowed' : ''}`}
                            >
                                {isLoading ? (
                                    <svg className="animate-spin h-5 w-5 text-white-bg mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                                    </svg>
                                ) : (
                                    'Save'
                                )}
                            </button>
                        </div>
                    </div>

                </form>

            </div>
        </>
    )
}

export { General };