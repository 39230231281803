import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object, number } from 'yup';
import { useEffect, useRef, useState } from 'react';
import { SelectBox } from '../../../../SelectBox';
import { SearchCaseReference } from '../../../SearchCaseReference';
import { useAppDispatch } from '../../../../../store/hooks/redux-hooks';
import { addProtectedFund } from '../../../../../store/slice/Accounting/ClientAccounts/ClientPosting/ProtectedFundSlice';
import { toast } from 'react-toastify';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';
import { getValue } from '@testing-library/user-event/dist/utils';
import { fetchCaseContact } from '../../../../../store/slice/CaseManagement/Case/CaseManagementSlice';

function AddProtectedFund({ openDrawer, Close, isEdit, protectedFund }: { openDrawer: boolean, Close: any, isEdit: boolean, protectedFund: any }) {
    const [title] = useState((!isEdit ? 'Add Protected Fund' : 'Edit Protected Fund'))
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [modalPlacement, setModalPlacement] = useState('center')
    const [openCaseAccountModal, setOpenCaseAccountModal] = useState(false)
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [existingProtectedFund, setExistingProtectedFund] = useState(protectedFund);
    const [selectedCaseReference, setSelectedCaseReference] = useState<string | null>(null);
    // const { caseContactData } = useSelector((state: RootState) => state.case.caseContactData);

    const { clientAccounts } = useSelector((state: RootState) => state.clientAccount);
    const [clientAccountLists, setClientAccountLists] = useState<any[]>([]);


    useEffect(() => {
        if (clientAccounts.length > 0) {
            let data = clientAccounts.map((clientAccount: any, index) => ({
                value: clientAccount.bankAccountId,
                label: `${clientAccount.institution} - ${clientAccount.bankAccountType}`
                ,
            }));
            setClientAccountLists(data);
        }
    }, [clientAccounts]);

    const handleEditClick = () => {
        setIsDisabled(!isDisabled);
    };

    const [isDisabled, setIsDisabled] = useState(true);


    const validationSchema = object().shape({
        fkCaseId: number()
            .required('Case Reference is required'),
        fkContactId: number(),
        protectedAmount: string()
            .required('Amount is required'),
        protectedDateTime: string()
            .required('Date is required'),
        entryDate: string()
            .required('Entry Date is required'),
        availableBalance: string(),
        paymentForFrom: string(),
        reason: string(),
        comments: string(),
        fkClientBankId: number()
            .required('Client Bank is required'),
        caseReferenceAuto: string(),
        protectedFundType: string(),
    });


    const { register, handleSubmit, reset, setValue, getValues, watch, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            protectedFundType: "Given",
        }
    });

    const onSubmit = async (data: any) => {
        console.log(JSON.stringify(data, null, 2));
        setIsLoading(true);
        try {
            await dispatch(addProtectedFund(data)).unwrap();
            Close();
            toast.success("Successfully Added office to Client details");
        } catch (error: any) {
            toast.error(error.toString());
        } finally {
            setIsLoading(false);
        }
    };

    const onError = (errors: any) => {
        console.log("Form submission errors", errors);
    };

    useEffect(() => {

        if (isEdit && protectedFund) {

            setExistingProtectedFund(protectedFund);
            reset(protectedFund);
        }
    }, [protectedFund, isEdit, reset, setValue]);


    const toggleCaseAccountModal = () => {
        setOpenCaseAccountModal(!openCaseAccountModal);
    }

    const handleCaseSelection = (selectedCase: any) => {
        dispatch(fetchCaseContact(selectedCase.caseId));
        setSelectedCaseReference(selectedCase?.caseReferenceAuto);
        setValue('fkCaseId', selectedCase.caseId);
        setValue('caseReferenceAuto', selectedCase.caseReferenceAuto);
        setOpenCaseAccountModal(false);
    };


    return (
        <>
            <form onSubmit={handleSubmit(onSubmit,onError)} className="mx-auto" >
                <div className="flex flex-row md:flex-col justify-between my-3 gap-2 w-full md:gap-3">
                    <div>
                        <div className="relative flex">
                        <input
                            type="search"
                            placeholder="Case Reference"
                            aria-label="Search"
                            value={selectedCaseReference || ""}
                            className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.fkCaseId ? 'is-invalid' : ''}`}
                        //  disabled
                        />

                        <button
                            onClick={toggleCaseAccountModal}
                            className="z-[2] inline-block rounded-e border-2 border-primary px-6 pb-[6px] pt-2 text-xs font-medium  leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-accent-300 hover:bg-primary-50/50 hover:text-primary-accent-300 focus:border-primary-600 focus:bg-primary-50/50 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:text-primary-500 bg-hover_bg text-white-bg border-hover_bg -ml-1"
                            data-twe-ripple-init
                            data-twe-ripple-color="white"
                            type="button">
                            Search
                        </button> 
                        <span className="text-red-500 mx-1">*</span>
                        {(openCaseAccountModal) &&
                            <SearchCaseReference
                                openDrawer={openCaseAccountModal}
                                Close={toggleCaseAccountModal}
                                onCaseSelect={handleCaseSelection} />
                        }
                        </div>
                        <div className="font-medium mt-2 text-xs text-red-600">{errors?.fkCaseId?.message?.toString()}</div>
                    </div>
                    <div>
                        <button
                            type="button"
                            onClick={() => {
                                Close();
                            }}
                            className="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-2 rounded">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>

                </div >
                <div className="grid grid-cols-4 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 gap-y-1 w-full">
                    <div className="relative">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Available Balance 
                        </label>
                        <NumericFormat decimalScale={2} className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('availableBalance')}
                        disabled
                            placeholder="Enter Available Balance"
                        />
                    </div>
                    <div className="relative">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Date <span className="text-red-500">*</span>
                        </label>
                        <input type="date" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.protectedDateTime ? 'is-invalid' : ''}`} {...register('protectedDateTime')}
                            placeholder="Enter Date"
                        />
                        <div className="font-medium mt-2 text-xs text-red-600">{errors?.protectedDateTime?.message?.toString()}</div>
                    </div>

                    <div className="relative">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Entry Date <span className="text-red-500">*</span>
                        </label>
                        <input type="date" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.entryDate ? 'is-invalid' : ''}`} {...register('entryDate')}
                            placeholder="Enter Date"
                        />
                        <div className="font-medium mt-2 text-xs text-red-600">{errors?.entryDate?.message?.toString()}</div>

                        <div className='flex items-center gap-x-4'>
                            <div className="flex items-center">
                                <input
                                type="radio"
                                value="Given"
                                checked={watch('protectedFundType') === "Given"}
                                onChange={() => setValue('protectedFundType', "Given")}
                                name="default-radio" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                <label className="ms-2 text-xs text-gray-900 dark:text-gray-300">Given</label>
                            </div>
                            <div className="flex items-center">
                                <input 
                                type="radio"
                                value="Taken"
                                checked={watch('protectedFundType') === "Taken"}
                                onChange={() => setValue('protectedFundType', "Taken")}
                                name="default-radio" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                <label className="ms-2 text-xs text-gray-900 dark:text-gray-300">Taken</label>
                            </div>
                        </div>

                    </div>
                    <div className="relative">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Payment For / From <span className="text-red-500">*</span>
                        </label>
                        <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.paymentForFrom ? 'is-invalid' : ''}`} {...register('paymentForFrom')}
                            placeholder="Enter Transaction Description"
                        />
                        <div className="font-medium mt-2 text-xs text-red-600">{errors?.paymentForFrom?.message?.toString()}</div>
                        <div className='flex gap-x-2'>
                            <button
                                className="text-xs py-1 px-3 font-medium text-white-bg bg-blue-500 rounded hover:bg-blue-600">
                                Case Contacts
                            </button>
                            <div className="flex items-center my-2">
                                <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                <label className="ms-2 text-xs font-medium text-gray-900 dark:text-gray-300">Other</label>
                            </div>

                        </div>
                    </div>
                    <div className="">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Client Bank <span className="text-red-500">*</span>
                        </label>
                        <SelectBox
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                            indicator={
                                <svg
                                    width="15"
                                    className="fill-current mr-2"
                                    height="15"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                </svg>
                            }
                            isMulti={false}
                            name="frameFiftySix"
                            options={clientAccountLists}
                            isSearchable={true}
                            value={getValues().fkClientBankId}
                            placeholder="Select"
                            shape="round"
                            color="indigo_50"
                            size="xs"
                            onChange={(selectedOption) => {
                                if (selectedOption) {
                                    setValue('fkClientBankId', selectedOption);
                                }
                            }}
                        />
                        <div className="font-medium mt-2 text-xs text-red-600" >{errors?.fkClientBankId?.message?.toString()}</div>
                    </div>
                    <div className="relative">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Amount (£) <span className="text-red-500">*</span>
                        </label>
                        <NumericFormat decimalScale={2} className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.protectedAmount ? 'is-invalid' : ''}`}
                        onValueChange={(values) => {
                            setValue('protectedAmount', values.value)
                        }}
                            placeholder="Enter Amount (£)"
                        />
                        <div className="font-medium mt-2 text-xs text-red-600">{errors?.protectedAmount?.message?.toString()}</div>
                    </div>
                    <div className="relative">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Reason
                        </label>
                        <textarea
                            className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                            placeholder="Reason" {...register('reason')}
                        ></textarea>
                    </div>
                    <div className="relative">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Comments
                        </label>
                        <textarea
                            className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                            placeholder="Comments" {...register('comments')}
                        ></textarea>
                    </div>




                </div>
                <div className="flex col-span-2 gap-2 justify-end my-5">
                    <button
                        type="button"
                        onClick={() => {
                            Close();
                        }}
                        className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]"
                    >  {isLoading ? (
                        <svg className="animate-spin h-5 w-5 text-white-bg mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                        </svg>
                    ) : (
                        'Save'
                    )}
                    </button>
                </div>

            </form>
        </>
    );
}

export { AddProtectedFund };
