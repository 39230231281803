import { useEffect, useRef, useState } from "react";
import { SearchInput } from "../Filters/SearchInput";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { FaEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { AddFixedFeeInvoicePayment } from "../Modal/AddOfficeAccounts/AddOfficePostings/AddFixedFeeInvoicePayment";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { fetchAllFixedFeeInvoicePayment } from "../../store/slice/Accounting/OfficeAccounts/OfficePostings/FixedFeeInvoicePaymentSlice";
import { toast } from "react-toastify";
import { Pagination } from "flowbite-react";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
function FixedFeeInvoicePayment() {
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [fixedFeeInvoicePaymentDatas, setFixedFeeInvoicePaymentDatas] = useState<any>([]);
    const [fixedFeeInvoicePaymentGetData, setFixedFeeInvoicePaymentGetData] = useState<any[]>([]);
    const dispatch = useAppDispatch();
    const [initialLoading, setInitialLoading] = useState(true);
    const fixedFeeInvoicePaymentData = useSelector((state: RootState) => state.fixedFeeInvoicePayment.fixedFeeInvoicePaymentData);
    const [totalPages, setTotalPages] = useState(0);
    const [currentFixedFeeInvoicePayment, setCurrentFixedFeeInvoicePayment] = useState<any[]>([]);
    const itemsPerPage = 10;
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [currentPage, setCurrentPage] = useState(1);



    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
            dispatch(fetchAllFixedFeeInvoicePayment())
                .then(() => setInitialLoading(false))
                .catch(() => setInitialLoading(false));
            effectServiceCall.current = true;
        }
    }, [dispatch]);

    useEffect(() => {
        if (fixedFeeInvoicePaymentData && fixedFeeInvoicePaymentData.listingData) {
            setFixedFeeInvoicePaymentGetData(fixedFeeInvoicePaymentData.listingData);
        }
    }, [fixedFeeInvoicePaymentData]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        if (fixedFeeInvoicePaymentGetData != null && fixedFeeInvoicePaymentGetData.length > 0) {
            const filteredByFixedFeeInvoicePayment = filterFixedFeeInvoicePaymentBySearchQuery();
            const indexOfLastFixedFeeInvoicePayment = currentPage * itemsPerPage;
            const indexOfFirstFixedFeeInvoicePayment = indexOfLastFixedFeeInvoicePayment - itemsPerPage;
            const currentFixedFeeInvoicePayment = filteredByFixedFeeInvoicePayment.slice(indexOfFirstFixedFeeInvoicePayment, indexOfLastFixedFeeInvoicePayment);
            const totalPages = Math.ceil(fixedFeeInvoicePaymentGetData.length / itemsPerPage);
            setTotalPages(totalPages)
            setCurrentFixedFeeInvoicePayment(currentFixedFeeInvoicePayment)
        }

    }, [fixedFeeInvoicePaymentGetData])


    const filterFixedFeeInvoicePaymentBySearchQuery = () => {

        return fixedFeeInvoicePaymentGetData.filter((fixedFeeInvoicePayment: any) =>
            fixedFeeInvoicePayment?.accountName.toLowerCase().includes(searchQuery.toLowerCase()));
    };

    const toggleDrawer = () => {
        setIsEdit(false);
        setOpenModal(!openModal);
    };


    return (
        <>
            <div className="">
                <div className="mb-5">
                    <SubHeaderTitle title="Fixed Fee Invoice Payment" buttonName="Payment" isAdd={true} onClickAdd={toggleDrawer} />
                    <AddFixedFeeInvoicePayment openDrawer={openModal} Close={toggleDrawer} isEdit={isEdit} fixedFeeInvoicePayment={fixedFeeInvoicePaymentDatas} />
                </div>
                <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
                    <SearchInput title="Keyword" />
                    <div>
                        <div className="flex gap-4 md:gap-1 w-full">
                            <CheckBoxFilter title="Status" />
                        </div>
                    </div>
                </div>
                <div className="relative overflow-x-auto sm:rounded-lg">
                    {initialLoading ? <MainAnimation /> : ""}
                    <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                        <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <th scope="col" className="px-6 py-3">
                                    Date
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Invoicee (s)
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Invoice
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Total
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Amount Paid
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Status
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentFixedFeeInvoicePayment.map((fixedFeeInvoicePayment: any, index) => (

                                <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    <td className="px-6 py-3">
                                        {new Date(fixedFeeInvoicePayment.invoicePaymentDate).toISOString().split('T')[0]}
                                    </td>
                                    <td className="px-6 py-3">
                                        {fixedFeeInvoicePayment.invoiceeName}
                                    </td>
                                    <td className="px-6 py-3">
                                        <a href="" className="hover:text-blue-500"> {fixedFeeInvoicePayment.invoiceName}</a>
                                    </td>
                                    <td className="text-right px-6 py-3">
                                        £ {fixedFeeInvoicePayment.totalAmount}
                                    </td>
                                    <td className="text-right px-6 py-3">
                                        £ {fixedFeeInvoicePayment.invoicePaymentAmount}
                                    </td>
                                    <td className="px-6 py-3">
                                        {fixedFeeInvoicePayment.status}
                                    </td>
                                    <td className="cursor-pointer px-6 py-3">
                                        <div className="flex gap-3 w-full">
                                            <FaEye className="fill-gray-500 w-6 h-6" />
                                            <FaEdit className="fill-blue-500 w-6 h-6" />
                                            <RiDeleteBin5Fill className="fill-red-600 w-6 h-6" />
                                        </div>
                                    </td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {currentFixedFeeInvoicePayment.length === 0 && (
                        <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
                            <p className="text-red-800">No Fixed FeeInvoice Payment Details found.</p>
                        </div>
                    )}
                </div>
                {currentFixedFeeInvoicePayment.length > 0 && (
                    <div className="w-full flex flex-col pb-[100px]">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={(page) => setCurrentPage(page)}
                        />
                    </div>
                )}
            </div>
        </>
    )
}

export { FixedFeeInvoicePayment };