import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import CaseManagementService from "../../../../services/CaseManagement/Case/CaseManagementService";
import { CaseAML, CaseDto, Case, StaffsInvolved, CaseClient, CaseContactData, CaseDetailsList, CaseNumber, CasePermission, ClientGreeting, Permission, PermittedStafs, RiskAssessment, RiskTypeStatus, PostRegisters, CaseMoneyLaundarings, CaseTitleDocuments, CaseSearchDocuments, CaseStatus, CaseContactAndClient } from "../../../model/CaseManagement/CaseInterface";




// Define the state type for cases
type CaseState = {
  cases: Case[];
  permission: Permission[];
  caseDetailsLists: CaseDetailsList;
  caseDto: CaseDto;
  permittedStafs: PermittedStafs[];
  staffsInvolved: StaffsInvolved;
  casePermission: CasePermission;
  riskAssessment: RiskAssessment[];
  caseAML: CaseAML[];
  caseNumbers: CaseNumber[];
  clientGreeting: ClientGreeting[];
  postRegisters: PostRegisters[];
  caseMoneyLaundarings: CaseMoneyLaundarings[];
  caseTitleDocuments: CaseTitleDocuments[];
  caseSearchDocuments: CaseSearchDocuments[];
  caseContactData: CaseContactData[];
  caseStatusData: CaseStatus[];
  caseClient: CaseClient;
  riskTypeStatus: RiskTypeStatus[];
  caseContactAndClient: CaseContactAndClient;
  status: "idle" | "loading" | "failed";
  error: string | null;
  caseId: number;
};

// Initial state
const initialState: CaseState = {
  caseId: 0,
  cases: [],
  permission: [],
  caseNumbers: null!,
  caseDetailsLists: null!,
  caseDto: null!,
  caseStatusData: [],
  permittedStafs: [],
  staffsInvolved: null!,
  clientGreeting: [],
  caseContactData: [],
  postRegisters: [],
  caseMoneyLaundarings: [],
  caseTitleDocuments: [],
  caseSearchDocuments: [],
  casePermission: null!,
  riskAssessment: [],
  riskTypeStatus: [],
  caseAML: [],
  caseClient: null!,
  caseContactAndClient: null!,
  status: "idle",
  error: null
};


// Async thunks for CRUD operations
export const fetchAllCase = createAsyncThunk(
  "fetchAllCase",
  async (_, thunkAPI) => {
    try {
      const response = await CaseManagementService.GetAllCase();
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchAllRiskTypeStatus = createAsyncThunk(
  "fetchAllRiskTypeStatus",
  async (_, thunkAPI) => {
    try {
      const response = await CaseManagementService.GetRiskTypeStatus();
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchByCaseId = createAsyncThunk(
  "fetchByCaseId",
  async ({ id }: { id: any }, thunkAPI) => {
    try {
      const response = await CaseManagementService.GetByCaseId(id);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchCaseContactAndClientByCaseId = createAsyncThunk(
  "fetchCaseContactAndClientByCaseId",
  async (data: any, thunkAPI) => {
    try {
      const response = await CaseManagementService.GetCaseContactAndClientByCaseId(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchCaseNumber = createAsyncThunk(
  "fetchCaseNumber",
  async (_, thunkAPI) => {
    try {
      const response = await CaseManagementService.GetCaseNumber();
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchPermittedStaffs = createAsyncThunk(
  "fetchPermittedStaffs",
  async (id: any, thunkAPI) => {
    try {
      const response = await CaseManagementService.GetPermittedStaffs(id);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchStaffInvolved = createAsyncThunk(
  "fetchStaffInvolved",
  async (id: any, thunkAPI) => {
    try {
      const response = await CaseManagementService.GetStaffInvolved(id);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchCasePermission = createAsyncThunk(
  "fetchCasePermission",
  async (id: any, thunkAPI) => {
    try {
      const response = await CaseManagementService.GetCasePermission(id);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchCaseClient = createAsyncThunk(
  "fetchCaseClient",
  async (id: any, thunkAPI) => {
    try {
      const response = await CaseManagementService.GetCaseClient(id);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchPostRegister = createAsyncThunk(
  "fetchPostRegister",
  async (id: any, thunkAPI) => {
    try {
      const response = await CaseManagementService.GetPostRegisters(id);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchCaseMoneyLaundarings = createAsyncThunk(
  "fetchCaseMoneyLaundarings",
  async (id: any, thunkAPI) => {
    try {
      const response = await CaseManagementService.GetCaseMoneyLaundarings(id);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchCaseTitleDocuments = createAsyncThunk(
  "fetchCaseTitleDocuments",
  async (id: any, thunkAPI) => {
    try {
      const response = await CaseManagementService.GetCaseTitleDocuments(id);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchCaseSearchDocuments = createAsyncThunk(
  "fetchCaseSearchDocuments",
  async (id: any, thunkAPI) => {
    try {
      const response = await CaseManagementService.GetCaseSearchDocuments(id);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchCaseContact = createAsyncThunk(
  "fetchCaseContact",
  async (id: any, thunkAPI) => {
    try {
      const response = await CaseManagementService.GetCaseContact(id);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const addCase = createAsyncThunk(
  "addCase",
  async (data: any, thunkAPI) => {
    try {
      const response = await CaseManagementService.PostCaseData(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const addCasePermission = createAsyncThunk(
  "addCasePermission",
  async ({ id, data }: { id: any, data: CasePermission }, thunkAPI) => {
    try {
      const response = await CaseManagementService.PostCasePermission(id, data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const addCaseStatus = createAsyncThunk(
  "addCaseStatus",
  async ({ id, data }: { id: any, data: CasePermission }, thunkAPI) => {
    try {
      const response = await CaseManagementService.PostCaseStatus(id, data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const addCaseRiskAssessment = createAsyncThunk(
  "addCaseRiskAssessment",
  async ({ id, data }: { id: any, data: RiskAssessment }, thunkAPI) => {
    try {
      const response = await CaseManagementService.PostCaseRiskAssessment(id, data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const addcaseAML = createAsyncThunk(
  "addcaseAML",
  async ({ id, data }: { id: any, data: RiskAssessment }, thunkAPI) => {
    try {
      const response = await CaseManagementService.PostCaseAML(id, data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const addCaseClients = createAsyncThunk(
  "addCaseClients",
  async ({ id, data }: { id: any, data: ClientGreeting }, thunkAPI) => {
    try {
      const response = await CaseManagementService.PostCaseClients(id, data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const addCaseContact = createAsyncThunk(
  "addCaseContact",
  async ({ id, data }: { id: any, data: CaseContactData }, thunkAPI) => {
    try {
      const response = await CaseManagementService.PostCaseContacts(id, data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updateCase = createAsyncThunk(
  "updateCase",
  async ({ id, data }: { id: number, data: Case }, thunkAPI) => {
    try {
      const response = await CaseManagementService.PutCaseData(id, data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const deleteCase = createAsyncThunk(
  "deleteCase",
  async (caseId: number, thunkAPI) => {
    try {
      await CaseManagementService.DeleteCaseData(caseId);
      return caseId;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Slice definition
const CaseManagementSlice = createSlice({
  name: "case",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCase.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllCase.fulfilled, (state, action: PayloadAction<CaseDetailsList>) => {
        state.status = "idle";
        state.caseDetailsLists = action.payload;
      })
      .addCase(fetchAllCase.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })

      .addCase(fetchAllRiskTypeStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllRiskTypeStatus.fulfilled, (state, action: PayloadAction<RiskTypeStatus[]>) => {
        state.status = "idle";
        state.riskTypeStatus = action.payload;
      })
      .addCase(fetchAllRiskTypeStatus.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })

      .addCase(fetchPermittedStaffs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPermittedStaffs.fulfilled, (state, action: PayloadAction<PermittedStafs[]>) => {
        state.status = "idle";
        state.permittedStafs = action.payload;
      })
      .addCase(fetchPermittedStaffs.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })

      .addCase(fetchStaffInvolved.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchStaffInvolved.fulfilled, (state, action: PayloadAction<StaffsInvolved>) => {
        state.status = "idle";
        state.staffsInvolved = action.payload;
      })
      .addCase(fetchStaffInvolved.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })

      .addCase(fetchCasePermission.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCasePermission.fulfilled, (state, action: PayloadAction<CasePermission>) => {
        state.status = "idle";
        state.casePermission = action.payload;
      })
      .addCase(fetchCasePermission.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })

      .addCase(fetchCaseClient.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCaseClient.fulfilled, (state, action: PayloadAction<CaseClient>) => {
        state.status = "idle";
        state.caseClient = action.payload;
      })
      .addCase(fetchCaseClient.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })

      .addCase(fetchPostRegister.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPostRegister.fulfilled, (state, action: PayloadAction<PostRegisters[]>) => {
        state.status = "idle";
        state.postRegisters = action.payload;
      })
      .addCase(fetchPostRegister.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })

      .addCase(fetchCaseMoneyLaundarings.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCaseMoneyLaundarings.fulfilled, (state, action: PayloadAction<CaseMoneyLaundarings[]>) => {
        state.status = "idle";
        state.caseMoneyLaundarings = action.payload;
      })
      .addCase(fetchCaseMoneyLaundarings.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })

      .addCase(fetchCaseTitleDocuments.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCaseTitleDocuments.fulfilled, (state, action: PayloadAction<CaseTitleDocuments[]>) => {
        state.status = "idle";
        state.caseTitleDocuments = action.payload;
      })
      .addCase(fetchCaseTitleDocuments.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })

      .addCase(fetchCaseSearchDocuments.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCaseSearchDocuments.fulfilled, (state, action: PayloadAction<CaseSearchDocuments[]>) => {
        state.status = "idle";
        state.caseSearchDocuments = action.payload;
      })
      .addCase(fetchCaseSearchDocuments.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })

      .addCase(fetchCaseContact.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCaseContact.fulfilled, (state, action: PayloadAction<any[]>) => {
        state.status = "idle";
        state.caseContactData = action.payload;
      })
      .addCase(fetchCaseContact.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })


      .addCase(fetchByCaseId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchByCaseId.fulfilled, (state, action: PayloadAction<CaseDto>) => {
        state.status = "idle";
        state.caseDto = action.payload;
      })
      .addCase(fetchByCaseId.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload ? action.payload.toString() : "Failed to fetch case by ID";
      })

      .addCase(fetchCaseContactAndClientByCaseId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCaseContactAndClientByCaseId.fulfilled, (state, action: PayloadAction<CaseContactAndClient>) => {
        state.status = "idle";
        state.caseContactAndClient = action.payload;
      })
      .addCase(fetchCaseContactAndClientByCaseId.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload ? action.payload.toString() : "Failed to fetch case by ID";
      })

      .addCase(fetchCaseNumber.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCaseNumber.fulfilled, (state, action: PayloadAction<CaseNumber[]>) => {
        state.status = "idle";
        state.caseNumbers = action.payload;
      })
      .addCase(fetchCaseNumber.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(addCase.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addCase.fulfilled, (state, action: PayloadAction<number>) => {
        state.status = "idle";
        state.caseId = action.payload;
      })
      .addCase(addCase.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(addCasePermission.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addCasePermission.fulfilled, (state, action: PayloadAction<Permission>) => {
        state.status = "idle";
        state.permission.push(action.payload);
      })
      .addCase(addCasePermission.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })

      .addCase(addCaseStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addCaseStatus.fulfilled, (state, action: PayloadAction<CaseStatus>) => {
        state.status = "idle";
        state.caseStatusData.push(action.payload);
      })
      .addCase(addCaseStatus.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })

      .addCase(addCaseRiskAssessment.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addCaseRiskAssessment.fulfilled, (state, action: PayloadAction<RiskAssessment>) => {
        state.status = "idle";
        state.riskAssessment.push(action.payload);
      })
      .addCase(addCaseRiskAssessment.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })

      .addCase(addcaseAML.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addcaseAML.fulfilled, (state, action: PayloadAction<CaseAML>) => {
        state.status = "idle";
        state.caseAML.push(action.payload);
      })
      .addCase(addcaseAML.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })

      .addCase(addCaseClients.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addCaseClients.fulfilled, (state, action: PayloadAction<ClientGreeting>) => {
        state.status = "idle";
        state.clientGreeting.push(action.payload);
      })
      .addCase(addCaseClients.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })

      .addCase(addCaseContact.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addCaseContact.fulfilled, (state, action: PayloadAction<CaseContactData>) => {
        state.status = "idle";
        state.caseContactData.push(action.payload);
      })
      .addCase(addCaseContact.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(updateCase.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateCase.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = "idle";
        if (action.payload != null) {
          state.cases = action.payload || [];
        }
      })
      .addCase(updateCase.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(deleteCase.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteCase.fulfilled, (state, action: PayloadAction<number>) => {
        state.status = "idle";
      })
      .addCase(deleteCase.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      });
  }
});

export default CaseManagementSlice.reducer;
