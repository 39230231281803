import { Modal } from "flowbite-react";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object, number } from "yup";
import { useAppDispatch } from "../../../../../store/hooks/redux-hooks";
import { SelectBox } from "../../../../SelectBox";
import { RootState } from "../../../../../store/store";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { InputBox } from "../../../../InputBox";

function AddUploadDocument({
  openDrawer,
  Close,
}: {
  openDrawer: boolean;
  Close: any;
}) {
  const dispatch = useAppDispatch();
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [modalPlacement, setModalPlacement] = useState("center");
  const caseDetailsData = useSelector((state: RootState) => state.case.caseDto);
  const [isLoading, setIsLoading] = useState(false);

  const MethodData = [
    { label: "Email", value: "email" },
    { label: "Post", value: "post" },
    { label: "Other", value: "other" },
    { label: "Inperson", value: "inperson" },
  ];

  const MethodDataOption = MethodData.map((type: any) => ({
    label: type.label,
    value: type.value,
  }));

  const validationSchema = object().shape({
    documentTitle: string(),
    description: string(),
    method: string(),
    dateSentReceived: string(),
    attachment: string(),
    corrDocDocumentRef: string(),
    isAntiMoneyLaunderingMeasure: number(),
    caseId: number(),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      corrDocDocumentRef: "sent",
    },
  });

  const onSubmit = async (data: any) => {
    console.log(JSON.stringify(data, null, 2));

    // setIsLoading(true);
    // try {
    //   await dispatch(AddUploadDocument({ data })).unwrap();
    //   Close();
    //   toast.success("Successfully Added Case Risk");
    // } catch (error: any) {
    //   toast.error(error.toString());
    // } finally {
    //   setIsLoading(false);
    // }
  };

  return (
    <Modal
      className={`backdrop-blur-sm items-center justify-center`}
      show={openDrawer}
      position={modalPlacement}
      onClose={() => {
        reset();
        Close();
      }}
      initialFocus={emailInputRef}
      size="2xl"
    >
      <Modal.Header className="bg-white-bg dark:bg-dark_bg p-5">
        <span className="text-blue_gray-900 dark:text-white-bg text-xl w-auto">
          Add New Document Title
        </span>
      </Modal.Header>
      <Modal.Body className="bg-white-bg dark:bg-dark_bg">
        <form onSubmit={handleSubmit(onSubmit)} className="mx-auto">
          <div className="space-y-3 w-full">
            <div className="bg-sky-50 dark:bg-gray-900 p-5 space-y-2">
              <div className="flex items-center">
                <label className="w-1/4 text-sm font-semibold dark:text-white-bg">
                  Case Type
                </label>
                <span className="mx-2 text-sm font-semibold dark:text-white-bg">
                  :
                </span>
                <p className="w-3/4 text-sm dark:text-white-bg">
                  {caseDetailsData?.area} {">>"} {caseDetailsData?.caseType}{" "}
                  {">>"} {caseDetailsData?.subCaseType}
                </p>
              </div>
              <div className="flex items-center">
                <label className="w-1/4 text-sm font-semibold dark:text-white-bg">
                  Case Ref
                </label>
                <span className="mx-2 text-sm font-semibold dark:text-white-bg">
                  :
                </span>
                <p className="w-3/4 text-sm dark:text-white-bg">
                  {caseDetailsData?.caseReferenceAuto}
                </p>
              </div>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-1 gap-x-3">
            <InputBox
              title="Document Title"
              dataType="text"
              placeholder="Enter Document Title"
              required={false} // Show red asterisk and make it required
              {...register("documentTitle")}
            />
            <div className="">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Attachment
              </label>
              <input
                className={`block w-full text-sm text-gray-900 border-2 border-gray-300 rounded-md cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 `}
                {...register("attachment")}
                type="file"
                multiple
              />
            </div>
            </div>
            <div className="flex items-center my-5">
              <input
                id="link-checkbox"
                {...register("isAntiMoneyLaunderingMeasure", {
                  setValueAs: (value) => (value ? 1 : 0),
                })}
                type="checkbox"
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                Is an Anti Money Laundering Measure?{" "}
              </label>
            </div>
            <div className="mb-5">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Description
              </label>
              <textarea
                className={`block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                placeholder="Write your thoughts here..."
                {...register("description")}
              ></textarea>
            </div>

            <div className="flex items-center justify-end">
              <div className="flex items-center  me-4">
                <input
                  type="radio"
                  value="sent"
                  checked={watch("corrDocDocumentRef") === "sent"}
                  onChange={() => setValue("corrDocDocumentRef", "sent")}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Sent
                </label>
              </div>
              <div className="flex items-center me-4">
                <input
                  type="radio"
                  value="received"
                  checked={watch("corrDocDocumentRef") === "received"}
                  onChange={() => setValue("corrDocDocumentRef", "received")}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Received
                </label>
              </div>
              <div className="flex items-center me-4">
                <input
                  type="radio"
                  value="other"
                  checked={watch("corrDocDocumentRef") === "other"}
                  onChange={() => setValue("corrDocDocumentRef", "other")}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Other
                </label>
              </div>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-1 gap-x-3">
            <div className="">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Method
              </label>
              <SelectBox
                className="border border-gray-300 border-solid bg-gray-100 text-sm rounded-md block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                indicator={
                  <svg
                    width="15"
                    className="fill-current mr-2"
                    height="15"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                }
                isMulti={false}
                name="frameFiftySix"
                isSearchable={true}
                placeholder="Select"
                options={MethodDataOption}
                shape="round"
                color="indigo_50"
                size="xs"
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setValue("method", selectedOption);
                  }
                }}
              />
            </div>
            <InputBox
              title="Date"
              dataType="Date"
              placeholder="Enter Document Title"
              required={false} // Show red asterisk and make it required
              {...register("dateSentReceived")}
            />
            </div>
            <div className="flex md:flex-col gap-2 flex-row justify-start w-full my-5">
              <div className="flex flex-row gap-2 items-end justify-end w-full">
                <button
                  onClick={() => {
                    reset();
                    Close();
                  }}
                  className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isLoading}
                  className={`cursor - pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[120px] ${
                    isLoading ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                >
                  {isLoading ? (
                    <svg
                      className="animate-spin h-5 w-5 text-white-bg mx-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v8H4z"
                      ></path>
                    </svg>
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export { AddUploadDocument };
