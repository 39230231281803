import { useEffect, useRef, useState } from "react";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SearchInput } from "../Filters/SearchInput";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { FaEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { MdOutlineRefresh } from "react-icons/md";
import { AddBankReceipt } from "../Modal/AddClientAccounts/AddClientPostings/AddBankReceipt";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { fetchAllBankReceipt } from "../../store/slice/Accounting/ClientAccounts/ClientPosting/BankReceiptSlice";
import { toast } from "react-toastify";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { Pagination } from "../Pagination";

function BankReceipt() {
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isLeftButtonOpen, setIsLeftButtonOpen] = useState(true);

  const [bankReceiptData] = useState<any>([]);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const bankRecpit = useSelector(
    (state: RootState) => state.bankReceipt.searchBankReciptResult
  );
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [currentBankReceipts, setCurrentBankReceipts] = useState<any[]>([]);
  const [recepitDatas, setRecepitDatas] = useState<any[]>([]);
  const itemsPerPage = 10;

  const effectServiceCall = useRef(false);
  const errorShownRef = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current) {
      dispatch(fetchAllBankReceipt())
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
    }
  }, [dispatch]);
  useEffect(() => {
    if (bankRecpit && bankRecpit.bankRecriptResult) {
      setRecepitDatas(bankRecpit.bankRecriptResult);
    }
  }, [bankRecpit]);

  const filterBankReceiptBySearchQuery = () => {
    const filtered = recepitDatas.filter(
      (bankReceipt: any) =>
        bankReceipt?.firstName
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        bankReceipt?.lastName
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        bankReceipt?.paymentType
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        bankReceipt?.caseName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    // console.log("Filtered data:", filtered); // Check what's being filtered
    return filtered;
  };

  useEffect(() => {
    if (recepitDatas && recepitDatas.length > 0) {
      // Make sure data is available
      const filteredByBankReceipt = filterBankReceiptBySearchQuery();
      const indexOfLastBankReceipt = currentPage * itemsPerPage;
      const indexOfFirstBankReceipt = indexOfLastBankReceipt - itemsPerPage;
      const currentBankReceipt = filteredByBankReceipt.slice(
        indexOfFirstBankReceipt,
        indexOfLastBankReceipt
      );
      const totalPages = Math.ceil(filteredByBankReceipt.length / itemsPerPage); // Use filtered data length
      setTotalPages(totalPages);
      setCurrentBankReceipts(currentBankReceipt);
    } else {
      setCurrentBankReceipts([]); // Handle case when no data matches
    }
  }, [recepitDatas, searchQuery, currentPage]);

  const toggleDrawer = () => {
    setIsEdit(false);
    setOpenModal(!openModal);
  };

  if (openModal) {
    return (
      <AddBankReceipt
        openDrawer={openModal}
        Close={toggleDrawer}
        isEdit={isEdit}
        bankReceipt={bankReceiptData}
      />
    );
  }

  return (
    <>
      <div className="">
        <div className="mb-5">
          <SubHeaderTitle
            title="Bank Receipt"
            buttonName="Receipt"
            isAdd={true}
            onClickAdd={toggleDrawer}
          />
        </div>
        {/* <div className="flex flex-row md:flex-col lg:flex-col xl:flex-col justify-between my-5 gap-4 w-full md:gap-3">
          <div className="md:flex lg:flex xl:flex">
            <SearchInput title="Keyword" />
          </div>
          <div>
            <div className="grid grid-cols-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 md:gap-1 w-full">
              <CheckBoxFilter title="Client Bank" />
              <CheckBoxFilter title="Areas of Practice" />
              <CheckBoxFilter title="Case Type" />
              <CheckBoxFilter title="Case Sub Type" />
              <CheckBoxFilter title="Staff" />
              <CheckBoxFilter title="Status" />
              <CheckBoxFilter title="Payment Type" />
              <CheckBoxFilter title="Date Between" />
            </div>
          </div>
        </div> */}
        <div className="flex flex-row md:flex-col lg:flex-col xl:flex-col justify-between my-5 gap-4 w-full md:gap-3">
          <div className="md:flex lg:flex xl:flex">
            <SearchInput title="Keyword" />
          </div>
          <div>
            <div
              className={`grid ${
                isLeftButtonOpen ? "grid-cols-4" : "grid-cols-6"
              } md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 md:gap-1 w-full`}
            >
              <CheckBoxFilter title="Client Bank" />
              <CheckBoxFilter title="Areas of Practice" />
              <CheckBoxFilter title="Case Type" />
              <CheckBoxFilter title="Case Sub Type" />
              <CheckBoxFilter title="Staff" />
              <CheckBoxFilter title="Status" />
              <CheckBoxFilter title="Payment Type" />
              <CheckBoxFilter title="Date Between" />
            </div>
          </div>
        </div>

        <div className="relative overflow-x-auto sm:rounded-lg">
          {initialLoading ? <MainAnimation /> : ""}
          <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
            <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <th scope="col" className="px-6 py-3">
                  Date
                </th>
                <th scope="col" className="px-6 py-3">
                  Receipt Number
                </th>
                <th scope="col" className="px-6 py-3">
                  Client and Case Details
                </th>
                <th scope="col" className="px-6 py-3">
                  Payment Method
                </th>
                <th scope="col" className="px-6 py-3">
                  Amount
                </th>
                <th scope="col" className="px-6 py-3">
                  Receipt
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {currentBankReceipts.map((bankReceipt: any, index) => (
                <tr
                  key={index}
                  className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  <td className="px-6 py-3">
                    {
                      new Date(bankReceipt.entryDate)
                        .toISOString()
                        .split("T")[0]
                    }
                  </td>
                  <td className="px-6 py-3">REC{bankReceipt.receiptNumber}</td>
                  <td className="px-6 py-3">{bankReceipt.caseReferenceAuto}</td>
                  <td className="px-6 py-3">
                    {bankReceipt.paymentType}
                    <br />
                    {new Date(bankReceipt.entryDate).toLocaleDateString(
                      "en-US",
                      {
                        year: "numeric",
                        month: "long",
                        day: "2-digit",
                      }
                    )}
                  </td>
                  <td className="px-6 py-3">{bankReceipt.amount}£</td>
                  <td className="px-6 py-3">
                    <a className="hover:text-blue-500" href="">
                      Rec{bankReceipt.receiptNumber}
                    </a>
                  </td>

                  <td className="cursor-pointer px-6 py-3">
                    <div className="flex gap-3 w-full">
                      <FaEye className="fill-gray-500 w-6 h-6" />
                      <FaEdit className="fill-blue-500 w-6 h-6" />
                      <RiDeleteBin5Fill className="fill-red-600 w-6 h-6" />
                      <MdOutlineRefresh className="fill-blue-800 w-6 h-6" />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {currentBankReceipts.length === 0 && (
            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
              <p className="text-red-800">No Bank New Receipts found.</p>
            </div>
          )}
        </div>
        {currentBankReceipts.length > 0 && (
          <div className="w-full flex flex-col pb-[100px]">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        )}
      </div>
    </>
  );
}

export { BankReceipt };
