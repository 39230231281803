import { SubHeaderTitle } from "../SubHeaderTitle";
import { IoIosPrint } from "react-icons/io";
import { BsFileEarmarkExcelFill } from "react-icons/bs";

function IncomeReport() {

    return (
        <>
            <div className="">
                <div className="mb-5">
                    <SubHeaderTitle title="Income Report" />
                </div>
                <div className="flex gap-x-2 ietms-center justify-end">
            <button
              type="submit"
              className="flex items-center gap-x-2 bg-blue-500 hover:bg-blue-800 text-white-bg cursor-pointer px-5 py-2 rounded text-center text-sm"
            >
              <IoIosPrint className="w-5 h-5" />
              Print
            </button>
            <button
              type="submit"
              className="flex items-center gap-x-2 bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer px-3 py-2 rounded text-center border text-sm"
            >
              <BsFileEarmarkExcelFill className="w-5 h-5" />
              Export to Excel
            </button>
          </div>
                    <div className="my-5 text-center">
                        <h1 className="text-2xl font-bold">Shan & Co Solicitors</h1>
                        <p className="text-lg font-semibold">Income Ledger - All</p>
                        <p className="font-semibold">06 Sep 2024 - 06 Sep 2024</p>
                    </div>
                    <div className="relative overflow-x-auto sm:rounded-lg my-6">
                    <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                        <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <th scope="col" className="px-6 py-3">
                                Income Type
                                </th>
                                <th scope="col" className="px-6 py-3">
                                Balance	
                                </th>
                                <th scope="col" className="px-6 py-3">
                                Percentage
                                </th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </>
    )
}

export { IncomeReport };