import { useEffect, useRef, useState } from "react";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SearchInput } from "../Filters/SearchInput";
import { AddRefundCreditNote } from "../Modal/AddClientAccounts/AddClientInvoice/AddRefundCreditNote";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { RootState } from "../../store/store";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { fetchCARefundCreditNoteSearch } from "../../store/slice/Accounting/ClientAccounts/Invoice/CARefundCreditNoteSlice";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
function RefundCreditNote() {
    const [openModal, setOpenModal] = useState(false);
    const dispatch = useAppDispatch();
    const [initialLoading, setInitialLoading] = useState(true);
    const caRefundCreditNote = useSelector((state: RootState) => state.carefundCreditNote.caRefundCreditNote);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [getDataList, setGetDataList] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [currentFetchDataList, setCurrentFetchDataList] = useState<any[]>([]);
    const [totalPages, setTotalPages] = useState(0);

    const toggleDrawer = () => {
        setOpenModal(!openModal);
    };

    const effectServiceCall = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
            dispatch(fetchCARefundCreditNoteSearch())
                .then(() => setInitialLoading(false))
                .catch(() => setInitialLoading(false));
            effectServiceCall.current = true;
        }
    }, [dispatch]);

    useEffect(() => {
        if (caRefundCreditNote && caRefundCreditNote.invoiceDisbResult) {
            setGetDataList(caRefundCreditNote.invoiceDisbResult);
        }
    }, [caRefundCreditNote]);

    const filterGetDataSearchQuery = () => {
        return getDataList.filter(
            (ledger: any) =>
                ledger?.billingDesc.toLowerCase().includes(searchQuery.toLowerCase()) ||
                ledger?.disbursementType.toLowerCase().includes(searchQuery.toLowerCase()) ||
                ledger?.lastName.toLowerCase().includes(searchQuery.toLowerCase())
        );
    };
    useEffect(() => {
        if (getDataList != null && getDataList.length > 0) {
            const filteredByDisbursement = filterGetDataSearchQuery();
            const indexOfLastData = currentPage * itemsPerPage;
            const indexOfFirstData = indexOfLastData - itemsPerPage;
            const currentGetData = filteredByDisbursement.slice(
                indexOfFirstData,
                indexOfLastData
            );
            const totalPages = Math.ceil(getDataList.length / itemsPerPage);
            setTotalPages(totalPages)
            setCurrentFetchDataList(currentGetData)
        }
    }, [getDataList])

    if (openModal) {
        return (
            <AddRefundCreditNote openDrawer={openModal} Close={toggleDrawer} modalType="Add" />
        )
    }
    return (
        <>
            <div className="">
            {initialLoading ? <MainAnimation /> : ""}
                <div className="mb-5">
                    <SubHeaderTitle title="Refund Credit Note" buttonName="Refund" isAdd={true} onClickAdd={toggleDrawer} />
                </div>
                <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
                    <SearchInput title="Keyword" />
                    <div>
                        <div className="flex gap-4 md:gap-1 w-full">
                            <CheckBoxFilter title="Status" />
                        </div>
                    </div>
                </div>
                <div className="relative overflow-x-auto sm:rounded-lg">
                    <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                        <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <th scope="col" className="px-6 py-3">
                                    Date
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Case Reference
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Refund Amount
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Credit Note
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Invoice
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Action
                                </th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </>
    )
}

export { RefundCreditNote };