import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SearchInput } from "../Filters/SearchInput";
import { SubHeaderTitle } from "../SubHeaderTitle";
function VATReturn() {

    return (
        <>
            <div className="">
                <div className="mb-5">
                    <SubHeaderTitle title="VAT Return" />

                </div>
                <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
                    <SearchInput title="Keyword" />
                    <div>
                        <div className="flex gap-4 md:gap-1 w-full">
                            <CheckBoxFilter title="VAT Period" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { VATReturn };