import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";



const GetTimeEntryServiceSearch = async () => {
    try {
        const response = await axiosInstance.post("TimerEntriesSearch", {});
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}
const PostTimerEntries = async (data: any) => {
    try {
        const response = await axiosInstance.post("CATimerEntriesSave", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const TimeEntryService = {
    GetTimeEntryServiceSearch,
    PostTimerEntries,
};


export default TimeEntryService;
