import { Modal } from "flowbite-react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FcPlus } from "react-icons/fc";
import { FcCancel } from "react-icons/fc";
import { string, object, number } from "yup";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../../../../store/hooks/redux-hooks";
import { addOfficeJournalEntry, OfficeJournalEntry } from "../../../../../store/slice/Accounting/OfficeAccounts/OfficePostings/OfficeJournalEntrySlice";
import { toast } from 'react-toastify';
import { AddInvoiceProfessionalFees } from "../../../AddClientAccounts/AddClientInvoice/AddQuickInvoice/AddInvoiceProfessionalFees";
import { InputBox } from "../../../../InputBox";
import { FaPlus } from "react-icons/fa6";
import { NumericFormat } from "react-number-format";
import { fetchNextReceiptNumber } from "../../../../../store/slice/Accounting/OfficeAccounts/OfficeBankAccountSlice";
import { OfficeAccountConstant } from "../../../../../store/model/Accounting/OfficeAccount/officeaccountconst";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { MainAnimation } from "../../../../loadingAnimation/MainAnimation";
import { SearchAccount } from "../../../SearchAccount";

function AddOfficeJournalEntry({
  openDrawer,
  Close,
  isEdit,
  officeJournalEntry,
}: {
  openDrawer: boolean;
  Close: any;
  isEdit: boolean;
  officeJournalEntry: any;
}) {
  const [title] = useState(
    !isEdit ? "Add Journal Entry" : "Edit Journal Entry"
  );
  const { receiptNumber } = useSelector((state: RootState) => state.officeBankAccount);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [modalPlacement] = useState("center");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [autoReceiptNumber, setAutoReceiptNumber] = useState<number>();
  const effectServiceCall = useRef(false);
  const errorShownRef = useRef(false);

  const [openFromBankAccountModal, setOpenFromBankAccountModal] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(0); // Track which row is being updated
  const [selectedValue, setSelectedValue] = useState<any>();
  const [addJournalEntries, setAddJournalEntries] = useState([
    { accountType: "", accountId: "", description: "", debitAmount: "", creditAmount: "" },
  ]);



  const toggleFromBankAccountModal = (index: number) => {
    setSelectedRowIndex(index);
    setOpenFromBankAccountModal(!openFromBankAccountModal);
  };


  const handleAccountSelect = (selectedAccount: any) => {
    console.log(selectedAccount);
    if (selectedRowIndex !== null) {
      const updatedRows = [...addJournalEntries];
      updatedRows[selectedRowIndex].accountType = `${selectedAccount.accountType} -${selectedAccount.accountName}`
        ; // Save accountType
      updatedRows[selectedRowIndex].accountId = selectedAccount.accountId; // Save bankAccountId

      setAddJournalEntries(updatedRows);

    }
    setOpenFromBankAccountModal(false);
  };
  const addRow = () => {
    setAddJournalEntries([...addJournalEntries, { accountType: "", accountId: "", description: "", debitAmount: "", creditAmount: "" }]);
  };



  useEffect(() => {
    if (!effectServiceCall.current) {
      const fetchPromises = [dispatch(fetchNextReceiptNumber(OfficeAccountConstant.JournalEntry))];

      Promise.all(fetchPromises)
        .then(() => {
          setInitialLoading(false);
        })
        .catch((error) => {
          if (!errorShownRef.current) {
            toast.error("Error fetching data: " + error.message);
            errorShownRef.current = true;
          }
          setInitialLoading(false);
        });

      effectServiceCall.current = true;
    }
  }, [dispatch]);

  useEffect(() => {
    if (receiptNumber != null) {
      setValue("journalEntryNumber", receiptNumber)
      setAutoReceiptNumber(receiptNumber)
    }
  }, [receiptNumber]);



  const validationSchema = object().shape({
    journalEntryNumber: number().required('Journal Entry Number is required'),
    journalEntryDate: string().transform((value, originalValue) => {
      if (originalValue === "" || value === null) return null;
      return new Date(value).toISOString().split("T")[0];
    }),
    journalEntryDescription: string(),
    bankAccountId: string(),

  });

  const {
    register,
    handleSubmit, // Use handleSubmit from useForm
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });


  const handleInputChange = (index: number, field: string, value: any) => {
    const updatedRows = [...addJournalEntries];
    updatedRows[index] = { ...updatedRows[index], [field]: value };
    setAddJournalEntries(updatedRows);
  };

  // Function to remove a row
  const removeRow = (index: number) => {
    setAddJournalEntries(addJournalEntries.filter((_, i) => i !== index));
  };

  // Handle form submission
  const onSubmit = async (data: any) => {
    // Combine the form data and rows data
    // const combinedData = {
    //   ...data, // Form validation schema values
    //   addJournalEntries, // Table data
    // };

    const OfficeJournalEntrys: OfficeJournalEntry = {
      journalEntryNumber: data.journalEntryNumber,
      journalEntryDate: data.journalEntryDate,
      journalEntryDescription: data.journalEntryDescription,
      journalEntryLines: addJournalEntries.map(journal => ({
        accountId: journal.accountId,
        description: journal.description,
        debitAmount: journal.debitAmount,
        creditAmount: journal.creditAmount,
      })),
    };

    console.log(JSON.stringify(OfficeJournalEntrys, null, 2)); // Log combined data

    setIsLoading(true);
    try {
      await dispatch(addOfficeJournalEntry(OfficeJournalEntrys)).unwrap();
      Close();
      toast.success("Successfully Added JournalEntry");
    } catch (error: any) {
      toast.error(error.toString());
    } finally {
      setIsLoading(false);
    }
  };

  // Handle edit click to enable/disable fields
  const handleEditClick = () => {
    setIsDisabled(!isDisabled);
  };

  // Load existing office journal entry data when editing
  useEffect(() => {
    if (isEdit && officeJournalEntry) {
      reset(officeJournalEntry);
    }
  }, [officeJournalEntry, isEdit, reset]);


  return (
    <Modal
      className={`backdrop-blur-sm items-center justify-center`}
      show={openDrawer}
      position={modalPlacement}
      onClose={() => {
        reset();
        Close();
      }}
      initialFocus={emailInputRef}
      size="3xl"
    >
      <Modal.Header className="bg-white-bg dark:bg-dark_bg p-5">
        <span className="text-blue_gray-900 dark:text-white-bg text-xl w-auto">
          {title}
        </span>
      </Modal.Header>
      <Modal.Body className="bg-white-bg dark:bg-dark_bg">
        <form onSubmit={handleSubmit(onSubmit)} className="mx-auto">
          <div className="">
            {initialLoading ? (<MainAnimation />) : ""}
            <div className="grid grid-cols-3 md:grid-cols-1 gap-x-10 gap-y-1 w-full my-5 ">
              <div className="relative">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                  Journal Entry
                </label>
                <input
                  type="text"
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                  {...register("journalEntryNumber")}
                  placeholder="Enter Transaction Date"
                />
              </div>
              <div className="relative">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                  Date
                </label>
                <input
                  type="date"
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                  {...register("journalEntryDate")}
                  max={new Date().toISOString().split("T")[0]}
                  placeholder="Enter Date"
                />
              </div>
              <div className="relative">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                  Description
                </label>
                <input
                  type="text"
                  className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                  {...register("journalEntryDescription")}
                  placeholder="Enter Description"
                />
              </div>
            </div>
            <div className="mb-5">
              <button
                className="flex items-center bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
                type="button"
                onClick={addRow}
              >
                <FaPlus className="w-4 h-4 fill-white-bg" />
                <span className="text-white-bg mx-2">Add Line</span>
              </button>
            </div>
            <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400 my-5">
              <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                  <th scope="col" className="px-6 py-3">
                    Account
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Description
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Debit
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Credit
                  </th>
                  <th scope="col" className="px-6 py-3"></th>
                </tr>
              </thead>
              <tbody>
                {addJournalEntries.map((row, index) => (
                  <tr key={index} className="border-2">
                    <td className="px-6 py-3">
                      <div className="flex items-center">
                        <button type="button" onClick={() => toggleFromBankAccountModal(index)}>
                          <FcPlus />
                        </button>

                        {openFromBankAccountModal && (
                          <SearchAccount
                            openDrawer={openFromBankAccountModal}
                            Close={() => setOpenFromBankAccountModal(false)}
                            onAccountSelect={handleAccountSelect} type="Office" fkAccountTypeIds="1" // Handle account selection

                          />
                        )}

                        <InputBox
                          dataType="text"
                          placeholder="Account Type"
                          value={row.accountType} // Display the selected account type
                          onChange={(e) => handleInputChange(index, 'accountType', e.target.value)}
                        />
                      </div>
                    </td>

                    <td className="px-6 py-3">
                      <InputBox
                        dataType={"text"}
                        placeholder={"Description"}
                        value={row.description}
                        onChange={(e) =>
                          handleInputChange(index, "description", e.target.value)
                        }
                      />
                    </td>

                    <td className="px-6 py-3">
                      <NumericFormat
                        value={row.creditAmount}
                        onValueChange={(values) => handleInputChange(index, 'creditAmount', values.floatValue)}
                        decimalScale={2}
                        className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                      />
                    </td>

                    <td className="px-6 py-3">
                      <NumericFormat
                        value={row.debitAmount}
                        onValueChange={(values) => handleInputChange(index, 'debitAmount', values.floatValue)}
                        decimalScale={2}
                        className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                      />
                    </td>

                    <td className="px-6 py-3">
                      <button type="button" onClick={() => removeRow(index)}>
                        <FcCancel className="w-6 h-6" />
                      </button>
                    </td>
                  </tr>
                ))}

                {/* Row for Total Calculation */}
                <tr>
                  <td></td>
                  <td className="px-6 py-3 text-right font-semibold">Total</td>
                  {/* Total Credit */}
                  <td className="px-6 py-3 text-right font-semibold">
                    £
                    {addJournalEntries
                      .reduce((acc, row) => acc + (parseFloat(row.creditAmount) || 0), 0)
                      .toFixed(2)}
                  </td>
                  {/* Total debitAmount */}
                  <td className="px-6 py-3 text-right font-semibold">
                    £
                    {addJournalEntries
                      .reduce((acc, row) => acc + (parseFloat(row.debitAmount) || 0), 0)
                      .toFixed(2)}
                  </td>
                  <td className="px-6 py-3"></td>
                </tr>
              </tbody>

            </table>
            <div className="flex col-span-2 gap-2 justify-end">
              <button
                type="button"
                onClick={() => {
                  Close();
                  reset();
                }}
                className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={isLoading}
                className={`cursor - pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[120px] ${isLoading ? "opacity-50 cursor-not-allowed" : ""
                  }`}
              >
                {isLoading ? (
                  <svg
                    className="animate-spin h-5 w-5 text-white-bg mx-auto"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8v8H4z"
                    ></path>
                  </svg>
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export { AddOfficeJournalEntry };
