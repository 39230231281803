import { useEffect, useRef, useState } from "react";
import { SearchInput } from "../Filters/SearchInput";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { IoIosPrint } from "react-icons/io";
import { BsFileEarmarkExcelFill } from "react-icons/bs";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { fetchAllTrialBalance } from "../../store/slice/Accounting/OfficeAccounts/OfficeReports/TrialBalanceSlice";
import { toast } from "react-toastify";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { Pagination } from "flowbite-react";
import { TrialBalanceTransactions } from "../Modal/AddOfficeAccounts/TrialBalanceTransactions";

function TrialBalance() {
  const [isEdit, setIsEdit] = useState(false);
  const [trialBalanceData, setTrialBalanceData] = useState<any>([]);
  const [openTrialModal, setOpenTrialModal] = useState(false);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const { trialBalances, status, error } = useSelector(
    (state: RootState) => state.trialBalance
  );
  const [totalPages, setTotalPages] = useState(0);
  const [currentTrialBalance, setCurrentTrialBalance] = useState<any[]>([]);
  const itemsPerPage = 10;
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);

  const effectServiceCall = useRef(false);
  const errorShownRef = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current) {
      dispatch(fetchAllTrialBalance())
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
    }

    if (status === "failed" && !errorShownRef.current) {
      toast.error(error);
      errorShownRef.current = true;
    }
  }, [dispatch, status, error]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    if (trialBalances != null && trialBalances.length > 0) {
      const filteredByTrialBalance = filterTrialBalanceBySearchQuery();
      const indexOfLastTrialBalance = currentPage * itemsPerPage;
      const indexOfFirstTrialBalance = indexOfLastTrialBalance - itemsPerPage;
      const currentTrialBalance = filteredByTrialBalance.slice(
        indexOfFirstTrialBalance,
        indexOfLastTrialBalance
      );
      const totalPages = Math.ceil(trialBalances.length / itemsPerPage);
      setTotalPages(totalPages);
      setCurrentTrialBalance(currentTrialBalance);
    }
  }, [trialBalances]);

  const filterTrialBalanceBySearchQuery = () => {
    return trialBalances.filter((financialYear: any) =>
      financialYear?.accountName
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase())
    );
  };

  const toggleTrialModal = () => {
    setOpenTrialModal(!openTrialModal);
  }

  return (
    <>
      <div className="">
        <div className="mb-5">
          <SubHeaderTitle title="Trial Balance" />
        </div>
        <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
          <SearchInput title="Keyword" />
        </div>
        <div className="flex gap-x-2 ietms-center justify-end">
            <button
              type="submit"
              className="flex items-center gap-x-2 bg-blue-500 hover:bg-blue-800 text-white-bg cursor-pointer px-5 py-2 rounded text-center text-sm"
            >
              <IoIosPrint className="w-5 h-5" />
              Print
            </button>
            <button
              type="submit"
              className="flex items-center gap-x-2 bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer px-3 py-2 rounded text-center border text-sm"
            >
              <BsFileEarmarkExcelFill className="w-5 h-5" />
              Export to Excel
            </button>
          </div>
        <div className="my-5 text-center">
          <h1 className="text-2xl font-bold">LAIT-LEGAL</h1>
          <p className="text-lg font-semibold">
            Trial Balance as at 06 Sep 2024
          </p>
        </div>
        <div className="relative overflow-x-auto sm:rounded-lg my-6">
          <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
            {initialLoading ? <MainAnimation /> : ""}
            <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <th scope="col" className="px-6 py-3">
                  Account Code
                </th>
                <th scope="col" className="px-6 py-3">
                  Account Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Debit
                </th>
                <th scope="col" className="px-6 py-3">
                  Credit
                </th>
                <th scope="col" className="px-6 py-3"></th>
              </tr>
            </thead>
            <tbody>
              {currentTrialBalance.map((trialBalance: any, index) => (
                <tr
                  key={index}
                  className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  <td className="px-6 py-3">{trialBalance.accountCode}</td>
                  <td className="px-6 py-3">{trialBalance.accountName}</td>
                  <td className="px-6 py-3">{trialBalance.debit}</td>
                  <td className="px-6 py-3">{trialBalance.credit}</td>
                  <td className="cursor-pointer px-6 py-3">
                    <button className="text-xs py-1 px-3 font-medium text-white-bg bg-green-500 rounded hover:bg-green-600">
                      View
                    </button>
                  </td>
                </tr>
              ))}
              <tr
                  className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  <td className="px-6 py-3">1</td>
                  <td className="px-6 py-3">Immigration</td>
                  <td className="px-6 py-3"></td>
                  <td className="px-6 py-3">£ 400.00</td>
                  <td className="cursor-pointer px-6 py-3">
                    <button type="button"
                    onClick={toggleTrialModal} 
                    className="text-xs py-1 px-3 font-medium text-white-bg bg-green-500 rounded hover:bg-green-600">
                      View
                    </button>
                    {(openTrialModal) &&
                                    <TrialBalanceTransactions openDrawer={openTrialModal} Close={toggleTrialModal} modalType={"Add"}                                    />
                                }
                  </td>
                </tr>
            </tbody>
          </table>
          {currentTrialBalance.length === 0 && (
            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
              <p className="text-red-800">No Trial Balance Details found.</p>
            </div>
          )}
        </div>
        {currentTrialBalance.length > 0 && (
          <div className="w-full flex flex-col pb-[100px]">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        )}
      </div>
    </>
  );
}

export { TrialBalance };
