import { useEffect, useRef, useState } from "react";
import { HeaderTitle } from "../HeaderTitle";
import { AddInvoiceNotices } from "../Modal/AccountSetup/AddInvoiceNotices";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { fetchAllInvoiceNotice } from "../../store/slice/Accounting/AccountSetup/InvoiceNoticeSlice";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { toast } from "react-toastify";
import { Pagination } from "../Pagination";
import { MdDelete } from "react-icons/md";
import { SubHeaderTitle } from "../SubHeaderTitle";

function InvoiceNotices() {
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const dispatch = useAppDispatch();
    const [initialLoading, setInitialLoading] = useState(true);
    const { invoiceNotices, status, error } = useSelector((state: RootState) => state.invoiceNotice);
    const [invoiceNoticeData, setInvoiceNoticeData] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;


    // pagination
    const indexOfLastInvoiceNotice = currentPage * itemsPerPage;
    const indexOfFirstInvoiceNotice = indexOfLastInvoiceNotice - itemsPerPage;
    const currentInvoiceNotice = invoiceNotices.slice(indexOfFirstInvoiceNotice, indexOfLastInvoiceNotice);
    const totalPages = Math.ceil(invoiceNotices.length / itemsPerPage);

    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
            dispatch(fetchAllInvoiceNotice())
                .then(() => setInitialLoading(false))
                .catch(() => setInitialLoading(false));
            effectServiceCall.current = true;
        }

        if (status === 'failed' && !errorShownRef.current) {
            // toast.error(error);
            errorShownRef.current = true;
        }
    }, [dispatch, status, error]);



    const toggleDrawer = () => {
        setIsEdit(false);
        setOpenModal(!openModal);
    };


    return (
        <div className="flex flex-col">
            <div className="">
                <SubHeaderTitle title="Invoice Notices" buttonName="Invoice Notice" isAdd={true} onClickAdd={toggleDrawer} />
                {(openModal) &&
                    <AddInvoiceNotices openDrawer={openModal} Close={toggleDrawer} isEdit={isEdit} invoiceNotice={invoiceNoticeData} />
                }
            </div>
            <div className="relative overflow-x-auto sm:rounded-lg my-5">
                <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                    {initialLoading ? (<MainAnimation />) : ""}
                    <thead className="text-sm bg-gray-50 dark:bg-gray-800 dark:text-gray-400">
                        <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                            <th scope="col" className="px-6 py-3">
                                Invoice Notice Title
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Is Default
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Edit
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Delete
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        {currentInvoiceNotice.map((invoiceNotice: any, index) => (
                            <tr key={index} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <td className="px-6 py-3">
                                    {invoiceNotice.title}
                                </td>
                                <td className="px-6 py-3">
                                    {invoiceNotice.isDefault == 1 ? "Yes" : "No"}
                                </td>
                                <td className="px-6 py-3 cursor-pointer">
                                    <svg className="fill-blue-500 w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" /></svg>
                                </td>
                                <td className="px-6 py-3 cursor-pointer">
                                    <MdDelete className="fill-red-600 w-6 h-6" />
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </table>
                {currentInvoiceNotice.length === 0 && (
                    <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
                        <p className="text-red-800">No InvoiceNotice found.</p>
                    </div>
                )}
            </div>
            {currentInvoiceNotice.length > 0 && (
                <div className="w-full flex flex-col pb-[100px]">
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={(page) => setCurrentPage(page)}
                    />
                </div>
            )}
        </div>
    )
}

export { InvoiceNotices };

