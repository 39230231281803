import { useEffect, useRef, useState } from "react";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SearchInput } from "../Filters/SearchInput";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { AddFinancialYear } from "../Modal/AddOfficeAccounts/AddGeneralSettings/AddFinancialYear";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { fetchAllFinancialYear } from "../../store/slice/Accounting/OfficeAccounts/GeneralSettings/FinancialYearSlice";
import { toast } from "react-toastify";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { Pagination } from "../Pagination";
import { formatDateOnlyDisplay } from "../../utils/date-time.utils";


function FinancialYear() {
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [financialYearData, setFinancialYearData] = useState<any>([]);
    const dispatch = useAppDispatch();
    const [initialLoading, setInitialLoading] = useState(true);
    const { financialYears, status, error } = useSelector((state: RootState) => state.financialYear);
    const [totalPages, setTotalPages] = useState(0);
    const [currentFinancialYear, setCurrentFinancialYear] = useState<any[]>([]);
    const itemsPerPage = 10;
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [currentPage, setCurrentPage] = useState(1);



    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
            let data = {
                keyword: ''
            }
            dispatch(fetchAllFinancialYear(data))
                .then(() => setInitialLoading(false))
                .catch(() => setInitialLoading(false));
            effectServiceCall.current = true;
        }

        if (status === 'failed' && !errorShownRef.current) {
            toast.error(error);
            errorShownRef.current = true;
        }
    }, [dispatch, status, error]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        if (financialYears != null && (financialYears.dataList != null && financialYears.dataList.length > 0)) {
            const totalPages = financialYears.totalRows;
            setTotalPages(totalPages)
            setCurrentFinancialYear(financialYears.dataList)
            
        }

    }, [financialYears])

    console.log(currentFinancialYear);


    const toggleDrawer = () => {
        setIsEdit(false);
        setOpenModal(!openModal);
    };

    return (
        <>
            <div className="">
                <div className="mb-5">
                    <SubHeaderTitle title="Financial Year" buttonName="Financial Year" isAdd={true} onClickAdd={toggleDrawer} />
                    {
                        (openModal) &&
                        <AddFinancialYear openDrawer={openModal} Close={toggleDrawer} isEdit={isEdit} financialYear={financialYearData} />
                    }
                </div>
                <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
                    <SearchInput title="Keyword" />
                    <div>
                        <div className="flex gap-4 md:gap-1 w-full">
                            <CheckBoxFilter title="Year" />
                        </div>
                    </div>
                </div>
                <div className="relative overflow-x-auto sm:rounded-lg">
                {initialLoading ? <MainAnimation /> : ""}
                    <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                        <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <th scope="col" className="px-6 py-3">
                                    Financial Year ID
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Financial Year
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Accounting Method
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Is Completed
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentFinancialYear.map((financialYear: any, index) => {
                                return(
                                <tr key={index} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    <td className="px-6 py-3">
                                        {financialYear.financialYearId}
                                    </td>
                                    <td className="px-6 py-3">
                                        {formatDateOnlyDisplay(financialYear.startDate)} - {formatDateOnlyDisplay(financialYear.endDate)}
                                    </td>
                                    <td className="px-6 py-3">
                                        {financialYear.accountingMethod}
                                    </td>
                                    <td className="px-6 py-3">
                                        {(financialYear.isCompleted === 0) ? "No" : "Yes"}
                                    </td>
                                    <td className="cursor-pointer px-6 py-3">
                                        <div className="flex gap-3 w-full">
                                            <FaEdit className="fill-blue-500 w-6 h-6" />
                                            <RiDeleteBin5Fill className="fill-red-600 w-6 h-6" />
                                        </div>
                                    </td>
                                </tr>
                            )} )}
                        </tbody>

                    </table>
                    {currentFinancialYear.length === 0 && (
                        <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
                            <p className="text-red-800">No Financial Year Details found.</p>
                        </div>
                    )}
                </div>
                {currentFinancialYear.length > 0 && (
                    <div className="w-full flex flex-col pb-[100px]">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={(page) => setCurrentPage(page)}
                        />
                    </div>
                )}
            </div>
        </>
    )
}

export { FinancialYear };