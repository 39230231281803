import React, { useState } from 'react';

interface InputBoxProps {
    title?: string;
    dataType?: string;
    placeholder?: string;
    value?: string;
    required?: boolean;
    disabled?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputBox: React.FC<InputBoxProps> = ({ title, dataType, placeholder, value, required = false, disabled, onChange }) => {
    const [error, setError] = useState<string | null>(null);

    const handleValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (required && !e.target.value) {
            setError(`${title} is required.`);
        } else {
            setError(null);
        }
        onChange?.(e); // If onChange is provided, call it
    };

    return (
        <div className="relative">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                {title} {required && <span className="text-red-500">*</span>}
            </label>
            <input
                type={dataType}
                placeholder={placeholder}
                value={value}
                onChange={handleValidation}
                disabled={disabled}
                className={`border border-gray-300 border-solid bg-gray-100 text-sm rounded-md block w-full p-3 dark:bg-gray-700  dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`} // Apply red border if there's an error
            />
            {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
        </div>
    );
};

export { InputBox };


// import React from 'react';

// interface InputBoxProps {
//     title?: string;
//     dataType?: string;
//     placeholder?: string;
//     value?: string;
//     disabled?: boolean;
//     onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;

// }
// const InputBox: React.FC<InputBoxProps> = ({  title, dataType, placeholder, disabled, value, onChange }) => {

//     return (
//         <div className="relative max-w-full col-span-2">
//             <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
//                 {title}
//             </label>
//             <input
//                 type={dataType}
//                 placeholder={placeholder}
//                 value={value} // Use value prop
//                 onChange={onChange} // Handle change event
//                 disabled={disabled} // Set disabled based on prop
//                 className={`border border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} 
//             />
//         </div>
//     )
// }

// export { InputBox };