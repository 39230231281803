import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import FeeTypeService from "../../../../services/Accounting/AccountSetup/FeeTypeService";



export interface FeeType {
    feeTypeId : number;
    feeCode: string;
    feeType: string;
    billingDescription : string;
    price: number;
    priceType : string;
    isActive: boolean;
    isVatInclude : boolean;
    fkVatTypeId : number;
};
// Define the state type for Checklist
type FeeTypeState = {
    feeTypes: FeeType[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: FeeTypeState = {
    feeTypes: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllFeeType = createAsyncThunk(
    "fetchAllFeeType",
    async (_, thunkAPI) => {
        try {
            const response = await FeeTypeService.GetAllFeeType();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addFeeType = createAsyncThunk(
    "addFeeType",
    async (data: any, thunkAPI) => {
        try {
            const response = await FeeTypeService.PostFeeType(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateFeeType = createAsyncThunk(
    "updateFeeType",
    async ({ id, data }: { id: number, data: FeeType }, thunkAPI) => {
        try {
            const response = await FeeTypeService.PutFeeType(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteFeeType = createAsyncThunk(
    "deleteFeeType",
    async (id: number, thunkAPI) => {
        try {
            await FeeTypeService.deleteFeeType(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const FeeTypeSlice = createSlice({
    name: "feeType",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllFeeType.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllFeeType.fulfilled, (state, action: PayloadAction<FeeType[]>) => {
                state.status = "idle";
                state.feeTypes = action.payload;
            })
            .addCase(fetchAllFeeType.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addFeeType.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addFeeType.fulfilled, (state, action: PayloadAction<FeeType>) => {
                state.status = "idle";
                state.feeTypes.push(action.payload);
            })
            .addCase(addFeeType.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateFeeType.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateFeeType.fulfilled, (state, action: PayloadAction<FeeType>) => {
                state.status = "idle";
                const index = state.feeTypes.findIndex(t => t.feeTypeId === action.payload.feeTypeId);
                if (index !== -1) {
                    state.feeTypes[index] = action.payload;
                }
            })
            .addCase(updateFeeType.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteFeeType.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteFeeType.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.feeTypes = state.feeTypes.filter(t => t.feeTypeId !== action.payload);
            })
            .addCase(deleteFeeType.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default FeeTypeSlice.reducer;
