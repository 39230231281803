import { Modal } from "flowbite-react";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object, boolean } from "yup";
import { useAppDispatch } from "../../../../store/hooks/redux-hooks";
import { addTaskRiskAssesment, updateTaskRiskAssesment } from "../../../../store/slice/SystemMaintenance/TaskAndAssesment/TaskRiskAssesmentSlice";
import { toast } from "react-toastify";

function AddRiskAssessment({
  openDrawer,
  Close,
  isEdit,
  taskRiskAssesment,
}: {
  openDrawer: boolean;
  Close: any;
  isEdit: boolean;
  taskRiskAssesment: any;
}) {
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState(true);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [modalPlacement, setModalPlacement] = useState("center");
  const [isLoading, setIsLoading] = useState(false);




  const validationSchema = object().shape({
    riskAssessmentName: string().required("Name is required"),
    description: string(),
    isActive: boolean(),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      isActive: true
    }
  });

  const onSubmit = async (data: any) => {
    console.log(JSON.stringify(data, null, 2));
    setIsLoading(true);

    if (isEdit === true) {
      try {
        await dispatch(addTaskRiskAssesment(data)).unwrap();
        Close();
        toast.success("Successfully update Task Risk Assesment");
      } catch (error: any) {
        toast.error(error.toString());
      } finally {
        setIsLoading(false);
      }
    } else {
      try {
        await dispatch(addTaskRiskAssesment(data)).unwrap();
        Close();
        toast.success("Successfully Added Task Risk Assesment");
      } catch (error: any) {
        toast.error(error.toString());
      } finally {
        setIsLoading(false);
      }
    }

  };
  const onError = (errors: any) => {
    console.log("Form submission errors", errors);
  };

  useEffect(() => {
    if (isEdit && taskRiskAssesment !== null) {
      reset(taskRiskAssesment);
    }
  }, [taskRiskAssesment, isEdit, reset,]);

  return (
    <Modal
      className={`backdrop-blur-sm items-center justify-center`}
      show={openDrawer}
      position={modalPlacement}
      onClose={() => {
        reset();
        setOpenModal(Close);
      }}
      initialFocus={emailInputRef}
    >
      <Modal.Header className="bg-white-bg dark:bg-dark_bg p-5">
        <span className="text-blue_gray-900 dark:text-white-bg text-xl w-auto">
          Add Risk Assessment
        </span>
      </Modal.Header>
      <Modal.Body className="bg-white-bg dark:bg-dark_bg">
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="space-y-6 w-full">
            <div className="mx-auto">
              <div className="mb-5">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                  Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.riskAssessmentName ? "is-invalid" : ""
                    }`}
                  {...register("riskAssessmentName")}
                />
                <div className="font-medium mt-2 text-xs text-red-600">
                  {errors?.riskAssessmentName?.message?.toString()}
                </div>
              </div>
              <div className="mb-5">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                  Description
                </label>
                <textarea
                  className={`block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                  placeholder="Write your thoughts here..."
                  {...register("description")}
                ></textarea>
              </div>
              <div className="space-x-10">
                <label className="text-sm font-medium text-gray-900 dark:text-white-bg">
                  Is Active
                </label>
                <input
                  type="radio"
                  value="true"
                  checked={watch('isActive') === true}
                  onChange={() => setValue('isActive', true)}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Yes
                </label>
                <input
                  type="radio"
                  value="true"
                  checked={watch('isActive') === false}
                  onChange={() => setValue('isActive', false)}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  No
                </label>
              </div>
            </div>
            <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
              <div className="flex flex-row gap-2 items-end justify-end w-full">
                <button
                  onClick={() => {
                    reset();
                    Close();
                  }}
                  type="button"
                  className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isLoading}
                  className={` cursor - pointer bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]  ${isLoading ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                >
                  {isLoading ? (
                    <svg
                      className="animate-spin h-5 w-5 text-white-bg mx-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v8H4z"
                      ></path>
                    </svg>
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export { AddRiskAssessment };
