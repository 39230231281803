import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import ClientToOfficeService from "../../../../../services/Accounting/ClientAccounts/ClientPostings/ClientToOfficeService";
import { ClientToOffice, ClientToOfficeDtata } from "../../../../model/Accounting/ClientAccount/ClientToOfficeInterface";


// Define the state type for Checklist
type ClientToOfficeState = {
    clientToOffices: ClientToOffice[];
    clientToOfficeDtata: ClientToOfficeDtata;
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: ClientToOfficeState = {
    clientToOfficeDtata: null!,
    clientToOffices: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllClientToOffice = createAsyncThunk(
    "fetchAllClientToOffice",
    async (_, thunkAPI) => {
        try {
            const response = await ClientToOfficeService.GetAllClientToOffice();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addClientToOffice = createAsyncThunk(
    "addClientToOffice",
    async (data: any, thunkAPI) => {
        try {
            const response = await ClientToOfficeService.PostClientToOffice(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateClientToOffice = createAsyncThunk(
    "updateClientToOffice",
    async ({ id, data }: { id: number, data: ClientToOffice }, thunkAPI) => {
        try {
            const response = await ClientToOfficeService.PutClientToOffice(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteClientToOffice = createAsyncThunk(
    "deleteClientToOffice",
    async (id: number, thunkAPI) => {
        try {
            await ClientToOfficeService.deleteClientToOffice(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const ClientToOfficeSlice = createSlice({
    name: "clientToOffice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllClientToOffice.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllClientToOffice.fulfilled, (state, action: PayloadAction<ClientToOfficeDtata>) => {
                state.status = "idle";
                state.clientToOfficeDtata = action.payload;
            })
            .addCase(fetchAllClientToOffice.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addClientToOffice.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addClientToOffice.fulfilled, (state, action: PayloadAction<ClientToOffice>) => {
                state.status = "idle";
                state.clientToOffices.push(action.payload);
            })
            .addCase(addClientToOffice.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateClientToOffice.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateClientToOffice.fulfilled, (state, action: PayloadAction<ClientToOffice>) => {
                state.status = "idle";
                const index = state.clientToOffices.findIndex(t => t.clientToOfficeId === action.payload.clientToOfficeId);
                if (index !== -1) {
                    state.clientToOffices[index] = action.payload;
                }
            })
            .addCase(updateClientToOffice.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteClientToOffice.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteClientToOffice.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.clientToOffices = state.clientToOffices.filter(t => t.clientToOfficeId !== action.payload);
            })
            .addCase(deleteClientToOffice.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default ClientToOfficeSlice.reducer;
