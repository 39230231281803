import { Modal } from 'flowbite-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object, boolean } from 'yup';
import { useEffect, useRef, useState } from 'react';
import { FaLock } from "react-icons/fa";
import { FaLockOpen } from "react-icons/fa";
import { Heading } from '../../../AccountSetupHeading/Heading';
import { InputBox } from '../../../InputBox';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { addReconciliation } from '../../../../store/slice/Accounting/ClientAccounts/ReconciliationSlice';


function AddBankReconciliation({ Close, isEdit, closingBalance, reconciliation, selectedData }: { closingBalance: any, Close: any, isEdit: boolean, reconciliation: any, selectedData: any[] }) {
    const [title] = useState((!isEdit ? 'Bank Reconciliation' : 'Edit Bank Reconciliation'))
    const dispatch = useDispatch();
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [modalPlacement, setModalPlacement] = useState('center')
    const [existingReconciliation, setExistingReconciliation] = useState(reconciliation);
    const [isLoading, setIsLoading] = useState(false);
    const handleEditClick = () => {
        setIsDisabled(!isDisabled);
    };

    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        if (selectedData != null) {
            console.log(selectedData)
        }        
    }, [selectedData])

    useEffect(() => {
        if (closingBalance != null) {
            const formattedClosingBalance = new Intl.NumberFormat('en-US', {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(closingBalance);
    
            console.log(formattedClosingBalance);
        }
    }, [closingBalance]);

    const validationSchema = object().shape({
        paymentNumber: string()
            .required('Payment Number is required'),
        transactionDate: string()
            .required('Transaction Date is required'),
        entryDate: string(),
        dueDate: string()
            .required('Entry Date is required'),
        city: string(),
        memo: string(),
        address1: string(),
        address2: string(),
        county: string(),
        postCode: string(),
    });

    const today = new Date().toISOString().split("T")[0];

    // useEffect(() => {
    //     setValue("officeStatementDate", today);
    // }, [today]);

    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });


    const onSubmit = async (data: any) => {

        setIsLoading(true);
        try {
            //  await dispatch(addReconciliation(data)).unwrap();
            Close();
            toast.success("Successfully Added Fee Type");
        } catch (error: any) {
            toast.error(error.toString());
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (isEdit && reconciliation) {
            setExistingReconciliation(reconciliation);
            reset(reconciliation);
        }
    }, [reconciliation, isEdit, reset, setValue]);


    return (
        <form onSubmit={handleSubmit(onSubmit)} className="w-full" >
            <div className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35 p-6 overflow-y-auto space-y-2">
                <InputBox title={'Reconcilliation Date'} dataType='date' />
                <InputBox title={'Statement Date'} dataType='date' />
                <InputBox title={'Statement Balance'} />
                <InputBox title={'ADD [0]'} placeholder={'£0.00'} />
                <InputBox title={'LESS [0]'} placeholder={'£0.00'} />
                <InputBox title={'System Bank Account'} placeholder={'£0.00'} />
                <InputBox title={'Difference'} placeholder={'£0.00'} />
            </div>
            <div className="flex col-span-2 gap-2 justify-center my-5">
                <button
                    type="submit"
                    disabled={isLoading}
                    className={`cursor - pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[120px] ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                    {isLoading ? (
                        <svg className="animate-spin h-5 w-5 text-white-bg mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                        </svg>
                    ) : (
                        'Save'
                    )}
                </button>
            </div>
        </form>
    );
}

export { AddBankReconciliation };
