import { useEffect, useRef, useState } from "react";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SearchInput } from "../Filters/SearchInput";
import { FaEye } from "react-icons/fa";
import { MdOutlineRefresh } from "react-icons/md";
import { AddOfficeToClient } from "../Modal/AddClientAccounts/AddClientPostings/AddOfficeToClient";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { fetchAllOfficeToClient } from "../../store/slice/Accounting/ClientAccounts/ClientPosting/OfficeToClientSlice";
import { toast } from "react-toastify";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { GetOfficeToClient } from "../../store/model/Accounting/ClientAccount/OfficeToClientInterFace";
import { Pagination } from "../Pagination";
function OfficeToClient() {
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const getOfficeToClients = useSelector(
    (state: RootState) => state.officeToClient.getOfficeToClient
  );
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [OfficeToClientData, setOfficeToClientData] = useState<any[]>([]);
  const [currentOfficeToClient, setCurrentOfficeToClient] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState(0);

  const effectServiceCall = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current) {
      dispatch(fetchAllOfficeToClient())
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
    }
  }, [dispatch]);

  useEffect(() => {
    if (getOfficeToClients && getOfficeToClients.officeToClientResult) {
      setOfficeToClientData(getOfficeToClients.officeToClientResult);
    }
  }, [getOfficeToClients]);

  const filterOfficeToClientBySearchQuery = () => {
    return OfficeToClientData.filter(
      (officeToClient: any) =>
        officeToClient?.accountName
          ?.toLowerCase()
          .includes(searchQuery?.toLowerCase()) ||
        officeToClient?.bankAccountType
          ?.toLowerCase()
          .includes(searchQuery?.toLowerCase()) ||
        officeToClient?.toBankAccountType
          ?.toLowerCase()
          .includes(searchQuery?.toLowerCase())
    );
  };

  useEffect(() => {
    if (OfficeToClientData != null && OfficeToClientData.length > 0) {
      const filteredByOfficeToClient = filterOfficeToClientBySearchQuery();
      const indexOfLastOfficeToClient = currentPage * itemsPerPage;
      const indexOfFirstOfficeToClient =
        indexOfLastOfficeToClient - itemsPerPage;
      const currentOfficeToClient = filteredByOfficeToClient.slice(
        indexOfFirstOfficeToClient,
        indexOfLastOfficeToClient
      );
      const totalPages = Math.ceil(OfficeToClientData.length / itemsPerPage);
      setTotalPages(totalPages);
      setCurrentOfficeToClient(currentOfficeToClient);
    }
  }, [OfficeToClientData]);

  const toggleDrawer = () => {
    setOpenModal(!openModal);
  };

  if (openModal) {
    return (
      <AddOfficeToClient
        openDrawer={openModal}
        Close={toggleDrawer}
        isEdit={isEdit}
        officeToClient={OfficeToClientData}
      />
    );
  }
  return (
    <>
      <div className="">
        <div className="mb-5">
          <SubHeaderTitle
            title="Office to Client"
            buttonName="Office to Client"
            isAdd={true}
            onClickAdd={toggleDrawer}
          />
        </div>
        <div className="flex flex-row md:flex-col lg:flex-col xl:flex-col justify-between my-5 gap-4 w-full md:gap-3">
          <div className="md:flex lg:flex xl:flex">
            <SearchInput title="Keyword" />
          </div>
          <div>
            <div className="grid grid-cols-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 md:gap-1 w-full">
              <CheckBoxFilter title="Areas of Practice" />
              <CheckBoxFilter title="Case Type" />
              <CheckBoxFilter title="Case Sub Type" />
              <CheckBoxFilter title="Office Bank" />
              <CheckBoxFilter title="Date between" />
            </div>
          </div>
        </div>
        {/* <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
                    <SearchInput title="Keyword" />
                    <div>
                        <div className="flex gap-4 md:gap-1 w-full">
                            <CheckBoxFilter title="Areas of Practice" />
                            <div className="w-px h-5 bg-gray-300"></div>
                            <CheckBoxFilter title="Case Type" />
                            <div className="w-px h-5 bg-gray-300"></div>
                            <CheckBoxFilter title="Case Sub Type" />
                            <div className="w-px h-5 bg-gray-300"></div>
                            <CheckBoxFilter title="Office Bank" />
                        </div>
                    </div>
                </div> */}
        <div className="relative overflow-x-auto sm:rounded-lg">
          {initialLoading ? <MainAnimation /> : ""}
          <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
            <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <th scope="col" className="px-6 py-3">
                  Date
                </th>
                <th scope="col" className="px-6 py-3">
                  Case Reference
                </th>
                <th scope="col" className="px-6 py-3">
                  From Bank (Office)
                </th>
                <th scope="col" className="px-6 py-3">
                  To Bank (Client)
                </th>
                <th scope="col" className="px-6 py-3">
                  Amount
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {currentOfficeToClient.map((officeToClient: any, index) => (
                <tr
                  key={index}
                  className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  <td className="px-6 py-3">
                    {
                      new Date(officeToClient.entryDate)
                        .toISOString()
                        .split("T")[0]
                    }
                  </td>
                  <td className="px-6 py-3">
                    {officeToClient.caseReferenceAuto}
                  </td>
                  <td className="px-6 py-3">
                    {officeToClient.accountName} -{" "}
                    {officeToClient.bankAccountType}
                    <br />({officeToClient.accountNumber})
                  </td>
                  <td className="px-6 py-3">
                    {officeToClient.toAccountName} -{" "}
                    {officeToClient.toBankAccountType}
                    <br />({officeToClient.toAccountNumber})
                  </td>
                  <td className="px-6 py-3">
                    {officeToClient.amountTransfer}£
                  </td>
                  <td className="cursor-pointer px-6 py-3">
                    <div className="flex gap-3 w-full">
                      <FaEye className="fill-gray-500 w-6 h-6" />
                      <MdOutlineRefresh className="fill-blue-800 w-6 h-6" />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {currentOfficeToClient.length === 0 && (
            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
              <p className="text-red-800"> office to client lists Not found.</p>
            </div>
          )}
        </div>
        {currentOfficeToClient.length > 0 && (
          <div className="w-full flex flex-col pb-[100px]">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        )}
      </div>
    </>
  );
}

export { OfficeToClient };
