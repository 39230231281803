import { Link, useLocation, useNavigate } from "react-router-dom";
import { SidebarData, MenuItem, SubSecondMenuItem } from "./sidebardata";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from "@fortawesome/free-solid-svg-icons";

function SideBar({ isMobileOpen }: { isMobileOpen: boolean }) {
    const location = useLocation();
    const navigate = useNavigate();
    const [activeItem, setActiveItem] = useState<MenuItem | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [menuTitle, setMenuTitle] = useState(String);

    useEffect(() => {
        if (isMobileOpen) {
            SidebarData.forEach(item => handleMouseEnter(item));
        }
    }, [isMobileOpen]);

    const toggleDropdown = (index: number) => {
        setSelectedIndex(index);
        setTimeout(() => {
            setIsOpen(!isOpen);
        }, 200);
    };

    const handleMouseEnter = (item: any) => {
        setTimeout(() => {
            setActiveItem(item);
        }, 100);
        setMenuTitle(item.title);
    };
    const handleMouseLeave = () => {
        setTimeout(() => {
            setActiveItem(null);
        }, 100);
        setIsOpen(false);
    };

    const handleClick = (subItem: any, index: number) => {
        if (subItem.path) {
            navigate(subItem.path);
        } else {
            toggleDropdown(index);
        }
    };

    return (
        <>
            <div>
                <div className="flex flex-row w-full " onMouseLeave={handleMouseLeave}>
                    <div className={`flex w-28 bg-sidebar-bg dark:bg-sidebar-bg h-screen shadow-sm ${!isMobileOpen ? 'md:hidden ' : ''}`}>
                        <ul className="pt-8 flex flex-col w-full gap-1 cursor-pointer">
                            <li className="" onMouseLeave={handleMouseLeave}>
                                <div className="flex flex-row w-full h-16 hover:bg-hover_bg hover:bg-opacity-60">
                                    <div className={`items-start w-[10%] ${location.pathname === "/dashboard" ? 'bg-hover_bg   ' : ''}`}>
                                    </div>
                                    <div className={`text-white-A700 items-center justify-center text-medium flex p-2  w-full  ${location.pathname === "/dashboard" ? 'bg-hover_bg bg-opacity-60' : ''}`}>
                                        <Link to={"/dashboard"} className="flex flex-row items-center">
                                            <FontAwesomeIcon className="w-6 h-6 text-white-bg" icon={faHouse} />,
                                        </Link>
                                    </div>
                                </div>
                            </li>
                            {SidebarData.map((item: MenuItem, index: number) => (
                                <li className="" key={index} onMouseEnter={() => {
                                    handleMouseEnter
                                        (item);
                                }} onClick={() => { handleMouseEnter(item); }} >
                                    <div className="flex flex-row w-full h-16 hover:bg-hover_bg hover:bg-opacity-60" key={index}>
                                        <div className={`items-start w-[10%] ${location.pathname === item.path || item.subItems?.some(subItem => location.pathname === subItem.path) || (item.subItems && item.subItems.some(subItem => subItem.subSecondMenuItem && subItem.subSecondMenuItem.some(subSecondMenuItem => location.pathname === subSecondMenuItem.path))) ? 'bg-hover_bg   ' : ''}`}>
                                        </div>
                                        <div className={`text-white-A700 items-center justify-center text-medium flex p-2  w-full ${location.pathname === item.path || item.subItems?.some(subItem => location.pathname === subItem.path) || (item.subItems && item.subItems.some(subItem => subItem.subSecondMenuItem && subItem.subSecondMenuItem.some(subSecondMenuItem => location.pathname === subSecondMenuItem.path))) ? 'bg-hover_bg bg-opacity-60' : ''}`} key={index}>
                                            <div className="flex flex-row gap-x-4 items-start">
                                                {item.icon}
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    {activeItem && (
                        <div className={`w-60 h-screen shadow-sm dark:bg-dark_bg bg-submenu_bg overflow-y-auto transition-transform duration-700 ease-in-out dark:border-2 dark:border-dark_border ${activeItem ? 'transform translate-x-0' : 'transform translate-x-full'}`}>
                            <div className="w-full p-2 flex flex-col gap-3 duration-500">
                                <div className="w-full">
                                    <div className="flex flex-row items-start dark:text-white-bg justify-start w-full py-4 text-lg">
                                        {menuTitle}
                                    </div>
                                    <form className="max-w-md mx-auto">
                                        <label className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                                        <div className="relative">
                                            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                                </svg>
                                            </div>
                                            <input type="search" id="default-search" className="block w-full p-2 ps-10 text-sm text-gray-900 border-2 dark:text-white-bg border-gray-300 dark:border-dark_border rounded-lg bg-gray-50 focus:ring-hover_bg focus:border-hover_bg dark:bg-dark_bg dark:placeholder-gray-400 dark:text-white dark:focus:ring-hover_bg dark:focus:border-hover_bg" placeholder="Search" />
                                        </div>
                                    </form>
                                </div>
                                <div className="w-full flex flex-col gap-2">
                                    <ul className={`flex flex-col gap-1 duration-500 cursor-pointer h-auto `}>
                                        {activeItem?.subItems?.map((subItem, index) => (
                                            <li key={index}>
                                                <div className={`flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:text-white-bg dark:text-white-bg hover:bg-hover_bg dark:hover:text-white-bg dark:hover:bg-hover_bg ${location.pathname === subItem.path || subItem.subSecondMenuItem?.some(subSecondMenuItem => location.pathname === subSecondMenuItem.path) ? 'bg-hover_bg text-white-bg  ' : ''}`}
                                                    // onClick={() => toggleDropdown(index)}
                                                    onClick={() => handleClick(subItem, index)}
                                                >
                                                    {subItem.icon}
                                                    <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">{subItem.title}</span>
                                                    {subItem.subSecondMenuItem && subItem.subSecondMenuItem.length > 0 && (
                                                        <svg className={`w-3 h-3 transition-transform ${isOpen && selectedIndex === index ? '' : '-rotate-90'}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                                                        </svg>
                                                    )}
                                                </div>
                                                <ul
                                                    id="dropdown-example"
                                                    className={`cursor-pointer py-1 h-auto max-h-[400px] overflow-y-auto space-y-1 ml-8 dark:text-white-bg ${(isOpen && selectedIndex === index) ? 'opacity-100 transform duration-500 translate-y-0' : 'opacity-0 transform -translate-y-2 duration-500 hidden'}`}
                                                >
                                                    {subItem.subSecondMenuItem && subItem.subSecondMenuItem.map((subSecondMenuItem: SubSecondMenuItem, subIndex: number) => (
                                                        subSecondMenuItem.title ? (
                                                            <li key={subIndex} className="">
                                                                <div className={`flex flex-row gap-2 p-1 rounded-lg hover:text-sidebar-bg hover:fill-sidebar-bg ${location.pathname === subSecondMenuItem.path ? 'text-sidebar-bg ' : ''}`}>
                                                                    {location.pathname === subSecondMenuItem.path ? (
                                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                                                                            className={`w-3 dark:text-white-bg ${location.pathname === subSecondMenuItem.path ? 'fill-sidebar-bg dark:fill-white-bg ' : ''}`}>
                                                                            <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z" />
                                                                        </svg>
                                                                    ) : (
                                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className={`w-3  dark:fill-white-bg`}>
                                                                            <path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z" />
                                                                        </svg>
                                                                    )}
                                                                    <Link to={subSecondMenuItem.path} className="flex flex-row gap-x-4 text-base items-start">
                                                                        {subSecondMenuItem.title}
                                                                    </Link>
                                                                </div>
                                                            </li>
                                                        ) : null
                                                    ))}
                                                </ul>

                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )}
                    {/* <div className="w-[15%]  h-screen md:hidden shadow-sm border-2 border-gray-900 overflow-y-auto">
                
            </div> */}
                </div>
            </div>
        </>
    );
}
export { SideBar };