import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../../store/hooks/redux-hooks";
import { fetchLocationByPostcode } from "../../../store/slice/CaseManagement/AddressSlice";
import LocationUtils from "../../../utils/location.utils";
import { SearchComponent } from "../../AutoSelectAddress/SearchInput";
import { SelectAddressComponent } from "../../AutoSelectAddress/SelectAddress";



function AddContactStepFour({ register, setValue, errors }: any) {
    const dispatch = useAppDispatch();
    const [postcode, setPostcode] = useState('');
    const [locationOptionsLists, setLocationOptionsLists] = useState<any[]>();
    const { locations } = useSelector((state: RootState) => state.location);

    const effectServiceCall = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current && postcode) {
            dispatch(fetchLocationByPostcode(postcode));
        }
        return () => { effectServiceCall.current = true; };
    }, [dispatch, postcode]);


    useEffect(() => {
        if (locations != null && locations.length > 0) {
            let data = locations.map((location: any) => ({
                value: location.address,
                label: location.address,
            }));
            setLocationOptionsLists(data);
        }
    }, [locations]);

    const onLocationChange = (location: any) => {
        if (location) {
            const obj = LocationUtils.splitAddress(location);
            if (obj != null) {
                setValue('personalContAddress1', obj.addressLine1)
                setValue('personalContAddress2', obj.addressLine2)
                setValue('personalContCity', obj.city)
                setValue('personalContCounty', obj.county)
                setValue('personalContPostCode', obj.postcode)
            }
            // console.log(obj)
        }
    }

    return (
        <div >
            <div>
                <div className="flex items-center justify-between mb-5">
                    <h3 className="text-lg  font-bold text-left text-gray-900 dark:text-white-bg">Personal Contact Details</h3>
                    <SearchComponent setPostcode={setPostcode} />
                </div>
                <div className="grid grid-cols-4 md:grid-cols-1 grid-rows-2 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35 mb-5">
                    <div className="">
                        <SelectAddressComponent
                            locationOptionsLists={locationOptionsLists ?? []}
                            onLocationChange={onLocationChange}
                            setValue={setValue}
                        />
                    </div>
                    <div className="">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Address Line 1 <span className="text-red-500">*</span>
                        </label>
                        <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.personalContAddress1 ? 'is-invalid' : ''}`} {...register('personalContAddress1')} />
                        <div className="font-medium mt-2 text-xs text-left text-red-600" > {errors?.personalContAddress1?.message?.toString()} </div>
                    </div>
                    <div className="">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Address Line 2
                        </label>
                        <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500  `} {...register('personalContAddress2')} />
                    </div>
                    <div className="">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            City
                        </label>
                        <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('personalContCity')} />
                    </div>
                    <div className="">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            County
                        </label>
                        <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('personalContCounty')} />
                    </div>
                    <div className="">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Postcode <span className="text-red-500">*</span>
                        </label>
                        <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.personalContPostCode ? 'is-invalid' : ''}`} {...register('personalContPostCode')} />
                        <div className="font-medium mt-2 text-xs text-left text-red-600" > {errors?.personalContPostCode?.message?.toString()} </div>
                    </div>
                    <div className="">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Email Address
                        </label>
                        <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`} {...register('personalContEmail')} />
                    </div>
                    <div className="">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Home Phone
                        </label>
                        <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`} {...register('personalContHomePhone')} />
                    </div>
                    <div className="">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                            Mobile
                        </label>
                        <input className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.personalContMobile ? 'is-invalid' : ''} `} {...register('personalContMobile')} />
                        <div className="font-medium mt-2 text-xs text-left text-red-600" > {errors?.personalContMobile?.message?.toString()} </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export { AddContactStepFour };
