import { Modal } from "flowbite-react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object, boolean } from "yup";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { addLEXCELOfficeManual } from "../../../../store/slice/OfficeManagement/OfficeInformation/LEXCELOfficeManualSlice";
import { useAppDispatch } from "../../../../store/hooks/redux-hooks";

function AddLEXCEL_OfficeManual({
  openDrawer,
  Close,
  lEXCELOfficeManual,
  isEdit,
}: {
  openDrawer: boolean;
  Close: any;
  lEXCELOfficeManual: any;
  isEdit: boolean;
}) {
  const [title] = useState(
    !isEdit ? "Add LEXCEL OfficeManual" : "Edit LEXCEL OfficeManual"
  );
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [modalPlacement, setModalPlacement] = useState("center");
  const [newLEXCELOfficeManualFileName, setNewLEXCELOfficeManualFileName] = useState("");
  const [documentNewLEXCELOfficeManualFile, setDocumentNewLEXCELOfficeManualFile] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [setExistingLEXCELOfficeManual] = useState(lEXCELOfficeManual);

  const validationSchema = object().shape({
    chapterName: string().required("Chapter Name is required"),
    file: string().required("File is required"),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: any) => {
    if (documentNewLEXCELOfficeManualFile == null) {
      toast.error("Please select the file");
      return;
    }

    data.file = newLEXCELOfficeManualFileName;

    const formData = new FormData();
    formData.append("requestData", JSON.stringify(data));
    formData.append("fileAttached", documentNewLEXCELOfficeManualFile);
    console.log(JSON.stringify(data, null, 2));
    setIsLoading(true);
    try {
      await dispatch(addLEXCELOfficeManual(data)).unwrap();
      Close();
      toast.success("Successfully Added Task");
    } catch (error: any) {
      toast.error(error.toString());
    } finally {
      setIsLoading(false);
    }
  };

  const onError = (errors: any) => {
    console.log("Form submission errors", errors);
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setDocumentNewLEXCELOfficeManualFile(file);
      setNewLEXCELOfficeManualFileName(file.name);
    } else {
      setNewLEXCELOfficeManualFileName("");
    }
  };

  useEffect(() => {
    if (isEdit && lEXCELOfficeManual) {
      setExistingLEXCELOfficeManual(lEXCELOfficeManual);
      reset(lEXCELOfficeManual);
    }
  }, [lEXCELOfficeManual, isEdit, reset, setValue]);

  return (
    <Modal
      className={`backdrop-blur-sm items-center justify-center`}
      show={openDrawer}
      position={modalPlacement}
      onClose={() => {
        reset();
        Close();
      }}
      initialFocus={emailInputRef}
      size="xl"
    >
      <Modal.Header className="bg-white-bg dark:bg-dark_bg p-5">
        <span className="text-blue_gray-900 dark:text-white-bg text-xl w-auto">
          {title}
        </span>
      </Modal.Header>
      <Modal.Body className="bg-white-bg dark:bg-dark_bg">
        <form onSubmit={handleSubmit(onSubmit,onError)} className="mx-auto">
          <div className="space-y-3 w-full">
          <label className="block mb-2 text-sm font-medium text-gray-800 dark:text-white-bg bg-gray-200 dark:bg-gray-900 p-2">
                Chapter
              </label>
            <div className="grid grid-cols-2 md:grid-cols-1 gap-3 w-full">
            <div className="relative">
              
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Chapter Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                  errors.chapterName ? "is-invalid" : ""
                }`}
                {...register("chapterName")}
                placeholder="Enter Chapter Name"
              />
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.chapterName?.message?.toString()}
              </div>
            </div>
            <div className="">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                File <span className="text-red-500">*</span>
              </label>
              <input
                className={`block w-full text-sm text-gray-900 border-2 border-gray-400 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 ${
                  errors.file ? "is-invalid" : ""
                }`}
                {...register("file")}
                id="multiple_files"
                type="file"
                multiple
                onChange={handleFileChange}
              />
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.file?.message?.toString()}
              </div>
            </div>
            </div>

            <div className="flex md:flex-col gap-2 flex-row justify-start w-full my-5">
              <div className="flex flex-row gap-2 items-end justify-end w-full">
                <button
                  type="button"
                  onClick={() => {
                    reset();
                    Close();
                  }}
                  className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]"
                >
                  {isLoading ? (
                    <svg
                      className="animate-spin h-5 w-5 text-white-bg mx-auto"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v8H4z"
                      ></path>
                    </svg>
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export { AddLEXCEL_OfficeManual };
