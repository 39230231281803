import { useEffect, useRef, useState } from "react";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SearchInput } from "../Filters/SearchInput";
import { FaEye } from "react-icons/fa";
import { MdOutlineRefresh } from "react-icons/md";
import { AddClientToOffice } from "../Modal/AddClientAccounts/AddClientPostings/AddClientToOffice";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { fetchAllClientToOffice } from "../../store/slice/Accounting/ClientAccounts/ClientPosting/ClientToOfficeSlice";
import { toast } from "react-toastify";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { ClientToOfficeDtata } from "../../store/model/Accounting/ClientAccount/ClientToOfficeInterface";
import { Pagination } from "../Pagination";

function ClientToOffice() {
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const dispatch = useAppDispatch();
    const [initialLoading, setInitialLoading] = useState(true);
    const clientToOffices = useSelector((state: RootState) => state.clientToOffice.clientToOfficeDtata);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [clientToOfficeData, setClientToOfficeData] = useState<any[]>([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentclientToOffic, setCurrentclientToOffic] = useState<any[]>([]);


    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
            dispatch(fetchAllClientToOffice())
                .then(() => setInitialLoading(false))
                .catch(() => setInitialLoading(false));
            effectServiceCall.current = true;
        }
    }, [dispatch]);

    useEffect(() => {
        if (clientToOffices && clientToOffices.clientToOfficeResult) {
            setClientToOfficeData(clientToOffices.clientToOfficeResult);
        }
    }, [clientToOffices]);

    const filterClientToOfficeBySearchQuery = () => {
        return clientToOfficeData.filter((clientToOffice: any) =>
            //     bankReceipt.date.toLowerCase().includes(searchQuery.toLowerCase()) ||
            // bankReceipt.receiptNumber.toLowerCase().includes(searchQuery.toLowerCase())||
            // bankReceipt.clientAndCaseDetails.toLowerCase().includes(searchQuery.toLowerCase()) ||
            // bankReceipt.paymentMethod.toLowerCase().includes(searchQuery.toLowerCase()) ||
            // bankReceipt.amount.toLowerCase().includes(searchQuery.toLowerCase()) ||    
            clientToOffice?.accountName?.toLowerCase().includes(searchQuery?.toLowerCase()));
    };

    useEffect(() => {
        if (clientToOfficeData != null && clientToOfficeData.length > 0) {
            const filteredByClientToOffice = filterClientToOfficeBySearchQuery();
            const indexOfLastClientToOffice = currentPage * itemsPerPage;
            const indexOfFirstClientToOffice = indexOfLastClientToOffice - itemsPerPage;
            const currentClientToOffice = filteredByClientToOffice.slice(indexOfFirstClientToOffice, indexOfLastClientToOffice);
            const totalPages = Math.ceil(clientToOfficeData.length / itemsPerPage);
            setTotalPages(totalPages)
            setCurrentclientToOffic(currentClientToOffice)
        }
    }, [clientToOfficeData])



    const toggleDrawer = () => {
        setOpenModal(!openModal);
    };

    if (openModal) {
        return (
            <AddClientToOffice openDrawer={openModal} Close={toggleDrawer} isEdit={isEdit} clientToOffice={clientToOfficeData} />
        )
    }
    return (
        <>
            <div className="">
                <div className="mb-5">
                    <SubHeaderTitle title="Client to Office" buttonName="Client to Office" isAdd={true} onClickAdd={toggleDrawer} />
                </div>
                <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
                    <SearchInput title="Keyword" />
                    <div>
                        <div className="flex gap-4 md:gap-1 w-full">
                            <CheckBoxFilter title="Areas of Practice" />
                            <div className="w-px h-5 bg-gray-300"></div>
                            <CheckBoxFilter title="Case Type" />
                            <div className="w-px h-5 bg-gray-300"></div>
                            <CheckBoxFilter title="Case Sub Type" />
                            <div className="w-px h-5 bg-gray-300"></div>
                            <CheckBoxFilter title="Client Bank" />
                        </div>
                    </div>
                </div>
                <div className="relative overflow-x-auto sm:rounded-lg">
                    {initialLoading ? (<MainAnimation />) : ""}
                    <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                        <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <th scope="col" className="px-6 py-3">
                                    Date
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Case Reference
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Details
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Amount
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentclientToOffic.map((clientToOffice: any, index) => (
                                <tr key={index} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    <td className="px-6 py-3">
                                        {new Date(clientToOffice.postDateTime).toISOString().split('T')[0]}
                                    </td>
                                    <td className="px-6 py-3">
                                        {clientToOffice.caseReferenceAuto}
                                    </td>
                                    <td className="px-6 py-3">
                                        INV{clientToOffice.fkInvoiceId}
                                    </td>
                                    <td className="px-6 py-3">
                                        {clientToOffice.amountTransfer}
                                    </td>
                                    <td className="cursor-pointer px-6 py-3">
                                        <div className="flex gap-3 w-full">
                                            <FaEye className="fill-gray-500 w-6 h-6" />
                                            <MdOutlineRefresh className="fill-blue-800 w-6 h-6" />
                                        </div>
                                    </td>

                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {currentclientToOffic.length === 0 && (
                        <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
                            <p className="text-red-800">No Bank New Payment found.</p>
                        </div>
                    )}
                </div>
                {currentclientToOffic.length > 0 && (
                    <div className="w-full flex flex-col pb-[100px]">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={(page) => setCurrentPage(page)}
                        />
                    </div>
                )}
            </div>

        </>
    )
}

export { ClientToOffice };