import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object, boolean, number } from 'yup';
import { useEffect, useRef, useState } from 'react';
import { SelectBox } from '../../../../SelectBox';
import { FaLock } from "react-icons/fa";
import { FaLockOpen } from "react-icons/fa";
import { SearchCaseReference } from '../../../SearchCaseReference';
import { useAppDispatch } from '../../../../../store/hooks/redux-hooks';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';
import { fetchCaseClient } from '../../../../../store/slice/Accounting';
import { NumericFormat } from 'react-number-format';
import { addBankToBank, fetchNextBankToBankNumber } from '../../../../../store/slice/Accounting/ClientAccounts/ClientPosting/BankToBankSlice';


function AddBankToBank({ openDrawer, Close, isEdit, bankToBank }: { openDrawer: boolean, Close: any, isEdit: boolean, bankToBank: any }) {
    const [title] = useState((!isEdit ? 'Add Bank to Bank' : 'Edit Bank to Bank'))
    const dispatch = useAppDispatch();
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [modalPlacement, setModalPlacement] = useState('center')
    const [openCaseAccountModal, setOpenCaseAccountModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [existingBankToBank, setExistingBankToBank] = useState(bankToBank);
    const [selectedCaseReference, setSelectedCaseReference] = useState<any>();
    const { authourisedClients } = useSelector((state: RootState) => state.authorisedClient);
    const [authorisedClientLists, setAuthorisedClientLists] = useState<any[]>([]);
    const { clientAccounts } = useSelector((state: RootState) => state.clientAccount);
    const [clientAccountLists, setClientAccountLists] = useState<any[]>([]);
    const { caseClient } = useSelector((state: RootState) => state.case);
    const receiptNumbers = useSelector((state: RootState) => state.bankToBank.receiptNumber);
    const { accounts } = useSelector((state: RootState) => state.account);
    const [caseClientLists, setcaseClientLists] = useState<any[]>([]);
    const [initialLoading, setInitialLoading] = useState(true);
    const [autoReceiptNumber, setAutoReceiptNumber] = useState<number>();
    const [officeAccountLists, setOfficeAccountLists] = useState<any[]>([]);

    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
            const fetchPromises = [
                dispatch(fetchNextBankToBankNumber()),
            ];
            Promise.all(fetchPromises)
                .then(() => {
                    setInitialLoading(false);
                })
                .catch((error) => {
                    if (!errorShownRef.current) {
                        toast.error("Error fetching data: " + error.message);
                        errorShownRef.current = true;
                    }
                    setInitialLoading(false);
                });
            effectServiceCall.current = true;
        }
    }, [dispatch]);

    useEffect(() => {
        if (receiptNumbers !== null) {
            setValue("bankToBankTransactionId", receiptNumbers)
            setAutoReceiptNumber(receiptNumbers)
        }
    }, [receiptNumbers])


    useEffect(() => {
        if (authourisedClients.length > 0) {
            let data = authourisedClients.map((authorisedClient: any, index) => ({
                value: authorisedClient.userId,
                label: `${authorisedClient.firstName} -${authorisedClient.lastName}`,
            }));
            setAuthorisedClientLists(data);
        }
    }, [authourisedClients]);


    useEffect(() => {
        if (clientAccounts.length > 0) {
            let data = clientAccounts.map((clientAccount: any, index) => ({
                value: clientAccount.bankAccountId,
                label: `${clientAccount.institution} - ${clientAccount.bankAccountType}`
                ,
            }));
            setClientAccountLists(data);
        }
    }, [clientAccounts]);

    useEffect(() => {
        if (accounts.length > 0) {
            console.log(accounts)
            let data = accounts.map((officeAccount: any, index) => ({
                value: officeAccount.bankAccountId,
                label: `${officeAccount.institution} - ${officeAccount.bankAccountType}`
                ,
            }));
            setOfficeAccountLists(data);

        }
    }, [accounts]);

    useEffect(() => {
        if (caseClient !== null && caseClient.caseClients.length > 0) {
            let data = caseClient.caseClients.map((client: any) => ({
                value: client.individualClientId,
                label: `${client.givenNames} - ${client.lastName}`
                ,
            }));
            setcaseClientLists(data);
        }
    }, [caseClient]);



    const transferMethod = [
        { label: 'Cheque', value: "Cheque" },
        { label: 'Electronic Transfer', value: "Electronic Transfer" },
    ];

    const mappedTransferMethod = transferMethod.map((type: any) => ({
        label: type.label,
        value: type.value
    }));


    const handleEditClick = () => {
        setIsDisabled(!isDisabled);
    };

    const [isDisabled, setIsDisabled] = useState(true);


    const validationSchema = object().shape({
        fkFormBankId: number()
            .required('From Bank Account is required'),
        bankToBankTransactionId: number(),
        transactionDate: string().required('Transaction Date is required')
            .nullable()
            .transform((value, originalValue) => {
                if (originalValue === "" || value === null) return null;
                return new Date(value).toISOString().split('T')[0];
            }),
        entryDate: string().required('Entry Date is required')
            .nullable()
            .transform((value, originalValue) => {
                if (originalValue === "" || value === null) return null;
                return new Date(value).toISOString().split('T')[0];
            }),
        fkCaseId: number()
            .required('Case Reference is required'),
        fromavailableBalance: string(),
        toavailableBalance: string(),
        fkToBankId: number()
            .required('To Bank Account is required'),
        transactionDescription: string()
            .required('Transaction Description is required'),
        amount: string()
            .required('Amount is required'),
        fkClientId: string(),
        comments: string(),
        authorisedBy: number()
            .required('Authorised By is required'),
        transactionReferenceNumber: string()
            .required('Reference Number is required'),
        transferMethod: string()
            .required('Transfer Method is required'),
        b2bStatus: boolean(),
        isClientBankToOfficeBank: boolean()
    });


    const { register, handleSubmit, reset, setValue, getValues, watch, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            bankToBankTransactionId: autoReceiptNumber,
            b2bStatus: true,
        }
    });

    const onSubmit = async (data: any) => {
        console.log(JSON.stringify(data, null, 2));
        setIsLoading(true);
        try {
            await dispatch(addBankToBank(data)).unwrap();
            Close();
            toast.success("Successfully Added client to office details");
        } catch (error: any) {
            toast.error(error.toString());
        } finally {
            setIsLoading(false);
        }
    };
    const onError = (errors: any) => {
        console.log("Form submission errors", errors);
    };


    useEffect(() => {

        if (isEdit && bankToBank) {

            setExistingBankToBank(bankToBank);
            reset(bankToBank);
        }
    }, [bankToBank, isEdit, reset, setValue]);

    const toggleCaseAccountModal = () => {
        setOpenCaseAccountModal(!openCaseAccountModal);
    }
    const handleCaseSelection = (selectedCase: any) => {
        setSelectedCaseReference(selectedCase);
        setValue('fkCaseId', selectedCase.caseId);
        dispatch(fetchCaseClient(selectedCase.caseId));
        setOpenCaseAccountModal(false);
    };

    useEffect(() => {
        if (selectedCaseReference != null) {
            // console.log(selectedCaseReference);        
        }
    });


    return (
        <>
            {openDrawer === true ? (
                <form onSubmit={handleSubmit(onSubmit, onError)} className="mx-auto" >
                    <div className="flex flex-row md:flex-col justify-between my-3 gap-2 w-full md:gap-3">
                        <div className="relative flex">
                            <input
                                type="text"
                                placeholder={selectedCaseReference?.caseReferenceAuto}
                                aria-label="Search"
                                value={selectedCaseReference?.caseReferenceAuto || ""}
                                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.fkCaseId ? 'is-invalid' : ''}`}
                                disabled
                            />
                            <div className="font-medium mt-2 text-xs text-red-600">{errors?.fkCaseId?.message?.toString()}</div>
                            <button
                                onClick={toggleCaseAccountModal}
                                className="z-[2] inline-block rounded-e border-2 border-primary px-6 pb-[6px] pt-2 text-xs font-medium  leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-accent-300 hover:bg-primary-50/50 hover:text-primary-accent-300 focus:border-primary-600 focus:bg-primary-50/50 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:text-primary-500 bg-hover_bg text-white-bg border-hover_bg -ml-1"
                                data-twe-ripple-init
                                data-twe-ripple-color="white"
                                type="button">
                                Search
                            </button>
                            {(openCaseAccountModal) &&
                                <SearchCaseReference
                                    openDrawer={openCaseAccountModal}
                                    Close={toggleCaseAccountModal}
                                    onCaseSelect={handleCaseSelection}
                                />
                            }
                        </div>
                        <div className='flex gap-x-10'>
                        <div className="flex items-center">
                            <input id="default-radio-1"
                                type="radio"
                                value="false"
                                checked={watch('b2bStatus') === false}
                                onChange={() => setValue('b2bStatus', false)}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                            <label className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">ClientBankToCOfficeBank</label>
                        </div>
                        <div className="flex items-center">
                            <input
                                id="default-radio-2"
                                type="radio"
                                value="true"
                                checked={watch('b2bStatus') === true}
                                onChange={() => setValue('b2bStatus', true)}
                                name="default-radio" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                            <label className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">ClientBankToClientBank</label>
                        </div>
                        </div>
                        <div>
                            <button
                                type="button"
                                onClick={() => {
                                    Close();
                                }}
                                className="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-2 rounded">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>

                    </div >
                    <div className="grid grid-cols-4 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 gap-y-1 w-full">
                        

                        <div className='col-start-1'>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Reference
                            </label>
                            <div className="flex gap-3">
                                <input
                                    type="text"
                                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg disabled:bg-gray-300 dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                                    value={autoReceiptNumber}
                                    disabled={isDisabled}
                                />
                                <button
                                    type="button"
                                    onClick={handleEditClick}
                                    className="mt-2 text-sm font-medium text-blue-600 dark:text-blue-500"
                                >
                                    {isDisabled ? <FaLock /> : <FaLockOpen />}
                                </button>
                            </div>
                        </div>


                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                From Bank Account <span className="text-red-500">*</span>
                            </label>
                            <SelectBox
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                indicator={
                                    <svg
                                        width="15"
                                        className="fill-current mr-2"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                    </svg>
                                }
                                isMulti={false}
                                name="frameFiftySix"
                                options={clientAccountLists}
                                isSearchable={true}
                                value={getValues().fkFormBankId}
                                placeholder="Select"
                                shape="round"
                                color="indigo_50"
                                size="xs"
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setValue('fkFormBankId', selectedOption);
                                    }
                                }}
                            />
                            <div className="font-medium mt-2 text-xs text-red-600" >{errors?.fkFormBankId?.message?.toString()}</div>
                        </div>
                        <div className="relative">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Available Balance
                            </label>
                            <NumericFormat decimalScale={2} className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.fromavailableBalance ? 'is-invalid' : ''}`} {...register('fromavailableBalance')}
                                placeholder="Enter Available Balance"
                            />
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                To Bank Account <span className="text-red-500">*</span>
                            </label>
                            <SelectBox
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                indicator={
                                    <svg
                                        width="15"
                                        className="fill-current mr-2"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                    </svg>
                                }
                                isMulti={false}
                                name="frameFiftySix"
                                options={officeAccountLists}
                                isSearchable={true}
                                value={getValues().fkToBankId}
                                placeholder="Select"
                                shape="round"
                                color="indigo_50"
                                size="xs"
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setValue('fkToBankId', selectedOption);
                                    }
                                }}
                            />
                            <div className="font-medium mt-2 text-xs text-red-600" >{errors?.fkToBankId?.message?.toString()}</div>
                        </div>
                        <div className="relative">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Available Balance
                            </label>
                            <NumericFormat decimalScale={2} className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.toavailableBalance ? 'is-invalid' : ''}`} {...register('toavailableBalance')}
                                placeholder="Enter Available Balance"
                            />
                        </div>
                        <div className="relative">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Transaction Date <span className="text-red-500">*</span>
                            </label>
                            <input type="date" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.transactionDate ? 'is-invalid' : ''}`} {...register('transactionDate')}
                                max={new Date().toISOString().split('T')[0]}
                                placeholder="Enter Transaction Date"
                            />
                            <div className="font-medium mt-2 text-xs text-red-600">{errors?.transactionDate?.message?.toString()}</div>
                        </div>

                        <div className="relative">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Entry Date <span className="text-red-500">*</span>
                            </label>
                            <input type="date" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.entryDate ? 'is-invalid' : ''}`} {...register('entryDate')}
                                max={new Date().toISOString().split('T')[0]}
                                placeholder="Enter Date"
                            />
                            <div className="font-medium mt-2 text-xs text-red-600">{errors?.entryDate?.message?.toString()}</div>
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Clients
                            </label>
                            <SelectBox
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                indicator={
                                    <svg
                                        width="15"
                                        className="fill-current mr-2"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                    </svg>
                                }
                                isMulti={false}
                                name="frameFiftySix"
                                options={caseClientLists}
                                isSearchable={true}
                                value={getValues().fkClientId}
                                placeholder="Select"
                                shape="round"
                                color="indigo_50"
                                size="xs"
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setValue('fkClientId', selectedOption);
                                    }
                                }}
                            />
                        </div>
                        <div className="relative">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Transaction Description <span className="text-red-500">*</span>
                            </label>
                            <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.transactionDescription ? 'is-invalid' : ''}`} {...register('transactionDescription')}
                                placeholder="Enter Transaction Description"
                            />
                            <div className="font-medium mt-2 text-xs text-red-600">{errors?.transactionDescription?.message?.toString()}</div>
                        </div>

                        <div className="relative">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Amount (£) <span className="text-red-500">*</span>
                            </label>
                            <NumericFormat decimalScale={2} className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.amount ? 'is-invalid' : ''}`}
                                onValueChange={(values) => {
                                    setValue('amount', values.value)
                                }}
                                placeholder="Enter Amount (£)"
                            />
                            <div className="font-medium mt-2 text-xs text-red-600">{errors?.amount?.message?.toString()}</div>
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Authorised By <span className="text-red-500">*</span>
                            </label>
                            <SelectBox
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                indicator={
                                    <svg
                                        width="15"
                                        className="fill-current mr-2"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                    </svg>
                                }
                                isMulti={false}
                                name="frameFiftySix"
                                options={authorisedClientLists}
                                isSearchable={true}
                                value={getValues().authorisedBy}
                                placeholder="Select"
                                shape="round"
                                color="indigo_50"
                                size="xs"
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setValue('authorisedBy', selectedOption);
                                    }
                                }}
                            />
                            <div className="font-medium mt-2 text-xs text-red-600" >{errors?.authorisedBy?.message?.toString()}</div>
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Transfer Method <span className="text-red-500">*</span>
                            </label>
                            <SelectBox
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                indicator={
                                    <svg
                                        width="15"
                                        className="fill-current mr-2"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                    </svg>
                                }
                                isMulti={false}
                                name="frameFiftySix"
                                options={mappedTransferMethod}
                                isSearchable={true}
                                placeholder="Select"
                                shape="round"
                                color="indigo_50"
                                size="xs"
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setValue('transferMethod', selectedOption);
                                    }
                                }}
                            />
                            <div className="font-medium mt-2 text-xs text-red-600" >{errors?.transferMethod?.message?.toString()}</div>
                        </div>
                        <div className="relative">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Reference Number <span className="text-red-500">*</span>
                            </label>
                            <input type="text" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.transactionReferenceNumber ? 'is-invalid' : ''}`} {...register('transactionReferenceNumber')}
                                placeholder="Enter Reference Number"
                            />
                            <div className="font-medium mt-2 text-xs text-red-600">{errors?.transactionReferenceNumber?.message?.toString()}</div>
                        </div>
                        <div className="relative">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Comments
                            </label>
                            <textarea
                                className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                placeholder="Comments" {...register('comments')}
                            ></textarea>
                        </div>




                    </div>
                    <div className="flex col-span-2 gap-2 justify-end mb-5">
                        <button
                            type="button"
                            onClick={() => {
                                reset();
                                Close();
                            }}
                            className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            disabled={isLoading}
                            className="bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]"
                        >
                            {isLoading ? (
                                <svg className="animate-spin h-5 w-5 text-white-bg mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                                </svg>
                            ) : (
                                'Save'
                            )}
                        </button>
                    </div>

                </form>
            ) : null}
        </>
    );
}

export { AddBankToBank };
