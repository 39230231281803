import { ErrorResult } from "../../../store/model/errorResult";
import axiosInstance from "../../axiosInstance";

const GetAllClientAccount = async () => {
    try {
        const response = await axiosInstance.get("AccountSetUp/clientaccounts");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const ClientAccountService = {
    GetAllClientAccount,
    
};


export default ClientAccountService;
