import { useEffect, useRef, useState } from "react";
import { SearchInput } from "../Filters/SearchInput";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { AddOfficeRefundCreditNote } from "../Modal/AddOfficeAccounts/AddOfficePostings/AddOfficeRefundCreditNote";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { fetchAllRefundCreditNote } from "../../store/slice/Accounting/OfficeAccounts/OfficePostings/RefundCreditNoteSlice";
import { toast } from "react-toastify";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { Pagination } from "flowbite-react";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";

function OfficeRefundCreditNote() {
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [refundCreditNoteData, setRefundCreditNoteData] = useState<any>([]);
    const dispatch = useAppDispatch();
    const [initialLoading, setInitialLoading] = useState(true);
    const { refundCreditNotes, status, error } = useSelector((state: RootState) => state.refundCreditNote);
    const [totalPages, setTotalPages] = useState(0);
    const [currentRefundCreditNote, setCurrentRefundCreditNote] = useState<any[]>([]);
    const itemsPerPage = 10;
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [currentPage, setCurrentPage] = useState(1);

    const { creditNotes } = useSelector((state: RootState) => state.creditNote);
    const [officeCreditNoteLists, setOfficeCreditNoteLists] = useState<any[]>([]);
   
    useEffect(() => {
        // console.log(authourisedClients);
        if (creditNotes.length > 0) {
            let data = creditNotes.map((creditNote: any) => ({
                value: creditNote.creditNoteId,
                label: `${creditNote.firstName}`,
            }));
            setOfficeCreditNoteLists(data);

        }
    }, [creditNotes]);

    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
             dispatch(fetchAllRefundCreditNote())
                .then(() => setInitialLoading(false))
                .catch(() => setInitialLoading(false));
            effectServiceCall.current = true;
        }

        if (status === 'failed' && !errorShownRef.current) {
            toast.error(error);
            errorShownRef.current = true;
        }
    }, [dispatch, status, error]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        if(refundCreditNotes!=null && refundCreditNotes.length > 0){
            const filteredByRefundCreditNote = filterRefundCreditNoteBySearchQuery();
            const indexOfLastRefundCreditNote = currentPage * itemsPerPage;
            const indexOfFirstRefundCreditNote = indexOfLastRefundCreditNote - itemsPerPage;
            const currentRefundCreditNote = filteredByRefundCreditNote.slice(indexOfFirstRefundCreditNote, indexOfLastRefundCreditNote);
            const totalPages = Math.ceil(refundCreditNotes.length / itemsPerPage);
            setTotalPages(totalPages)
            setCurrentRefundCreditNote(currentRefundCreditNote)
        }

    },[refundCreditNotes])


    const filterRefundCreditNoteBySearchQuery = () => {

        return refundCreditNotes.filter((refundCreditNote: any) =>    
            refundCreditNote?.creditNote?.toLowerCase().includes(searchQuery.toLowerCase()));
    };

    const toggleDrawer = () => {
        setIsEdit(false);
        setOpenModal(!openModal);
    };


    return (
        <>
            <div className="">
                <div className="mb-5">
                    <SubHeaderTitle title="Refund Credit Note" buttonName="Refund Credit Note" isAdd={true} onClickAdd={toggleDrawer} />
                    <AddOfficeRefundCreditNote openDrawer={openModal} Close={toggleDrawer} isEdit ={isEdit} refundCreditNote ={refundCreditNoteData} />
                </div>
                <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
                    <SearchInput title="Keyword" />
                    <div>
                        <div className="flex gap-4 md:gap-1 w-full">
                            <CheckBoxFilter title="Status" />
                        </div>
                    </div>
                </div>
                <div className="relative overflow-x-auto sm:rounded-lg my-6">
                <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                {initialLoading ? <MainAnimation /> : ""}
                    <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                        <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                            <th scope="col" className="px-6 py-3">
                            Date
                            </th>
                            <th scope="col" className="px-6 py-3">
                            Invoicee (s)
                            </th>
                            <th scope="col" className="px-6 py-3">
                            Credit Note
                            </th>
                            <th scope="col" className="px-6 py-3">
                            Refund Amount
                            </th>
                            <th scope="col" className="px-6 py-3">
                            Status	
                            </th>
                            <th scope="col" className="px-6 py-3">
                            Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                            {currentRefundCreditNote.map((refundCreditNote: any, index) => (

                          
                        <tr key={index} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <td className="px-6 py-3">
                                {refundCreditNote.date}
                                </td>
                                <td className="px-6 py-3">
                                {refundCreditNote.invoicee}
                                </td>
                                <td className="px-6 py-3">
                                {refundCreditNote.creditNote}
                                </td>
                                <td className="text-right px-6 py-3">
                                {refundCreditNote.refundAmount}
                                </td>
                                <td className="text-right px-6 py-3">
                                {refundCreditNote.status}
                                </td>
                                <td className="cursor-pointer px-6 py-3">
                                    <div className="flex gap-3 w-full">
                                        <FaEdit className="fill-blue-500 w-6 h-6" />
                                        <RiDeleteBin5Fill className="fill-red-600 w-6 h-6" />
                                    </div>
                                </td>
                            </tr>
                              ))}
                        </tbody>

                    </table>
                    {currentRefundCreditNote.length === 0 && (
                            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
                                <p className="text-red-800">No Office Refund Credit Note Details found.</p>
                            </div>
                        )}
                </div>
                {currentRefundCreditNote.length > 0 && (
                    <div className="w-full flex flex-col pb-[100px]">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={(page) => setCurrentPage(page)}
                        />
                    </div>
                )}
            </div>
        </>
    )
}

export { OfficeRefundCreditNote };