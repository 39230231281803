import { Modal } from 'flowbite-react';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object, number } from 'yup';
import { useAppDispatch } from '../../../../store/hooks/redux-hooks';
import { SelectBox } from '../../../SelectBox';
import { addTimeEntryUnitPrice } from '../../../../store/slice/Accounting/AccountSetup/TimeEntryUnitPriceSlice';
import { toast } from 'react-toastify';
import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { fetchAllRole } from '../../../../store/slice/Administrator/RoleManagementSlice';
import { fetchAllTimeEntryType } from '../../../../store/slice/Accounting/AccountSetup/TimeEntryTypeSlice';

function AddTimeEntryUnitPrices({ openDrawer, Close, isEdit, timeEntryUnitPrice }: { openDrawer: boolean, Close: any, isEdit: boolean, timeEntryUnitPrice: any }) {
    const [title] = useState((!isEdit ? 'Add New Time Entry Unit Price' : 'Edit Time Entry Unit Price'))
    const dispatch = useAppDispatch();
    const [openModal, setOpenModal] = useState(true);
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [modalPlacement, setModalPlacement] = useState('center')
    const [isLoading, setIsLoading] = useState(false);
    const [existingTimeEntryUnitPrice, setExistingTimeEntryUnitPrice] = useState(timeEntryUnitPrice);
    const { timeEntryTypes } = useSelector((state: RootState) => state.timeEntryType);
    const { roles } = useSelector((state: RootState) => state.role);
    const [roleOptionsLists, setRoleOptionsLists] = useState<any[]>([]);
    const [timeEntryTypeOptionsLists, setTimeEntryTypeOptionsLists] = useState<any[]>([]);
    const [initialLoading, setInitialLoading] = useState(true);


    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);

    useEffect(() => {
        if (!effectServiceCall.current) {
            const fetchPromises = [
                dispatch(fetchAllRole()),
                dispatch(fetchAllTimeEntryType()),
            ];

            Promise.all(fetchPromises)
                .then(() => {
                    setInitialLoading(false);
                })
                .catch((error) => {
                    if (!errorShownRef.current) {
                        toast.error("Error fetching data: " + error.message);
                        errorShownRef.current = true;
                    }
                    setInitialLoading(false);
                });

            effectServiceCall.current = true;
        }
    }, [dispatch]);

    useEffect(() => {
        if (roles != null && roles.length > 0) {
            let data = roles
                .filter((roles: any) => roles.isActive == 1)
                .map((role: any) => ({
                    value: role.userRoleId,
                    label: role.userRoleName
                }));
            setRoleOptionsLists(data);
        }
    }, [roles]);

    useEffect(() => {
        if (timeEntryTypes != null && timeEntryTypes.length > 0) {
            let data = timeEntryTypes
                .filter((timeEntryTypes: any) => timeEntryTypes.isActive == 1)
                .map((timeEntryType: any) => ({
                    value: timeEntryType.timeEntryTypeId,
                    label: timeEntryType.timeEntryType
                }));
            setTimeEntryTypeOptionsLists(data);
        }
    }, [timeEntryTypes]);


    const validationSchema = object().shape({
        fkRoleId: number()
            .required('Role is required'),
        fkTimeEntryTypeId: number()
            .required('Time Entry Type is required'),
        unitPrice: number()
            .required('Unit Price is required'),
    });

    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = async (data: any) => {
        console.log(JSON.stringify(data, null, 2));

        setIsLoading(true);
        try {
            await dispatch(addTimeEntryUnitPrice(data)).unwrap();
            Close();
            toast.success("Successfully Added Time Entry Unit Price");
        } catch (error: any) {
            toast.error(error.toString());
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (isEdit && timeEntryUnitPrice) {
            setExistingTimeEntryUnitPrice(timeEntryUnitPrice);
            reset(timeEntryUnitPrice);
        }
    }, [timeEntryUnitPrice, isEdit, reset, setValue]);


    return (
        <Modal
            className={`backdrop-blur-sm items-center justify-center`}
            show={openDrawer}
            position={modalPlacement}
            onClose={() => { reset(); setOpenModal(Close); }}
            initialFocus={emailInputRef}
        >
            <Modal.Header className="bg-white-bg dark:bg-dark_bg p-5">
                <span className="dark:text-white-bg dark:bg-dark_bg text-xl w-auto">{title}</span>
            </Modal.Header>
            <Modal.Body className="bg-white-bg dark:bg-dark_bg">
                <form onSubmit={handleSubmit(onSubmit)} className="mx-auto" >
                    <div className="space-y-3 w-full">
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Role <span className="text-red-500">*</span>
                            </label>
                            <SelectBox
                                className={`col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                indicator={
                                    <svg
                                        width="15"
                                        className="fill-current mr-2"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                    </svg>
                                }
                                isMulti={false}
                                name="frameFiftySix"
                                options={roleOptionsLists}
                                isSearchable={true}
                                placeholder="Role"
                                shape="round"
                                color="indigo_50"
                                size="xs"
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setValue('fkRoleId', selectedOption);
                                    }
                                }}
                            />
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Time Entry Type <span className="text-red-500">*</span>
                            </label>
                            <SelectBox
                                className={`col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                indicator={
                                    <svg
                                        width="15"
                                        className="fill-current mr-2"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                    </svg>
                                }
                                isMulti={false}
                                name="frameFiftySix"
                                options={timeEntryTypeOptionsLists}
                                isSearchable={true}
                                placeholder="TimeEntry Type"
                                shape="round"
                                color="indigo_50"
                                size="xs"
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setValue('fkTimeEntryTypeId', selectedOption);
                                    }
                                }}
                            />
                        </div>
                        <div className="relative max-w-full col-span-2">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Unit Price <span className="text-red-500">*</span>
                            </label>
                            <input type="number" className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-lg block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.unitPrice ? 'is-invalid' : ''}`} {...register('unitPrice')}
                                placeholder="Enter Unit Price"
                            />
                            <div className="font-medium mt-2 text-xs text-red-600" >{errors?.unitPrice?.message?.toString()}</div>
                        </div>
                        <div className="flex md:flex-col gap-2 flex-row justify-start w-full">
                            <div className="flex flex-row gap-2 items-end justify-end w-full">
                                <button
                                    type='button'
                                    onClick={() => {
                                        reset();
                                        Close();
                                    }}
                                    className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                                >
                                    Cancel
                                </button>
                                <button
                                    type='submit'
                                    disabled={isLoading}
                                    className="cursor - pointer bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]"
                                >
                                    {isLoading ? (
                                        <svg className="animate-spin h-5 w-5 text-white-bg mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                                        </svg>
                                    ) : (
                                        'Save'
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export { AddTimeEntryUnitPrices };
