import { Modal } from "flowbite-react";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object } from "yup";
import { useAppDispatch } from "../../../../../store/hooks/redux-hooks";
import { InputBox } from "../../../../InputBox";

function AddNote({
  openDrawer,
  Close,
}: {
  openDrawer: boolean;
  Close: any;
}) {
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState(true);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [modalPlacement, setModalPlacement] = useState("center");

  const validationSchema = object().shape({
    subject: string()
       .required('Subject is required')
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data: any) => {
    console.log(JSON.stringify(data, null, 2));
    // dispatch(AddNote(data));
    Close();
  };

  return (
    <Modal
      className={`backdrop-blur-sm items-center justify-center`}
      show={openDrawer}
      position={modalPlacement}
      onClose={() => {
        reset();
        Close();
      }}
      initialFocus={emailInputRef}
      size="xl"
    >
      <Modal.Header className="bg-white-bg dark:bg-dark_bg p-5">
        <span className="text-blue_gray-900 dark:text-white-bg text-xl w-auto">
          Add Note
        </span>
      </Modal.Header>
      <Modal.Body className="bg-white-bg dark:bg-dark_bg">
        <form onSubmit={handleSubmit(onSubmit)} className="mx-auto">
          <div className="space-y-3 w-full">
            <div className="bg-sky-50 dark:bg-gray-900 p-5 space-y-2">
                <div className="flex items-center">
                    <label className="w-1/4 text-sm font-semibold dark:text-white-bg">Case Type</label>
                    <span className="mx-2 text-sm font-semibold dark:text-white-bg">:</span>
                    <p className="w-3/4 text-sm dark:text-white-bg">Civil litigation {">>"} Landlord and Tenant {">>"} General</p>
                </div>
                <div className="flex items-center">
                    <label className="w-1/4 text-sm font-semibold dark:text-white-bg">Case Ref</label>
                    <span className="mx-2 text-sm font-semibold dark:text-white-bg">:</span>
                    <p className="w-3/4 text-sm dark:text-white-bg">AS/AS/CLT/150002</p>
                </div>
            </div>
            <div>
            <InputBox
              title="Subject"
              dataType="text"
              placeholder="Enter Document Title"
              required={true} 
              {...register("subject")}
            />
            <div className="font-medium mt-2 text-xs text-red-600">{errors?.subject?.message?.toString()}</div>
            </div>
            {/* <div className="">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
              Subject <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.subject ? 'is-invalid' : ''}`}
                {...register("subject")}
              />
              <div className="font-medium mt-2 text-xs text-red-600">{errors?.subject?.message?.toString()}</div>
            </div> */}

            <div className="flex md:flex-col gap-2 flex-row justify-start w-full my-5">
              <div className="flex flex-row gap-2 items-end justify-end w-full">
                <button
                  onClick={() => {
                    reset();
                    setOpenModal(Close);
                  }}
                  className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export { AddNote };
