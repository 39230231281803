import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { SelectBox } from "../../../SelectBox";
import { useState } from "react";
// import { object, string } from "yup";
import * as Yup from 'yup';


function AddOrganisationContactThree({ onSubmitStep, initialData, titleName, onCancel, isLoading }: any) {
    const [contactCount, setContactCount] = useState(1);

    const titleOption = titleName ? titleName.map((title: any) => ({
        label: title.title,
        value: title.titleId
    })) : [];

    const validationSchema = Yup.object().shape({
        contPerson1Title: Yup.string(),
        contPerson1GivenName: Yup.string(),
        contPerson1LastName: Yup.string(),
        contPerson1JobTitle: Yup.string(),
        contPerson1Email: Yup.string(),
        contPerson1DirectLine: Yup.string(),
        contPerson1OfficePhone: Yup.string(),
        contPerson1Mobile: Yup.string(),
        contPerson1IncludeCompanyName: Yup.string(),
        contPerson1IncludeJobTitle: Yup.string(),

        contPerson2Title: Yup.string(),
        contPerson2GivenName: Yup.string(),
        contPerson2LastName: Yup.string(),
        contPerson2JobTitle: Yup.string(),
        contPerson2Email: Yup.string(),
        contPerson2DirectLine: Yup.string(),
        contPerson2OfficePhone: Yup.string(),
        contPerson2Mobile: Yup.string(),
        contPerson2IncludeCompanyName: Yup.string(),
        contPerson2IncludeJobTitle: Yup.string(),

        contPerson3Title: Yup.string(),
        contPerson3GivenName: Yup.string(),
        contPerson3LastName: Yup.string(),
        contPerson3JobTitle: Yup.string(),
        contPerson3Email: Yup.string(),
        contPerson3DirectLine: Yup.string(),
        contPerson3OfficePhone: Yup.string(),
        contPerson3Mobile: Yup.string(),
        contPerson3IncludeCompanyName: Yup.string(),
        contPerson3IncludeJobTitle: Yup.string(),
        organisationNote: Yup.string(),


    });


    const { register, handleSubmit, reset, setValue, formState: { errors }, watch } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            ...initialData,
            contactCount,
        }
    });

    const handleFormSubmit = async (data: any) => {
        console.log(JSON.stringify(data, null, 2));
        await onSubmitStep(data);
    };

    const addContactForm = () => {
        if (contactCount < 3) {
            setContactCount(contactCount + 1);
        }
    };

    const removeContactForm = (index: number) => {
        if (contactCount > 1) {
            setContactCount(contactCount - 1);
            reset({
                ...initialData,
                [`contPerson${index + 1}Title`]: '',
                [`contPerson${index + 1}GivenNames`]: '',
                [`contPerson${index + 1}LastName`]: '',
                [`contPerson${index + 1}JobTitle`]: '',
                [`contPerson${index + 1}Email`]: '',
                [`contPerson${index + 1}DirectLine`]: '',
                [`contPerson${index + 1}OfficePhone`]: '',
                [`contPerson${index + 1}Mobile`]: '',
                [`contPerson${index + 1}IncludeCompanyName`]: '',
                [`contPerson${index + 1}IncludeJobTitle`]: '',
            });
        }
    };

    const handleCompanyName = (checked: boolean) => {
        setValue('contPerson3IncludeCompanyName', checked ? 'Yes' : 'No');
    };

    const isChecked = watch('contPerson3IncludeCompanyName') === 'Yes';

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
            <h3 className="text-lg font-bold text-left text-gray-900 dark:text-white-bg">Organizational Contacts</h3>

            {[...Array(contactCount)].map((_, index) => (
                <div key={index} className="mb-5 animate-slide-in">
                    <div className="flex items-center justify-between mb-5">
                        <h3 className="text-lg font-bold text-left text-gray-900 dark:text-white-bg">
                            Contact Person {index + 1}
                        </h3>
                        <button type="button" onClick={() => removeContactForm(index)} className="text-red-500">
                            Remove
                        </button>
                    </div>
                    <div className="grid grid-cols-4 md:grid-cols-1 grid-rows-2 gap-x-10 gap-y-2 border p-6 dark:border dark:border-gray-400 dark:border-opacity-35">
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Title <span className="text-red-500">*</span>
                            </label>
                            <SelectBox
                                className="col-span-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                                indicator={
                                    <svg
                                        width="15"
                                        className="fill-current mr-2"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512">
                                        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                                    </svg>
                                }
                                isMulti={false}
                                name="Title"
                                options={titleOption}
                                isSearchable={true}
                                placeholder="Title"
                                shape="round"
                                color="indigo_50"
                                size="xs"
                                onChange={(selectedOption) => {
                                    if (selectedOption) {
                                        setValue(`contPerson${index + 1}Title`, selectedOption);
                                    }
                                }}
                            />
                            <div className="font-medium mt-2 text-xs text-left text-red-600">
                                {errors[`contPerson${index + 1}Title`]?.message?.toString()}
                            </div>
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Given Names
                            </label>
                            <input
                                type="text"
                                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                                {...register(`contPerson${index + 1}GivenNames`)}
                            />
                            <div className="font-medium mt-2 text-xs text-left text-red-600">
                                {errors[`contPerson${index + 1}GivenNames`]?.message?.toString()}
                            </div>
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Last Name
                            </label>
                            <input
                                type="text"
                                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                                {...register(`contPerson${index + 1}LastName`)}
                            />
                            <div className="font-medium mt-2 text-xs text-left text-red-600">
                                {errors[`contPerson${index + 1}LastName`]?.message?.toString()}
                            </div>
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Job Title
                            </label>
                            <input
                                type="text"
                                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                                {...register(`contPerson${index + 1}JobTitle`)}
                            />
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Email
                            </label>
                            <input
                                type="text"
                                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                                {...register(`contPerson${index + 1}Email`)}
                            />
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Direct Line
                            </label>
                            <input
                                type="text"
                                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                                {...register(`contPerson${index + 1}DirectLine`)}
                            />
                            <div className="font-medium mt-2 text-xs text-left text-red-600">
                                {errors[`contPerson${index + 1}DirectLine`]?.message?.toString()}
                            </div>
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Office Phone
                            </label>
                            <input
                                type="text"
                                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                                {...register(`contPerson${index + 1}OfficePhone`)}
                            />
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Mobile
                            </label>
                            <input
                                type="text"
                                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                                {...register(`contPerson${index + 1}Mobile`)}
                            />
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Mobile
                            </label>
                            <input
                                type="checkbox"
                                className="h-4 w-4 text-blue-600 border-2 border-black rounded-sm"
                                checked={isChecked}
                                onChange={(e: any) => handleCompanyName(e.target.checked)}
                            />
                        </div>
                        <div className="">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                                Mobile
                            </label>

                        </div>
                    </div>
                </div>
            ))}

            {contactCount < 3 && (
                <button
                    type="button"
                    onClick={addContactForm}
                    className="bg-blue-500 text-white font-medium py-2 px-4 rounded hover:bg-blue-700 transition duration-200">
                    + Add Contact
                </button>
            )}

            <div className="">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                    Note
                </label>
                <textarea className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `} {...register('organisationNote')} />
            </div>

            <div className="flex flex-row gap-2 items-end justify-between w-full mt-5">
                <button
                    type="button"
                    onClick={onCancel}
                    className="flex items-center gap-2 px-8 cursor-pointer bg-red-500 text-white font-medium h-10 rounded-lg text-center text-sm w-[120px]">
                    Cancel
                </button>
                {/* <button
                    type="submit"
                    className="flex items-center gap-2 px-8 cursor-pointer bg-green-700 text-white font-medium h-10 rounded-lg text-center text-sm w-[120px]">
                    Save
                </button> */}
                <button
                    type="submit"
                    disabled={isLoading}
                    className={`cursor-pointer bg-green-700 font-medium text-white-bg h-10 bg-white-A700 rounded-lg text-center text-sm w-[120px] ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                    {isLoading ? (
                        <svg className="animate-spin h-5 w-5 text-white-bg mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                        </svg>
                    ) : (
                        'Save'
                    )}
                </button>
            </div>
        </form>
    );
}

export { AddOrganisationContactThree };
