import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { SearchInput } from "../Filters/SearchInput";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { FaEye } from "react-icons/fa";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { fetchAllSuppliersControlAccount } from "../../store/slice/Accounting/OfficeAccounts/Suppliers/SuppliersControlAccountSlice";
import { toast } from "react-toastify";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { Pagination } from "../Pagination";
import { SuppliersControlAccountDetails } from "./SuppliersControlAccountDetails";
function SuppliersControlAccount() {
    const dispatch = useAppDispatch();
    const [openSuppliersModal, setOpenSuppliersModal] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);
    const suppliersControlAccounts = useSelector((state: RootState) => state.suppliersControlAccount.suppliersControlAccounts);
    const [totalPages, setTotalPages] = useState(0);
    const [currentSuppliersControlAccount, setCurrentSuppliersControlAccount] = useState<any[]>([]);
    const [suppliersControlGetData, setSuppliersControlGetData] = useState<any[]>([]);
    const itemsPerPage = 10;
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [currentPage, setCurrentPage] = useState(1);

    
    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
             dispatch(fetchAllSuppliersControlAccount())
                .then(() => setInitialLoading(false))
                .catch(() => setInitialLoading(false));
            effectServiceCall.current = true;
        }
    }, [dispatch]);

    useEffect(() => {
        if (suppliersControlAccounts && suppliersControlAccounts.listingData) {
            setSuppliersControlGetData(suppliersControlAccounts.listingData);
        }
    }, [suppliersControlAccounts]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        if(suppliersControlGetData!=null && suppliersControlGetData.length > 0){
            const filteredBySuppliersControlAccount = filterSuppliersControlAccountBySearchQuery();
            const indexOfLastSuppliersControlAccount = currentPage * itemsPerPage;
            const indexOfFirstSuppliersControlAccount = indexOfLastSuppliersControlAccount - itemsPerPage;
            const currentSuppliersControlAccount = filteredBySuppliersControlAccount.slice(indexOfFirstSuppliersControlAccount, indexOfLastSuppliersControlAccount);
            const totalPages = Math.ceil(suppliersControlGetData.length / itemsPerPage);
            setTotalPages(totalPages)
            setCurrentSuppliersControlAccount(currentSuppliersControlAccount)
        }

    },[suppliersControlGetData])


    const filterSuppliersControlAccountBySearchQuery = () => {

        return suppliersControlGetData.filter((suppliersControlAccount: any) =>    
            suppliersControlAccount?.supplierName?.toLowerCase().includes(searchQuery.toLowerCase()));
    };

    const toggleSuppliersDrawer = () => {
        setOpenSuppliersModal(!openSuppliersModal);
      }
    
      if (openSuppliersModal) {
        return (
            <SuppliersControlAccountDetails openModal={openSuppliersModal} Close={toggleSuppliersDrawer} isEdit={false}  />
        )
      }

    return (
        <>
            <div className="">
                <div className="mb-5">
                    <SubHeaderTitle title="Suppliers Control Account" />
                </div>
                <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
                    <SearchInput title="Keyword" />
                </div>
                <div className="flex items-center mb-4">
                    <input id="default-radio-1" type="radio" value="" name="default-radio" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                    <label className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Do not show zero balances</label>
                </div>
                <div className="relative overflow-x-auto sm:rounded-lg">
                    <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                    {initialLoading ? <MainAnimation /> : ""}
                        <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <th scope="col" className="px-6 py-3">
                                Supplier
                                </th>
                                <th scope="col" className="px-6 py-3">
                                Service / Product
                                </th>
                                <th scope="col" className="px-6 py-3">
                                Current Balance	
                                </th>
                                <th scope="col" className="px-6 py-3">
                                Action	
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        {currentSuppliersControlAccount.map((suppliersControlAccount: any, index) => (
                            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <td className="px-6 py-3">
                                {suppliersControlAccount.supplierName}
                                </td>
                                <td className="px-6 py-3">
                                {suppliersControlAccount.servicesProducts}
                                </td>
                                <td className="px-6 py-3">
                                {suppliersControlAccount.runningBalance}.00
                                </td>
                                <td className="cursor-pointer px-6 py-3">
                                    <FaEye className="fill-gray-500 w-6 h-6" onClick = {toggleSuppliersDrawer}/>
                                </td>
                            </tr>
                        ))}
                           
                        </tbody>

                    </table>
                    {currentSuppliersControlAccount.length === 0 && (
                            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
                                <p className="text-red-800">No Suppliers Control Account Details found.</p>
                            </div>
                        )}
                </div>
                {currentSuppliersControlAccount.length > 0 && (
                    <div className="w-full flex flex-col pb-[100px]">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={(page) => setCurrentPage(page)}
                        />
                    </div>
                )}
                </div>
        </>
    )
}

export { SuppliersControlAccount };