import { useEffect, useRef, useState } from "react";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SearchInput } from "../Filters/SearchInput";
import { AddFeeTypes } from "../Modal/AccountSetup/AddFeeTypes";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { fetchAllFeeType } from "../../store/slice/Accounting/AccountSetup/FeeTypeSlice";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { toast } from "react-toastify";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { FaEdit } from "react-icons/fa";
import { Pagination } from "../Pagination";
import { MdDelete } from "react-icons/md";
import { SubHeaderTitle } from "../SubHeaderTitle";

const  FeeTypes =() =>{
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const dispatch = useAppDispatch();
    const [initialLoading, setInitialLoading] = useState(true);
    const { feeTypes, status, error } = useSelector((state: RootState) => state.feeType);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [feeTypeData, setFeeTypeData] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
            dispatch(fetchAllFeeType())
                .then(() => setInitialLoading(false))
                .catch(() => setInitialLoading(false));
            effectServiceCall.current = true;
        }

        if (status === 'failed' && !errorShownRef.current) {
            // toast.error(error);
            errorShownRef.current = true;
        }
    }, [dispatch, status, error]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    // const filterFeeTypeBySearchQuery = () => {
    //     return feeTypes.filter((feeType: any) =>
    //         feeType?.feeType.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //         feeType?.feeCode.toLowerCase().includes(searchQuery.toLowerCase())
    //     );
    // };

    // const filteredByFeeType = filterFeeTypeBySearchQuery();



    const indexOfLastFeeType = currentPage * itemsPerPage;
    const indexOfFirstFeeType = indexOfLastFeeType - itemsPerPage;
    const currentFeeType = feeTypes.slice(indexOfFirstFeeType, indexOfLastFeeType);


    const totalPages = Math.ceil(feeTypes.length / itemsPerPage);


    const toggleDrawer = () => {
        setIsEdit(false);
        setOpenModal(!openModal);
    };

    return (
        <>
            <div className="">
                <div className=" w-full flex flex-col gap-2">
                <div className="mb-5">
                    <SubHeaderTitle title="Fee Types" buttonName="Fee Type" isAdd={true} onClickAdd={toggleDrawer} />
                    {(openModal) &&
                        <AddFeeTypes openDrawer={openModal} Close={toggleDrawer} isEdit={isEdit} feeType={feeTypeData} />
                    }
                </div>
                <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
                    <SearchInput title="Search FeeType" value={searchQuery} onChange={handleSearchChange} />
                    <div>
                        <div className="flex gap-4 md:gap-1 w-full">
                            <CheckBoxFilter title="Is Active" />
                        </div>
                    </div>
                </div>
                </div>
                <div className="relative overflow-x-auto sm:rounded-lg my-5">
                    <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                        {initialLoading ? (<MainAnimation />) : ""}
                        <thead className="text-sm bg-gray-50 dark:bg-gray-800 dark:text-gray-400">
                            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <th scope="col" className="px-6 py-3">
                                    Fee Type
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Fee Code
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    VAT Code
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Is Active
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Edit
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Delete
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            {currentFeeType.map((feeType: any, index) => (
                                <tr key={index} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    <td className="px-6 py-3">
                                        {feeType.feeType}
                                    </td>
                                    <td className="px-6 py-3">
                                        {feeType.feeCode}
                                    </td>
                                    <td className="px-6 py-3">
                                        test
                                    </td>
                                    <td className="px-6 py-3">
                                        {feeType.isActive == 1 ? 'Active' : 'Inactive'}
                                    </td>
                                    <td className="px-6 py-3 cursor-pointer">
                                        <FaEdit className="fill-blue-500 w-6 h-6"
                                            onClick={() => { setOpenModal(true); setIsEdit(true); setFeeTypeData(feeType) }} />
                                    </td>
                                    <td className="px-6 py-3 cursor-pointer">
                                        <MdDelete className="fill-red-600 w-6 h-6" />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {currentFeeType.length === 0 && (
                        <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
                            <p className="text-red-800">No FeeType found.</p>
                        </div>
                    )}
                </div>
                {currentFeeType.length > 0 && (
                    <div className="w-full flex flex-col pb-[100px]">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={(page) => setCurrentPage(page)}
                        />
                    </div>
                )}
            </div>
        </>
    )
}

export { FeeTypes };