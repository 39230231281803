import axios from 'axios';


import axiosInstance from "../axiosInstance";
import { ErrorResult } from '../../store/model/errorResult';



const fetchAllRoleData = async () => {
  try {
    const response = await axiosInstance.get("UserRole");
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch roles: ${error}`);
  }
}

// Add a new role
const addRoleData = async (data: any) => {
  try {
    const response = await axiosInstance.post("UserRole", data);
    return response.data;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
}

// Update a role
const updateRoleData = async (id: number, data: any) => {
  try {
    const response = await axiosInstance.put(`UserRole/${id}`, data);
    return response.data;
  } catch (error: any) {
    let data = error as ErrorResult;
    throw new Error(data.messages.toString());
  }
}

// Delete a role
const deleteRoleData = async (roleId: number) => {
  try {
    await axiosInstance.delete(`/UserRole/${roleId}`);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('Axios error:', error.response?.data || error.message);
      throw new Error(`Failed to delete Userrole: ${error.response?.data?.message || error.message}`);
    } else {
      console.error('Unexpected error:', error);
      throw new Error('An unexpected error occurred');
    }
  }
}
const RoleManagementServices = {
  fetchAllRoleData,
  addRoleData,
  updateRoleData,
  deleteRoleData,
};

export default RoleManagementServices;
