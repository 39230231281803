import { useEffect, useRef, useState } from "react";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { IoIosPrint } from "react-icons/io";
import { BsFileEarmarkExcelFill } from "react-icons/bs";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { toast } from "react-toastify";
import { Pagination } from "flowbite-react";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { fetchAllAgedCreditorsReport } from "../../store/slice/Accounting/OfficeAccounts/OfficeReports/AgedCreditorsReportSlice";
function AgedCreditorsReport() {
    const [isEdit, setIsEdit] = useState(false);
    const [agedCreditorsReporttData, setAgedCreditorsReportData] = useState<any>([]);
    const dispatch = useAppDispatch();
    const [initialLoading, setInitialLoading] = useState(true);
    const { agedCreditorsReports, status, error } = useSelector((state: RootState) => state.agedCreditorsReport);
    const [totalPages, setTotalPages] = useState(0);
    const [currentAgedCreditorsReport, setCurrentAgedCreditorsReport] = useState<any[]>([]);
    const itemsPerPage = 10;
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [currentPage, setCurrentPage] = useState(1);



    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
             dispatch(fetchAllAgedCreditorsReport())
                .then(() => setInitialLoading(false))
                .catch(() => setInitialLoading(false));
            effectServiceCall.current = true;
        }

        if (status === 'failed' && !errorShownRef.current) {
            toast.error(error);
            errorShownRef.current = true;
        }
    }, [dispatch, status, error]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        if(agedCreditorsReports!=null && agedCreditorsReports.length > 0){
            const filteredByAgedCreditorsReport = filterAgedCreditorsReportBySearchQuery();
            const indexOfLastAgedCreditorsReport = currentPage * itemsPerPage;
            const indexOfFirstAgedCreditorsReport = indexOfLastAgedCreditorsReport - itemsPerPage;
            const currentAgedCreditorsReport = filteredByAgedCreditorsReport.slice(indexOfFirstAgedCreditorsReport, indexOfLastAgedCreditorsReport);
            const totalPages = Math.ceil(agedCreditorsReports.length / itemsPerPage);
            setTotalPages(totalPages)
            setCurrentAgedCreditorsReport(currentAgedCreditorsReport)
        }

    },[agedCreditorsReports])


    const filterAgedCreditorsReportBySearchQuery = () => {

        return agedCreditorsReports.filter((agedCreditorsReport: any) =>    
            agedCreditorsReport?.accountName?.toLowerCase().includes(searchQuery.toLowerCase()));
    };

    return (
        <>
            <div className="">
                <div className="mb-5">
                    <SubHeaderTitle title="Aged Creditors Report" />
                </div>
                <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
                    <div>
                        <div className="flex gap-4 md:gap-1 w-full">
                            <CheckBoxFilter title="As Date" />
                            <div className="w-px h-5 bg-gray-300"></div>
                            <CheckBoxFilter title="Supplier" />
                            <div className="w-px h-5 bg-gray-300"></div>
                            <CheckBoxFilter title="Period" />
                        </div>
                    </div>
                </div>
                <div className="flex gap-x-2 ietms-center justify-end">
            <button
              type="submit"
              className="flex items-center gap-x-2 bg-blue-500 hover:bg-blue-800 text-white-bg cursor-pointer px-5 py-2 rounded text-center text-sm"
            >
              <IoIosPrint className="w-5 h-5" />
              Print
            </button>
            <button
              type="submit"
              className="flex items-center gap-x-2 bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer px-3 py-2 rounded text-center border text-sm"
            >
              <BsFileEarmarkExcelFill className="w-5 h-5" />
              Export to Excel
            </button>
          </div>
                    <div className="space-y-2">
                        <div className="flex items-center">
                            <label className="w-1/5 text-sm font-semibold dark:text-white-bg">As Date</label>
                            <span className="mx-2 text-sm font-semibold dark:text-white-bg">:</span>
                            <p className="w-4/5 text-sm dark:text-white-bg">06-09-2024</p>
                        </div>
                        <div className="flex items-center">
                            <label className="w-1/5 text-sm font-semibold dark:text-white-bg">Supplier</label>
                            <span className="mx-2 text-sm font-semibold dark:text-white-bg">:</span>
                            <p className="w-4/5 text-sm dark:text-white-bg">All</p>
                        </div>
                        <div className="flex items-center">
                            <label className="w-1/5 text-sm font-semibold dark:text-white-bg">Period</label>
                            <span className="mx-2 text-sm font-semibold dark:text-white-bg">:</span>
                            <p className="w-4/5 text-sm dark:text-white-bg">N/A</p>
                        </div>
                    </div>
                    <div className="relative overflow-x-auto sm:rounded-lg my-5">
                    <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                    {initialLoading ? <MainAnimation /> : ""}
                        <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <th scope="col" className="px-6 py-3">
                                Supplier
                                </th>
                                <th scope="col" className="px-6 py-3">
                                Bill
                                </th>
                                <th scope="col" className="px-6 py-3">
                                Due Amount	
                                </th>
                                <th scope="col" className="px-6 py-3">
                                Due Days
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="border border-gray-300 px-6 py-3 text-center col-span-4" colSpan={4}>1-30 Days</td>
                        </tr>
                        <tr>
                            <td className="border border-gray-300 px-6 py-3 text-center col-span-4" colSpan={4}>31-60 Days</td>
                        </tr>
                        <tr>
                            <td className="border border-gray-300 px-6 py-3 text-center col-span-4" colSpan={4}>61-90 Days</td>
                        </tr>
                        <tr>
                            <td className="border border-gray-300 px-6 py-3 text-center col-span-4" colSpan={4}>90 Days</td>
                        </tr>
                        {currentAgedCreditorsReport.map((agedCreditorsReport: any, index) => (
                        
                        <tr key={index} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <td className="px-6 py-3">
                                    {agedCreditorsReport.supplier}
                                </td>
                                <td className="px-6 py-3">
                                {agedCreditorsReport.bill}
                                </td>
                                <td className="text-right px-6 py-3">
                                   {agedCreditorsReport.dueAmount}
                                </td>
                                <td className="px-6 py-3">
                                    {agedCreditorsReport.dueDays}
                                </td>
                            </tr>
                        ))}
                           
                            
                        </tbody>

                    </table>
                    {currentAgedCreditorsReport.length === 0 && (
                            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
                                <p className="text-red-800">No Aged Creditors Report Details found.</p>
                            </div>
                        )}
                </div>
                {currentAgedCreditorsReport.length > 0 && (
                    <div className="w-full flex flex-col pb-[100px]">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={(page) => setCurrentPage(page)}
                        />
                    </div>
                )}
                </div>
           
        </>
    )
}

export { AgedCreditorsReport };