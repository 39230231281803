import { Modal } from 'flowbite-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object } from 'yup';
import { useRef, useState } from 'react';
import { SearchInput } from '../../../../Filters/SearchInput';
import { CheckBoxFilter } from '../../../../Filters/CheckBoxFilter';


function AddTimeEntry({ openDrawer, Close, modalType, }: { openDrawer: boolean, Close: any, modalType: string }) {
    const [title] = useState((modalType === 'Add' ? 'Add Time Entry' : 'Edit Time Entry'))
    const emailInputRef = useRef<HTMLInputElement>(null);
    const [modalPlacement, setModalPlacement] = useState('center')


    const handleEditClick = () => {
        setIsDisabled(!isDisabled);
    };

    const [isDisabled, setIsDisabled] = useState(true);


    const validationSchema = object().shape({
        journalEntry: string(),
        date: string(),
        description: string()


    });

    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const onSubmit = (data: any) => {
        console.log(JSON.stringify(data, null, 2));
        // dispatch(addCaseType(data))
        //     .unwrap()
        //     .then(() => {
        //         Close();
        //         navigate("/systemmanagement/systemmaintenance/casetype");
        //     })
        //     .catch((error) => {
        //         console.error("Failed to add case type:", error);
        //     });
    };


    return (
        <Modal
            className={`backdrop-blur-sm items-center justify-center`}
            show={openDrawer}
            position={modalPlacement}
            onClose={() => { reset(); Close(); }}
            initialFocus={emailInputRef}
            size="6xl"
        >
            <Modal.Header className="bg-white-bg dark:bg-dark_bg p-5">
                <span className="text-blue_gray-900 dark:text-white-bg text-xl w-auto">{title}</span>
            </Modal.Header>
            <Modal.Body className="bg-white-bg dark:bg-dark_bg">

                <form onSubmit={handleSubmit(onSubmit)} className="mx-auto" >

                    <div className="grid grid-cols-3 gap-x-4 gap-y-1 w-full my-5">

                        <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
                            <SearchInput title="Keyword" />
                            <div>
                                <div className="flex gap-4 md:gap-1 w-full">
                                    <CheckBoxFilter title="Areas of Practice" />
                                    <div className="w-px h-5 bg-gray-300"></div>
                                    <CheckBoxFilter title="Case Type" />
                                    <div className="w-px h-5 bg-gray-300"></div>
                                    <CheckBoxFilter title="Case Sub Type" />
                                    <div className="w-px h-5 bg-gray-300"></div>
                                    <CheckBoxFilter title="Status" />
                                </div>
                            </div>
                        </div>

                    </div>
                    <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400 my-5">
                        <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <th scope="col" className="px-6 py-3">

                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Elapsed time
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Case Reference
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Staff
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Activity
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Units
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Rate
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Value
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    VAT(%)
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Total
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Status
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <td className="px-6 py-3">
                                    1
                                </td>
                                <td className="px-6 py-3">
                                    -1:-59:00
                                </td>
                                <td className="px-6 py-3">
                                    LW/AP/FC/15000
                                </td>
                                <td className="px-6 py-3">
                                    Andrew Smith
                                </td>
                                <td className="px-6 py-3">
                                    Court Hearing
                                </td>
                                <td className="px-6 py-3">
                                    -1
                                </td>
                                <td className="px-6 py-3">
                                    £ 150.00
                                </td>
                                <td className="px-6 py-3">
                                    £ (150.00)
                                </td>
                                <td className="px-6 py-3">
                                    20.00%
                                </td>
                                <td className="px-6 py-3">
                                    £ (180.00)
                                </td>
                                <td className="px-6 py-3">

                                </td>
                            </tr>
                            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <td className="px-6 py-3">
                                    2
                                </td>
                                <td className="px-6 py-3">
                                00:01:00	
                                </td>
                                <td className="px-6 py-3">
                                AS/YO/FC/10000
                                </td>
                                <td className="px-6 py-3">
                                    
                                </td>
                                <td className="px-6 py-3">
                                Phone Consultation	
                                </td>
                                <td className="px-6 py-3">
                                    -1
                                </td>
                                <td className="px-6 py-3">
                                    £ 150.00
                                </td>
                                <td className="px-6 py-3">
                                    £ (150.00)
                                </td>
                                <td className="px-6 py-3">
                                    20.00%
                                </td>
                                <td className="px-6 py-3">
                                    £ (180.00)
                                </td>
                                <td className="px-6 py-3">

                                </td>
                            </tr>
                        </tbody>
                        <tbody>

                        </tbody>
                    </table>
                    <div className="flex col-span-2 gap-2 justify-end">
                        <button
                            type="button"
                            onClick={() => {
                                Close();
                            }}
                            className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]"
                        >
                            Save
                        </button>
                    </div>

                </form>

            </Modal.Body>
        </Modal>
    );
}

export { AddTimeEntry };
