import { SearchInput } from "../Filters/SearchInput";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { FaEye } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";
import { AddSuppliersCreditNote } from "./SuppliersCreditNoteDetails/AddSuppliersCreditNote";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { fetchAllOfficeSupplierCreditNote } from "../../store/slice/Accounting/OfficeAccounts/Suppliers/OfficeSupplierCreditNoteSlice";
import { toast } from "react-toastify";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { Pagination } from "flowbite-react";
function SuppliersCreditNote() {
    const [openModal, setOpenModal] = useState(false);
    const [officeSupplierCreditNoteData, setOfficeSupplierCreditNoteData] = useState<any>([]);
    const dispatch = useAppDispatch();
    const [initialLoading, setInitialLoading] = useState(true);
    const { officeSupplierCreditNotes, status, error } = useSelector((state: RootState) => state.officeSupplierCreditNote);
    const [totalPages, setTotalPages] = useState(0);
    const [currentOfficeSupplierCreditNote, setCurrentOfficeSupplierCreditNote] = useState<any[]>([]);
    const itemsPerPage = 10;
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [currentPage, setCurrentPage] = useState(1);

    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
            let data={
                keyword: ''
            }
             dispatch(fetchAllOfficeSupplierCreditNote(data))
                .then(() => setInitialLoading(false))
                .catch(() => setInitialLoading(false));
            effectServiceCall.current = true;
        }

        if (status === 'failed' && !errorShownRef.current) {
            toast.error(error);
            errorShownRef.current = true;
        }
    }, [dispatch, status, error]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    // useEffect(() => {
    //     if(officeSupplierCreditNotes!=null && officeSupplierCreditNotes.length > 0){
    //         const filteredByOfficeSupplierCreditNote = filterOfficeSupplierCreditNoteBySearchQuery();
    //         const indexOfLastOfficeSupplierCreditNote = currentPage * itemsPerPage;
    //         const indexOfFirstOfficeSupplierCreditNote = indexOfLastOfficeSupplierCreditNote - itemsPerPage;
    //         const currentOfficeSupplierCreditNote = filteredByOfficeSupplierCreditNote.slice(indexOfFirstOfficeSupplierCreditNote, indexOfLastOfficeSupplierCreditNote);
    //         const totalPages = Math.ceil(officeSupplierCreditNotes.length / itemsPerPage);
    //         setTotalPages(totalPages)
    //         setCurrentOfficeSupplierCreditNote(currentOfficeSupplierCreditNote)
    //     }

    // },[officeSupplierCreditNotes])

    useEffect(() => {
        if(officeSupplierCreditNotes!=null && (officeSupplierCreditNotes.listingData!=null && officeSupplierCreditNotes.listingData.length > 0)){
           const totalPages = officeSupplierCreditNotes.totalRecords;
            setTotalPages(totalPages)
            setCurrentOfficeSupplierCreditNote(officeSupplierCreditNotes.listingData)
        }
    
    },[officeSupplierCreditNotes]);


    // const filterOfficeSupplierCreditNoteBySearchQuery = () => {

    //     return officeSupplierCreditNotes.filter((officeSupplierCreditNote: any) =>    
    //         officeSupplierCreditNote?.supplier?.toLowerCase().includes(searchQuery.toLowerCase()));
    // };


    
    const toggleDrawer = () => {
        setOpenModal(!openModal);
    };

    if (openModal) {
        return (
            <AddSuppliersCreditNote openModal={openModal} Close={toggleDrawer} officeSupplierCreditNote={officeSupplierCreditNoteData} />
        )
    }
    return (
        <>
            <div className="">
                <div className="mb-5">
                    <SubHeaderTitle title="Credit Note" buttonName="Credit Note" isAdd={true} onClickAdd={toggleDrawer} />
                </div>
                <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
                    <SearchInput title="Keyword" />
                    <div>
                        <div className="flex gap-4 md:gap-1 w-full">
                            <CheckBoxFilter title="Supplier" />
                        </div>
                    </div>
                </div>
                <div className="relative overflow-x-auto sm:rounded-lg my-6">
                    <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                    {initialLoading ? <MainAnimation /> : ""}
                        <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <th scope="col" className="px-6 py-3">
                                Credit Note Number
                                </th>
                                <th scope="col" className="px-6 py-3">
                                Supplier
                                </th>
                                <th scope="col" className="px-6 py-3">
                                Invoice Number
                                </th>
                                <th scope="col" className="px-6 py-3">
                                Date
                                </th>
                                <th scope="col" className="px-6 py-3">
                                Description
                                </th>
                                <th scope="col" className="px-6 py-3">
                                Net Amount	
                                </th>
                                <th scope="col" className="px-6 py-3">
                                Action	
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        {currentOfficeSupplierCreditNote.map((officeSupplierCreditNote: any, index) => (

                        <tr key={index} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <td className="px-6 py-3">
                                CN{officeSupplierCreditNote.supplierCreditNoteId}
                                </td>
                                <td className="px-6 py-3">
                                {officeSupplierCreditNote.supplierName}
                                </td>
                                <td className="px-6 py-3">
                                {officeSupplierCreditNote.invoiceNumber}
                                </td>
                                <td className="px-6 py-3">
                                {officeSupplierCreditNote.entryDate.split('T')[0]}
                                </td>	
                                <td className="px-6 py-3">
                                {officeSupplierCreditNote.description}
                                </td>	
                                <td className="text-right px-6 py-3">
                                £{officeSupplierCreditNote.netAmount}
                                </td>
                                <td className="cursor-pointer px-6 py-3">
                                    <FaEye className="fill-gray-500 w-6 h-6" />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    {currentOfficeSupplierCreditNote.length === 0 && (
                            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
                                <p className="text-red-800">No Supplier Credit Note Details found.</p>
                            </div>
                        )}
                </div>
                {currentOfficeSupplierCreditNote.length > 0 && (
                    <div className="w-full flex flex-col pb-[100px]">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={(page) => setCurrentPage(page)}
                        />
                    </div>
                )}
                </div>
            
        </>
    )
}

export { SuppliersCreditNote };