import { useEffect, useRef, useState } from "react";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SearchInput } from "../Filters/SearchInput";
import { FaEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { AddJournalEntry } from "../Modal/AddClientAccounts/AddClientPostings/AddJournalEntry";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { toast } from "react-toastify";
import { fetchAllJournalEntry } from "../../store/slice/Accounting/ClientAccounts/ClientPosting/JournalEntrySlice";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { Pagination } from "../Pagination";
import { JournalEntryDetails } from "./JournalEntryDetails";
function JournalEntry() {
  const [openModal, setOpenModal] = useState(false);
  const [openJournalModal, setOpenJournalModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useAppDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const journalEntries = useSelector(
    (state: RootState) => state.journalEntry.journalEntryData
  );
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [journalEntryData, setJournalEntryData] = useState<any>([]);
  const [currentJournals, setCurrentJournals] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState(0);

  const effectServiceCall = useRef(false);
  const errorShownRef = useRef(false);
  useEffect(() => {
    if (!effectServiceCall.current) {
      dispatch(fetchAllJournalEntry())
        .then(() => setInitialLoading(false))
        .catch(() => setInitialLoading(false));
      effectServiceCall.current = true;
    }
  }, [dispatch]);

  useEffect(() => {
    if (journalEntries && journalEntries.journalEntryResult) {
      setJournalEntryData(journalEntries.journalEntryResult);
    }
  }, [journalEntries]);

  const filterJournalEntryBySearchQuery = () => {
    return journalEntryData.filter((journalEntry: any) =>
      journalEntry?.firstName
        ?.toLowerCase()
        .includes(searchQuery?.toLowerCase())
    );
  };

  useEffect(() => {
    if (journalEntryData != null && journalEntryData.length > 0) {
      const filteredByJournalEntry = filterJournalEntryBySearchQuery();
      const indexOfLastJournalEntry = currentPage * itemsPerPage;
      const indexOfFirstJournalEntry = indexOfLastJournalEntry - itemsPerPage;
      const currentJournalEntry = filteredByJournalEntry.slice(
        indexOfFirstJournalEntry,
        indexOfLastJournalEntry
      );
      const totalPages = Math.ceil(journalEntryData.length / itemsPerPage);
      setTotalPages(totalPages);
      setCurrentJournals(currentJournalEntry);
    }
  }, [journalEntryData]);

  const toggleDrawer = () => {
    setOpenModal(!openModal);
  };

  const toggleJournalDrawer = () => {
    setOpenJournalModal(!openJournalModal);
  };

  if (openModal) {
    return (
      <AddJournalEntry
        openDrawer={openModal}
        Close={toggleDrawer}
        isEdit={isEdit}
        journalEntry={journalEntryData}
      />
    );
  }

  if (openJournalModal) {
    return (
      <JournalEntryDetails
        openModal={openJournalModal}
        Close={toggleJournalDrawer}
        isEdit={false}
      />
    );
  }
  return (
    <>
      <div className="">
        <div className="mb-5">
          <SubHeaderTitle
            title="Journal Entry"
            buttonName="Journal Entry"
            isAdd={true}
            onClickAdd={toggleDrawer}
          />
        </div>
        <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
          <SearchInput title="Keyword" />
          <div>
            <div className="flex gap-4 md:gap-1 w-full">
              <CheckBoxFilter title="From Ledger" />
              <div className="w-px h-5 bg-gray-300"></div>
              <CheckBoxFilter title="To Ledger" />
            </div>
          </div>
        </div>
        <div className="relative overflow-x-auto sm:rounded-lg">
          {initialLoading ? <MainAnimation /> : ""}
          <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
            <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
              <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                <th scope="col" className="px-6 py-3">
                  Journal Entry Id
                </th>
                <th scope="col" className="px-6 py-3">
                  Transaction Date
                </th>
                <th scope="col" className="px-6 py-3">
                  Number of Accounts
                </th>
                <th scope="col" className="px-6 py-3">
                  Total
                </th>
                <th scope="col" className="px-6 py-3">
                  Staff
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {currentJournals.map((journalEntry: any, index) => (
                <tr
                  key={index}
                  className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35"
                >
                  <td className="px-6 py-3">JE{journalEntry.journalEntryId}</td>
                  <td className="px-6 py-3">
                    {
                      new Date(journalEntry.journalEntryDate)
                        .toISOString()
                        .split("T")[0]
                    }
                  </td>
                  <td className="px-6 py-3 w-[250px]">
                    {journalEntry.totalEntries}
                  </td>
                  <td className="text-right px-6 py-3">{journalEntry.total}</td>
                  <td className="px-6 py-3">
                    {journalEntry.firstName} {journalEntry.lastName}
                  </td>
                  <td className="cursor-pointer px-6 py-3">
                    <div className="flex gap-3 w-full">
                      <FaEye
                        className="fill-gray-500 w-6 h-6"
                        onClick={toggleJournalDrawer}
                      />
                      <FaEdit className="fill-blue-500 w-6 h-6" />
                      <RiDeleteBin5Fill className="fill-red-600 w-6 h-6" />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {currentJournals.length === 0 && (
            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
              <p className="text-red-800">No Bank New Payment found.</p>
            </div>
          )}
        </div>
        {currentJournals.length > 0 && (
          <div className="w-full flex flex-col pb-[100px]">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        )}
      </div>
    </>
  );
}

export { JournalEntry };
