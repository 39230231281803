import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";

const GetLedgerToLedgerNumber = async () => {
    try {
        const response = await axiosInstance.get("LedgerToLedgerNumber");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const GetAllLedgerToLedger = async () => {
    try {
        const response = await axiosInstance.post("LedgerToLedgerSearch", {});
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const PostLedgerToLedger = async (data: any) => {
    console.log(data);
    try {
        const response = await axiosInstance.post("LedgerToLedger", data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const PutLedgerToLedger = async (id: number, data: any) => {
    try {
        const response = await axiosInstance.put(`LedgerToLedger/${id}`, data);
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}


const deleteLedgerToLedger = async (id: number) => {
    try {
        await axiosInstance.delete(`/LedgerToLedger/${id}`);
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}

const LedgerToLedgerService = {
    GetAllLedgerToLedger,
    PostLedgerToLedger,
    PutLedgerToLedger,
    deleteLedgerToLedger,
    GetLedgerToLedgerNumber
};


export default LedgerToLedgerService;
