import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import InvoiceNoticeService from "../../../../services/Accounting/AccountSetup/InvoiceNoticeService";


export interface InvoiceNotice {
    invoiceNoticeId: number;
    title: string;
    invoiceNotice: string;
    isDefault: boolean;

};
// Define the state type for Checklist
type InvoiceNoticeState = {
    invoiceNotices: InvoiceNotice[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: InvoiceNoticeState = {
    invoiceNotices: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllInvoiceNotice = createAsyncThunk(
    "fetchAllInvoiceNotice",
    async (_, thunkAPI) => {
        try {
            const response = await InvoiceNoticeService.GetAllInvoiceNotice();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addInvoiceNotice = createAsyncThunk(
    "addInvoiceNotice",
    async (data: any, thunkAPI) => {
        try {
            const response = await InvoiceNoticeService.PostInvoiceNotice(data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateInvoiceNotice = createAsyncThunk(
    "updateInvoiceNotice",
    async ({ id, data }: { id: number, data: InvoiceNotice }, thunkAPI) => {
        try {
            const response = await InvoiceNoticeService.PutInvoiceNotice(id, data);
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteInvoiceNotice = createAsyncThunk(
    "deleteInvoiceNotice",
    async (id: number, thunkAPI) => {
        try {
            await InvoiceNoticeService.deleteInvoiceNotice(id);
            return id;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

// Slice definition
const InvoiceNoticeSlice = createSlice({
    name: "invoiceNotice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllInvoiceNotice.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllInvoiceNotice.fulfilled, (state, action: PayloadAction<InvoiceNotice[]>) => {
                state.status = "idle";
                state.invoiceNotices = action.payload;
            })
            .addCase(fetchAllInvoiceNotice.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(addInvoiceNotice.pending, (state) => {
                state.status = "loading";
            })
            .addCase(addInvoiceNotice.fulfilled, (state, action: PayloadAction<InvoiceNotice>) => {
                state.status = "idle";
                state.invoiceNotices.push(action.payload);
            })
            .addCase(addInvoiceNotice.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(updateInvoiceNotice.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateInvoiceNotice.fulfilled, (state, action: PayloadAction<InvoiceNotice>) => {
                state.status = "idle";
                const index = state.invoiceNotices.findIndex(t => t.invoiceNoticeId === action.payload.invoiceNoticeId);
                if (index !== -1) {
                    state.invoiceNotices[index] = action.payload;
                }
            })
            .addCase(updateInvoiceNotice.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
            .addCase(deleteInvoiceNotice.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteInvoiceNotice.fulfilled, (state, action: PayloadAction<number>) => {
                state.status = "idle";
                state.invoiceNotices = state.invoiceNotices.filter(t => t.invoiceNoticeId !== action.payload);
            })
            .addCase(deleteInvoiceNotice.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            });
    }
});

export default InvoiceNoticeSlice.reducer;
