import { Modal } from "flowbite-react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object } from "yup";
import { useRef, useState } from "react";

function TrialBalanceTransactions({
  openDrawer,
  Close,
  modalType,
}: {
  openDrawer: boolean;
  Close: any;
  modalType: string;
}) {
  const [title] = useState(
    modalType === "Add" ? "Add COLP & COFA" : "Edit COLP & COFA"
  );
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [modalPlacement, setModalPlacement] = useState("center");

  const validationSchema = object().shape({});

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data: any) => {
    console.log(JSON.stringify(data, null, 2));
    // dispatch(addCaseType(data))
    //     .unwrap()
    //     .then(() => {
    //         Close();
    //         navigate("/systemmanagement/systemmaintenance/casetype");
    //     })
    //     .catch((error) => {
    //         console.error("Failed to add case type:", error);
    //     });
  };

  return (
    <Modal
      className={`backdrop-blur-sm items-center justify-center`}
      show={openDrawer}
      position={modalPlacement}
      onClose={() => {
        reset();
        Close();
      }}
      initialFocus={emailInputRef}
      size="2xl"
    >
      <Modal.Header className="bg-white-bg dark:bg-dark_bg p-5">
        <span className="text-blue_gray-900 dark:text-white-bg text-xl w-auto">
          Trial Balance Transactions
        </span>
      </Modal.Header>
      <Modal.Body className="bg-white-bg dark:bg-dark_bg">
        <form onSubmit={handleSubmit(onSubmit)} className="mx-auto">
          <div className="space-y-3 w-full">
            <div className="grid md:inline-block grid-cols-2 md:grid-cols-1 gap-y-3 gap-x-5 md:space-y-5 bg-sky-50 dark:bg-gray-900 dark:text-white-bg p-6 rounded-b-md">
              <div className="flex items-center">
                <label className="w-1/2 text-sm font-semibold dark:text-white-bg">
                  Account Code
                </label>
                <span className="mx-2 text-sm font-semibold dark:text-white-bg">
                  :
                </span>
                <p className="w-1/2 text-sm dark:text-white-bg">1</p>
              </div>

              <div className="flex items-center">
                <label className="w-1/2 text-sm font-semibold dark:text-white-bg">
                  Account
                </label>
                <span className="mx-2 text-sm font-semibold dark:text-white-bg">
                  :
                </span>
                <p className="w-1/2 text-sm dark:text-white-bg">Immigration</p>
              </div>

              <div className="flex items-center">
                <label className="w-1/2 text-sm font-semibold dark:text-white-bg">
                  Account Type
                </label>
                <span className="mx-2 text-sm font-semibold dark:text-white-bg">
                  :
                </span>
                <p className="w-1/2 text-sm dark:text-white-bg">Income</p>
              </div>

              <div className="flex items-center">
                <label className="w-1/2 text-sm font-semibold dark:text-white-bg">
                  Running Balance
                </label>
                <span className="mx-2 text-sm font-semibold dark:text-white-bg">
                  :
                </span>
                <p className="w-1/2 text-sm dark:text-white-bg">£ 400.00</p>
              </div>

              <div className="flex items-center">
                <label className="w-1/2 text-sm font-semibold dark:text-white-bg">
                Date
                </label>
                <span className="mx-2 text-sm font-semibold dark:text-white-bg">
                  :
                </span>
                <p className="w-1/2 text-sm dark:text-white-bg">01 November 2024</p>
              </div>
            </div>
            <div className="relative overflow-x-auto sm:rounded-lg">
              <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                  <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <th scope="col" className="p-3">
                      Trans No.
                    </th>
                    <th scope="col" className="p-3">
                      Date
                    </th>
                    <th scope="col" className="p-3">
                      Details
                    </th>
                    <th scope="col" className="p-3">
                      Debit
                    </th>
                    <th scope="col" className="p-3">
                      Credit
                    </th>
                    <th scope="col" className="p-3">
                      Balance
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <td className="p-3">1</td>
                    <td className="p-3">01-10-2020</td>
                    <td className="p-3">AW/RES/PUR/10/WILLIAMS_inv_3</td>
                    <td className="p-3">£ 0.00</td>
                    <td className="p-3">£ 0.00</td>
                    <td className="p-3">£ 0.00</td>
                  </tr>
                  <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                    <td className="p-3">1</td>
                    <td className="p-3">01-10-2020</td>
                    <td className="p-3">AW/RES/PUR/10/WILLIAMS_inv_2</td>
                    <td className="p-3">£ 0.00</td>
                    <td className="p-3">£ 0.00</td>
                    <td className="p-3">£ 0.00</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export { TrialBalanceTransactions };
