import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import BudgetVarianceReportService from "../../../../../services/Accounting/OfficeAccount/OfficeReports/BudgetVarianceReportService";



export interface BudgetVarianceReport {
    //creditNoteId: number;
   
};
// Define the state type for Checklist
type BudgetVarianceReportState = {
    budgetVarianceReports: BudgetVarianceReport[];
    status: "idle" | "loading" | "failed";
    error: string | null;
};

// Initial state
const initialState: BudgetVarianceReportState = {
    budgetVarianceReports: [],
    status: "idle",
    error: null
};

// Async thunks for CRUD operations
export const fetchAllBudgetVarianceReport = createAsyncThunk(
    "fetchAllBudgetVarianceReport",
    async (_, thunkAPI) => {
        try {
            const response = await BudgetVarianceReportService.GetAllBudgetVarianceReport();
            return response;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);


// Slice definition
const BudgetVarianceReportSlice = createSlice({
    name: "budgetVarianceReport",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllBudgetVarianceReport.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchAllBudgetVarianceReport.fulfilled, (state, action: PayloadAction<BudgetVarianceReport[]>) => {
                state.status = "idle";
                state.budgetVarianceReports = action.payload;
            })
            .addCase(fetchAllBudgetVarianceReport.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload as string;
            })
           
    }
});

export default BudgetVarianceReportSlice.reducer;
