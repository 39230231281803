import { useEffect, useRef, useState } from "react";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SearchInput } from "../Filters/SearchInput";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { fetchAllReportBankReconcilliation } from "../../store/slice/Accounting/OfficeAccounts/OfficeReports/ReportBankReconcilliationSlice";
import { toast } from "react-toastify";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { Pagination } from "flowbite-react";
function OfficeBankReconcilliation() {
    const [isEdit, setIsEdit] = useState(false);
    const dispatch = useAppDispatch();
    const [initialLoading, setInitialLoading] = useState(false);
    const {reportBankReconcilliations, status, error } = useSelector((state: RootState) => state.reportBankReconcilliation);
    const [totalPages, setTotalPages] = useState(0);
    const [currentReportBankReconcilliation, setCurrentReportBankReconcilliation] = useState<any[]>([]);
    const itemsPerPage = 10;
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [currentPage, setCurrentPage] = useState(1);



    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
            //  dispatch(fetchAllReportBankReconcilliation())
            //     .then(() => setInitialLoading(false))
            //     .catch(() => setInitialLoading(false));
             effectServiceCall.current = true;
        }

        // if (status === 'failed' && effectServiceCall.current) {
        //     toast.error(error);
        //     errorShownRef.current = true;
        // }
    }, [dispatch, status, error]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        if(reportBankReconcilliations!=null && reportBankReconcilliations.length > 0){
            const filteredByReportBankReconcilliation = filterReportBankReconcilliationBySearchQuery();
            const indexOfLastReportBankReconcilliation = currentPage * itemsPerPage;
            const indexOfFirstReportBankReconcilliation = indexOfLastReportBankReconcilliation - itemsPerPage;
            const currentReportBankReconcilliation = filteredByReportBankReconcilliation.slice(indexOfFirstReportBankReconcilliation, indexOfLastReportBankReconcilliation);
            const totalPages = Math.ceil(reportBankReconcilliations.length / itemsPerPage);
            setTotalPages(totalPages)
            setCurrentReportBankReconcilliation(currentReportBankReconcilliation)
        }

    },[reportBankReconcilliations])


    const filterReportBankReconcilliationBySearchQuery = () => {

        return reportBankReconcilliations.filter((reportBankReconcilliation: any) =>    
            reportBankReconcilliation?.accountName?.toLowerCase().includes(searchQuery.toLowerCase()));
    };

    return (
        <>
            <div className="">
                <div className="mb-5">
                    <SubHeaderTitle title="Bank Reconcilliation" />
                </div>
                <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
                    <SearchInput title="Keyword" />
                    <div>
                        <div className="grid grid-cols-4 gap-x-3 md:gap-1 w-full">
                            <CheckBoxFilter title="Client Bank" />
                            <CheckBoxFilter title="Staff" />
                            <CheckBoxFilter title="Year" />
                            <CheckBoxFilter title="Month" />
                        </div>
                    </div>
                </div>
                <div className="relative overflow-x-auto sm:rounded-lg">
                    <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                    {initialLoading ? <MainAnimation /> : ""}
                        <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <th scope="col" className="px-6 py-3">
                                Statement Date
                                </th>
                                <th scope="col" className="px-6 py-3">
                                Bank
                                </th>
                                <th scope="col" className="px-6 py-3">
                                Staff
                                </th>
                                <th scope="col" className="px-6 py-3">
                                Report
                                </th>
                                <th scope="col" className="px-6 py-3">
                                Action	
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        {currentReportBankReconcilliation.map((reportBankReconcilliation: any, index) => (
                        
                        <tr key={index} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <td className="px-6 py-3">
                                {reportBankReconcilliation.statementDate}
                                </td>
                                <td className="px-6 py-3">
                                {reportBankReconcilliation.bank}
                                </td>
                                <td className="px-6 py-3">
                                {reportBankReconcilliation.staff}
                                </td>
                                <td className="cursor-pointer px-6 py-3">
                                {reportBankReconcilliation.report}
                                </td>
                                <td className="cursor-pointer px-6 py-3">
                                    
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    {currentReportBankReconcilliation.length === 0 && (
                            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
                                <p className="text-red-800">No Bank Reconcilliation Report Details found.</p>
                            </div>
                        )}
                </div>
                {currentReportBankReconcilliation.length > 0 && (
                    <div className="w-full flex flex-col pb-[100px]">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={(page) => setCurrentPage(page)}
                        />
                    </div>
                )}
            </div>
        </>
    )
}

export { OfficeBankReconcilliation };