import { useEffect, useRef, useState } from "react";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SearchInput } from "../Filters/SearchInput";
import { AddBankToBank } from "../Modal/AddClientAccounts/AddClientPostings/AddBankToBank";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { fetchAllBankToBank } from "../../store/slice/Accounting/ClientAccounts/ClientPosting/BankToBankSlice";
import { toast } from "react-toastify";
import { FaEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { MdOutlineRefresh } from "react-icons/md";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { Pagination } from "../Pagination";


function BankToBank() {
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const dispatch = useAppDispatch();
    const [initialLoading, setInitialLoading] = useState(true);
    const bankToBankGetData = useSelector((state: RootState) => state.bankToBank.bankToBankGetData);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [bankToBankData, setBankToBankData] = useState<any>([]);
    const [currentBankToBank, setCurrentBankToBank] = useState<any[]>([]);
    const [totalPages, setTotalPages] = useState(0);

    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
            dispatch(fetchAllBankToBank())
                .then(() => setInitialLoading(false))
                .catch(() => setInitialLoading(false));
            effectServiceCall.current = true;
        }
    }, [dispatch]);

    useEffect(() => {
        if (bankToBankGetData && bankToBankGetData.bankToBankResult) {
            setBankToBankData(bankToBankGetData.bankToBankResult);
        }
    }, [bankToBankGetData]);

    const filterBankToBankBySearchQuery = () => {
        return bankToBankData.filter((bankTobank: any) =>
            bankTobank?.individualNames.toLowerCase().includes(searchQuery.toLowerCase())
        );
    };

    useEffect(() => {
        if (bankToBankData != null && bankToBankData.length > 0) {
            const filteredByBankToBank = filterBankToBankBySearchQuery();
            const indexOfLastBankToBank = currentPage * itemsPerPage;
            const indexOfFirstBankToBank = indexOfLastBankToBank - itemsPerPage;
            const currentBankToBanks = filteredByBankToBank.slice(indexOfFirstBankToBank, indexOfLastBankToBank);
            const totalPages = Math.ceil(bankToBankData.length / itemsPerPage);
            setTotalPages(totalPages)
            setCurrentBankToBank(currentBankToBanks)
        }
    }, [bankToBankData])

    const toggleDrawer = () => {
        setOpenModal(!openModal);
    };

    if (openModal) {
        return (
            <AddBankToBank openDrawer={openModal} Close={toggleDrawer} isEdit={isEdit} bankToBank={bankToBankData} />
        )
    }
    return (
        <>
            <div className="">
                <div className="mb-5">
                    <SubHeaderTitle title="Bank To Bank" buttonName="Bank to Bank" isAdd={true} onClickAdd={toggleDrawer} />
                </div>
                <div className="flex flex-row md:flex-col lg:flex-col xl:flex-col justify-between my-5 gap-4 w-full md:gap-3">
                    <div className="md:flex lg:flex xl:flex">
                        <SearchInput title="Keyword" />
                    </div>
                    <div>
                    <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 md:gap-1 w-full">
                                <CheckBoxFilter title="From Bank" />
                                <CheckBoxFilter title="To Bank" />
                        </div>
                    </div>
                </div>
                {/* <div className="flex flex-row md:flex-col justify-between my-5 gap-2 w-full md:gap-3">
                    <SearchInput title="Keyword" />
                    <div>
                        <div className="flex gap-4 md:gap-1 w-full">
                            <CheckBoxFilter title="From Bank" />
                            <div className="w-px h-5 bg-gray-300"></div>
                            <CheckBoxFilter title="To Bank" />
                        </div>
                    </div>
                </div> */}
                <div className="relative overflow-x-auto sm:rounded-lg">
                    {initialLoading ? (<MainAnimation />) : ""}
                    <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                        <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <th scope="col" className="px-6 py-3">
                                    Transaction Ref
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Transaction Date
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Client and Case Details
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    From Bank
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    To Bank
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Amount
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Action
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {currentBankToBank.map((bankToBank: any, index) => (
                                <tr key={index} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                    <td className="px-6 py-3">
                                        {bankToBank.transactionReferenceNumber}
                                    </td>
                                    <td className="px-6 py-3">
                                        {new Date(bankToBank.entryDate).toISOString().split('T')[0]}
                                    </td>
                                    <td className="px-6 py-3">
                                        {bankToBank.clientAndCaseDetails}
                                    </td>
                                    <td className="px-6 py-3">
                                        {bankToBank.fromBank}
                                    </td>
                                    <td className="px-6 py-3">
                                        {bankToBank.toBank}
                                    </td>
                                    <td className="px-6 py-3">
                                        {bankToBank.amount}£
                                    </td>
                                    <td className="cursor-pointer px-6 py-3">
                                        <div className="flex gap-3 w-full">
                                            <FaEye className="fill-gray-500 w-6 h-6" />
                                            <FaEdit className="fill-blue-500 w-6 h-6" />
                                            <RiDeleteBin5Fill className="fill-red-600 w-6 h-6" />
                                            <MdOutlineRefresh className="fill-blue-800 w-6 h-6" />
                                        </div>
                                    </td>

                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {currentBankToBank.length === 0 && (
                        <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
                            <p className="text-red-800">No Bank New Payment found.</p>
                        </div>
                    )}
                </div>
                {currentBankToBank.length > 0 && (
                    <div className="w-full flex flex-col pb-[100px]">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={(page) => setCurrentPage(page)}
                        />
                    </div>
                )}
            </div>

        </>
    )
}

export { BankToBank };