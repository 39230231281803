import { ErrorResult } from "../../../../store/model/errorResult";
import axiosInstance from "../../../axiosInstance";

const GetAllAgedCreditorsReport = async () => {
    try {
        const response = await axiosInstance.get("AgedCreditorsReport");
        return response.data;
    } catch (error: any) {
        let data = error as ErrorResult;
        throw new Error(data.messages.toString());
    }
}




const AgedCreditorsReportService = {
    GetAllAgedCreditorsReport,
   
};


export default AgedCreditorsReportService;
