import React, { useRef, useState } from 'react';
import useOutsideClick from '../../Dropdown';
import { FaCalendarAlt } from "react-icons/fa";

interface JustifyDateFilterProps {
    title: string;
    plannedStartDate: string | null;
    plannedEndDate: string | null;
    onPlannedStartDateChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onPlannedEndDateChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const JustifyDateFilter: React.FC<JustifyDateFilterProps> = ({ title, plannedStartDate, plannedEndDate, onPlannedStartDateChange, onPlannedEndDateChange }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const dropdownRef = useRef<HTMLDivElement>(null);
    useOutsideClick({
        ref: dropdownRef,
        handler: () => setIsOpen(false),
    });

    return (
        <div className={`relative ${isOpen ? 'text-hover_bg' : ''}`} ref={dropdownRef}>
            {/* Dropdown Header */}
            <div
                className="flex cursor-pointer items-center gap-1 pb-1 dark:text-white-bg fill-hover_bg transition"
                onClick={toggleDropdown}
            >
                <FaCalendarAlt className={`${isOpen ? 'fill-hover_bg' : ''}`} />
                <span className="text-sm font-medium ">{title}</span>
                <span className={`transition ${isOpen ? 'rotate-180' : ''}`}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="h-4 w-4"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                    </svg>
                </span>
            </div>

            {/* Dropdown Content */}
            {isOpen && (
                <div className="z-50 p-4 absolute end-0 top-full mt-2 bg-white-bg dark:bg-gray-900 w-fit rounded border border-gray-200 dark:border-opacity-35">
                    <div date-rangepicker className="flex items-center end-0">
                        <div className="relative">
                            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                                </svg>
                            </div>
                            <input
                                name="plannedStartDate"
                                value={plannedStartDate || ''}
                                onChange={onPlannedStartDateChange}
                                type="date"
                                className="bg-gray-50 border border-gray-300 text-gray-400 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Select start date"
                            />
                        </div>
                        <span className="mx-4 text-gray-500">to</span>
                        <div className="relative">
                            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                                </svg>
                            </div>
                            <input
                                name="plannedEndDate"
                                value={plannedEndDate || ''}
                                onChange={onPlannedEndDateChange}
                                type="date"
                                className="bg-gray-50 border border-gray-300 text-gray-400 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Select end date"
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export { JustifyDateFilter };