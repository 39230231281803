import { Heading, SearchAccount, SelectBox } from "../..";
import { useEffect, useState } from "react";
import { boolean, number, object, string } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch } from "../../../store/hooks/redux-hooks";
import { addOfficeSupplier } from "../../../store/slice/Accounting/OfficeAccounts/Suppliers/OfficeSupplierSlice";
import { toast } from "react-toastify";
import { SearchNominalLedger } from "../../Modal/SearchNominalLedger";

const AddSuppliers = ({
  openModal,
  Close,
  isEdit,
  officeSupplier,
}: {
  openModal: boolean;
  Close: any;
  isEdit: boolean;
  officeSupplier: any;
}) => {
  const [title] = useState(!isEdit ? "Add  supplier" : "Edit  Supplier");
  const [openDescriptionModal, setOpenDescriptionModal] = useState(false);
  const [openCaseAccountModal, setOpenCaseAccountModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [setExistingOfficeSupplier] = useState(officeSupplier);
  const dispatch = useAppDispatch();

  // const [openLedgerModal, setOpenLedgerModal] = useState(false);
  // const [selectedNominalLedger, setSelectedNominalLedger] = useState<any>();
  const [openFromBankAccountModal, setOpenFromBankAccountModal] = useState(false);
  const [fromBankAccount, setFromBankAccount] = useState<string>(""); 

  const supplierTitle = [
    { label: "MR.", value: 1 },
    { label: "Miss", value: 2 },
    { label: "MRS", value: 3 },
    { label: "Dr.", value: 4 },
    { label: "Prof", value: 5 },
  ];

  const mappedSupplierTitle = supplierTitle.map((type: any) => ({
    label: type.label,
    value: type.value,
  }));
  

  const validationSchema = object().shape({
    supplierName: string().required("Name is required"),
    service: string(),
    nominalLedger: string(),
    supplierVatNumber: number(),
    supplierUtrn: string(),
    supplierCompanyRegNumber: number(),
    fkContactPersonTitleId: string(),

    suplierTitle: string(),
    contactPersonGivenName: string(),
    contactPersonLastName: string(),
    contactPersonJobTitle: string(),
    contactPersonEmail: string(),
    contactPersonDirectLine: string(),
    contactPersonExtension:string(),
    contactPersonOfficePhone: string(),
    contactPersonMobile: string(),
    supplierWebSite: string(),
    supplierAddressLine1: string(),
    supplierAddressLine2: string(),
    supplierCity: string(),
    supplierCountry: string(),
    supplierPostCode: string(),
    supplierPhone1: number(),
    supplierPhone2: number(),
    supplierFax: string(),

    supplierDxNumber: number(),
    supplierExchange: string(),
    supplierPoBoxNumber: number(),
    supplierPoBoxTown: string(),
    supplierPoBoxPostcode: string(),
    supplierPoBoxCountry: string(),
    supplierIsActive: boolean(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async(data: any) => {
    console.log(JSON.stringify(data, null, 2));
    setIsLoading(true);
    try {
      await dispatch(addOfficeSupplier(data)).unwrap();
      Close();
      toast.success("Successfully Added Supplier");
    } catch (error: any) {
      toast.error(error.toString());
    } finally {
      setIsLoading(false);
    }
    };

    const onError = (errors: any) => {
      console.log("Form submission errors", errors);
  };

    useEffect(() => {
        if (isEdit && officeSupplier) {
          setExistingOfficeSupplier(officeSupplier);
          reset(officeSupplier);
        }
      }, [officeSupplier, isEdit, reset, setValue]);


      const toggleFromBankAccountModal = () => {
        setOpenFromBankAccountModal(!openFromBankAccountModal);
      };

      const handleFromAccountSelect = (selectedAccountType: any,) => {
        setFromBankAccount(selectedAccountType.accountName); // Set the selected account type to display in input field
        console.log(selectedAccountType.accountId);
        setValue('nominalLedger', selectedAccountType.accountId);
    
        // if (selectedAccountType){
        //   setSelectedFromAccountBalance(selectedAccountType.runningBalance);
        //   setValue("fromBankAvailableBalance",selectedAccountType.runningBalance);
        //  }
        // console.log(selectedAccountType.accountId);
      //  console.log(selectedAccountType.runningBalance);
        setOpenFromBankAccountModal(false);
      
      };
 


  const [isDisabled, setIsDisabled] = useState(true);
  return (
    <>
      {openModal !== false && (
        <form onSubmit={handleSubmit(onSubmit,onError)} className="mx-auto my-3" >
        <div className="">
          <div className="flex flex-row md:flex-col justify-between mb-5 gap-2 w-full md:gap-3">
            <div className="relative flex">
              <input
                type="text"
                placeholder=""
                aria-label="Search"
                value={fromBankAccount}
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                  errors.nominalLedger ? "is-invalid" : ""
                }`}
                {...register("nominalLedger")}

                //  disabled
              />
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.nominalLedger?.message?.toString()}
              </div>
              <button
                onClick={toggleFromBankAccountModal}
                className="z-[2] inline-block rounded-e border-2 border-primary px-6 pb-[6px] pt-2 text-xs font-medium  leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-accent-300 hover:bg-primary-50/50 hover:text-primary-accent-300 focus:border-primary-600 focus:bg-primary-50/50 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:text-primary-500 bg-hover_bg text-white-bg border-hover_bg -ml-1"
                data-twe-ripple-init
                data-twe-ripple-color="white"
                type="button"
              >
                Search
              </button>
              {openFromBankAccountModal && (
                <SearchAccount
                openDrawer={openFromBankAccountModal}
                Close={toggleFromBankAccountModal}
                onAccountSelect={handleFromAccountSelect} // Pass the callback function to child
              />
            )}
              
            </div>
            <div>
              <button
                type="button"
                onClick={() => {
                  Close();
                }}
                className="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-2 rounded"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="grid grid-cols-4 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 gap-y-1 w-full">
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                {...register("supplierName")}
                placeholder="Enter Name"
              />
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                VAT Reg Number
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                {...register("supplierVatNumber")}
                placeholder="Enter VAT Reg Number"
              />
            </div>

            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                UTRN
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                {...register("supplierUtrn")}
                placeholder="Enter UTRN"
              />
            </div>

            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Company Reg Number
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                {...register("supplierCompanyRegNumber")}
                placeholder="Enter Company Reg Number"
              />
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Service(s) / product(s) provides
              </label>
              <textarea
                className={`bg-gray-100 border-2 border-gray-300 border-solid gap-2 h-[88px] px-4 py-2.5 rounded-md placeholder:text-blue_gray-500 text-blue_gray-500 text-left text-xs w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                placeholder="Service(s) / product(s) provides"
                {...register("service")}
              ></textarea>
            </div>
          </div>

          <div className="mt-5">
            <Heading title={"Supplier"} />
          </div>
          <div className="grid grid-cols-4 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 gap-y-1 w-full">
            <div className="">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Title
              </label>
              <SelectBox
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md  block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholderClassName="text-blue_gray-500 text-txtInterMedium14"
                indicator={
                  <svg
                    width="15"
                    className="fill-current mr-2"
                    height="15"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                  </svg>
                }
                isMulti={false}
                name="frameFiftySix"
                isSearchable={true}
                options={mappedSupplierTitle}
                value={getValues().suplierTitle}
                placeholder="Select"
                shape="round"
                color="indigo_50"
                size="xs"
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setValue("suplierTitle", selectedOption);
                  }
                }}
              />
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Given Name
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                {...register("contactPersonGivenName")}
                placeholder="Enter Given Name"
              />
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Last Name
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                {...register("contactPersonLastName")}
                placeholder="Enter Last Name"
              />
            </div>

            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Job Title
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                {...register("contactPersonJobTitle")}
                placeholder="Enter Job Title"
              />
            </div>

            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Email
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                {...register("contactPersonEmail")}
                placeholder="Enter Email"
              />
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Direct Line
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                {...register("contactPersonDirectLine")}
                placeholder="Enter Direct Line"
              />
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Extension
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
                  errors.contactPersonExtension ? "is-invalid" : ""
                }`}
                {...register("contactPersonExtension")}
                placeholder="Enter Extension"
              />
              <div className="font-medium mt-2 text-xs text-red-600">
                {errors?.contactPersonExtension?.message?.toString()}
              </div>
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Office Phone
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                {...register("contactPersonOfficePhone")}
                placeholder="Enter Entry Date"
              />
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Mobile
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                {...register("contactPersonMobile")}
                placeholder="Enter Mobile"
              />
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Website
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                {...register("supplierWebSite")}
                placeholder="Enter Website"
              />
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Address Line 1
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                {...register("supplierAddressLine1")}
                placeholder="Enter Address Line 1"
              />
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Address Line 2
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                {...register("supplierAddressLine2")}
                placeholder="Enter Address Line 2"
              />
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                City
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                {...register("supplierCity")}
                placeholder="Enter City"
              />
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Country
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                {...register("supplierCountry")}
                placeholder="Enter Country"
              />
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Post Code
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                {...register("supplierPostCode")}
                placeholder="Enter Post Code"
              />
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Phone 1
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                {...register("supplierPhone1")}
                placeholder="Enter Phone 1"
              />
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Phone 2
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                {...register("supplierPhone2")}
                placeholder="Enter Phone 2"
              />
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                FAX
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                {...register("supplierFax")}
                placeholder="Enter FAX"
              />
            </div>
          </div>
          <div className="mt-5">
            <Heading title={"DX Address"} />
          </div>
          <div className="grid grid-cols-4 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 gap-y-1 w-full">
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Number
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                {...register("supplierDxNumber")}
                placeholder="Enter Number"
              />
            </div>

            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Exchange
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                {...register("supplierExchange")}
                placeholder="Enter Exchange"
              />
            </div>
          </div>
          <div className="mt-5">
            <Heading title={"P.O Box Address"} />
          </div>
          <div className="grid grid-cols-4 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 gap-y-1 w-full">
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Number
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                {...register("supplierPoBoxNumber")}
                placeholder="Enter Number"
              />
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Town
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                {...register("supplierPoBoxTown")}
                placeholder="Enter Town"
              />
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Country
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                {...register("supplierPoBoxCountry")}
                placeholder="Enter Country"
              />
            </div>
            <div className="relative">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
                Post Code
              </label>
              <input
                type="text"
                className={`border-2 border-gray-300 border-solid bg-gray-100 text-xs rounded-md block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 `}
                {...register("supplierPoBoxPostcode")}
                placeholder="Enter Post Code"
              />
            </div>
            <div className="flex space-x-5 mt-5">
              <label className="mb-2 text-sm font-medium text-gray-900 dark:text-white-bg">
              Is Active
              </label>
              <div>
                <input
                  id="bordered-radio-1"
                  type="radio"
                  value=""
                  name="bordered-radio"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label className="w-full py-3 ms-2 text-gray-900 dark:text-gray-300 mb-2 text-sm font-medium">
                  Yes
                </label>
              </div>
              <div>
                <input
                  id="bordered-radio-1"
                  type="radio"
                  value=""
                  name="bordered-radio"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label className="w-full py-3 ms-2 text-gray-900 dark:text-gray-300 mb-2 text-sm font-medium">
                  No
                </label>
              </div>
            </div>
          </div>
          <div className="flex md:flex-col gap-2 flex-row justify-start w-full my-5">
            <div className="flex flex-row gap-2 items-end justify-end w-full">
              <button
                type="button"
                onClick={() => {
                  Close();
                }}
                className="cursor-pointer h-10 rounded-lg text-center hover:bg-gray-50 border dark:border-red-500 dark:bg-red-500 dark:hover:bg-red-600 dark:text-white-bg text-sm w-[120px]"
              >
                Cancel
              </button>
              <button
                type="submit"
                //onClick={handleSaveClick}
                disabled={isLoading}
                className={` cursor - pointer bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer h-10 rounded-lg text-center border text-sm w-[120px]  ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
                    {isLoading ? (
                            <svg className="animate-spin h-5 w-5 text-white-bg mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                            </svg>
                        ) : (
                            'Save'
                        )}
              </button>
            </div>
          </div>
        </div>
        </form>
      )}
    </>
  );
};

export { AddSuppliers };
