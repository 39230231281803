import { useEffect, useRef, useState } from "react";
import { CheckBoxFilter } from "../Filters/CheckBoxFilter";
import { SubHeaderTitle } from "../SubHeaderTitle";
import { IoIosPrint } from "react-icons/io";
import { BsFileEarmarkExcelFill } from "react-icons/bs";
import { toast } from "react-toastify";
import { fetchAllAgedDebtorsReport } from "../../store/slice/Accounting/OfficeAccounts/OfficeReports/AgedDebtorsReportSlice";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store/hooks/redux-hooks";
import { RootState } from "../../store/store";
import { MainAnimation } from "../loadingAnimation/MainAnimation";
import { Pagination } from "flowbite-react";
function AgedDebtorsReport() {
    const [isEdit, setIsEdit] = useState(false);
    const [agedDebtorsReportData, setAgedDebtorsReportData] = useState<any>([]);
    const dispatch = useAppDispatch();
    const [initialLoading, setInitialLoading] = useState(true);
    const { agedDebtorsReports, status, error } = useSelector((state: RootState) => state.agedDebtorsReport);
    const [totalPages, setTotalPages] = useState(0);
    const [currentAgedDebtorsReport, setCurrentAgedDebtorsReport] = useState<any[]>([]);
    const itemsPerPage = 10;
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [currentPage, setCurrentPage] = useState(1);



    const effectServiceCall = useRef(false);
    const errorShownRef = useRef(false);
    useEffect(() => {
        if (!effectServiceCall.current) {
             dispatch(fetchAllAgedDebtorsReport())
                .then(() => setInitialLoading(false))
                .catch(() => setInitialLoading(false));
            effectServiceCall.current = true;
        }

        if (status === 'failed' && !errorShownRef.current) {
            toast.error(error);
            errorShownRef.current = true;
        }
    }, [dispatch, status, error]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        if(agedDebtorsReports!=null && agedDebtorsReports.length > 0){
            const filteredByAgedDebtorsReport = filterAgedDebtorsReportBySearchQuery();
            const indexOfLastAgedDebtorsReport = currentPage * itemsPerPage;
            const indexOfFirstAgedDebtorsReport = indexOfLastAgedDebtorsReport - itemsPerPage;
            const currentAgedDebtorsReport = filteredByAgedDebtorsReport.slice(indexOfFirstAgedDebtorsReport, indexOfLastAgedDebtorsReport);
            const totalPages = Math.ceil(agedDebtorsReports.length / itemsPerPage);
            setTotalPages(totalPages)
            setCurrentAgedDebtorsReport(currentAgedDebtorsReport)
        }

    },[agedDebtorsReports])


    const filterAgedDebtorsReportBySearchQuery = () => {

        return agedDebtorsReports.filter((agedDebtorsReport: any) =>    
            agedDebtorsReport?.accountName?.toLowerCase().includes(searchQuery.toLowerCase()));
    };

    return (
        <>
            <div className="">
                <div className="mb-5">
                    <SubHeaderTitle title="Aged Debtors Report" />
                </div>
                <div>
                        <div className="flex flex-col gap-x-4 gap-y-2 md:gap-1 w-full">
                            <div className="flex border-b-2 pb-1.5">
                                <CheckBoxFilter title="As Date" />
                                <div className="w-px h-5 mx-2 bg-gray-300"></div>
                                <CheckBoxFilter title="Area of Practice" />
                                <div className="w-px h-5 mx-2 bg-gray-300"></div>
                                <CheckBoxFilter title="Case Type" />
                                <div className="w-px h-5 mx-2 bg-gray-300"></div>
                                <CheckBoxFilter title="Case Sub Type" />
                                <div className="w-px h-5 mx-2 bg-gray-300"></div>
                                <CheckBoxFilter title="Period" />
                                <div className="w-px h-5 mx-2 bg-gray-300"></div>
                                <CheckBoxFilter title="Person Responsible" />
                            </div>
                            <div className="flex">
                                <CheckBoxFilter title="Person Acting" />
                            </div>
                        </div>
                    </div>
                    <div className="flex gap-x-2 ietms-center justify-end">
            <button
              type="submit"
              className="flex items-center gap-x-2 bg-blue-500 hover:bg-blue-800 text-white-bg cursor-pointer px-5 py-2 rounded text-center text-sm"
            >
              <IoIosPrint className="w-5 h-5" />
              Print
            </button>
            <button
              type="submit"
              className="flex items-center gap-x-2 bg-green-700 dark:border-green-700 hover:bg-green-800 text-white-bg cursor-pointer px-3 py-2 rounded text-center border text-sm"
            >
              <BsFileEarmarkExcelFill className="w-5 h-5" />
              Export to Excel
            </button>
          </div>
                    <div className="space-y-2">
                        <div className="flex items-center">
                            <label className="w-1/5 text-sm font-semibold dark:text-white-bg">As Date</label>
                            <span className="mx-2 text-sm font-semibold dark:text-white-bg">:</span>
                            <p className="w-4/5 text-sm dark:text-white-bg">06-09-2024</p>
                        </div>
                        <div className="flex items-center">
                            <label className="w-1/5 text-sm font-semibold dark:text-white-bg">Person Responsible</label>
                            <span className="mx-2 text-sm font-semibold dark:text-white-bg">:</span>
                            <p className="w-4/5 text-sm dark:text-white-bg">All</p>
                        </div>
                        <div className="flex items-center">
                            <label className="w-1/5 text-sm font-semibold dark:text-white-bg">Person Acting</label>
                            <span className="mx-2 text-sm font-semibold dark:text-white-bg">:</span>
                            <p className="w-4/5 text-sm dark:text-white-bg">All</p>
                        </div>
                        <div className="flex items-center">
                            <label className="w-1/5 text-sm font-semibold dark:text-white-bg">Area of Practice</label>
                            <span className="mx-2 text-sm font-semibold dark:text-white-bg">:</span>
                            <p className="w-4/5 text-sm dark:text-white-bg">All</p>
                        </div>
                        <div className="flex items-center">
                            <label className="w-1/5 text-sm font-semibold dark:text-white-bg">Case Type</label>
                            <span className="mx-2 text-sm font-semibold dark:text-white-bg">:</span>
                            <p className="w-4/5 text-sm dark:text-white-bg">All</p>
                        </div>
                        <div className="flex items-center">
                            <label className="w-1/5 text-sm font-semibold dark:text-white-bg">Case Sub Type</label>
                            <span className="mx-2 text-sm font-semibold dark:text-white-bg">:</span>
                            <p className="w-4/5 text-sm dark:text-white-bg">N/A</p>
                        </div>
                        <div className="flex items-center">
                            <label className="w-1/5 text-sm font-semibold dark:text-white-bg">Period</label>
                            <span className="mx-2 text-sm font-semibold dark:text-white-bg">:</span>
                            <p className="w-4/5 text-sm dark:text-white-bg">N/A</p>
                        </div>
                    </div>
                    <div className="relative overflow-x-auto sm:rounded-lg my-5">
                    <table className="w-full text-xs text-left rtl:text-right dark:text-gray-400">
                    {initialLoading ? <MainAnimation /> : ""}
                        <thead className="text-sm bg-gray-50 dark:bg-gray-900 dark:text-gray-400">
                            <tr className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <th scope="col" className="px-6 py-3">
                                Case Reference
                                </th>
                                <th scope="col" className="px-6 py-3">
                                Client / Case Details
                                </th>
                                <th scope="col" className="px-6 py-3">
                                Invoice
                                </th>
                                <th scope="col" className="text-right px-6 py-3">
                                Due Amount	
                                </th>
                                <th scope="col" className="px-6 py-3">
                                Due Days
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="border border-gray-300 px-6 py-3 text-center font-semibold col-span-5" colSpan={5}>1-30 Days</td>
                        </tr>
                        <tr>
                            <td className="border border-gray-300 px-6 py-3 text-center font-semibold col-span-5" colSpan={5}>31-60 Days</td>
                        </tr>
                        <tr>
                            <td className="border border-gray-300 px-6 py-3 text-center font-semibold col-span-5" colSpan={5}>61-90 Days</td>
                        </tr>
                        <tr>
                            <td className="border border-gray-300 px-6 py-3 text-center font-semibold col-span-5" colSpan={5}>90 Days</td>
                        </tr>
                        {currentAgedDebtorsReport.map((agedDebtorsReport: any, index) => (
                        
                        <tr key={index} className="border-2 dark:border dark:border-gray-400 dark:border-opacity-35">
                                <td className="px-6 py-3">
                                    {agedDebtorsReport.caseReference}
                                </td>
                                <td className="px-6 py-3">
                                    {/* <p className="font-semibold">agedDebtorsReport</p>
                                    <p>Windmill Services Station Ltd</p>
                                    <p>Immigration {">"} General</p> */}
                                </td>
                                <td className="px-6 py-3">
                                    {/* <p className="font-semibold">Inv_32</p>
                                    <p>£ 1,000.00</p> */}
                                </td>
                                <td className="text-right px-6 py-3">
                                   {agedDebtorsReport.dueAmount}
                                </td>
                                <td className="px-6 py-3">
                                    {agedDebtorsReport.dueDays}
                                </td>
                            </tr>
                        ))}
                           
                            
                        </tbody>

                    </table>
                    {currentAgedDebtorsReport.length === 0 && (
                            <div className="bg-white border border-red-200 flex flex-col h-auto items-center justify-center mt-1 p-4 w-full">
                                <p className="text-red-800">No Aged Debtors Report Details found.</p>
                            </div>
                        )}
                </div>
                {currentAgedDebtorsReport.length > 0 && (
                    <div className="w-full flex flex-col pb-[100px]">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={(page) => setCurrentPage(page)}
                        />
                    </div>
                )}
                </div>
            
        </>
    )
}

export { AgedDebtorsReport };