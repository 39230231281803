import React, { useRef, useState } from "react";
import useOutsideClick from "../../Dropdown";
import { IoIosArrowDown } from "react-icons/io";
import { FaSearch } from "react-icons/fa";

interface CheckBoxFilterProps {
  title: string;
  data?: any[];
}

const CheckBoxFilter: React.FC<CheckBoxFilterProps> = ({ title, data }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  // console.log(data);

  const dropdownRef = useRef<HTMLDivElement>(null);
  useOutsideClick({
    ref: dropdownRef,
    handler: () => setIsOpen(false),
  });

  return (
    <div
      className={`relative ${
        isOpen
          ? "text-hover_bg bg-gray-100 dark:bg-gray-900 rounded"
          : "bg-gray-100 dark:bg-gray-900 rounded"
      }`}
      ref={dropdownRef}
    >
      {/* Dropdown Header */}
      <div
        className="flex cursor-pointer items-center gap-1 p-1.5 dark:text-gray-300 fill-hover_bg transition border dark:border dark:border-gray-400 dark:border-opacity-35 rounded"
        onClick={toggleDropdown}
      >
        <span className="text-sm font-medium w-32 lg:w-44 xl:w-48">
          {title}
        </span>
        {/* <span className="bg-gray-200 py-1 md:py-0.5 px-2.5 md:px-2 rounded-md text-xs font-medium dark:text-black">1</span> */}
        <span className={`transition ${isOpen ? "rotate-180" : ""}`}>
          <IoIosArrowDown className="h-4 w-4" />
        </span>
      </div>

      {/* Dropdown Content */}
      {isOpen && (
        <div className="z-50 absolute end-0 top-full mt-2 bg-gray-100 dark:bg-gray-900 w-72 rounded border border-gray-200 dark:border-opacity-35">
          <header className="flex items-center justify-between p-3">
            <span className="text-sm font-medium text-gray-700 dark:text-gray-400">
              0 Selected
            </span>
            <button
              type="button"
              className="text-sm font-medium text-gray-700 dark:text-gray-400 underline underline-offset-4"
            >
              Reset
            </button>
          </header>
          <div className="flex items-center justify-between p-3">
            <div className="relative">
              <input
                type="text"
                placeholder="Search"
                className="w-full px-10 py-2 border border-gray-300 rounded-md bg-white text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white-bg dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 dark:text-gray-400" />
            </div>
          </div>
          <ul className="space-y-1 border-t dark:border-t-gray-400 border-t-gray-200 p-4 dark:bg-gray-900 dark:border-opacity-35">
            <li>
              <label className="inline-flex items-center gap-2">
                <input
                  type="checkbox"
                  id="FilterInStock"
                  className="size-5 rounded border-gray-300 dark:bg-gray-900"
                />
                <span className="text-sm font-medium text-gray-700 dark:text-gray-400">
                  Critical
                </span>
              </label>
            </li>
            <li>
              <label className="inline-flex items-center gap-2">
                <input
                  type="checkbox"
                  id="FilterPreOrder"
                  className="size-5 rounded border-gray-300 dark:bg-gray-900"
                />
                <span className="text-sm font-medium text-gray-700 dark:text-gray-400">
                  High
                </span>
              </label>
            </li>
            <li>
              <label className="inline-flex items-center gap-2">
                <input
                  type="checkbox"
                  id="FilterPreOrder"
                  className="size-5 rounded border-gray-300 dark:bg-gray-900"
                />
                <span className="text-sm font-medium text-gray-700 dark:text-gray-400">
                  Normal
                </span>
              </label>
            </li>
            <li>
              <label className="inline-flex items-center gap-2">
                <input
                  type="checkbox"
                  id="FilterPreOrder"
                  className="size-5 rounded border-gray-300 dark:bg-gray-900"
                />
                <span className="text-sm font-medium text-gray-700 dark:text-gray-400">
                  Low
                </span>
              </label>
            </li>
            <li>
              <label className="inline-flex items-center gap-2">
                <input
                  type="checkbox"
                  id="FilterPreOrder"
                  className="size-5 rounded border-gray-300 dark:bg-gray-900"
                />
                <span className="text-sm font-medium text-gray-700 dark:text-gray-400">
                  Not Applicable
                </span>
              </label>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export { CheckBoxFilter };
